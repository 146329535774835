import {AfterViewInit, Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {map, Observable, of} from "rxjs";
import {Hospital, Hsubtype} from "../../../../generated-model/model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TypeService} from "../../services/type.service";
import {filter} from "rxjs/operators";

@Component({
    selector: 'gt-hsubtype-autocomplete',
    templateUrl: './gt-hsubtype-autocomplete.component.html',
    styleUrls: ['./gt-hsubtype-autocomplete.component.css'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GtHsubtypeAutocompleteComponent),
            multi: true
        }
    ],
})
export class GtHsubtypeAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {
    hsubtype$: Observable<Hsubtype[]> = of(null)
    private _value: Hsubtype;
    @Input('id') id: string = 'hsubtype';
    @Input('name') name;
    @Input() readonly: boolean = false;
    @Input() htypeId: string;
    @Input() restrictHsubtype: Hsubtype
    @Input() clearable = true
    @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) autocomplete: NgSelectComponent;
    @ViewChild(NgControl) innerNgControl: NgControl;
    @Input("standalone") standalone: boolean;
    @Input() removeValueFromInput: boolean = false
    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
    @Input() hsubTypeFilter: Hsubtype[];

    private onTouchedCallback: () => void = () => {
    };
    private onChangeCallback: (_: Hsubtype) => void = () => {
    };
    compareWithId = (o1: Hsubtype, o2: Hsubtype): boolean => {
        return (o1 == o2) || ((!!o1 && !!o2) && (o1.hsubtypeId == o2.hsubtypeId))
    };

    constructor(public typeService: TypeService, private inj: Injector) {
    }

    ngAfterViewInit() {
        //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
        // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
        const outerControl = this.inj.get(NgControl).control;
        const prevMarkAsTouched = outerControl.markAsTouched;
        outerControl.markAsTouched = (...args: any) => {
            this.innerNgControl.control.markAsTouched();
            prevMarkAsTouched.bind(outerControl)(...args);
        };
        const prevMarkAsDirty = outerControl.markAsDirty;
        outerControl.markAsDirty = (...args: any) => {
            this.innerNgControl.control.markAsDirty();
            prevMarkAsDirty.bind(outerControl)(...args);
        };
        const prevMarkAsPristine = outerControl.markAsPristine;
        outerControl.markAsPristine = (...args: any) => {
            this.innerNgControl.control.markAsPristine();
            prevMarkAsPristine.bind(outerControl)(...args);
        };
        const prevMarkAsUntouched = outerControl.markAsUntouched;
        outerControl.markAsUntouched = (...args: any) => {
            this.innerNgControl.control.markAsUntouched();
            prevMarkAsUntouched.bind(outerControl)(...args);
        };
    }

    ngOnInit(): void {
    }

    get value(): Hsubtype {
        return this._value;
    }

    set value(value: Hsubtype) {
        this.autocomplete.searchTerm = undefined
        if (value !== this._value) {
            this._value = value;
            this.onChangeCallback(value);
        }
        this.clearInput()
    }

    writeValue(value: Hsubtype) {
        this.autocomplete.searchTerm = undefined
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    clear() {
        this.autocomplete.handleClearClick();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(changes)
        const hsubtypeChange = changes['restrictHsubtype']
        if (hsubtypeChange?.currentValue) {
            this.hsubtype$ = of([hsubtypeChange.currentValue])
        } else if (this.htypeId && this.hsubTypeFilter?.length >= 1) {
            this.hsubtype$ = this.typeService.getHsubtype(hsubtypeChange?.currentValue, this.htypeId)
                .pipe(map(e => [...e].filter(ee => !this.hsubTypeFilter.map(ee => ee.hsubtypeId).includes(ee.hsubtypeId))));
        } else if (this.htypeId) {
            this.hsubtype$ = this.typeService.getHsubtype(hsubtypeChange?.currentValue, this.htypeId)
        }
    }

    clearInput() {
        if (this.removeValueFromInput) {
            this.ngSelectComponent?.handleClearClick();
        }
    }
}
