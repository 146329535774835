import {AfterViewInit, ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {NgForm} from "@angular/forms";
import {LoadingService} from "../../../../shared/commons/loader/loading.service";
import {SapPageDataDto, SapStaffDto} from "../../../../../generated-model/model";

@Component({
  selector: 'app-sap-patcher-modal',
  templateUrl: './sap-patcher-modal.component.html',
  // styleUrls: ['./method-modal.component.scss']
  styles: [
    `
      .custom-modal-content .modal-content {
        overflow: auto;
      }

      .custom-modal-content .modal-content .modal-body {
        //background-color: aliceblue;
      }
    `
  ],
})
export class SapPatcherModalComponent implements OnInit, AfterViewInit {

  @ViewChild('form', {static: true}) public form: NgForm;
  data: SapStaffDto = {};
  @Input() sapData: SapPageDataDto;

  constructor(private cdr: ChangeDetectorRef,
              protected loadingService: LoadingService,
              public activeModal: NgbActiveModal,
              public modalService: NgbModal) {
  }

  ngOnInit(): void {
    this.data.taxId = this.sapData.applicant.organization.id;
    this.data.vendorName = this.sapData.hname;
  }

  submit() {
    this.activeModal.close(this.data);
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
