import {Component} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ConfirmDialogService} from "../../../commons/dialog/confirm-dialog/confirm-dialog.service";
import {filter} from "rxjs/operators";
import {MasterDataService} from "../../../../pages/officer/master-data/master-data.service";
import {ToastService} from "../../../commons/toast/toast-service";
import {FileConfigItem} from "../../../../../generated-model/model";

@Component({
  selector: 'app-file-config-item-modal',
  templateUrl: './file-config-item-modal.component.html',
  styleUrls: ['./file-config-item-modal.component.scss']
})
export class FileConfigItemModalComponent {
  prefix = ''
  fileConfigItem: FileConfigItem = {}

  constructor(public activeModal: NgbActiveModal,
              private confirmDialogService: ConfirmDialogService,
              private masterDataService: MasterDataService,
              private toastService: ToastService) {

  }

  save() {
    this.confirmDialogService.open({content: 'ยืนยันการบันทึกข้อมูล'})
      .pipe(filter(e => !!e))
      .subscribe({
        next: e => {
          this.masterDataService.saveConfigItem(this.fileConfigItem).subscribe({
            next: e => {
              this.activeModal.close(e)
              this.toastService.showSuccess(this.prefix + 'ข้อมูลสำเร็จ')
            }
          })
        }
      });
  }

}
