<div class="d-flex align-items-center justify-content-center vh-100">
  <div class="text-center">
    <h1 class="display-1 fw-bold">403</h1>
    <p class="fs-3">Forbidden</p>
    <p class="lead">
      ไม่มีสิทธิ์เข้าถึงข้อมูล
    </p>
    <button class="btn btn-light border" (click)="redirect()">
      กลับสู่หน้าหลัก
    </button>
  </div>
</div>