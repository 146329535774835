import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {ContractSearchDto, Page, RegistrationDto, SapBulkChangeResponse, SapCheckDto, SapStaffDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SapService {

  constructor(private http: HttpClient) {
  }

  search(param: IgnoreNullHttpParams) {
    return this.http.get<Page<ContractSearchDto>>(`${environment.serverUrl}/api/sap/search`, {params: param.toHttpParam()});
  }

  check(item: RegistrationDto) {
    return this.http.get<SapCheckDto>(`${environment.serverUrl}/api/sap/${item.id}/check`);
  }

  create(item: RegistrationDto) {
    return this.http.post(`${environment.serverUrl}/api/sap/staff/${item.id}/resend/create`, item);
  }

  change(item: RegistrationDto) {
    return this.http.post(`${environment.serverUrl}/api/sap/${item.id}/change`, item);
  }

  staffCreate(item: SapStaffDto) {
    return this.http.post(`${environment.serverUrl}/api/sap/staff/${item.registrationId}/create`, item);
  }

  staffChange(item: SapStaffDto) {
    return this.http.post(`${environment.serverUrl}/api/sap/staff/${item.registrationId}/change`, item);
  }

  bulkChange(vendorIdList: string[]): Observable<SapBulkChangeResponse> {
    return this.http.post(`${environment.serverUrl}/api/sap/staff/bulk-change`, {vendorIdList: vendorIdList});
  }
}
