import {Component, inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {SapPageDataDto} from "../../../generated-model/model";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IgnoreNullHttpParams} from "../../shared/commons/Ignore-null-http-params";
import {ToastService} from "../../shared/commons/toast/toast-service";
import {SharedModule} from "../../shared/shared.module";
import {JsonPipe, NgIf} from "@angular/common";
import {environment} from "../../../environments/environment";
import {NgxJsonViewerModule} from "ngx-json-viewer";
import {ConfirmDialogComponent} from "../../shared/commons/dialog/confirm-dialog/confirm-dialog.component";
import {from} from "rxjs";
import {filter} from "rxjs/operators";
import {SapService} from "../officer/sap/sap.service";
import {SapPatcherModalService} from "../officer/sap/sap-patcher-modal/sap-patcher-modal.service";
import {AlertDialogService} from "../../shared/commons/dialog/alert-dialog/alert-dialog.service";

@Component({
  selector: 'app-sap-patcher',
  templateUrl: './sap-patcher.component.html',
  styleUrls: ['./sap-patcher.component.css'],
  imports: [
    SharedModule,
    NgIf,
    JsonPipe,
    NgxJsonViewerModule
  ],
  standalone: true
})
export class SapPatcherComponent implements OnInit {

  data: SapPageDataDto;
  // hcode: string = 'Q2221';
  hcode: string;
  private sapService: SapService = inject(SapService);
  private sapModalService: SapPatcherModalService = inject(SapPatcherModalService);
  private alertService: AlertDialogService = inject(AlertDialogService);
  private toastService: ToastService = inject(ToastService);

  constructor(private http: HttpClient,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
    // this.search();
  }

  search() {
    if (!this.hcode) {
      alert("ไม่ได้ระบุ hcode");
    }
    this.http.get(`${environment.serverUrl}/api/sap-patcher`, {params: IgnoreNullHttpParams.fromObject({hcode: this.hcode}).toHttpParam()})
      .subscribe(value => {
        this.data = value;
      });
  }

  patchCreate(id: number) {
    this.sapService.check({id: id})
      .subscribe(value => {
        console.log('check result = ', value);
        if (value.sapActionType == "BY_STAFF") {
          if (value.sapProcessType == "CREATE") {
            this.sapModalService.open(this.data)
              .pipe(filter(e => !!e))
              .subscribe(sap => {
                sap.registrationId = value.registrationId;
                this.http.post(`${environment.serverUrl}/api/sap-patcher/${id}/create-by-staff`, sap)
                  .subscribe(value => {
                    this.toastService.showSuccess("Create SAP เรียบร้อยแล้ว");
                    this.data = {...value} as SapPageDataDto;
                    this.search();
                  });
              });
          } else {
            this.alertService.open({content: 'ข้อมูลนี้สร้าง vendor แล้วที่ SAP'});
          }
        } else {
          const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
          modalRef.componentInstance.content = `ต้อง Create SAP ใช่หรือไม่?`;
          from(modalRef.result)
            .subscribe(e => {
              if (!!e) {
                this.http.post(`${environment.serverUrl}/api/sap-patcher/${id}/create`, {})
                  .subscribe(value => {
                    this.toastService.showSuccess("Create SAP เรียบร้อยแล้ว");
                    this.data = {...value} as SapPageDataDto;
                    this.search();
                  });
              } else {
                console.log('denied.');
              }
            });
        }
      });
  }

  patchChange(id: number) {
    // this.sapService.check({id: id})
    //   .subscribe(value => {
    //     console.log('check result = ', value);
    //     if (value.sapActionType == "BY_STAFF") {
    //       if (value.sapProcessType == "CHANGE") {
    //         this.sapModalService.open(this.data)
    //           .pipe(filter(e => !!e))
    //           .subscribe(sap => {
    //             sap.registrationId = value.registrationId;
    //             this.http.post(`${environment.serverUrl}/api/sap-patcher/${id}/change-by-staff`, {})
    //               .subscribe(value => {
    //                 this.toastService.showSuccess("Change SAP เรียบร้อยแล้ว");
    //                 this.data = value;
    //                 this.search();
    //               });
    //           });
    //       }
    //     } else {
    //       const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    //       modalRef.componentInstance.content = `ต้อง Change SAP ใช่หรือไม่?`;
    //       from(modalRef.result)
    //         .subscribe(e => {
    //           if (!!e) {
    //             this.http.post(`${environment.serverUrl}/api/sap-patcher/${id}/change`, {})
    //               .subscribe(value => {
    //                 this.toastService.showSuccess("Change SAP เรียบร้อยแล้ว");
    //                 this.data = value;
    //                 this.search();
    //               });
    //           } else {
    //             console.log('denied.');
    //           }
    //         });
    //     }
    //   });
    const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    modalRef.componentInstance.content = `ต้อง Change SAP ใช่หรือไม่?`;
    from(modalRef.result)
      .subscribe(e => {
        if (!!e) {
          this.http.post(`${environment.serverUrl}/api/sap-patcher/${id}/change`, {})
            .subscribe(value => {
              this.toastService.showSuccess("Change SAP เรียบร้อยแล้ว");
              this.data = {...value} as SapPageDataDto;
              this.search();
            });
        } else {
          console.log('denied.');
        }
      });
  }
}
