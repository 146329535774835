import {Directive} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: '[requiredAtLeastOneOpeningHour][ngModelGroup]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: RequiredAtLeastOneOpeningHour,
      multi: true
    }
  ],
})
export class RequiredAtLeastOneOpeningHour implements Validator {

  constructor() {
  }

  validate(group: AbstractControl): ValidationErrors | null {
    let currentIndex = undefined;
    let list: { [id: string]: any } = {};
    let values = Object.keys(group.value);
    console.log('values = ', values);
    for (const k of values) {
      console.log('k ', k)
      if (k.startsWith(currentIndex)) {
        list[currentIndex][k.replace(`${currentIndex}.`, '')] = group.value[k];
      } else {
        currentIndex = k.split('.')?.[0];
        list[currentIndex] = {};
        list[currentIndex][k.replace(`${currentIndex}.`, '')] = group.value[k];
      }
    }
    console.log('list = ', list);
    let errors = {};
    for (let key of Object.keys(list)) {
      if (list[key].timeOpening || list[key].timeClosing) {
        if (!list[key].value) {
          errors['required'] = true
        }
      }
    }
    return !Object.keys(errors).length ? undefined : errors;
  }

}
