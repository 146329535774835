/* tslint:disable */
/* eslint-disable */
// Generated using typescript-generator version 2.29.814 on 2025-04-03 06:40:12.

export interface Page<T> extends Slice<T> {
    totalPages?: number;
    totalElements?: number;
}

export interface Slice<T> extends Streamable<T> {
    size?: number;
    content?: T[];
    number?: number;
    sort?: any;
    first?: boolean;
    pageable?: Pageable;
    numberOfElements?: number;
    last?: boolean;
}

export interface UserRepresentation extends AbstractUserRepresentation {
    self?: string;
    origin?: string;
    createdTimestamp?: number;
    enabled?: boolean;
    /**
     * @deprecated
     */
    totp?: boolean;
    federationLink?: string;
    serviceAccountClientId?: string;
    credentials?: CredentialRepresentation[];
    disableableCredentialTypes?: string[];
    requiredActions?: string[];
    federatedIdentities?: FederatedIdentityRepresentation[];
    realmRoles?: string[];
    clientRoles?: { [index: string]: string[] };
    clientConsents?: UserConsentRepresentation[];
    notBefore?: number;
    /**
     * @deprecated
     */
    applicationRoles?: { [index: string]: string[] };
    socialLinks?: SocialLinkRepresentation[];
    groups?: string[];
    access?: { [index: string]: boolean };
}

export interface PharmacyCouncilRespone {
    ipAddress?: string;
    data?: Data;
    error_code?: number;
    error_message?: string;
    return_message?: string;
    date_time?: string;
}

export interface PhysicalTherapyResponse {
    active?: boolean;
    id_card?: string;
    titleName?: string;
    firstName?: string;
    lastName?: string;
    expire_date?: string;
    remark?: string;
    status_active?: string;
}

export interface Temple {
    id?: string;
    title?: string;
    name?: string;
    dateEffective?: any;
    catm?: Catm;
    address?: string;
    soi?: string;
    road?: string;
    province?: Province;
    postCode?: string;
    tel?: string;
    fax?: string;
    website?: string;
    email?: string;
    used?: string;
    fullName?: string;
    htitle?: Htitle;
    catmCode?: string;
    htype?: Htype;
    hsubtype?: Hsubtype;
    fullAddressWithoutComma?: string;
}

export interface HaCheckResult extends Record {
    hcode?: string;
    active?: boolean;
}

export interface ICommitteeInformation {
    type?: string;
    title?: string;
    lastName?: string;
    firstName?: string;
    sequence?: string;
    cmCapital?: string;
    cmCapitalAmt?: string;
}

export interface IJuristicProfile {
    juristicType?: string;
    juristicTypeName?: string;
    juristicID?: string;
    juristicName?: string;
    juristicStatus?: string;
    registerDate?: string;
    nfName?: string;
}

export interface IJuristicProfileInformation {
    juristicStatus?: string;
    registerDate?: string;
    juristicId?: string;
    numberOfObjective?: string;
    numberOfPageOfObjective?: string;
    standardId?: string;
    juristicType?: string;
    oldJuristicId?: string;
    juristicNameTh?: string;
    juristicNameEn?: string;
    registerCapital?: string;
    paidRegisterCapital?: string;
}

export interface GDXAddressInformation {
    Sequence?: string;
    AddressName?: string;
    FullAddress?: string;
    Building?: string;
    RoomNo?: string;
    Floor?: string;
    VillageName?: string;
    AddressNo?: string;
    Moo?: string;
    Soi?: string;
    Road?: string;
    Tumbol?: string;
    Ampur?: string;
    Province?: string;
    Phone?: string;
    Email?: string;
}

export interface GDXAuthenResponse {
    Result?: string;
}

export interface GDXAuthorizeDescription {
    Sequence?: string;
    AuthorizeDescription?: string;
    Type?: string;
}

export interface GDXCommitteeInformation {
    Sequence?: string;
    FirstName?: string;
    LastName?: string;
    Title?: string;
    Type?: string;
    CMCapital?: string;
    CMCapitalAmt?: string;
}

export interface GDXJuristicProfileInformationFileResponse {
    ID?: string;
    FileName?: string;
    FileSize?: string;
    mimeType?: string;
    Hashes?: string;
    Result?: string;
}

export interface GDXJuristicProfileInformationResponse {
    ResultList?: GDXJuristicProfileInformationResponseResult[];
}

export interface GDXJuristicProfileInformationResponseResult {
    JuristicType?: string;
    JuristicID?: string;
    OldJuristicID?: string;
    RegisterDate?: string;
    JuristicName_TH?: string;
    JuristicName_EN?: string;
    RegisterCapital?: string;
    PaidRegisterCapital?: string;
    NumberOfObjective?: string;
    NumberOfPageOfObjective?: string;
    JuristicStatus?: string;
    StandardID?: string;
    CommitteeInformations?: GDXCommitteeInformation[];
    AuthorizeDescriptions?: GDXAuthorizeDescription[];
    StandardObjectives?: GDXStandardObjective[];
    AddressInformations?: GDXAddressInformation[];
}

export interface GDXJuristicProfileResponse {
    ResultList?: Result[];
}

export interface Result extends JuristicProfile {
    JuristicType?: string;
    JuristicTypeName?: string;
    JuristicID?: string;
    JuristicName?: string;
    JuristicStatus?: string;
    NFName?: string;
}

export interface GDXMedicalOrganization {
    "cd:OrganizationLicenseCode"?: string;
    "cd:OrganizationLicenseCodeStartDate"?: Date;
    "cd:OrgainzationLicenseCodeEndDate"?: Date;
    "cd:OrganizationLicenseReplacementText"?: string;
    "cd:OrganizationType"?: string;
    "cd:OrganizationName"?: string;
    "cd:OrganizationCharacteristicTextTH"?: string;
    "cd:OrganizationBedCapacity"?: string;
    "cd:OrganizationPrincipalOfficial"?: GDXMedicalOrganizationPrincipalOfficial;
    "cd:OrganizationLocation"?: GDXOrganizationLocation;
    "cd:OrganizationWorkTime1"?: string;
    "cd:OrganizationWorkTime2"?: string;
    "cd:MedicalAdditionalServices"?: string;
}

export interface GDXMedicalOrganizationPrincipalOfficial {
    "cd:PersonID"?: string;
    "cd:PersonNameTH"?: string;
}

export interface GDXMedicalOrganizationResponse {
    "cd:MedicalOrganizations"?: GDXMedicalOrganization[];
}

export interface GDXOrganizationAddress {
    "cd:AddressNumber"?: string;
    "cd:AddressMoo"?: string;
    "cd:AddressSoi"?: GDXOrganizationLocationSoi;
    "cd:AddressRoad"?: GDXOrganizationLocationRoad;
}

export interface GDXOrganizationLocation {
    "cd:Address"?: GDXOrganizationAddress;
    "cr:Tambon"?: GDXOrganizationLocationTambon;
    "cr:Amphoe"?: GDXOrganizationLocationAmphoe;
    "cr:Province"?: GDXOrganizationLocationProvince;
    "cd:PostCode"?: string;
}

export interface GDXOrganizationLocationAmphoe {
    "cr:AmphoeTextTH"?: string;
}

export interface GDXOrganizationLocationProvince {
    "cr:ProvinceTextTH"?: string;
}

export interface GDXOrganizationLocationRoad {
    "cr:RoadTextTH"?: string;
}

export interface GDXOrganizationLocationSoi {
    "cr:SoiTextTH"?: string;
}

export interface GDXOrganizationLocationTambon {
    "cr:TambonTextTH"?: string;
}

export interface GDXStandardObjective {
    Sequence?: string;
    ObjectiveDescription?: string;
}

export interface HaRecord {
    _id?: number;
    เขต?: string;
    จังหวัด?: string;
    โรงพยาบาล?: string;
    "H Code"?: string;
    สังกัด?: string;
    ชนิดรพ?: string;
    "ขั้นปัจจุบัน HA"?: string;
    "วันรับรอง HA"?: any;
    "วันหมดอายุ HA"?: any;
    หมายเหตุ?: string;
    rank?: number;
}

export interface PhrDrugstore {
    LOCATION_INFO?: PhrDrugstoreLocationInfo;
    LOCATION_BSN?: PhrDrugstoreLocationBsn[];
}

export interface PhrDrugstoreLocationBsn {
    CTZNO_OPERATOR?: string;
    OPERATOR_NAME?: string;
}

export interface PhrDrugstoreLocationInfo {
    ENTREPRENEUR_IDENTIFY?: string;
    ENTREPRENEUR_NAME?: string;
    LOCATION_LICENSE?: string;
    LOCATION_NAME?: string;
    LOCATION_FULL_ADDR?: string;
    ADDR_NO?: string;
    SUBDISTRICT?: string;
    DISTRICT?: string;
    PROVINCE?: string;
    ZIPCODE?: string;
    TEL?: string;
    LOCATION_JOB_TIME?: string;
    APPROVE_DATE?: any;
    EXPIRE_DATE?: any;
}

export interface PhrDrugstoreResponse {
    ok?: string;
    rows?: string;
    error?: string;
}

export interface TDCAuthorizedResponse {
    message?: string;
    Token?: TDCTokenResponse;
}

export interface TDCTokenResponse {
    token?: string;
}

export interface TDCLicenseResponse {
    message?: string;
    results?: TDCLicenseBodyResponse;
}

export interface TDCLicenseBodyResponse {
    Prefix?: string;
    Fullname?: string;
    Tdc_id?: string;
    Active_status?: boolean;
    startdate_license?: any;
    enddate_license?: any;
    course?: string;
    course_date?: any;
}

export interface TMCError {
    status?: number;
    error?: string;
    messages?: { [index: string]: string };
}

export interface TMCMd5Response {
    name?: string;
    lname?: string;
    codecpe?: string;
    specialized?: TMCMd5Specialized[];
    licensedate?: any;
    active?: boolean;
    prefix?: string;
}

export interface TMCMd5Specialized {
    sname_th?: string;
    sname_en?: string;
    prectition_code?: string;
}

export interface AddressInformation {
    sequence?: string;
    addressName?: string;
    fullAddress?: string;
    building?: string;
    roomNo?: string;
    floor?: string;
    villageName?: string;
    addressNo?: string;
    moo?: string;
    soi?: string;
    road?: string;
    tumbol?: string;
    ampur?: string;
    province?: string;
    phone?: string;
    email?: string;
}

export interface AuthorizeDescription {
    sequence?: string;
    authorizeDescription?: string;
    type?: string;
}

export interface CommitteeInformation extends ICommitteeInformation {
}

export interface Drugstore {
    locationInfo?: DrugstoreLocationInfo;
    locationBsnList?: DrugstoreLocationBsn[];
}

export interface DrugstoreLocationBsn {
    ctznoOperator?: string;
    operatorName?: string;
}

export interface DrugstoreLocationInfo {
    entrepreneurIdentify?: string;
    entrepreneurName?: string;
    locationLicense?: string;
    locationName?: string;
    locationFullAddr?: string;
    addrNo?: string;
    subdistrict?: string;
    province?: string;
    district?: string;
    zipcode?: string;
    tel?: string;
    locationJobTime?: string;
    approveDate?: any;
    expireDate?: any;
}

export interface DrugstoreResult {
    drugstoreList?: Drugstore[];
    drugstoreSet?: Drugstore[];
    rawJson?: string;
}

export interface HospitalLicense {
    organizationLicenseCode?: string;
    organizationLicenseCodeStartDate?: any;
    orgainzationLicenseCodeEndDate?: any;
    organizationLicenseReplacementText?: string;
    organizationType?: string;
    organizationName?: string;
    organizationCharacteristicTextTh?: string;
    organizationBedCapacity?: string;
    organizationPrincipalOfficial?: OrganizationPrincipalOfficial;
    organizationLocation?: OrganizationLocation;
    organizationWorkTime1?: string;
    organizationWorkTime2?: string;
    medicalAdditionalServices?: string;
    fullAddress?: string;
}

export interface OrganizationPrincipalOfficial {
    personId?: string;
    personNameTh?: string;
}

export interface JuristicProfile {
    juristicType?: string;
    juristicTypeName?: string;
    juristicID?: string;
    juristicName?: string;
    juristicStatus?: string;
    registerDate?: string;
    nfName?: string;
}

export interface JuristicProfileInformation {
    juristicType?: string;
    juristicId?: string;
    oldJuristicId?: string;
    registerDate?: string;
    juristicNameTh?: string;
    juristicNameEn?: string;
    registerCapital?: string;
    paidRegisterCapital?: string;
    numberOfObjective?: string;
    numberOfPageOfObjective?: string;
    juristicStatus?: string;
    standardId?: string;
    committeeInformationList?: CommitteeInformation[];
    authorizeDescriptionList?: AuthorizeDescription[];
    standardObjectiveList?: StandardObjective[];
    addressInformationList?: AddressInformation[];
}

export interface JuristicProfileInformationFile {
    id?: string;
    fileName?: string;
    fileSize?: string;
    mimeType?: string;
    hashes?: string;
    result?: string;
}

export interface Md5 {
    name?: string;
    lname?: string;
    codecpe?: string;
    specialized?: Md5Specialized[];
    licenseDate?: any;
    active?: boolean;
    prefix?: string;
}

export interface Md5Specialized {
    snameTh?: string;
    snameEn?: string;
    prectitionCode?: string;
}

export interface OrganizationLocation {
    address?: OrganizationLocationAddress;
    tambon?: OrganizationLocationTambon;
    amphoe?: OrganizationLocationAmphoe;
    province?: OrganizationLocationProvince;
    postCode?: string;
}

export interface OrganizationLocationAddress {
    addressNumber?: string;
    addressMoo?: string;
    addressSoi?: Soi;
    addressRoad?: Road;
}

export interface Road {
    roadTextTh?: string;
}

export interface Soi {
    soiTextTh?: string;
}

export interface OrganizationLocationAmphoe {
    amphoeTextTh?: string;
}

export interface OrganizationLocationProvince {
    provinceTextTh?: string;
}

export interface OrganizationLocationTambon {
    tambonTextTH?: string;
}

export interface StandardObjective {
    sequence?: string;
    objectiveDescription?: string;
}

export interface TDCLicense {
    prefix?: string;
    fullName?: string;
    tdcId?: string;
    activeStatus?: boolean;
    startDateLicense?: any;
    endDateLicense?: any;
    course?: string;
    courseDate?: any;
}

export interface ApiProblem {
    id?: number;
    organizationName?: string;
    citizenId?: string;
    username?: string;
    typeLicense?: string;
    problem?: string;
    dateProblem?: any;
}

export interface AbstractAuditingEntity {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
}

export interface AccountGroup {
    code?: string;
    nr?: string;
    ota?: string;
    name?: string;
    accountGroupHsubtypeList?: AccountGroupHsubtype[];
}

export interface AccountGroupHsubtype {
    id?: AccountGroupHsubtypeId;
    accountGroup?: AccountGroup;
    hsubtype?: Hsubtype;
    ktokk?: string;
}

export interface AccountGroupHsubtypeId {
    groupCode?: string;
    hsubtypeId?: string;
}

export interface AdditionalFileItem extends AbstractAuditingEntity {
    id?: number;
    displayText?: string;
    type?: AddForType;
    file?: FileUpload;
}

export interface Applicant {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    organization?: ApplicantOrganization;
    signatureImage?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
    personal?: boolean;
    company?: boolean;
    temple?: boolean;
    gov?: boolean;
    ga?: boolean;
}

export interface ApplicantOrganization extends AbstractAuditingEntity {
    id?: string;
    orgType?: OrgType;
    name?: string;
    vatId?: string;
    fromType?: FromType;
    authorizeDescriptionList?: OssAuthorizeDescription[];
    authorizedPersonalId?: string;
    authorizedName?: string;
}

export interface Attribute {
    id?: number;
    desc?: string;
}

export interface Bank {
    code?: string;
    name?: string;
    country?: string;
    vendors?: BankVendor[];
}

export interface BankAccount extends AbstractAuditingEntity {
    id?: number;
    organization?: OssOrganization;
    accountNumber?: string;
    accountName?: string;
    accountNameEn?: string;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
    bank?: Bank;
    branch?: string;
    branchCode?: string;
    branchEn?: string;
    bookBank?: FileUpload;
}

export interface CardiacSurgeryStatistic {
    id?: number;
    desc?: string;
    type?: StatType;
}

export interface DashboardResultTypeRenew {
    id?: DashboardResultTypeRenewId;
    zone?: Zone;
    totalAssessment?: number;
    totalPass?: number;
    totalNotPass?: number;
    totalUnknown?: number;
}

export interface DashboardResultTypeRenewId {
    zoneId?: string;
    assessType?: string;
    budgetYear?: number;
}

export interface DashboardStatusRenew {
    id?: DashboardStatusRenewId;
    zone?: Zone;
    totalHospital?: number;
    totalUnSave?: number;
    totalSaving?: number;
    totalSaveComplete?: number;
}

export interface DashboardStatusRenewId {
    zoneId?: string;
    budgetYear?: number;
}

export interface DelegateOfAuthority extends AbstractAuditingEntity {
    id?: number;
    userId?: number;
    user?: OssUser;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    savedAuthorized?: boolean;
    authorizedPersonalId?: string;
    authorizedName?: string;
    organization?: OssOrganization;
    hospital?: Hospital;
    authorizedFile?: FileUpload;
    delegateFile?: FileUpload;
    approvedThaiId?: boolean;
    authorizedApprovedThaiId?: boolean;
    verificationDate?: any;
    authorizedVerificationDate?: any;
    authorizationDate?: any;
    hasExpireDate?: boolean;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    approver?: string;
    authorized?: boolean;
}

export interface District {
    code?: string;
    name?: string;
    province?: Province;
}

export interface EmailConfigMcup extends AbstractAuditingEntity {
    id?: number;
    email?: string;
    name?: string;
    zone?: Zone;
    active?: ActiveStatus;
}

export interface Employee {
    id?: string;
    thName?: string;
    enName?: string;
    bureauId?: string;
    email?: string;
    status?: string;
    username?: string;
    pid?: string;
    positionName?: string;
    positionCode?: string;
    bu3?: string;
    bu3Name?: string;
}

export interface EnrollSp19 extends AbstractAuditingEntity {
    id?: number;
    enrollment?: HealthlinkEnrollment;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    fullAddress?: string;
}

export interface EnrollSp7 extends AbstractAuditingEntity {
    id?: number;
    enrollment?: HealthlinkEnrollment;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    fullAddress?: string;
}

export interface FileConfigHofftype extends AbstractAuditingEntity {
    id?: number;
    hofftype?: Hofftype;
    fileConfigItem?: FileConfigItem;
    active?: ActiveStatus;
    lastUpdateUser?: OssUser;
}

export interface FileConfigHtype extends AbstractAuditingEntity {
    id?: number;
    htype?: Htype;
    fileConfigItem?: FileConfigItem;
    active?: ActiveStatus;
    lastUpdateUser?: OssUser;
}

export interface FileConfigItem extends AbstractAuditingEntity {
    id?: number;
    displayText?: string;
    children?: FileConfigItem[];
    parent?: FileConfigItem;
    active?: ActiveStatus;
    lastUpdateUser?: OssUser;
}

export interface FileConfigType extends AbstractAuditingEntity {
    id?: number;
    type?: Type;
    fileConfigItem?: FileConfigItem;
    lastUpdateUser?: OssUser;
    active?: ActiveStatus;
}

export interface FileUpload extends AbstractAuditingEntity, IFileUpload {
    id?: number;
    note?: string;
    state?: State;
    fileConfigItem?: FileConfigItem;
    upload?: boolean;
}

export interface GuaranteeHospitalType {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    typeParent?: number;
    parent?: GuaranteeHospitalType;
    subTypes?: GuaranteeHospitalType[];
    orderSeq?: number;
    used?: string;
}

export interface HealthLinkDelegate extends AbstractAuditingEntity {
    id?: number;
    userId?: number;
    user?: OssUser;
    personalId?: string;
    name?: string;
    position?: string;
    executivePersonalId?: string;
    executiveName?: string;
    savedAuthorized?: boolean;
    authorizedPersonalId?: string;
    authorizedName?: string;
    organization?: OssOrganization;
    authorizedFile?: FileUpload;
    delegateFile?: FileUpload;
    approvedThaiId?: boolean;
    authorizedApprovedThaiId?: boolean;
    verificationDate?: any;
    authorizedVerificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    approver?: string;
    province?: Province;
    authorized?: boolean;
}

export interface HealthlinkDelegateOfAuthority extends AbstractAuditingEntity {
    id?: number;
    enrollment?: HealthlinkEnrollment;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    organization?: OssOrganization;
    authorizedFile?: FileUpload;
    delegateFile?: FileUpload;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface HealthlinkEnrollment extends AbstractAuditingEntity {
    id?: number;
    uuid?: string;
    sp7?: EnrollSp7;
    sp19?: EnrollSp19;
    sp19CitizenId?: string;
    personaName?: string;
    personPosition?: string;
    enrollDate?: any;
    hospital?: Hospital;
    applicant?: OssUser;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    delegateOfAuthority?: HealthlinkDelegateOfAuthority;
    file?: FileUpload;
}

export interface HospitalOpeningHour extends AbstractAuditingEntity {
    hcode?: string;
    hospital?: Hospital;
    openingItems?: RegOpeningHourItem[];
    totalDay?: number;
    totalHour?: number;
}

export interface IFileUpload {
    size?: number;
    path?: string;
    fileName?: string;
    uuid?: string;
    mime?: string;
    originalFileName?: string;
}

export interface LetterGuaranteePublishedSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: Htitle;
    letterGuaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    province?: Province;
    htype?: Htype;
    hsubtype?: Hsubtype;
    hospital?: Hospital;
    considerDate?: any;
    guaranteeType?: LetterGuaranteeType;
    letterGuaranteeDate?: any;
    approveDate?: any;
    charge?: LetterGuaranteeCharge;
    approveUser?: OssUser;
    types?: Type[];
    applicant?: OssUser;
    lastUpdatedDate?: any;
    correctionReason?: string;
    hid?: number;
    contractType?: RegalContractType;
    regalContract?: RegalContract;
    regLetterGuarantee?: RegLetterGuarantee;
}

export interface LetterGuaranteeSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: Htitle;
    registrationStatus?: RegistrationStatus;
    letterGuaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    province?: Province;
    htype?: Htype;
    hsubtype?: Hsubtype;
    hospital?: Hospital;
    considerDate?: any;
    guaranteeType?: LetterGuaranteeType;
    letterGuaranteeDate?: any;
    approveDate?: any;
    charge?: LetterGuaranteeCharge;
    approveUser?: OssUser;
    types?: Type[];
    fileLetterGuaranteeItem?: RegLetterGuaranteeItem[];
    applicant?: OssUser;
    bankAccount?: BankAccount;
    lastUpdatedDate?: any;
    correctionReason?: string;
    regalContract?: RegalContract;
    hid?: number;
    typeTrans?: TypeTrans[];
}

export interface LgRequestDetail {
    appId?: string;
    requesterTaxId?: string;
    approveDate?: string;
    customerName?: string;
    requesterNameTh?: string;
    beneficiaryNameTh?: string;
    contingentTypeName?: string;
    contractDate?: string;
    lgAmt?: number;
    periodDesc?: string;
    lgEffectiveDate?: string;
    lgExpireDate?: string;
    updateDate?: string;
    contingentNo?: string;
    requestTypeDesc?: string;
    accountStatus?: string;
    hospitalCode?: string;
}

export interface MedicalTechType {
    id?: number;
    name?: string;
}

export interface NonInvasiveAcuteCoronarySyndrome {
    id?: number;
    desc?: string;
}

export interface Officer {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    signatureImage?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
}

export interface OrgOther {
    vendorId?: string;
    vendorName?: string;
    address?: string;
    postCode?: string;
    used?: string;
    catm?: Catm;
    subDistrict?: SubDistrict;
}

export interface OssAddressInformation extends AbstractAuditingEntity {
    id?: number;
    sequence?: string;
    addressName?: string;
    fullAddress?: string;
    building?: string;
    roomNo?: string;
    floor?: string;
    villageName?: string;
    addressNo?: string;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: string;
    district?: string;
    province?: string;
    phone?: string;
    email?: string;
    organization?: OssOrganization;
}

export interface OssAuthorizeDescription extends AbstractAuditingEntity {
    id?: number;
    sequence?: string;
    organization?: OssOrganization;
    description?: string;
    type?: string;
}

export interface OssCommitteeInformation extends AbstractAuditingEntity, ICommitteeInformation {
    id?: number;
    juristicId?: string;
}

export interface OssOrganization extends AbstractAuditingEntity {
    id?: string;
    orgType?: OrgType;
    vendorId?: string;
    juristicType?: string;
    juristicStatus?: string;
    standardId?: string;
    registerDate?: string;
    name?: string;
    nameEn?: string;
    registerCapital?: string;
    authorizeDescriptionList?: OssAuthorizeDescription[];
    addressInformationList?: OssAddressInformation[];
    vatId?: string;
    fromType?: FromType;
    authorizedPersonalId?: string;
    authorizedName?: string;
    htype?: Htype;
}

export interface OssUser {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    source?: Source;
    zoneCode?: string;
    zoneName?: string;
    organization?: OssOrganization;
    dcUserId?: number;
    updateDate?: any;
    signatureImage?: string;
    signatureInfo?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
}

export interface Pci1Statistic {
    id?: number;
    desc?: string;
    type?: StatType;
}

export interface Pci2Statistic {
    id?: number;
    desc?: string;
    type?: StatType;
}

export interface Population {
    populationInArea?: number;
    rightsPopulation?: number;
}

export interface PotentialConsiderHistory extends AbstractAuditingEntity {
    id?: number;
    potentialRequestId?: number;
    status?: string;
    officer?: OssUser;
    activeDate?: any;
}

export interface PotentialImproveAttachmentItem {
    id?: number;
    potentialAttachment?: ServicePotentialAttachment;
    file?: FileUpload;
}

export interface PotentialImproveCaringCabinetItem {
    id?: number;
    cabinetCode?: string;
    cabinetLocation?: string;
    pictureFile?: FileUpload;
}

export interface PotentialImproveDentalCarDetail {
    id?: number;
    carRegistration?: string;
    unitesAmount?: number;
    requestPermissionFile?: FileUpload;
}

export interface PotentialImproveHealthCheckupFileItem {
    id?: number;
    month?: string;
    planFile?: FileUpload;
}

export interface PotentialImproveHealthCheckupFileItemDto {
    id?: number;
    month?: string;
    planFile?: FileUploadDto;
}

export interface PotentialImproveItem {
    id?: number;
    type?: Type;
    typeHospId?: number;
    service?: Service;
    dateIn?: any;
    hasH24Flow?: boolean;
    hasH24Record?: boolean;
    hasH24Standard?: boolean;
    homeCareServices?: boolean;
    schoolPatientServices?: boolean;
    schoolCode?: string;
    caringCabinetServices?: boolean;
    cabinetItems?: PotentialImproveCaringCabinetItem[];
    clinicHealthCheckupServices?: boolean;
    healthCheckupCommunity?: string;
    healthCheckupFileItems?: PotentialImproveHealthCheckupFileItem[];
    roboticSurgery?: boolean;
    surgicalOperationPlanFile?: FileUpload;
    hasH25Immediate?: boolean;
    hasH25LongActing?: boolean;
    hasH25Absorbed?: boolean;
    attachmentItems?: PotentialImproveAttachmentItem[];
    personItems?: PotentialImprovePersonItem[];
    toolItems?: PotentialImproveToolItem[];
    locationItems?: PotentialImproveLocationItem[];
}

export interface PotentialImproveLocationItem {
    id?: number;
    potentialLocation?: ServicePotentialLocation;
    amount?: number;
}

export interface PotentialImprovePersonItem {
    id?: number;
    person?: CppMPerson;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    hourItems?: RegStaffHourItem[];
    totalDay?: number;
    totalHour?: number;
}

export interface PotentialImproveRequest extends PotentialRequest {
    items?: PotentialImproveItem[];
}

export interface PotentialImproveToolItem {
    id?: number;
    potentialTool?: ServicePotentialTool;
    amount?: number;
    applicationNameList?: string[];
    hasMobileLabSp7?: boolean;
    carDetails?: PotentialImproveDentalCarDetail[];
    mobileLabSp7WorkTime?: string;
}

export interface PotentialRequest extends AbstractAuditingEntity {
    id?: number;
    applicant?: OssUser;
    hospital?: Hospital;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    cancelReason?: string;
    dtype?: PotentialFormType;
    officer?: OssUser;
    considerDate?: any;
}

export interface PotentialRequestWithItem extends AbstractAuditingEntity {
    id?: number;
    applicant?: OssUser;
    hospital?: Hospital;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    cancelReason?: string;
    dtype?: PotentialFormType;
    officer?: OssUser;
    considerDate?: any;
    itemDesc?: string[];
}

export interface PotentialResignItem {
    id?: number;
    type?: Type;
    serviceHospId?: number;
    service?: Service;
    resignDate?: any;
    reject?: boolean;
}

export interface PotentialResignRequest extends PotentialRequest {
    items?: PotentialResignItem[];
}

export interface Property {
    id?: number;
    desc?: string;
    option?: PropertyOption;
    type?: PropertyType;
    conditionFlag?: boolean;
    conditionType?: ConditionType;
}

export interface PropertyOther {
    id?: number;
    desc?: string;
    option?: PropertyOption;
    type?: PropertyType;
    conditionFlag?: boolean;
    conditionType?: ConditionType;
    disabledServiceCenterFlag?: boolean;
    foundationFlag?: boolean;
    civilSocietyFlag?: boolean;
    section3Flag?: boolean;
}

export interface PublishNo extends AbstractAuditingEntity {
    id?: number;
    currentNo?: number;
    year?: number;
}

export interface PublishSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: Htitle;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    province?: Province;
    htype?: Htype;
    hsubtype?: Hsubtype;
    bookNumber?: number;
    bookYear?: number;
    fileTypeStatus?: PublishFileTypeStatus;
    types?: Type[];
    file?: FileUpload;
    lastUpdatedDate?: any;
    applicant?: OssUser;
    hospital?: Hospital;
    publishedDate?: any;
    considerDate?: any;
    signDate?: any;
    signUser?: OssUser;
    regLetterGuarantee?: RegLetterGuarantee;
    regalContract?: RegalContract;
    regOpeningHour?: RegOpeningHour;
    delegateOfAuthority?: RegDelegateOfAuthority;
    sp7List?: RegSp7[];
    approvedThaiD?: boolean;
    contractType?: RegalContractType;
    property?: RegProperty;
    budgetYear?: number;
    hid?: number;
    typeTrans?: TypeTrans[];
    verificationDate?: any;
    effectiveDate?: any;
    companyAddress?: string;
}

export interface R01RegStaffConfig {
    id?: number;
    name?: string;
    description?: string;
    staffSize?: RegStaffSizeConfig[];
}

export interface RateLetterGuarantee extends AbstractAuditingEntity {
    id?: number;
    type?: GuaranteeHospitalType;
    effectiveDate?: any;
    companyCharge?: boolean;
    companyAmount?: number;
    companyPopulationRate?: PopulationRate;
    companyExcept?: boolean;
    govCharge?: boolean;
    govAmount?: number;
    govPopulationRate?: PopulationRate;
    govExcept?: boolean;
    active?: ActiveStatus;
    userUpdate?: OssUser;
    hsubtypes?: Hsubtype[];
    newService?: boolean;
    oldService?: boolean;
    rateLetterGuaranteeNo?: string;
    rateLetterGuaranteeDate?: any;
}

export interface RegAssessItem extends AbstractAuditingEntity, IRegAssessItem<boolean> {
    value?: boolean;
    textAnswerExtension?: TextAnswerExtension;
    fileAnswerExtension?: FileAnswerExtension;
    radioAnswerExtension?: RadioAnswerExtension;
    checkboxAnswerExtensionMap?: { [index: string]: CheckboxAnswerExtension };
    hcodeAnswerExtensionList?: HcodeAnswerExtension[];
    nhsoValue?: NhsoValue;
    nhsoAutoAssess?: boolean;
}

export interface RegAssessItemCheckbox extends AbstractAuditingEntity, IRegAssessItem<boolean> {
    value?: boolean;
}

export interface RegAssessItemDate extends AbstractAuditingEntity, IRegAssessItem<any> {
    value?: any;
}

export interface RegAssessType extends AbstractAuditingEntity {
    id?: number;
    assessType?: AssessType;
    questionClassName?: string;
    itemMap?: { [index: string]: RegAssessItem };
    itemCheckboxMap?: { [index: string]: RegAssessItemCheckbox };
    itemDateMap?: { [index: string]: RegAssessItemDate };
    optionalFlag?: boolean;
    nhsoOpinion?: string;
    nhsoSuggestions?: string;
    nhsoConsider?: boolean;
    fileUpload?: FileUpload;
}

export interface RegAssessment extends AbstractAuditingEntity {
    id?: number;
    typeMap?: { [P in AssessType]?: RegAssessType };
    budgetYear?: number;
}

export interface RegAttribute extends AbstractAuditingEntity {
    id?: number;
    itemList?: RegAttributeItem[];
    population?: Population;
    registrationQualification?: RegQualification;
}

export interface RegAttributeItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    attribute?: Attribute;
    pccCheckDate?: any;
    pccCheckRemark?: string;
    pccDate?: any;
    desc?: string;
}

export interface RegBankAccount extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    accountNumber?: string;
    accountName?: string;
    accountNameEn?: string;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
    bank?: Bank;
    branch?: string;
    branchCode?: string;
    branchEn?: string;
    bookBank?: FileUpload;
}

export interface RegCardiacSurgeryCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    numberOfBeds?: number;
    cathLab?: boolean;
    cathLabReferralHospital?: Hospital;
    generalRadiologyDiagnosis24hrs?: boolean;
    prc?: boolean;
    ffp?: boolean;
    plt?: boolean;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    heartTeamConferenceWithPhysicianPerMonth?: number;
    heartTeamConferenceWithPediatricPerMonth?: number;
    morbidityWithSurgicalTeamPerMonth?: number;
}

export interface RegCardiacSurgeryDirector extends AbstractAuditingEntity {
    id?: number;
    iso?: RegISO;
    fullName?: string;
    pid?: string;
    licenseNo?: string;
    specializedList?: RegDirectorSpecialized[];
    totalExperienceOfSurgery?: string;
    numberOfSurgeryInAYear?: string;
}

export interface RegCardiacSurgeryStatistic extends AbstractAuditingEntity {
    id?: number;
    serviceDesc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    service?: CardiacSurgeryStatistic;
    serviceOtherList?: string[];
}

export interface RegConsiderHistory extends AbstractAuditingEntity {
    id?: number;
    registrationId?: number;
    status?: string;
    autoApprove?: boolean;
    considerLevel?: ConsiderLevel;
    officer?: OssUser;
    activeDate?: any;
    considerReason?: string;
    requestType?: RequestType;
}

export interface RegCriteria extends AbstractAuditingEntity {
    id?: number;
    qualification?: RegQualification;
    radiationTherapyCriteriaMap?: { [index: string]: RegRadiationTherapyCriteria };
    cardiacSurgeryCriteriaMap?: { [index: string]: RegCardiacSurgeryCriteria };
    hsctCriteriaMap?: { [index: string]: RegHSCTCriteria };
}

export interface RegDelegateOfAuthority extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    organization?: OssOrganization;
    authorizedFile?: FileUpload;
    delegateFile?: FileUpload;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface RegDepartment {
    checked?: boolean;
    department?: Department;
}

export interface RegDirectorSpecialized {
    prectitionCode?: string;
    snameTh?: string;
    snameEn?: string;
    sdate?: any;
}

export interface RegFile extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    files?: { [index: string]: FileUpload };
    additionalFiles?: AdditionalFileItem[];
}

export interface RegHSCTCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfServices?: number;
    totalNotLessThan?: number;
}

export interface RegISO extends AbstractAuditingEntity {
    id?: number;
    qualification?: RegQualification;
    surgeryDirector?: RegCardiacSurgeryDirector;
}

export interface RegInfo extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    address?: string;
    soi?: string;
    moo?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    tel?: string;
    email?: string;
    lat?: number;
    lon?: number;
    fullAddress?: string;
}

export interface RegLetterGuarantee extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    guaranteeStatus?: LetterGuaranteeStatus;
    guaranteeType?: LetterGuaranteeType;
    fileLetterGuaranteeItem?: RegLetterGuaranteeItem[];
    letterGuaranteeDate?: any;
    letterGuaranteeUser?: OssUser;
    approveDate?: any;
    approveUser?: OssUser;
    charge?: LetterGuaranteeCharge;
    correctionReason?: string;
    directLinkChecked?: boolean;
    directLinkDate?: any;
}

export interface RegLetterGuaranteeCorrection extends AbstractAuditingEntity {
    id?: number;
    registrationId?: number;
    status?: LetterGuaranteeStatus;
    correctionDate?: any;
    correctionUser?: OssUser;
    reason?: string;
}

export interface RegLetterGuaranteeItem extends AbstractAuditingEntity {
    id?: number;
    file?: FileUpload;
    rateLetterGuarantee?: RateLetterGuarantee;
    amount?: number;
    totalAmount?: number;
    letterGuaranteeNo?: string;
    requestDate?: any;
    requestExcept?: boolean;
    fileRequestExcept?: FileUpload;
    fileRegistration?: FileUpload;
    bank?: Bank;
    maxValue?: boolean;
    letterGuaranteeId?: number;
    orders?: number;
    lgRequestDetail?: LgRequestDetail;
    bgExpireDate?: any;
}

export interface RegOpeningHour extends AbstractAuditingEntity {
    id?: number;
    totalDay?: number;
    totalHour?: number;
    displayText?: string;
    registrationQualification?: RegQualification;
    openingHourItems?: RegOpeningHourItem[];
}

export interface RegOpeningHourItem {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface RegPCI1Potential extends AbstractAuditingEntity {
    id?: number;
    potential?: RegPotential;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: RegRadiationProtection };
}

export interface RegPCI1Statistic extends AbstractAuditingEntity {
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci1Statistic?: Pci1Statistic;
    desc?: string;
}

export interface RegPCI2Potential extends AbstractAuditingEntity {
    id?: number;
    potential?: RegPotential;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: RegRadiationProtection };
    referralHospital?: Hospital;
}

export interface RegPCI2Statistic extends AbstractAuditingEntity {
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci2Statistic?: Pci2Statistic;
    desc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    serviceOtherList?: string[];
}

export interface RegPh5 extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUpload;
    fullAddress?: string;
}

export interface RegPotential extends AbstractAuditingEntity {
    id?: number;
    qualification?: RegQualification;
    numberOfBeds?: number;
    numberOfOr?: number;
    numberOfIcu?: number;
    numberOfNicu?: number;
    departmentList?: RegDepartment[];
    surgeryPotential?: RegSurgeryPotential;
    pci1Potential?: RegPCI1Potential;
    pci2Potential?: RegPCI2Potential;
}

export interface RegProperty extends AbstractAuditingEntity {
    id?: number;
    itemList?: RegPropertyItem[];
    itemOtherList?: RegPropertyOtherItem[];
    mn4?: string;
    mn4Date?: any;
    sk6?: string;
    sk6Date?: any;
    registrationQualification?: RegQualification;
}

export interface RegPropertyItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    property?: Property;
    fileUpload?: FileUpload;
    desc?: string;
}

export interface RegPropertyOtherItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    property?: PropertyOther;
    fileUpload?: FileUpload;
    desc?: string;
}

export interface RegPublish extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    file?: FileUpload;
    fileTypeStatus?: PublishFileTypeStatus;
    publishedDate?: any;
    signDate?: any;
    signUser?: OssUser;
    status?: PublishStatus;
    bookNo?: string;
    bookNumber?: number;
    bookYear?: number;
    statusSync?: boolean;
}

export interface RegQualification extends AbstractAuditingEntity {
    id?: number;
    attribute?: RegAttribute;
    property?: RegProperty;
    potential?: RegPotential;
    openingHour?: RegOpeningHour;
    support?: RegSupport;
    staffList?: RegStaff[];
    mode?: RegMode;
    criteria?: RegCriteria;
    iso?: RegISO;
    statistic?: RegStatistic;
}

export interface RegQuotaLimited {
    typeName?: string;
    resultText?: string;
}

export interface RegRadiationProtection extends AbstractAuditingEntity {
    id?: number;
    protectionId?: string;
    protectionClassName?: string;
    desc?: string;
    authorizedDate?: any;
    expiredDate?: any;
    maintainDate?: any;
    numberOfRadiationMetersForASurgeon?: number;
}

export interface RegRadiationTherapyCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    fileUpload?: FileUpload;
    referralHospital?: Hospital;
}

export interface RegSp19 extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUpload;
    fullAddress?: string;
}

export interface RegSp7 extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    subDistrictId?: string;
    postCode?: string;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUpload;
    fullAddress?: string;
}

export interface RegStaff extends AbstractAuditingEntity {
    id?: number;
    qualificationId?: number;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    cppMPerson?: CppMPerson;
    regStaffHourItems?: RegStaffHourItem[];
    totalDay?: number;
    totalHour?: number;
    licenseStartDate?: any;
    licenseEndDate?: any;
    course?: string;
    courseDate?: any;
}

export interface RegStaffHourItem {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface RegStaffSizeConfig {
    hospitalSize?: HospitalSize;
    minBed?: number;
    maxBed?: number;
    minStaff?: number;
}

export interface RegStatistic extends AbstractAuditingEntity {
    id?: number;
    qualification?: RegQualification;
    year1?: number;
    year2?: number;
    year3?: number;
    cardiacSurgeryList?: RegCardiacSurgeryStatistic[];
    pci1StatisticList?: RegPCI1Statistic[];
    pci2StatisticList?: RegPCI2Statistic[];
}

export interface RegSupport extends AbstractAuditingEntity {
    id?: number;
    itemList?: RegSupportItem[];
    registrationQualification?: RegQualification;
}

export interface RegSupportItem extends AbstractAuditingEntity {
    id?: number;
    checked?: boolean;
    support?: Support;
    desc?: string;
}

export interface RegSurgeryPotential {
    pediatricCardiacSurgery?: boolean;
    cardiacSurgery?: boolean;
}

export interface RegType extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    types?: Type[];
    medicalTechTypes?: MedicalTechType[];
    medicalEfficiencyTypes?: MedicalTechType[];
    preventionLabFile?: FileUpload;
    otherLabFile?: FileUpload;
    capitation?: boolean;
    hmainOp?: Hospital;
    hmain?: Hospital;
    hsubList?: Hospital[];
    hreferList?: Hospital[];
}

export interface RegalContract extends AbstractAuditingEntity {
    id?: number;
    registration?: Registration;
    contractType?: RegalContractType;
    contractItems?: RegalContractItem[];
    approvedThaiD?: boolean;
    verificationDate?: any;
    verificationUser?: OssUser;
    orgOther?: OrgOther;
    attorney?: OssUser;
    attorneyPosition?: string;
    attorneyNo?: string;
    attorneyDate?: any;
    titleName?: string;
    sec3MeetingReportNo?: string;
    sec3No?: string;
    sec3Date?: any;
    foundationType?: ContractFoundationType;
    foundationNo?: string;
    foundationDate?: any;
    correctionResult?: string;
    companyAddress?: string;
    delegateAttorneyPosition?: string;
    delegateAttorneyNo?: string;
    delegateAttorneyDate?: any;
    effectiveDate?: any;
}

export interface RegalContractItem extends AbstractAuditingEntity {
    id?: number;
    file?: FileUpload;
    contractNo?: string;
    refNo?: string;
    contractYear?: number;
    letterGuaranteeTypeItem?: RegLetterGuaranteeItem;
    medical?: boolean;
    maxMedical?: number;
    hide?: boolean;
}

export interface RegalContractNo extends AbstractAuditingEntity {
    id?: number;
    prefix1?: string;
    prefix2?: string;
    currentNo?: number;
    year?: number;
}

export interface RegalContractRefNo extends AbstractAuditingEntity {
    budgetYear?: number;
    prefix?: string;
    currentNo?: number;
}

export interface RegalContractSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: Htitle;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    province?: Province;
    htype?: Htype;
    hsubtype?: Hsubtype;
    bookNumber?: number;
    bookYear?: number;
    fileTypeStatus?: PublishFileTypeStatus;
    types?: Type[];
    file?: FileUpload;
    lastUpdatedDate?: any;
    applicant?: OssUser;
    hospital?: Hospital;
    publishedDate?: any;
    considerDate?: any;
    signDate?: any;
    signUser?: OssUser;
    regLetterGuarantee?: RegLetterGuarantee;
    regalContract?: RegalContract;
    regOpeningHour?: RegOpeningHour;
    delegateOfAuthority?: RegDelegateOfAuthority;
    sp7List?: RegSp7[];
    approvedThaiD?: boolean;
    contractType?: RegalContractType;
    property?: RegProperty;
    budgetYear?: number;
    hid?: number;
    typeTrans?: TypeTrans[];
    verificationDate?: any;
    effectiveDate?: any;
    companyAddress?: string;
}

export interface Registration extends AbstractAuditingEntity, Sp7Getter {
    id?: number;
    version?: number;
    uuid?: string;
    sp19?: RegSp19;
    sp19CitizenId?: string;
    ph5?: RegPh5;
    delegateOfAuthority?: RegDelegateOfAuthority;
    bankAccount?: RegBankAccount;
    info?: RegInfo;
    hcode?: string;
    templeId?: string;
    htitle?: Htitle;
    hospital?: Hospital;
    temple?: Temple;
    hcode9?: string;
    step?: Step;
    hname?: string;
    htype?: Htype;
    hofftype?: Hofftype;
    hsubtype?: Hsubtype;
    province?: Province;
    applicant?: OssUser;
    regType?: RegType;
    regFile?: RegFile;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    cancelReason?: string;
    cancelRegistrationDate?: any;
    acceptConsiderResult?: boolean;
    acceptConsiderDate?: any;
    sendConsiderDate?: any;
    considerLevel?: ConsiderLevel;
    officerSignature?: string;
    officer?: OssUser;
    considerDate?: any;
    considerReason?: string;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    step1ReachFlag?: boolean;
    step2ReachFlag?: boolean;
    step3ReachFlag?: boolean;
    step4ReachFlag?: boolean;
    step5ReachFlag?: boolean;
    step6ReachFlag?: boolean;
    sp7DeniedFlag?: boolean;
    blacklistConsiderFlag?: boolean;
    quotaLimitedList?: RegQuotaLimited[];
    section3Flag?: boolean;
    section3NoHcode?: boolean;
    active?: ActiveStatus;
    zoning?: Zoning;
    quotaType?: QuotaType;
    requestType?: RequestType;
    latestRegistrationFlag?: boolean;
    sapStatus?: SapStatus;
    sapSendDate?: any;
    sapRequestBody?: string;
    sapResponseMessage?: string;
    sapErrorStackTrace?: string;
    sapOutbound?: string;
    sapInbound?: string;
    sapProcessType?: SapProcessType;
    sapVendorType?: SapVendorType;
    sapActionType?: SapActionType;
    sapIgnoreFlag?: boolean;
    manualFormFlag?: boolean;
    sapTaxNumber1?: string;
    useInvalidData?: boolean;
    isPrivateHospitalOwnByFoundation?: boolean;
}

export interface RegistrationSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    status?: RegistrationStatus;
    applicant?: Applicant;
    autoApprove?: boolean;
    sendConsiderDate?: any;
    officer?: Officer;
    considerDate?: any;
    considerLevel?: ConsiderLevel;
    step?: Step;
    lastUpdatedDate?: any;
    orgId?: string;
    catm?: string;
    provinceId?: string;
    province?: Province;
    zoneCode?: string;
    types?: Type[];
    zone?: Zone;
    acceptConsiderResult?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
    thaidDate?: any;
    guaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    useInvalidData?: boolean;
    manualFormFlag?: boolean;
    sp7ConsiderFlag?: boolean;
    ph5ConsiderFlag?: boolean;
}

export interface RenewRound {
    budgetYear?: number;
    startDate?: any;
    endDate?: any;
}

export interface ReportRenew {
    id?: ReportRenewId;
    zone?: Zone;
    province?: Province;
    hname?: string;
    sendConsiderDate?: any;
    applicantName?: string;
    status?: AssessmentResult;
    considerDate?: any;
    officerName?: string;
}

export interface ReportRenewId {
    zoneId?: string;
    provinceId?: string;
    hcode?: string;
    assessType?: AssessType;
    budgetYear?: number;
}

export interface Resignation extends AbstractAuditingEntity {
    id?: number;
    applicant?: OssUser;
    hospital?: Hospital;
    resignDate?: any;
    resignationTypes?: ResignationType[];
    approvedThaiId?: boolean;
    status?: ResignationStatus;
    addBy?: AddBy;
    notApproveReason?: string;
    moreThan180Days?: boolean;
    directorComments?: string;
    haveImpact?: boolean;
    impactSuggestions?: string;
    items?: ResignationItem[];
    approve?: boolean;
    approveDetails?: string;
    contractNumber?: string;
    contractEndDate?: any;
    rejectReason?: string;
    rejectContractNumber?: string;
}

export interface ResignationItem extends AbstractAuditingEntity {
    id?: number;
    department?: DirectorDepartment;
    found?: boolean;
    cause?: string;
    currentSave?: boolean;
}

export interface ResignationPublish extends AbstractAuditingEntity {
    id?: number;
    resignation?: Resignation;
    file?: FileUpload;
    fileTypeStatus?: ResignPublishFileTypeStatus;
    publishedDate?: any;
    signDate?: any;
    signUser?: OssUser;
    status?: ResignationPublishStatus;
    bookNo?: string;
    bookNumber?: number;
    bookYear?: number;
}

export interface ResignationPublishNo extends AbstractAuditingEntity {
    id?: number;
    currentNo?: number;
    year?: number;
}

export interface ResignationType extends AbstractAuditingEntity {
    id?: number;
    type?: Type;
    checked?: boolean;
    contractNumber?: string;
    contractItem?: RegalContractItem;
}

export interface ResponseZoneConfig {
    code?: string;
    zone?: Zone;
    name?: OssUser;
}

export interface ResponsibleZoneConfig {
    code?: string;
    zone?: Zone;
    responsibleName?: string;
}

export interface ReviewLetterGuarantee extends AbstractAuditingEntity {
    id?: number;
    amount?: number;
    guaranteeType?: LetterGuaranteeType;
    bank?: Bank;
    serviceItems?: ReviewLetterGuaranteeServiceItem[];
    items?: ReviewLetterGuaranteeItem[];
    file?: FileUpload;
    status?: ReviewLetterGuaranteeStatus;
    expireDate?: any;
    noneExpire?: boolean;
    applicant?: OssUser;
    correctionResult?: string;
    nhsoFile?: FileUpload;
}

export interface ReviewLetterGuaranteeImport {
    id?: number;
    hcode?: string;
    districtId?: string;
    name?: string;
    contract?: string;
    amount?: number;
}

export interface ReviewLetterGuaranteeItem extends AbstractAuditingEntity {
    id?: number;
    letterGuaranteeNo?: string;
    lgRequestDetail?: LgRequestDetail;
    requestDate?: any;
}

export interface ReviewLetterGuaranteeServiceItem extends AbstractAuditingEntity {
    id?: number;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    address?: string;
    contractNo?: string;
    amount?: number;
    regLetterGuarantee?: RegLetterGuarantee;
}

export interface Revinfo {
    rev?: number;
    revtstmp?: number;
}

export interface SapPageData {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: Htitle;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    province?: Province;
    htype?: Htype;
    hsubtype?: Hsubtype;
    bookNumber?: number;
    bookYear?: number;
    file?: FileUpload;
    lastUpdatedDate?: any;
    applicant?: OssUser;
    hospital?: Hospital;
    publishedDate?: any;
    considerDate?: any;
    signDate?: any;
    contractType?: RegalContractType;
    sapStatus?: SapStatus;
    sapSendDate?: any;
    sapRequestBody?: string;
    sapResponseMessage?: string;
    sapErrorStackTrace?: string;
    verificationDate?: any;
    sapProcessType?: SapProcessType;
    sapIgnoreFlag?: boolean;
    sapOutbound?: string;
    sapInbound?: string;
    sapActionType?: SapActionType;
}

export interface ServiceMaster {
    serviceCode?: string;
    serviceName?: string;
}

export interface ServicePotential {
    id?: number;
    service?: ServiceMaster;
    attachmentItem?: ServicePotentialAttachment[];
    attachmentValidateType?: PotentialValidateType;
    personTypeItem?: CppMPerson[];
    personValidateType?: PotentialValidateType;
    toolItem?: ServicePotentialTool[];
    locationItem?: ServicePotentialLocation[];
}

export interface ServicePotentialAttachment extends AbstractAuditingEntity {
    id?: number;
    name?: string;
    active?: ActiveStatus;
    lastUpdateUser?: OssUser;
}

export interface ServicePotentialLocation extends AbstractAuditingEntity {
    id?: number;
    name?: string;
    active?: ActiveStatus;
    lastUpdateUser?: OssUser;
}

export interface ServicePotentialService {
    id?: number;
    typeId?: string;
    typeName?: string;
    serviceParentId?: string;
    serviceCode?: string;
    serviceName?: string;
}

export interface ServicePotentialTool {
    id?: number;
    name?: string;
    active?: ActiveStatus;
}

export interface SignLock {
    id?: number;
}

export interface Sp19 extends AbstractAuditingEntity {
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    fullAddress?: string;
}

export interface Sp7 extends AbstractAuditingEntity {
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    subDistrictId?: string;
    postCode?: string;
    fullAddress?: string;
}

export interface Sp7Getter {
    sp7?: RegSp7;
}

export interface SubDistrict {
    code?: string;
    name?: string;
    district?: District;
    catm?: string;
}

export interface Support {
    id?: number;
    desc?: string;
}

export interface ZoneCoordinator {
    id?: number;
    zone?: Zone;
    name?: string;
    organizationName?: string;
    email?: string;
    link?: string;
    mobile?: string;
    active?: ActiveStatus;
    note?: string;
    responseProvinceCode?: string[];
}

export interface ZoneEmail {
    code?: string;
    name?: string;
    emails?: string[];
    hname?: string;
    hmain?: string;
}

export interface Zoning extends AbstractAuditingEntity {
    id?: number;
    zone?: Zone;
    orgType?: QuotaOrgType;
    startDate?: any;
    endDate?: any;
    type?: Type;
    total?: number;
    used?: number;
    provinces?: ZoningProvince[];
    lastUpdateUser?: OssUser;
    modifyUser?: OssUser;
}

export interface ZoningDistrict extends AbstractAuditingEntity {
    id?: number;
    district?: District;
    total?: number;
    used?: number;
}

export interface ZoningProvince extends AbstractAuditingEntity {
    id?: number;
    province?: Province;
    total?: number;
    used?: number;
    districts?: ZoningDistrict[];
}

export interface ZoningSearchParam {
    zoneCode?: string;
    type?: Type;
    username?: string;
    startDate?: any;
    endDate?: any;
    recruitment?: string;
}

export interface CppMBed {
    id?: number;
    name?: string;
    description?: string;
    sequence?: number;
    parentId?: string;
    used?: boolean;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface CppMPerson {
    id?: number;
    personNo?: string;
    personName?: string;
    sequenceOrder?: number;
    personType?: string;
    parentId?: number;
    prefixProfessionId?: string;
    requireFlag?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface CppMStationPersonnelType {
    id?: number;
    displayNo?: string;
    typeDescription?: string;
    prefixProfessionId?: string;
    parentId?: number;
    remark?: string;
    used?: boolean;
    createBy?: string;
    createDate?: any;
    updateBy?: string;
    updateDate?: any;
    version?: number;
}

export interface CppTBedItem {
    id?: CppTBedItemId;
    hospital?: Hospital;
    bed?: CppMBed;
    amount?: number;
    used?: boolean;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface CppTBedItemId {
    hid?: number;
    bedId?: number;
}

export interface CppTPersonItem {
    id?: number;
    hospital?: Hospital;
    cppMPerson?: CppMPerson;
    professionId?: string;
    firstName?: string;
    lastName?: string;
    isFullTime?: string;
    totalPartTime?: number;
    status?: string;
    moveToHid?: number;
    studyLeaveYear?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface AssessmentCriteriaTemplate {
    key?: string;
    parent?: AssessmentCriteriaTemplate;
    children?: AssessmentCriteriaTemplate[];
    desc?: string;
    nodeType?: any;
    seq?: string;
}

export interface CardiacSurgeryCriteria_V1 {
    key?: string;
    seq?: string;
    desc?: string;
    nodeType?: any;
    children?: AssessmentCriteriaTemplate[];
}

export interface HSCTCriteria_V1 {
    key?: string;
    seq?: string;
    desc?: string;
    nodeType?: any;
    children?: AssessmentCriteriaTemplate[];
}

export interface RadiationTherapyCriteria_V1 {
    key?: string;
    seq?: string;
    desc?: string;
    nodeType?: any;
    children?: AssessmentCriteriaTemplate[];
}

export interface HosAdditionalFileItem extends AbstractAuditingEntity {
    id?: number;
    displayText?: string;
    type?: AddForType;
    file?: FileUpload;
}

export interface HosAssessItem extends AbstractAuditingEntity, IRegAssessItem<boolean> {
    value?: boolean;
    textAnswerExtension?: HosTextAnswerExtension;
    fileAnswerExtension?: HosFileAnswerExtension;
    radioAnswerExtension?: HosRadioAnswerExtension;
    checkboxAnswerExtensionMap?: { [index: string]: HosCheckboxAnswerExtension };
    hcodeAnswerExtensionList?: HosHcodeAnswerExtension[];
    nhsoValue?: NhsoValue;
    nhsoAutoAssess?: boolean;
}

export interface HosAssessItemCheckbox extends AbstractAuditingEntity, IRegAssessItem<boolean> {
    value?: boolean;
}

export interface HosAssessItemDate extends AbstractAuditingEntity, IRegAssessItem<any> {
    value?: any;
}

export interface HosAssessType extends AbstractAuditingEntity {
    id?: number;
    assessType?: AssessType;
    questionClassName?: string;
    itemMap?: { [index: string]: HosAssessItem };
    itemCheckboxMap?: { [index: string]: HosAssessItemCheckbox };
    itemDateMap?: { [index: string]: HosAssessItemDate };
    optionalFlag?: boolean;
    nhsoOpinion?: string;
    nhsoSuggestions?: string;
    nhsoConsider?: boolean;
    fileUpload?: FileUpload;
}

export interface HosAssessment extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    typeMap?: { [P in AssessType]?: HosAssessType };
    budgetYear?: number;
}

export interface HosAttribute extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    itemList?: HosAttributeItem[];
    population?: Population;
}

export interface HosAttributeItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    attribute?: Attribute;
    pccCheckDate?: any;
    pccCheckRemark?: string;
    pccDate?: any;
    desc?: string;
}

export interface HosBankAccount extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    accountNumber?: string;
    accountName?: string;
    accountNameEn?: string;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
    bank?: Bank;
    branch?: string;
    branchEn?: string;
    branchCode?: string;
    bookBank?: FileUpload;
}

export interface HosCardiacSurgeryCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    numberOfBeds?: number;
    cathLab?: boolean;
    cathLabReferralHospital?: Hospital;
    generalRadiologyDiagnosis24hrs?: boolean;
    prc?: boolean;
    ffp?: boolean;
    plt?: boolean;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    heartTeamConferenceWithPhysicianPerMonth?: number;
    heartTeamConferenceWithPediatricPerMonth?: number;
    morbidityWithSurgicalTeamPerMonth?: number;
}

export interface HosCardiacSurgeryDirector extends AbstractAuditingEntity {
    id?: number;
    iso?: HosISO;
    fullName?: string;
    pid?: string;
    licenseNo?: string;
    specializedList?: HosDirectorSpecialized[];
    totalExperienceOfSurgery?: string;
    numberOfSurgeryInAYear?: string;
}

export interface HosCardiacSurgeryStatistic extends AbstractAuditingEntity {
    id?: number;
    serviceDesc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    service?: CardiacSurgeryStatistic;
    serviceOtherList?: string[];
}

export interface HosConsiderHistory extends AbstractAuditingEntity {
    id?: number;
    hosistrationId?: number;
    status?: string;
    autoApprove?: boolean;
    considerLevel?: ConsiderLevel;
    officer?: OssUser;
    activeDate?: any;
    considerReason?: string;
    requestType?: RequestType;
}

export interface HosCriteria extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    radiationTherapyCriteriaMap?: { [index: string]: HosRadiationTherapyCriteria };
    cardiacSurgeryCriteriaMap?: { [index: string]: HosCardiacSurgeryCriteria };
    hsctCriteriaMap?: { [index: string]: HosHSCTCriteria };
}

export interface HosDelegateOfAuthority extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    organization?: OssOrganization;
    authorizedFile?: FileUpload;
    delegateFile?: FileUpload;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface HosDepartment {
    checked?: boolean;
    department?: Department;
}

export interface HosDirectorSpecialized {
    prectitionCode?: string;
    snameTh?: string;
    snameEn?: string;
    sdate?: any;
}

export interface HosFile extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    files?: { [index: string]: FileUpload };
    additionalFiles?: HosAdditionalFileItem[];
}

export interface HosHSCTCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfServices?: number;
    totalNotLessThan?: number;
}

export interface HosISO extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    surgeryDirector?: HosCardiacSurgeryDirector;
}

export interface HosInfo extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    address?: string;
    soi?: string;
    moo?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    tel?: string;
    email?: string;
    lat?: number;
    lon?: number;
    fullAddress?: string;
}

export interface HosOpeningHour extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    openingHourItems?: HosOpeningHourItem[];
    totalDay?: number;
    totalHour?: number;
}

export interface HosOpeningHourItem {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface HosPCI1Potential extends AbstractAuditingEntity {
    id?: number;
    potential?: HosPotential;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: HosRadiationProtection };
}

export interface HosPCI1Statistic extends AbstractAuditingEntity {
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci1Statistic?: Pci1Statistic;
    desc?: string;
}

export interface HosPCI2Potential extends AbstractAuditingEntity {
    id?: number;
    potential?: HosPotential;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: HosRadiationProtection };
    referralHospital?: Hospital;
}

export interface HosPCI2Statistic extends AbstractAuditingEntity {
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci2Statistic?: Pci2Statistic;
    desc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    serviceOtherList?: string[];
}

export interface HosPh5 extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
}

export interface HosPotential extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    numberOfBeds?: number;
    numberOfOr?: number;
    numberOfIcu?: number;
    numberOfNicu?: number;
    departmentList?: HosDepartment[];
    surgeryPotential?: HosSurgeryPotential;
    pci1Potential?: HosPCI1Potential;
    pci2Potential?: HosPCI2Potential;
}

export interface HosProperty extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    itemList?: HosPropertyItem[];
    itemOtherList?: HosPropertyOtherItem[];
    mn4?: string;
    mn4Date?: any;
    sk6?: string;
    sk6Date?: any;
}

export interface HosPropertyItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    property?: Property;
    fileUpload?: FileUpload;
    desc?: string;
}

export interface HosPropertyOtherItem extends AbstractAuditingEntity {
    id?: number;
    value?: boolean;
    property?: PropertyOther;
    fileUpload?: FileUpload;
    desc?: string;
}

export interface HosQualification extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    attribute?: HosAttribute;
    property?: HosProperty;
    potential?: HosPotential;
    openingHour?: HosOpeningHour;
    support?: HosSupport;
    staffList?: HosStaff[];
    mode?: RegMode;
    criteria?: HosCriteria;
    iso?: HosISO;
    statistic?: HosStatistic;
}

export interface HosRadiationProtection extends AbstractAuditingEntity {
    id?: number;
    protectionId?: string;
    protectionClassName?: string;
    desc?: string;
    authorizedDate?: any;
    expiredDate?: any;
    maintainDate?: any;
    numberOfRadiationMetersForASurgeon?: number;
}

export interface HosRadiationTherapyCriteria extends AbstractAuditingEntity {
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    fileUpload?: FileUpload;
    referralHospital?: Hospital;
}

export interface HosRegistration extends AbstractAuditingEntity, HosSp7Getter {
    id?: number;
    version?: number;
    uuid?: string;
    sp19?: HosSp19;
    sp19CitizenId?: string;
    ph5?: HosPh5;
    delegateOfAuthority?: HosDelegateOfAuthority;
    bankAccount?: HosBankAccount;
    info?: HosInfo;
    hcode?: string;
    temple?: Temple;
    templeId?: string;
    htitle?: Htitle;
    hospital?: Hospital;
    hcode9?: string;
    step?: Step;
    hname?: string;
    htype?: Htype;
    hofftype?: Hofftype;
    hsubtype?: Hsubtype;
    province?: Province;
    applicant?: OssUser;
    type?: HosType;
    qualification?: HosQualification;
    assessment?: HosAssessment;
    file?: HosFile;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    cancelReason?: string;
    cancelRegistrationDate?: any;
    acceptConsiderResult?: boolean;
    acceptConsiderDate?: any;
    sendConsiderDate?: any;
    considerLevel?: ConsiderLevel;
    officerSignature?: string;
    officer?: Officer;
    considerDate?: any;
    considerReason?: string;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    step1ReachFlag?: boolean;
    step2ReachFlag?: boolean;
    step3ReachFlag?: boolean;
    step4ReachFlag?: boolean;
    step5ReachFlag?: boolean;
    step6ReachFlag?: boolean;
    sp7DeniedFlag?: boolean;
    blacklistConsiderFlag?: boolean;
    section3Flag?: boolean;
    section3NoHcode?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
    latestRegistrationFlag?: boolean;
}

export interface HosSp19 extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    postCode?: string;
    fullAddress?: string;
}

export interface HosSp7 extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrict;
    subDistrictId?: string;
    postCode?: string;
    fullAddress?: string;
}

export interface HosSp7Getter {
    sp7?: HosSp7;
}

export interface HosStaff extends AbstractAuditingEntity {
    id?: number;
    qualificationId?: number;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    cppMPerson?: CppMPerson;
    hosStaffHourItems?: HosStaffHourItem[];
    totalDay?: number;
    totalHour?: number;
    licenseStartDate?: any;
    licenseEndDate?: any;
    course?: string;
    courseDate?: any;
}

export interface HosStaffHourItem {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface HosStatistic extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    year1?: number;
    year2?: number;
    year3?: number;
    cardiacSurgeryList?: HosCardiacSurgeryStatistic[];
    pci1StatisticList?: HosPCI1Statistic[];
    pci2StatisticList?: HosPCI2Statistic[];
}

export interface HosSupport extends AbstractAuditingEntity {
    id?: number;
    qualification?: HosQualification;
    itemList?: HosSupportItem[];
}

export interface HosSupportItem extends AbstractAuditingEntity {
    id?: number;
    checked?: boolean;
    support?: Support;
    desc?: string;
}

export interface HosSurgeryPotential {
    pediatricCardiacSurgery?: boolean;
    cardiacSurgery?: boolean;
}

export interface HosType extends AbstractAuditingEntity {
    id?: number;
    registration?: HosRegistration;
    types?: Type[];
    medicalTechTypes?: MedicalTechType[];
    medicalEfficiencyTypes?: MedicalTechType[];
    capitation?: boolean;
    hmainOp?: Hospital;
    hmain?: Hospital;
    hsubList?: Hospital[];
    hreferList?: Hospital[];
    preventionLabFile?: FileUpload;
    otherLabFile?: FileUpload;
}

export interface HosistrationSearch {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    status?: RegistrationStatus;
    applicant?: Applicant;
    autoApprove?: boolean;
    sendConsiderDate?: any;
    officer?: Officer;
    considerDate?: any;
    considerLevel?: ConsiderLevel;
    step?: Step;
    lastUpdatedDate?: any;
    orgId?: string;
    provinceId?: string;
    zoneCode?: string;
    types?: Type[];
    zone?: Zone;
    acceptConsiderResult?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
}

export interface HosCheckboxAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    label?: string;
    value?: boolean;
}

export interface HosFileAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: HosAssessItem;
    value?: FileUpload;
}

export interface HosHcodeAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    hcode?: string;
}

export interface HosRadioAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: HosAssessItem;
    value?: boolean;
}

export interface HosTextAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: HosAssessItem;
    value?: string;
    nhsoValue?: string;
}

export interface CheckboxAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    label?: string;
    value?: boolean;
}

export interface FileAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: RegAssessItem;
    value?: FileUpload;
}

export interface HcodeAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    hcode?: string;
}

export interface RadioAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: RegAssessItem;
    value?: boolean;
}

export interface TextAnswerExtension extends AbstractAuditingEntity {
    id?: number;
    item?: RegAssessItem;
    value?: string;
    nhsoValue?: string;
}

export interface RadiationProtection_V1 {
    key?: string;
    seq?: string;
    desc?: string;
    nodeType?: any;
    children?: SafetyTemplate[];
}

export interface SafetyTemplate {
    key?: string;
    parent?: SafetyTemplate;
    children?: SafetyTemplate[];
    desc?: string;
    nodeType?: any;
    seq?: string;
}

export interface ActivityDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hname?: string;
    hsubtype?: HsubtypeDto;
    step?: Step;
    status?: RegistrationStatus;
}

export interface AdditionalFileItemDto {
    id?: number;
    displayText?: string;
    type?: AddForType;
    file?: FileUploadWithConfigDto;
}

export interface AdditionalRegisterDto {
    no?: string;
    year?: number;
    hospitalName?: string;
    addType?: string;
    type?: string;
    address?: string;
    subDistrict?: string;
    district?: string;
    province?: string;
    oldTye?: string;
    effectiveDate?: any;
    date?: any;
    authorizedSignatory?: string;
    position?: string;
    hospitalId?: number;
    registrationId?: number;
}

export interface ApplicantDto {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    organization?: ApplicantOrganizationDto;
    signatureImage?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
    personal?: boolean;
    company?: boolean;
    temple?: boolean;
    gov?: boolean;
    ga?: boolean;
}

export interface ApplicantOrganizationDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: string;
    orgType?: OrgType;
    name?: string;
    vatId?: string;
    fromType?: FromType;
    authorizeDescriptionList?: OssAuthorizeDescriptionDto[];
    authorizedPersonalId?: string;
    authorizedName?: string;
}

export interface AssessmentSummary {
    assessType?: AssessType;
    assessName?: string;
    totalItem?: number;
    totalPassingScore?: number;
    totalNonPassingScore?: number;
    result?: AssessmentResult;
}

export interface BankAccountDto {
    id?: number;
    accountNumber?: string;
    accountName?: string;
    accountNameEn?: string;
    bank?: BankDto;
    branch?: string;
    branchCode?: string;
    branchEn?: string;
    bookBank?: FileUploadDto;
    organization?: OssOrganizationDto;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
}

export interface BankAccountSearchDto {
    accountNumber?: string;
    accountName?: string;
    bank?: Bank;
    branch?: string;
    organization?: string;
    createdDate?: any;
}

export interface BankDto {
    code?: string;
    name?: string;
    country?: string;
    vendors?: BankVendorDto[];
}

export interface BankVendorDto {
    sapProviderCode?: string;
    name?: string;
    providerTypeName?: string;
    providerName?: string;
    address?: string;
    vatId?: string;
    provinceName?: string;
}

export interface CatmDto {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
    fullCatm?: string;
}

export interface CheckConsiderationResult extends Record {
    checkClause?: string;
    checkMessage?: string;
}

export interface CheckPublishReportCondition extends ReportCondition {
    fromConsiderDate?: any;
    toConsiderDate?: any;
    fromSignDate?: any;
    toSignDate?: any;
    provinceId?: string;
    publishStatus?: PublishStatus;
    statusList?: PublishStatus[];
    statusListIsEmpty?: number;
}

export interface CheckRegTypeResult {
    registrationId?: number;
    isR?: boolean;
    isP?: boolean;
    isH?: boolean;
    isR01?: boolean;
    isR02?: boolean;
    isR0211?: boolean;
    isR0207?: boolean;
    isR0208?: boolean;
    isR0213?: boolean;
    isR0209?: boolean;
    isR0212?: boolean;
    isR0210?: boolean;
    isR0206?: boolean;
    isR0201?: boolean;
    isR0202?: boolean;
    isR020201?: boolean;
    isR020202?: boolean;
    isR0203?: boolean;
    isR0204?: boolean;
    isR020401?: boolean;
    isR020402?: boolean;
    isR020403?: boolean;
    isR020404?: boolean;
    isR0205?: boolean;
    isR0214?: boolean;
    isR0215?: boolean;
    isR0216?: boolean;
    isR0218?: boolean;
    isR0219?: boolean;
}

export interface CheckRegisterHsubtypeResult {
    group?: boolean;
    pharmacy?: boolean;
    disabledServiceCenter?: boolean;
    nursingRoom?: boolean;
    civilSociety?: boolean;
    foundation?: boolean;
    vaccineCenter?: boolean;
    association?: boolean;
    club?: boolean;
    center?: boolean;
    isPharmacy?: boolean;
    isDisabledServiceCenter?: boolean;
    isNursingRoom?: boolean;
    isCivilSociety?: boolean;
    isFoundation?: boolean;
    isVaccineCenter?: boolean;
    isAssociation?: boolean;
    isClub?: boolean;
    isGroup?: boolean;
    isCenter?: boolean;
}

export interface CheckRegistrationValidResult extends Record {
    isStep1Saved?: boolean;
    isStep2Saved?: boolean;
    isStep3Saved?: boolean;
    isStep4Saved?: boolean;
    isStep5Saved?: boolean;
}

export interface CheckboxAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    label?: string;
    value?: boolean;
}

export interface ConfirmReason {
    id?: number;
    reason?: string;
    version?: number;
    capitation?: boolean;
}

export interface ConfirmRegistrationDto {
    id?: number;
    thaidName?: string;
    thaidPid?: string;
    thaidDate?: any;
}

export interface ConsiderationDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    version?: number;
    delegateOfAuthority?: RegDelegateOfAuthorityDto;
    hcode?: string;
    hname?: string;
    applicant?: ApplicantDto;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    considerDate?: any;
    officerSignature?: string;
    officer?: OfficerDto;
    considerReason?: string;
    sp7DeniedFlag?: boolean;
    cancelReason?: string;
    quotaLimitedList?: RegQuotaLimitedDto[];
    fullAddress?: string;
    regType?: RegTypeDto;
    blacklistConsiderFlag?: boolean;
    acceptConsiderResult?: boolean;
    acceptConsiderDate?: any;
    publishStatus?: PublishStatus;
    htitle?: HtitleDto;
    legalApprovedThaiD?: boolean;
    stateIn?: boolean;
    guaranteeStatus?: LetterGuaranteeStatus;
    charge?: LetterGuaranteeCharge;
    manualFormFlag?: boolean;
    sp7Name?: string;
    info?: RegInfoDto;
    sp7?: RegSp7Dto;
}

export interface ContractSearchDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hname?: string;
    htitle?: HtitleDto;
    publishStatus?: PublishStatus;
    bookNumber?: number;
    bookYear?: number;
    types?: TypeWithoutChildAndParentDto[];
    considerDate?: any;
    signDate?: any;
    signUser?: OssUserDto;
    province?: ProvinceDto;
    approvedThaiD?: boolean;
    regalContract?: RegalContractDto;
    regLetterGuarantee?: RegLetterGuaranteeDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    applicant?: OssUserDto;
    sp7List?: RegSp7Dto[];
    hospital?: HospitalInfo;
    budgetYear?: number;
}

export interface ControlStateExtra {
    secret?: string;
}

export interface CppMPersonDto {
    id?: number;
    personNo?: string;
    personName?: string;
    sequenceOrder?: number;
    personType?: string;
    parentId?: number;
    prefixProfessionId?: string;
    requireFlag?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    displayMessage?: string;
}

export interface CppTPersonItemDto {
    id?: number;
    hospital?: HospitalInfo;
    cppMPerson?: CppMPersonDto;
    professionId?: string;
    firstName?: string;
    lastName?: string;
    isFullTime?: string;
    totalPartTime?: number;
    status?: string;
    moveToHid?: number;
    studyLeaveYear?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface DashBoardSearchParam {
    budgetYear?: number;
    zone?: ZoneDto;
}

export interface DelegateAuthSearchParam {
    name?: string;
    status?: DelegateStatus;
    personalId?: string;
    delegateAuthName?: string;
    verificationDate?: any;
    approver?: string;
    lastUpdatedDate?: any;
    zone?: Zone;
    province?: Province;
    zoneCode?: string;
}

export interface DelegateOfAuthorityDto {
    id?: number;
    userId?: number;
    user?: OssUserDto;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    organization?: OssOrganizationDto;
    authorizedFile?: FileUploadDto;
    delegateFile?: FileUploadDto;
    approvedThaiId?: boolean;
    verificationDate?: any;
    authorizationDate?: any;
    expireDate?: any;
    hasExpireDate?: boolean;
    status?: DelegateStatus;
    notApproveReason?: string;
    savedAuthorized?: boolean;
    authorizedPersonalId?: string;
    authorizedName?: string;
    delegateType?: DelegateType;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    approver?: string;
    lastUpdatedDate?: any;
    hospital?: HospitalDto;
    authorized?: boolean;
    expired?: boolean;
}

export interface DelegateRevertStatusDto {
    id?: number;
    status?: DelegateStatus;
    periodStatus?: DelegateStatus;
}

export interface DistrictDto {
    code?: string;
    name?: string;
    province?: ProvinceDto;
}

export interface EmailConfigMcupDto {
    id?: number;
    email?: string;
    name?: string;
    zone?: ZoneDto;
    active?: ActiveStatus;
}

export interface EnrollSp19Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
}

export interface EnrollSp7Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
}

export interface FileAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: FileUploadDto;
}

export interface FileConfigHofftypeDto {
    id?: number;
    hofftype?: Hofftype;
    fileConfigItem?: FileConfigItem;
    active?: ActiveStatus;
    lastUpdateUser?: OssUserDto;
}

export interface FileConfigHtypeDto {
    id?: number;
    active?: ActiveStatus;
    htype?: HtypeDto;
    fileConfigItem?: FileConfigItemDto;
    lastUpdateUser?: OssUserDto;
}

export interface FileConfigItemDto {
    id?: number;
    displayText?: string;
    active?: ActiveStatus;
    lastUpdateUser?: OssUserDto;
}

export interface FileConfigTypeDto {
    id?: number;
    active?: ActiveStatus;
    type?: TypeWithoutChildAndParentDto;
    fileConfigItem?: FileConfigItemDto;
    lastUpdateUser?: OssUserDto;
}

export interface FileUploadDto extends IFileUpload {
    id?: number;
}

export interface FileUploadPatcher {
    id?: number;
    ref?: string;
    newFile?: FileUploadDto;
}

export interface FileUploadRequest {
    file?: MultipartFile;
    note?: string;
}

export interface FileUploadWithConfigDto extends IFileUpload {
    id?: number;
    fileConfigItem?: FileConfigItemDto;
    note?: string;
    state?: State;
}

export interface FirstRegisterDto {
    no?: string;
    year?: number;
    hospitalName?: string;
    address?: string;
    subDistrict?: string;
    district?: string;
    province?: string;
    type?: string;
    effectiveDate?: any;
    date?: any;
    authorizedSignatory?: string;
    position?: string;
    registrationId?: number;
}

export interface HardIncreaseQuotaDto {
    id?: number;
}

export interface HardRevertStatusDto {
    id?: number;
    reason?: string;
    status?: RegistrationStatus;
}

export interface HcodeAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    hcode?: string;
}

export interface HealthLinkDelegateDto {
    id?: number;
    userId?: number;
    user?: OssUserDto;
    personalId?: string;
    name?: string;
    position?: string;
    executivePersonalId?: string;
    executiveName?: string;
    savedAuthorized?: boolean;
    authorizedPersonalId?: string;
    authorizedName?: string;
    organization?: OssOrganizationDto;
    authorizedFile?: FileUploadDto;
    delegateFile?: FileUploadDto;
    approvedThaiId?: boolean;
    authorizedApprovedThaiId?: boolean;
    verificationDate?: any;
    authorizedVerificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    approver?: string;
    province?: ProvinceDto;
    lastUpdatedDate?: any;
    authorized?: boolean;
}

export interface HealthServiceAgreementDto {
    registrationId?: number;
    regalConTractNo?: string;
    regalContractDate?: any;
    signUserName?: string;
    signUserPosition1?: string;
    signUserPosition2?: string;
    signDate?: any;
    publishNo?: string;
    attorneyName?: string;
    attorneyName2?: string;
    attorneyPosition?: string;
    attorneyNo?: string;
    orgOtherName?: string;
    orgOtherAddress?: string;
    hname?: string;
    hnameAddress?: string;
    typeNames?: string;
    attorneyDate?: any;
    rOpenDateTime?: string;
    pOpenDateTime?: string;
    budgetYear?: string;
    titleName?: string;
    fixNo?: string;
    fixDate?: any;
    delegateAttorneyPosition?: string;
    delegateAttorneyNo?: string;
    delegateAttorneyDate?: any;
}

export interface HealthServiceContractDto {
    registrationId?: number;
    regalConTractNo?: string;
    regalContractDate?: any;
    signUserName?: string;
    signUserPosition1?: string;
    signUserPosition2?: string;
    signDate?: any;
    publishNo?: string;
    attorneyName?: string;
    attorneyName2?: string;
    attorneyPosition?: string;
    hname?: string;
    hnameAddress?: string;
    typeNames?: string;
    budgetYear?: string;
    guaranteeType?: LetterGuaranteeType;
    juristicName?: string;
    applicantName?: string;
    juristicId?: string;
    juristicRegisterDate?: any;
    juristicRegisterDateString?: string;
    delegateVerificationDate?: any;
    sp7LicenseCode?: string;
    sp7LicenseCodeStartDate?: any;
    sp7WorkTime1?: string;
    sp7WorkTime2?: string;
    letterGuaranteeApproveDate?: any;
    amount?: string;
    amountText?: string;
    type?: Type;
    mondayToFriday?: string;
    saturdayToSunday?: string;
    orgType?: OrgType;
    licenseType?: HospitalLicenseType;
    bankName?: string;
    letterGuaranteeNo?: string;
    requestDate?: any;
    maxValue?: boolean;
    totalAmount?: number;
    openDateTime?: string;
    titleName?: string;
    fixNo?: string;
    fixDate?: any;
    sec3MeetingReportNo?: string;
    sec3No?: string;
    sec3Date?: any;
    foundationNo?: string;
    foundationDate?: any;
    foundationType?: ContractFoundationType;
    companyAddress?: string;
}

export interface HealthlinkDelegateOfAuthorityDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    organization?: OssOrganizationDto;
    authorizedFile?: FileUploadDto;
    delegateFile?: FileUploadDto;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface HealthlinkEnrollCheck extends Record {
    checkDelegateOfAuthority?: boolean;
    checkAuthorizedDirector?: boolean;
}

export interface HealthlinkEnrollSearchParam {
    name?: string;
    hcode?: string;
    hname?: string;
    type?: Type;
    considerDate?: any;
    status?: RegistrationStatus;
    zone?: Zone;
    province?: Province;
}

export interface HealthlinkEnrollmentDto {
    id?: number;
    uuid?: string;
    sp7?: EnrollSp7Dto;
    sp19?: EnrollSp19Dto;
    sp19CitizenId?: string;
    personaName?: string;
    personPosition?: string;
    enrollDate?: any;
    hospital?: HospitalDto;
    applicant?: OssUserDto;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    delegateOfAuthority?: HealthlinkDelegateOfAuthorityDto;
    file?: FileUploadDto;
    typeTrans?: string;
}

export interface HealthlinkEnrollmentReportCondition extends ReportCondition {
}

export interface HofftypeDto {
    hofftypeId?: string;
    hofftypeDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface HospitalAddressMatcherResult {
    allMatch?: boolean;
    addressNoMatches?: boolean;
    mooMatches?: boolean;
    soiMatches?: boolean;
    roadMatches?: boolean;
    subDistrictMatches?: boolean;
    districtMatches?: boolean;
    provinceMatches?: boolean;
}

export interface HospitalDto {
    hid?: number;
    hcode?: string;
    hname?: string;
    hcode9?: string;
    htitle?: HtitleDto;
    fullAddress?: string;
    address?: string;
    moo?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    subDistrict?: SubDistrictDto;
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    hofftype?: HofftypeDto;
    useInvalidData?: boolean;
}

export interface HospitalInfo {
    hid?: number;
    refHid?: number;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: HtitleDto;
    address?: string;
    moo?: string;
    soi?: string;
    road?: string;
    tumbonName?: string;
    province?: ProvinceDto;
    postCode?: string;
    tel?: string;
    fax?: string;
    website?: string;
    email?: string;
    lat?: number;
    lon?: number;
    catm?: CatmDto;
    fullAddress?: string;
}

export interface HospitalLicenseResult {
    licenseList?: HospitalLicense[];
    rawJson?: string;
}

export interface HospitalOffTypeDto {
    id?: string;
    name?: string;
}

export interface HospitalRegistrationTypeDto {
    hospital?: HospitalDto;
    types?: TypeWithoutChildAndParentDto[];
}

export interface HospitalSubTypeDto {
    id?: string;
    name?: string;
    offTypeList?: HospitalOffTypeDto[];
}

export interface HospitalTypeDto {
    id?: string;
    name?: string;
    subTypeList?: HospitalSubTypeDto[];
}

export interface HospitalWithHtypeDto {
    hid?: number;
    hcode?: string;
    hname?: string;
    hcode9?: string;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    hofftype?: HofftypeDto;
    htitle?: HtitleDto;
    tel?: string;
    email?: string;
    lat?: number;
    lon?: number;
    temple?: TempleDto;
}

export interface HsubtypeDto {
    hsubtypeId?: string;
    htypeId?: string;
    hsubtypeDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface HtitleDto {
    htitleId?: number;
    htitleName?: string;
    shortName?: string;
    used?: string;
}

export interface HtypeDto {
    htypeId?: string;
    htypeName?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    hsubtypes?: HsubtypeDto[];
}

export interface IRegAssessItem<T> {
    value?: T;
    id?: number;
    questionId?: string;
    autoAssess?: boolean;
    additionalDesc?: string;
    autoAssessNote?: string;
}

export interface ImportReviewLetterGuaranteeDto {
    hcode?: string;
    districtId?: string;
    name?: string;
    contract1?: string;
    amount1?: number;
    contract2?: string;
    amount2?: number;
    contract3?: string;
    amount3?: number;
    contract4?: string;
    amount4?: number;
    contract5?: string;
    amount5?: number;
    contract6?: string;
    amount6?: number;
}

export interface KeyCloakAttributeDto {
    attributes?: { [index: string]: string };
}

export interface LetterGuaranteePublishedSearchDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    letterGuaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    considerDate?: any;
    guaranteeType?: LetterGuaranteeType;
    letterGuaranteeDate?: any;
    approveDate?: any;
    charge?: LetterGuaranteeCharge;
    approveUser?: OssUserDto;
    applicant?: OssUserDto;
    lastUpdatedDate?: any;
    correctionReason?: string;
    hid?: number;
    contractType?: RegalContractType;
    regalContract?: RegalContractDto;
    regLetterGuarantee?: RegLetterGuaranteeDto;
    types?: TypeWithoutChildAndParentDto[];
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    hospital?: HospitalDto;
    htitle?: HtitleDto;
    fullAddressWithoutComma?: string;
}

export interface LetterGuaranteeReportCondition extends ReportCondition {
    approveDate?: any;
    statusList?: LetterGuaranteeStatus[];
    guaranteeType?: LetterGuaranteeType;
}

export interface LetterGuaranteeSearchDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: HtitleDto;
    registrationStatus?: RegistrationStatus;
    letterGuaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    considerDate?: any;
    guaranteeType?: LetterGuaranteeType;
    letterGuaranteeDate?: any;
    approveDate?: any;
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    hospital?: HospitalInfo;
    approveUser?: OssUserDto;
    applicant?: OssUserDto;
    types?: TypeWithoutChildAndParentDto[];
    fullAddressWithoutComma?: string;
    bankAccount?: BankAccountDto;
    charge?: LetterGuaranteeCharge;
    fileLetterGuaranteeItem?: RegLetterGuaranteeItemDto[];
    lastUpdatedDate?: any;
    correctionReason?: string;
    regalContract?: RegalContractDto;
    typeTrans?: TypeTransDto[];
    hid?: number;
}

export interface LetterGuaranteeSearchParam {
    hcode?: string;
    hname?: string;
    zone?: Zone;
    approveDate?: any;
    considerDate?: any;
    type?: Type;
    statusList?: LetterGuaranteeStatus[];
    guaranteeType?: LetterGuaranteeType;
    province?: Province;
}

export interface LgRequestDetailDto {
    appId?: string;
    requesterTaxId?: string;
    approveDate?: string;
    customerName?: string;
    requesterNameTh?: string;
    beneficiaryNameTh?: string;
    contingentTypeName?: string;
    contractDate?: string;
    lgAmt?: number;
    periodDesc?: string;
    lgEffectiveDate?: string;
    lgExpireDate?: string;
    updateDate?: string;
    contingentNo?: string;
    requestTypeDesc?: string;
    accountStatus?: string;
    hospitalCode?: string;
}

export interface LgRequestRequest {
    requestHeader?: RequestHeader;
    contingentNo?: string;
    totalAmount?: number;
}

export interface RequestHeader {
    channelId?: string;
    requestDate?: string;
    requestTime?: string;
}

export interface LgRequestResponse {
    scope?: string;
    respDesc?: string;
    respCode?: string;
    jti?: string;
    access_token?: string;
    token_type?: string;
    expires_in?: string;
}

export interface MasterDataSearchParam {
    registrationId?: number;
    attachFileName?: string;
    district?: string;
    type?: Type;
    htype?: Htype;
    hofftype?: Hofftype;
    createUserName?: string;
    statusList?: ActiveStatus[];
    typeListId?: number[];
    zoneCode?: string;
}

export interface Md5DoctorName {
    name?: string;
    lastName?: string;
}

export interface NhsoContractDocDto {
    contractNo?: string;
    district?: string;
    number?: string;
    alley?: string;
    road?: string;
    s_distrc?: string;
    s_prvcn?: string;
    province?: string;
    date?: string;
    month?: string;
    year?: string;
    nhso_cdt?: string;
    nhso_fONo?: string;
    nhso_lONo?: string;
    nhso_oD?: string;
    nhso_ctrcN?: string;
    jrtc_hq_no?: string;
    jrtc_hq_r?: string;
    jrtc_hq_sdis?: string;
    jrtc_hq_spro?: string;
    jrtc_hq_pro?: string;
    jrtc_name?: string;
    cc_No?: string;
    cc_D?: string;
    cc_M?: string;
    cc_Y?: string;
    au_d?: string;
    au_m?: string;
    au_y?: string;
    h_name?: string;
    h_l_no?: string;
    h_ally?: string;
    h_road?: string;
    h_sdis?: string;
    h_spro?: string;
    h_pro?: string;
    h_lcns_no?: string;
    h_lcns_d?: string;
    h_lcns_m?: string;
    h_lcns_y?: string;
    cmm_2_1?: string;
    cmm_2_1_par?: string;
    cmm_2_2?: string;
    cmm_2_2_par?: string;
    cmm_2_3?: string;
    cmm_2_3_par?: string;
    cmm_2_4?: string;
    cmm_2_4_par?: string;
    cmm_2_5?: string;
    cmm_16_fee?: string;
    cmm_3_1_forwarding_check?: string;
    juristic_location?: string;
    cmm_2_5_futhermore?: string;
    cmm_3_1_regular_check?: string;
    cmm_3_1_forwardingUnit?: string;
    cmm_3_1_primary_check?: string;
    cmm_3_1_forwarding_normal_check?: string;
    cmm_3_1_forwarding_special_check?: string;
    cmm_3_1_forwarding_special?: string;
    cmm_3_2_norm_day?: string;
    cmm_3_2_weekend?: string;
    cmm_6_1_collateral?: string;
    cmm_6_1_number?: string;
    cmm_6_1_date?: string;
    cmm_6_1_month?: string;
    cmm_6_1_year?: string;
    cmm_6_1_money?: string;
    cmm_6_1_money_parenthesis?: string;
    cmm_9_1_start_date?: string;
    cmm_9_1_start_month?: string;
    cmm_9_1_start_year?: string;
    cmm_9_1_end_year?: string;
    cmm_16_fee_parenthesis?: string;
    cmm_16_with_6_fee?: string;
    cmm_16_with_6_fee_parenthesis?: string;
}

export interface OfficerDto {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    signatureImage?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
}

export interface OidcUserInfo {
    nameTh?: string;
    sub?: string;
    ldapWsInfo?: string;
    email_verified?: boolean;
    email?: string;
    mobile?: string;
    preferred_username?: string;
    source?: string;
    given_name?: string;
    ldapWsZoneName?: string;
    userId?: number;
    ldapWsZoneCode?: string;
    name?: string;
    ldapWsSignImage?: string;
    personalId?: string;
    position?: string;
    titleName?: string;
}

export interface OrgOtherDto {
    vendorId?: string;
    vendorName?: string;
    address?: string;
    postCode?: string;
    used?: string;
    catm?: CatmDto;
    subDistrict?: SubDistrictDto;
}

export interface OssAddressInformationDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    sequence?: string;
    addressName?: string;
    fullAddress?: string;
    building?: string;
    roomNo?: string;
    floor?: string;
    villageName?: string;
    addressNo?: string;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: string;
    district?: string;
    province?: string;
    phone?: string;
    email?: string;
    organization?: OssOrganizationDto;
}

export interface OssAuthorizeDescriptionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    sequence?: string;
    description?: string;
    type?: string;
}

export interface OssOrganizationDto {
    id?: string;
    orgType?: OrgType;
    vendorId?: string;
    juristicType?: string;
    juristicStatus?: string;
    standardId?: string;
    registerDate?: string;
    name?: string;
    nameEn?: string;
    registerCapital?: string;
    authorizeDescriptionList?: OssAuthorizeDescriptionDto[];
    addressInformationList?: OssAddressInformationDto[];
    vatId?: string;
    fromType?: FromType;
    authorizedPersonalId?: string;
    authorizedName?: string;
    htype?: HtypeDto;
}

export interface OssUserDto {
    id?: number;
    createdDate?: any;
    personalId?: string;
    username?: string;
    name?: string;
    email?: string;
    mobile?: string;
    source?: Source;
    zoneCode?: string;
    zoneName?: string;
    organization?: OssOrganizationDto;
    dcUserId?: number;
    updateDate?: any;
    signatureImage?: string;
    signatureInfo?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
}

export interface PaymentParam {
    taxId?: string;
    registraionId?: number;
    itemId?: number;
    hname?: string;
    address?: string;
    dueDateString?: string;
    dateString?: string;
    mobile?: string;
    amount?: number;
    amountInWord?: string;
    formatedAmountNoDot?: string;
    bankBranch?: string;
    suffix?: string;
    ref1?: string;
    ref2?: string;
    qrCodeString?: string;
    barcodeString?: string;
    vatId?: string;
    hcode?: string;
    letterGuaranteeItem?: RegLetterGuaranteeItemDto;
}

export interface PccHsubInfo {
    clusterCode?: string;
    unitCode5?: string;
    unitName?: string;
    hcode?: string;
    hname?: string;
    unitYear?: string;
    createdAt?: string;
    updatedAt?: string;
}

export interface PopulationDto {
    populationInArea?: number;
    rightsPopulation?: number;
}

export interface PotentialConsiderHistoryDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    potentialRequestId?: number;
    status?: string;
    officer?: OssUserDto;
    activeDate?: any;
}

export interface PotentialImproveAttachmentItemDto {
    id?: number;
    potentialAttachment?: ServicePotentialAttachmentDto;
    file?: FileUploadDto;
}

export interface PotentialImproveCaringCabinetItemDto {
    id?: number;
    cabinetCode?: string;
    cabinetLocation?: string;
    pictureFile?: FileUploadDto;
}

export interface PotentialImproveDentalCarDetailDto {
    id?: number;
    carRegistration?: string;
    unitesAmount?: number;
    requestPermissionFile?: FileUploadDto;
}

export interface PotentialImproveItemDto {
    id?: number;
    type?: TypeDto;
    typeHospId?: number;
    service?: ServiceDto;
    dateIn?: any;
    hasH24Flow?: boolean;
    hasH24Record?: boolean;
    hasH24Standard?: boolean;
    homeCareServices?: boolean;
    schoolPatientServices?: boolean;
    schoolCode?: string;
    caringCabinetServices?: boolean;
    cabinetItems?: PotentialImproveCaringCabinetItemDto[];
    clinicHealthCheckupServices?: boolean;
    healthCheckupCommunity?: string;
    healthCheckupFileItems?: PotentialImproveHealthCheckupFileItemDto[];
    roboticSurgery?: boolean;
    surgicalOperationPlanFile?: FileUpload;
    hasH25Immediate?: boolean;
    hasH25LongActing?: boolean;
    hasH25Absorbed?: boolean;
    attachmentItems?: PotentialImproveAttachmentItemDto[];
    personItems?: PotentialImprovePersonItemDto[];
    toolItems?: PotentialImproveToolItemDto[];
    locationItems?: PotentialImproveLocationItemDto[];
    filterPersonType?: number[];
}

export interface PotentialImproveLocationItemDto {
    id?: number;
    potentialLocation?: ServicePotentialLocationDto;
    amount?: number;
}

export interface PotentialImprovePersonItemDto {
    id?: number;
    person?: CppMPersonDto;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    hourItems?: RegStaffHourItemDto[];
    totalDay?: number;
    totalHour?: number;
    displayMessage?: { [index: string]: string };
}

export interface PotentialImproveRequestDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    applicant?: ApplicantDto;
    hospital?: HospitalInfo;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    items?: PotentialImproveItemDto[];
}

export interface PotentialImproveToolItemDto {
    id?: number;
    potentialTool?: ServicePotentialToolDto;
    amount?: number;
    applicationNameList?: string[];
    hasMobileLabSp7?: boolean;
    carDetails?: PotentialImproveDentalCarDetailDto[];
    mobileLabSp7WorkTime?: string;
}

export interface PotentialMpersonDto {
    cppMPerson?: CppMPerson;
    licenseNo?: string;
    hcode?: string;
    hname?: string;
    workingType?: WorkingType;
}

export interface PotentialRequestDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    applicant?: ApplicantDto;
    hospital?: HospitalInfo;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    cancelReason?: string;
    dtype?: PotentialFormType;
    officer?: OssUserDto;
    considerDate?: any;
}

export interface PotentialRequestWithItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    applicant?: OssUserDto;
    hospital?: HospitalInfo;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    cancelReason?: string;
    dtype?: PotentialFormType;
    officer?: OssUserDto;
    considerDate?: any;
    itemDesc?: string[];
}

export interface PotentialResignItemDto {
    id?: number;
    type?: TypeDto;
    serviceHospId?: number;
    service?: ServiceDto;
    resignDate?: any;
    reject?: boolean;
}

export interface PotentialResignRequestDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    applicant?: ApplicantDto;
    hospital?: HospitalInfo;
    sendConsiderDate?: any;
    status?: PotentialModifyStatus;
    notApproveReason?: string;
    correctionReason?: string;
    cancelReason?: string;
    items?: PotentialResignItemDto[];
}

export interface PotentialSearchParam {
    userId?: number;
    code?: string;
    name?: string;
    zone?: Zone;
    province?: Province;
    serviceName?: string;
    status?: PotentialModifyStatus;
    type?: PotentialFormType;
    sendDate?: any;
}

export interface PrerequisiteCheck extends Record {
    checkDelegateOfAuthority?: boolean;
    checkBankAccount?: boolean;
    checkAuthorizedDirector?: boolean;
}

export interface ProcessingTypeWithStatus extends Record {
    type?: TypeWithoutChildAndParentDto;
    status?: RegistrationStatus;
    registrationId?: number;
}

export interface ProcessingTypeWithStatusResign extends Record {
    type?: TypeWithoutChildAndParentDto;
    status?: ResignationStatus;
    resignationId?: number;
    addBy?: AddBy;
}

export interface ProviderDto {
    code?: string;
    name?: string;
    provinceName?: string;
    postalCode?: string;
    providerCode?: string;
    hospNo?: string;
    provinceNo?: string;
}

export interface ProvinceDto {
    code?: string;
    name?: string;
    zone?: ZoneDto;
}

export interface PublicHealthServiceDto {
    no?: number;
    date?: any;
    hname?: string;
    codeVender?: string;
    ratePerformanceBond?: number;
    responsiblePerson?: string;
}

export interface PublishCheckParam {
    registrationId?: number;
    hcode?: string;
    hname?: string;
}

export interface PublishDetailDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: HtitleDto;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    bookNumber?: number;
    bookYear?: number;
    fileTypeStatus?: PublishFileTypeStatus;
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    types?: TypeWithoutChildAndParentDto[];
    file?: FileUploadDto;
    lastUpdatedDate?: any;
    applicant?: OssUserDto;
    hospital?: HospitalDto;
    publishedDate?: any;
    fullAddressWithoutComma?: string;
    considerDate?: any;
    signDate?: any;
    signUser?: OssUserDto;
    checkRegType?: CheckRegTypeResult;
    checkHsubType?: CheckRegisterHsubtypeResult;
    property?: RegPropertyDto;
    regLetterGuarantee?: RegLetterGuaranteeDto;
    approvedThaiD?: boolean;
    contractType?: RegalContractType;
    regalContract?: RegalContractDto;
    regOpeningHour?: RegOpeningHourDto;
    sp7List?: RegSp7Dto[];
    budgetYear?: number;
    typeTrans?: TypeTransDto[];
    hid?: number;
    verificationDate?: any;
    effectiveDate?: any;
    companyAddress?: string;
    delegateOfAuthority?: RegDelegateOfAuthorityDto;
}

export interface PublishDto {
    id?: number;
    publishedDate?: any;
    bookNo?: string;
    registration?: RegistrationDto;
    status?: PublishStatus;
    type?: TypeWithoutChildAndParentDto;
    file?: FileUploadDto;
}

export interface PublishInfo {
    id?: number;
    publishedDate?: any;
    status?: PublishStatus;
    bookNo?: string;
    registrationId?: number;
    file?: FileUploadDto;
    type?: TypeWithoutChildAndParentDto;
    statusSync?: boolean;
    fileTypeStatus?: PublishFileTypeStatus;
    signDate?: any;
    bookYear?: number;
    bookNumber?: number;
    signUser?: OssUserDto;
}

export interface PublishSearchDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: HtitleDto;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    bookNumber?: number;
    bookYear?: number;
    fileTypeStatus?: PublishFileTypeStatus;
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    types?: TypeWithoutChildAndParentDto[];
    file?: FileUploadDto;
    lastUpdatedDate?: any;
    applicant?: OssUserDto;
    hospital?: HospitalInfo;
    publishedDate?: any;
    fullAddressWithoutComma?: string;
    considerDate?: any;
    signDate?: any;
    signUser?: OssUserDto;
    regLetterGuarantee?: RegLetterGuaranteeDto;
    approvedThaiD?: boolean;
    contractType?: RegalContractType;
    regalContract?: RegalContractDto;
    regOpeningHour?: RegOpeningHourDto;
    sp7List?: RegSp7Dto[];
    property?: RegPropertyDto;
    budgetYear?: number;
    typeTrans?: TypeTransDto[];
    hid?: number;
    verificationDate?: any;
    effectiveDate?: any;
    companyAddress?: string;
    delegateOfAuthority?: RegDelegateOfAuthorityDto;
}

export interface PublishSearchInfoDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hname?: string;
    htitle?: HtitleDto;
    publishStatus?: PublishStatus;
    bookNumber?: number;
    bookYear?: number;
    types?: TypeWithoutChildAndParentDto[];
    lastUpdatedDate?: any;
    hospital?: HospitalInfo;
    considerDate?: any;
    signDate?: any;
    signUser?: OssUserDto;
    province?: ProvinceDto;
    approvedThaiD?: boolean;
    contractType?: RegalContractType;
    regalContract?: RegalContractDto;
    regLetterGuarantee?: RegLetterGuaranteeDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    regOpeningHour?: RegOpeningHourDto;
    applicant?: OssUserDto;
    sp7List?: RegSp7Dto[];
    property?: RegPropertyDto;
    budgetYear?: number;
    typeTrans?: TypeTransDto[];
    hid?: number;
    verificationDate?: any;
    effectiveDate?: any;
    companyAddress?: string;
    delegateOfAuthority?: RegDelegateOfAuthorityDto;
}

export interface PublishSearchParam {
    hcode?: string;
    hname?: string;
    zone?: Zone;
    approveDate?: any;
    fromConsiderDate?: any;
    toConsiderDate?: any;
    fromSignDate?: any;
    toSignDate?: any;
    signUserName?: string;
    type?: Type;
    fromLetterGuaranteeDate?: any;
    toLetterGuaranteeDate?: any;
    statusList?: PublishStatus[];
    letterGuaranteeType?: LetterGuaranteeType;
    contractType?: RegalContractType;
    approvedThaiD?: boolean;
    province?: Province;
    contractNo?: string;
    companyName?: string;
    juristicId?: string;
    govIn?: boolean;
    verificationDateFrom?: any;
    verificationDateTo?: any;
    typeCodeList?: string[];
    budgetYear?: number;
}

export interface PublishedReportCondition extends ReportCondition {
    fromConsiderDate?: any;
    toConsiderDate?: any;
    fromSignDate?: any;
    toSignDate?: any;
    verificationDateFrom?: any;
    verificationDateTo?: any;
    zone?: ZoneDto;
    province?: ProvinceDto;
    excludePilotProvince?: boolean;
    statusList?: PublishStatus[];
    provinceId?: string;
    statusListIsEmpty?: number;
    excludePilotProvinceAsInteger?: number;
}

export interface RadioAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
}

export interface RateLetterGuaranteeDto {
    id?: number;
    type?: TypeWithoutChildAndParentDto;
    effectiveDate?: any;
    active?: ActiveStatus;
    userUpdate?: OssUserDto;
    companyCharge?: boolean;
    companyAmount?: number;
    companyPopulationRate?: PopulationRate;
    companyExcept?: boolean;
    govCharge?: boolean;
    govAmount?: number;
    govPopulationRate?: PopulationRate;
    govExcept?: boolean;
    hsubtypes?: HsubtypeDto[];
    newService?: boolean;
    oldService?: boolean;
    rateLetterGuaranteeNo?: string;
    rateLetterGuaranteeDate?: any;
    lastUpdatedDate?: any;
}

export interface RateLetterGuaranteeSearchParam {
    zone?: Zone;
    userName?: string;
    active?: ActiveStatus;
    type?: string;
    effectiveDate?: any;
    companyAndGovCharge?: boolean;
    companyAndGovExcept?: boolean;
}

export interface RateLetterGuaranteeTypeDto {
    id?: number;
    charge?: boolean;
    amount?: number;
    populationRate?: PopulationRate;
    except?: boolean;
}

export interface RegAssessItemCheckboxDto extends IRegAssessItem<boolean> {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    value?: boolean;
}

export interface RegAssessItemDateDto extends IRegAssessItem<any> {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    value?: any;
}

export interface RegAssessItemDto extends IRegAssessItem<boolean> {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    value?: boolean;
    textAnswerExtension?: TextAnswerExtensionDto;
    fileAnswerExtension?: FileAnswerExtensionDto;
    radioAnswerExtension?: RadioAnswerExtensionDto;
    checkboxAnswerExtensionMap?: { [index: string]: CheckboxAnswerExtensionDto };
    hcodeAnswerExtensionList?: HcodeAnswerExtensionDto[];
    nhsoValue?: NhsoValue;
    nhsoAutoAssess?: boolean;
}

export interface RegAssessTypeDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    assessType?: AssessType;
    itemMap?: { [index: string]: RegAssessItemDto };
    itemCheckboxMap?: { [index: string]: RegAssessItemCheckboxDto };
    itemDateMap?: { [index: string]: RegAssessItemDateDto };
    questionClassName?: string;
    optionalFlag?: boolean;
    nhsoOpinion?: string;
    nhsoSuggestions?: string;
    fileUpload?: FileUploadDto;
    nhsoConsider?: boolean;
}

export interface RegAssessmentDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    typeMap?: { [P in AssessType]?: RegAssessTypeDto };
    budgetYear?: number;
}

export interface RegAttributeDto {
    id?: number;
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    itemList?: RegAttributeItemDto[];
    population?: PopulationDto;
}

export interface RegAttributeItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
    attribute?: Attribute;
    desc?: string;
    pccCheckDate?: any;
    pccCheckRemark?: string;
    pccDate?: any;
}

export interface RegBankAccountDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    accountNumber?: string;
    accountName?: string;
    bank?: BankDto;
    branch?: string;
    bookBank?: FileUploadDto;
    accountNameEn?: string;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
    branchCode?: string;
    branchEn?: string;
}

export interface RegCardiacSurgeryCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    numberOfBeds?: number;
    cathLab?: boolean;
    cathLabReferralHospital?: HospitalDto;
    generalRadiologyDiagnosis24hrs?: boolean;
    prc?: boolean;
    ffp?: boolean;
    plt?: boolean;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    heartTeamConferenceWithPhysicianPerMonth?: number;
    heartTeamConferenceWithPediatricPerMonth?: number;
    morbidityWithSurgicalTeamPerMonth?: number;
}

export interface RegCardiacSurgeryDirectorDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    fullName?: string;
    pid?: string;
    licenseNo?: string;
    specializedList?: RegDirectorSpecializedDto[];
    totalExperienceOfSurgery?: string;
    numberOfSurgeryInAYear?: string;
}

export interface RegCardiacSurgeryStatisticDto {
    id?: number;
    serviceDesc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    service?: CardiacSurgeryStatistic;
    serviceOtherList?: string[];
}

export interface RegConsiderHistoryDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    registrationId?: number;
    status?: string;
    autoApprove?: boolean;
    considerLevel?: ConsiderLevel;
    officer?: OssUserDto;
    activeDate?: any;
    considerReason?: string;
}

export interface RegCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    radiationTherapyCriteriaMap?: { [index: string]: RegRadiationTherapyCriteriaDto };
    cardiacSurgeryCriteriaMap?: { [index: string]: RegCardiacSurgeryCriteriaDto };
    hsctCriteriaMap?: { [index: string]: RegHSCTCriteriaDto };
}

export interface RegDelegateOfAuthorityDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    organization?: OssOrganizationDto;
    authorizedFile?: FileUploadDto;
    delegateFile?: FileUploadDto;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface RegDepartmentDto {
    checked?: boolean;
    department?: Department;
}

export interface RegDirectorSpecializedDto {
    prectitionCode?: string;
    snameTh?: string;
    snameEn?: string;
    sdate?: any;
}

export interface RegFileDto {
    id?: number;
    registration?: RegistrationDto;
    files?: { [index: string]: FileUploadDto };
    additionalFiles?: AdditionalFileItemDto[];
}

export interface RegHSCTCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfServices?: number;
    totalNotLessThan?: number;
}

export interface RegHospHospitalCreateDto {
    hcode?: string;
    hcode9?: string;
    htitleId?: number;
    hname?: string;
    vendorId?: string;
    dateEffective?: any;
    fstatusId?: string;
    htypeId?: string;
    hsubtypeId?: string;
    hofftypeId?: string;
    catmId?: string;
    address?: string;
    soi?: string;
    road?: string;
    postCode?: string;
    latitude?: number;
    longitude?: number;
    tel?: string;
    email?: string;
    bedAmount?: number;
    contractorName?: string;
    contractorPid?: string;
    contractorTel?: string;
    remark?: string;
    createdUserId?: string;
    typeRegisters?: RegHospTypeRegisterDto[];
}

export interface RegHospHospitalUpdateDto {
    hcode?: string;
    latitude?: number;
    longitude?: number;
    tel?: string;
    email?: string;
    updatedUserId?: string;
    typeRegisters?: RegHospTypeRegisterDto[];
}

export interface RegHospTypeRegisterDto {
    typeCode?: string;
    startDate?: any;
    endDate?: any;
    provinceId?: string;
}

export interface RegISODto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    surgeryDirector?: RegCardiacSurgeryDirectorDto;
}

export interface RegInfoDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    address?: string;
    soi?: string;
    moo?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
    tel?: string;
    email?: string;
    lat?: number;
    lon?: number;
}

export interface RegLetterContractTypeProj {
    typeCode?: string;
    typeId?: number;
    contractNo?: string;
    regalContractItemId?: number;
    hcode?: string;
}

export interface RegLetterGuaranteeDto {
    id?: number;
    guaranteeStatus?: LetterGuaranteeStatus;
    guaranteeType?: LetterGuaranteeType;
    letterGuaranteeDate?: any;
    letterGuaranteeUser?: OssUserDto;
    approveDate?: any;
    approveUser?: OssUserDto;
    charge?: LetterGuaranteeCharge;
    fileLetterGuaranteeItem?: RegLetterGuaranteeItemDto[];
    correctionReason?: string;
    directLinkChecked?: boolean;
    directLinkDate?: any;
}

export interface RegLetterGuaranteeItemDto {
    id?: number;
    file?: FileUploadDto;
    rateLetterGuarantee?: RateLetterGuaranteeDto;
    amount?: number;
    totalAmount?: number;
    fileRequestExcept?: FileUploadDto;
    fileRegistration?: FileUploadDto;
    requestExcept?: boolean;
    letterGuaranteeNo?: string;
    requestDate?: any;
    bank?: BankDto;
    maxValue?: boolean;
    orders?: number;
    letterGuaranteeId?: number;
    lgRequestDetail?: LgRequestDetailDto;
    bgExpireDate?: any;
}

export interface RegOnlyTypeDto {
    id?: number;
    types?: TypeWithoutChildAndParentDto[];
}

export interface RegOpeningHourDto {
    id?: number;
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    openingHourItems?: RegOpeningHourItemDto[];
    totalDay?: number;
    totalHour?: number;
    displayText?: string;
}

export interface RegOpeningHourItemDto {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface RegPCI1PotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: RegRadiationProtectionDto };
}

export interface RegPCI1StatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    desc?: string;
    pci1Statistic?: Pci1Statistic;
}

export interface RegPCI2PotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: RegRadiationProtectionDto };
    referralHospital?: HospitalDto;
}

export interface RegPCI2StatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    desc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    pci2Statistic?: Pci2Statistic;
    serviceOtherList?: string[];
}

export interface RegPh5Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    fullAddress?: string;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUploadDto;
    rawData?: Drugstore;
    licenseCodeStartDateGreaterThanToday?: boolean;
}

export interface RegPotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBeds?: number;
    numberOfOr?: number;
    numberOfIcu?: number;
    numberOfNicu?: number;
    departmentList?: RegDepartmentDto[];
    surgeryPotential?: RegSurgeryPotentialDto;
    pci1Potential?: RegPCI1PotentialDto;
    pci2Potential?: RegPCI2PotentialDto;
}

export interface RegPropertyDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    itemList?: RegPropertyItemDto[];
    itemOtherList?: RegPropertyOtherItemDto[];
    mn4?: string;
    mn4Date?: any;
    sk6?: string;
    sk6Date?: any;
}

export interface RegPropertyItemDto {
    id?: number;
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    value?: boolean;
    property?: Property;
    fileUpload?: FileUploadDto;
    desc?: string;
}

export interface RegPropertyOtherItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
    property?: PropertyOther;
    fileUpload?: FileUploadDto;
    desc?: string;
}

export interface RegQualificationDto {
    id?: number;
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    attribute?: RegAttributeDto;
    property?: RegPropertyDto;
    potential?: RegPotentialDto;
    support?: RegSupportDto;
    openingHour?: RegOpeningHourDto;
    staffList?: RegStaffDto[];
    mode?: RegMode;
    criteria?: RegCriteriaDto;
    iso?: RegISODto;
    statistic?: RegStatisticDto;
}

export interface RegQualificationHospitalDto {
    hmain?: HospitalDto;
    hsubList?: HospitalDto[];
    hreferList?: HospitalDto[];
}

export interface RegQuotaLimitedDto {
    typeName?: string;
    resultText?: string;
}

export interface RegRadiationProtectionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    protectionId?: string;
    protectionClassName?: string;
    desc?: string;
    authorizedDate?: any;
    expiredDate?: any;
    maintainDate?: any;
    numberOfRadiationMetersForASurgeon?: number;
}

export interface RegRadiationTherapyCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaClassName?: string;
    criteriaId?: string;
    desc?: string;
    numberOfRooms?: number;
    fileUpload?: FileUploadDto;
    referralHospital?: HospitalDto;
}

export interface RegSp19Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    fullAddress?: string;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUploadDto;
}

export interface RegSp7Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    subDistrictId?: string;
    postCode?: string;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    fullAddress?: string;
    rawLocation?: OrganizationLocation;
    considerFlag?: boolean;
    considerReason?: string;
    file?: FileUploadDto;
    licenseCodeStartDateGreaterThanToday?: boolean;
}

export interface RegStaffDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    qualificationId?: number;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    cppMPerson?: CppMPersonDto;
    regStaffHourItems?: RegStaffHourItemDto[];
    totalDay?: number;
    totalHour?: number;
    licenseStartDate?: any;
    licenseEndDate?: any;
    course?: string;
    courseDate?: any;
    displayMessage?: { [index: string]: string };
}

export interface RegStaffHourItemDto {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface RegStaffMpersonDto {
    cppMPerson?: CppMPerson;
    licenseNo?: string;
    registrationId?: number;
    workingType?: WorkingType;
}

export interface RegStaffMpersonResultDto {
    hcode?: string;
    hname?: string;
}

export interface RegStatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    year1?: number;
    year2?: number;
    year3?: number;
    cardiacSurgeryList?: RegCardiacSurgeryStatisticDto[];
    pci1StatisticList?: RegPCI1StatisticDto[];
    pci2StatisticList?: RegPCI2StatisticDto[];
}

export interface RegSupportDto {
    id?: number;
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    itemList?: RegSupportItemDto[];
}

export interface RegSupportItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    checked?: boolean;
    support?: Support;
    desc?: string;
}

export interface RegSurgeryPotentialDto {
    pediatricCardiacSurgery?: boolean;
    cardiacSurgery?: boolean;
}

export interface RegTypeDto {
    id?: number;
    types?: TypeWithoutChildAndParentDto[];
    medicalTechTypes?: MedicalTechType[];
    medicalEfficiencyTypes?: MedicalTechType[];
    preventionLabFile?: FileUploadDto;
    otherLabFile?: FileUploadDto;
    hmainOp?: HospitalDto;
    hmain?: HospitalDto;
    hsubList?: HospitalDto[];
    hreferList?: HospitalDto[];
    capitation?: boolean;
}

export interface RegTypeLightDto {
    id?: number;
    types?: TypeWithoutChildAndParentDto[];
}

export interface RegalContractCorrectionResultDto {
    id?: number;
    correctionResult?: string;
}

export interface RegalContractDto {
    id?: number;
    contractType?: RegalContractType;
    contractItems?: RegalContractItemDto[];
    approvedThaiD?: boolean;
    verificationDate?: any;
    verificationUser?: OssUserDto;
    orgOther?: OrgOtherDto;
    attorney?: OssUserDto;
    attorneyPosition?: string;
    attorneyNo?: string;
    attorneyDate?: any;
    titleName?: string;
    sec3MeetingReportNo?: string;
    sec3No?: string;
    sec3Date?: any;
    foundationType?: ContractFoundationType;
    foundationNo?: string;
    foundationDate?: any;
    correctionResult?: string;
    effectiveDate?: any;
    companyAddress?: string;
    delegateAttorneyPosition?: string;
    delegateAttorneyNo?: string;
    delegateAttorneyDate?: any;
}

export interface RegalContractItemDto {
    id?: number;
    file?: FileUploadDto;
    letterGuaranteeTypeItem?: RegLetterGuaranteeItemDto;
    contractNo?: string;
    refNo?: string;
    contractYear?: number;
    medical?: boolean;
    maxMedical?: number;
    hide?: boolean;
}

export interface RegistrationControlState {
    id?: number;
    hcode?: string;
    uuid?: string;
    step?: Step;
    pendingStep?: Step;
    readonly?: boolean;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    step1ReachFlag?: boolean;
    step2ReachFlag?: boolean;
    step3ReachFlag?: boolean;
    step4ReachFlag?: boolean;
    step5ReachFlag?: boolean;
    step6ReachFlag?: boolean;
    showStaffEditableStep1Button?: boolean;
    showStaffEditableStep3Button?: boolean;
}

export interface RegistrationDto {
    id?: number;
    version?: number;
    uuid?: string;
    createdId?: string;
    createdDate?: any;
    lastUpdatedId?: string;
    lastUpdatedDate?: any;
    sp7?: RegSp7Dto;
    sp19?: RegSp19Dto;
    ph5?: RegPh5Dto;
    sp19CitizenId?: string;
    hcode?: string;
    hospital?: HospitalDto;
    htitle?: HtitleDto;
    hcode9?: string;
    hname?: string;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    applicant?: ApplicantDto;
    hofftype?: HofftypeDto;
    province?: ProvinceDto;
    step?: Step;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    cancelReason?: string;
    cancelRegistrationDate?: any;
    acceptConsiderResult?: boolean;
    acceptConsiderDate?: any;
    sendConsiderDate?: any;
    officer?: OfficerDto;
    considerDate?: any;
    considerReason?: string;
    delegateOfAuthority?: RegDelegateOfAuthorityDto;
    bankAccount?: RegBankAccountDto;
    info?: RegInfoDto;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    step1ReachFlag?: boolean;
    step2ReachFlag?: boolean;
    step3ReachFlag?: boolean;
    step4ReachFlag?: boolean;
    step5ReachFlag?: boolean;
    step6ReachFlag?: boolean;
    considerLevel?: ConsiderLevel;
    officerSignature?: string;
    sp7DeniedFlag?: boolean;
    blacklistConsiderFlag?: boolean;
    section3Flag?: boolean;
    section3NoHcode?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
    latestRegistrationFlag?: boolean;
    quotaLimitedList?: RegQuotaLimitedDto[];
    quotaType?: QuotaType;
    templeId?: string;
    temple?: TempleDto;
    sapStatus?: SapStatus;
    sapSendDate?: any;
    sapRequestBody?: string;
    sapResponseMessage?: string;
    sapErrorStackTrace?: string;
    sapOutbound?: string;
    sapInbound?: string;
    sapProcessType?: SapProcessType;
    sapVendorType?: SapVendorType;
    sapActionType?: SapActionType;
    sapIgnoreFlag?: boolean;
    manualFormFlag?: boolean;
    useInvalidData?: boolean;
    isPrivateHospitalOwnByFoundation?: boolean;
}

export interface RegistrationFileStep5Dto {
    id?: number;
    files?: { [index: string]: FileUploadWithConfigDto };
    additionalFiles?: AdditionalFileItemDto[];
}

export interface RegistrationSearchDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    status?: RegistrationStatus;
    applicant?: ApplicantDto;
    autoApprove?: boolean;
    officer?: OfficerDto;
    sendConsiderDate?: any;
    considerDate?: any;
    lastUpdatedDate?: any;
    considerLevel?: ConsiderLevel;
    step?: Step;
    orgId?: string;
    provinceId?: string;
    zoneCode?: string;
    catm?: string;
    types?: TypeWithoutChildAndParentDto[];
    zone?: ZoneInfo;
    acceptConsiderResult?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
    province?: Province;
    thaidDate?: any;
    guaranteeStatus?: LetterGuaranteeStatus;
    publishStatus?: PublishStatus;
    useInvalidData?: boolean;
    hospitalAddressMismatch?: boolean;
    manualNoHcode?: boolean;
    manualBySp7?: boolean;
    manualByPh5?: boolean;
}

export interface RegistrationSearchParam {
    name?: string;
    hcode?: string;
    hname?: string;
    type?: Type;
    considerDate?: any;
    status?: RegistrationStatus;
    zone?: Zone;
    province?: Province;
    district?: District;
    nameOfficer?: string;
    manualForm?: boolean;
    manualBySp7?: boolean;
    manualByPh5?: boolean;
    manualBySp7Ph5?: boolean;
    addressNotMatches?: boolean;
}

export interface RegistrationStep5Dto {
    id?: number;
    version?: number;
    uuid?: string;
    step?: Step;
    status?: RegistrationStatus;
    htype?: HtypeDto;
    hofftype?: HofftypeDto;
    hsubtype?: HsubtypeDto;
    regType?: RegOnlyTypeDto;
    regFile?: RegistrationFileStep5Dto;
    templeId?: string;
    temple?: TempleDto;
}

export interface RenewReportCondition extends ReportCondition {
    budgetYear?: string;
    assessType?: string;
    assessmentStatus?: string;
    zone?: string;
    province?: string;
}

export interface RenewRoundDto {
    budgetYear?: number;
    startDate?: any;
    endDate?: any;
}

export interface RenewedHospital {
    hcode?: string;
    hname?: string;
    htypeName?: string;
    hsubtypeName?: string;
    regSp7Dto?: RegSp7Dto;
    regSp19Dto?: RegSp19Dto;
    blackList?: boolean;
    renewedHospitalRegistrationList?: RenewedHospitalRegistration[];
    haveAssessment?: boolean;
}

export interface RenewedHospitalRegistration {
    registrationId?: number;
    status?: RegistrationStatus;
    typeList?: TypeWithoutChildAndParentDto[];
    registrationOrder?: number;
    sendConsiderDate?: any;
    applicantFullName?: string;
    assessmentResult?: AssessmentResult;
}

export interface ReportCondition {
    attachment?: boolean;
    fileType?: string;
    reportName?: string;
    reportPath?: string;
    className?: string;
    page?: number;
    hcode?: string;
    hname?: string;
    considerDate?: string;
    typeCode?: string;
    name?: string;
    status?: string;
    zoneCode?: string;
    provinceCode?: string;
    personalId?: string;
    delegateAuthName?: string;
    verificationDate?: string;
    lastUpdatedDate?: string;
    approver?: string;
    accountNumber?: string;
    accountName?: string;
    branch?: string;
    bankId?: string;
    createdDate?: string;
    orgId?: string;
    qrCodeParam?: PaymentParam;
    fileRootPath?: string;
    date?: string;
    condition?: string;
}

export interface ReportRenewSearchParam {
    zoneId?: string;
    provinceId?: string;
    hcode?: string;
    hname?: string;
    budgetYear?: number;
    assessType?: AssessType;
    status?: AssessmentResult;
}

export interface RequestVerifySP19 {
    citizenId?: string;
    sp7?: RegSp7Dto;
}

export interface ResignationDto {
    id?: number;
    hospital?: HospitalDto;
    resignDate?: any;
    resignationTypes?: ResignationTypeDto[];
    approvedThaiId?: boolean;
    status?: ResignationStatus;
    addBy?: AddBy;
    notApproveReason?: string;
    applicant?: OssUserDto;
    moreThan180Days?: boolean;
    directorComments?: string;
    haveImpact?: boolean;
    impactSuggestions?: string;
    items?: ResignationItemDto[];
    approve?: boolean;
    approveDetails?: string;
    contractNumber?: string;
    contractEndDate?: any;
    rejectReason?: string;
    rejectContractNumber?: string;
}

export interface ResignationItemDto {
    id?: number;
    department?: DirectorDepartment;
    found?: boolean;
    cause?: string;
    lastUpdatedDate?: any;
    currentSave?: boolean;
}

export interface ResignationPublishDto {
    id?: number;
    resignation?: ResignationDto;
    file?: FileUploadDto;
    fileTypeStatus?: ResignPublishFileTypeStatus;
    publishedDate?: any;
    signDate?: any;
    signUser?: OssUserDto;
    status?: ResignationPublishStatus;
    bookNo?: string;
    bookNumber?: number;
    bookYear?: number;
}

export interface ResignationSearchParam {
    code?: string;
    name?: string;
    zone?: string;
    status?: ResignationStatus;
    publishStatus?: ResignationPublishStatus;
    resignDate?: any;
    publishedDate?: any;
    type?: TypeDto;
}

export interface ResignationTypeDto {
    id?: number;
    type?: TypeDto;
    checked?: boolean;
    contractNumber?: string;
    regalContractItem?: RegalContractItemDto;
}

export interface ResponseZoneConfigDto {
    code?: string;
    zone?: ZoneDto;
    name?: OssUserDto;
}

export interface ResponsibleZoneConfigDto {
    code?: string;
    responsibleName?: string;
}

export interface RevertStatusDto {
    id?: number;
    reason?: string;
    status?: RegistrationStatus;
}

export interface ReviewLetterGuaranteeDto {
    id?: number;
    amount?: number;
    guaranteeType?: LetterGuaranteeType;
    bank?: BankDto;
    items?: ReviewLetterGuaranteeItemDto[];
    serviceItems?: ReviewLetterGuaranteeServiceItemDto[];
    file?: FileUploadDto;
    status?: ReviewLetterGuaranteeStatus;
    applicant?: OssUserDto;
    expireDate?: any;
    noneExpire?: boolean;
    correctionResult?: string;
    nhsoFile?: FileUploadDto;
}

export interface ReviewLetterGuaranteeImportDto {
    id?: number;
    hcode?: string;
    districtId?: string;
    name?: string;
    contract?: string;
    amount?: number;
    regLetterGuarantee?: RegLetterGuaranteeDto;
}

export interface ReviewLetterGuaranteeItemDto {
    id?: number;
    letterGuaranteeNo?: string;
    lgRequestDetail?: LgRequestDetailDto;
    requestDate?: any;
}

export interface ReviewLetterGuaranteeSearchParam {
    hcode?: string;
    hname?: string;
    zone?: Zone;
    approveDate?: any;
    fromConsiderDate?: any;
    toConsiderDate?: any;
    type?: Type;
    fromLetterGuaranteeDate?: any;
    toLetterGuaranteeDate?: any;
    statusList?: ReviewLetterGuaranteeStatus[];
    letterGuaranteeType?: LetterGuaranteeType;
    contractType?: RegalContractType;
    province?: Province;
    contractNo?: string;
    companyName?: string;
    juristicId?: string;
    verificationDateFrom?: any;
    verificationDateTo?: any;
}

export interface ReviewLetterGuaranteeServiceItemDto {
    id?: number;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    address?: string;
    contractNo?: string;
    amount?: number;
    regLetterGuarantee?: RegLetterGuaranteeDto;
}

export interface SMTContractPublicHealthReceiveDataRequest {
    contractType?: string;
    refNo?: string;
    docDate?: any;
    contractNo?: string;
    contractDate?: any;
    contractStartDate?: any;
    contractEndDate?: any;
    vendorId?: string;
    vendorName?: string;
    provinceName?: string;
    vendorType?: string;
    guaranteeAmount?: number;
    contractStatus?: string;
    guaranteeStatus?: string;
    guaranteeList?: string;
    cash?: number;
    depositBank?: string;
    depositDate?: any;
    runningNumber?: number;
    dated?: any;
    bank?: string;
    bgAmount?: number;
    bgNo?: string;
    bgDate?: string;
    bgExpireDate?: string;
    remark?: string;
    elgKTBAmount?: number;
    elgNo?: string;
    elgDate?: string;
    elgExpireDate?: string;
    ref1?: string;
    ref2?: string;
}

export interface SMTDirectLinkReceiveDataRequest {
    ref1?: string;
    ref2?: string;
    amount?: number;
    vendorName?: string;
    vendorId?: string;
    productCode?: string;
    billerId?: string;
}

export interface SMTReceiveDataResponse {
    statusCode?: string;
    statusMessage?: string;
}

export interface SapBulkChangeDto {
    vendorIdList?: string[];
}

export interface SapBulkChangeResponse {
    response?: string;
}

export interface SapCheckDto {
    registrationId?: number;
    sapActionType?: SapActionType;
    sapProcessType?: SapProcessType;
    sapStatus?: SapStatus;
}

export interface SapPageDataDto {
    id?: number;
    uuid?: string;
    hcode?: string;
    hcode9?: string;
    hname?: string;
    htitle?: HtitleDto;
    registrationStatus?: RegistrationStatus;
    publishStatus?: PublishStatus;
    province?: ProvinceDto;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
    bookNumber?: number;
    bookYear?: number;
    file?: FileUploadDto;
    lastUpdatedDate?: any;
    applicant?: OssUserDto;
    hospital?: HospitalDto;
    publishedDate?: any;
    considerDate?: any;
    signDate?: any;
    contractType?: RegalContractType;
    sapStatus?: SapStatus;
    sapSendDate?: any;
    sapRequestBody?: string;
    sapResponseMessage?: string;
    sapErrorStackTrace?: string;
    verificationDate?: any;
    sapProcessType?: SapProcessType;
    sapIgnoreFlag?: boolean;
    sapOutbound?: string;
    sapInbound?: string;
    sapActionType?: SapActionType;
}

export interface SapSearchParam {
    hcode?: string;
    hname?: string;
    zone?: Zone;
    approveDate?: any;
    fromConsiderDate?: any;
    toConsiderDate?: any;
    fromSignDate?: any;
    toSignDate?: any;
    signUserName?: string;
    contractType?: RegalContractType;
    approvedThaiD?: boolean;
    province?: Province;
    contractNo?: string;
    companyName?: string;
    juristicId?: string;
    verificationDateFrom?: any;
    verificationDateTo?: any;
}

export interface SapStaffDto {
    registrationId?: number;
    vendorName?: string;
    taxId?: string;
    sapVendorType?: SapVendorType;
}

export interface SapVendorDto {
    sapVendorType?: SapVendorType;
    registration?: Registration;
    taxId?: string;
    repName?: string;
}

export interface ServiceDto {
    serviceId?: number;
    typeId?: number;
    serviceCode?: string;
    serviceName?: string;
    serviceDesc?: string;
    serviceParent?: number;
    orderSeq?: number;
    used?: string;
}

export interface ServiceHospDto {
    serviceHospId?: number;
    typeHospId?: number;
    serviceId?: number;
    service?: ServiceDto;
    startDate?: any;
    endDate?: any;
    used?: string;
}

export interface ServiceMasterDto {
    serviceCode?: string;
    serviceName?: string;
}

export interface ServicePotentialAttachmentDto {
    id?: number;
    name?: string;
    active?: ActiveStatus;
    lastUpdateUser?: OssUserDto;
}

export interface ServicePotentialDto {
    id?: number;
    service?: ServiceMasterDto;
    attachmentItem?: ServicePotentialAttachmentDto[];
    personTypeItem?: CppMPersonDto[];
    toolItem?: ServicePotentialToolDto[];
    locationItem?: ServicePotentialLocationDto[];
    attachmentValidateType?: PotentialValidateType;
    personValidateType?: PotentialValidateType;
}

export interface ServicePotentialLocationDto {
    id?: number;
    name?: string;
    active?: ActiveStatus;
    lastUpdateUser?: OssUserDto;
}

export interface ServicePotentialSearchParam {
    name?: string;
    active?: ActiveStatus;
    userName?: string;
    service?: ServicePotentialServiceDto;
}

export interface ServicePotentialServiceDto {
    id?: number;
    typeId?: string;
    typeName?: string;
    serviceParentId?: string;
    serviceCode?: string;
    serviceName?: string;
}

export interface ServicePotentialToolDto {
    id?: number;
    name?: string;
    active?: ActiveStatus;
}

export interface ServiceRegisterDto {
    id?: number;
    hospitalTypeRegisterId?: number;
    serviceId?: number;
    startDate?: any;
    endDate?: any;
}

export interface SubDistrictDto {
    code?: string;
    name?: string;
    district?: DistrictDto;
    catm?: string;
}

export interface TempleDto {
    id?: string;
    title?: string;
    name?: string;
    dateEffective?: any;
    catm?: CatmDto;
    address?: string;
    soi?: string;
    road?: string;
    province?: ProvinceDto;
    postCode?: string;
    tel?: string;
    fax?: string;
    website?: string;
    email?: string;
    used?: string;
    fullName?: string;
    htitle?: Htitle;
    catmCode?: string;
    htype?: HtypeDto;
    hsubtype?: HsubtypeDto;
}

export interface TextAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: string;
    nhsoValue?: string;
}

export interface TypeChangeResult extends Record {
    isChanged?: boolean;
    codeList?: TypeChangeDiff[];
}

export interface TypeChangeDiff extends Record {
    code?: string;
    name?: string;
    diffType?: DiffType;
}

export interface TypeDto {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    typeParent?: number;
    orderSeq?: number;
    used?: string;
}

export interface TypeNodeDto {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    typeParent?: number;
    subTypes?: TypeNodeDto[];
    orderSeq?: number;
    used?: string;
}

export interface TypeTransDto {
    typeHospId?: number;
    hid?: number;
    typeId?: number;
    type?: TypeDto;
    startDate?: any;
    endDate?: any;
    provinceIdRegis?: string;
    hrib?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface TypeWithoutChildAndParentDto {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    orderSeq?: number;
    used?: string;
}

export interface UpdateHospitalRequest {
    registrationId?: number;
    hcode?: string;
    hname?: string;
}

export interface UserAuthorityDto {
    username?: string;
    position?: string;
    nameTh?: string;
    titleName?: string;
    authorities?: Authority[];
    announceNumber?: string;
    announceDate?: string;
}

export interface UserAuthoritySearchParam {
    fullName?: string;
    position?: string;
}

export interface ZoneCoordinatorDto {
    id?: number;
    zone?: ZoneDto;
    name?: string;
    organizationName?: string;
    email?: string;
    link?: string;
    mobile?: string;
    active?: ActiveStatus;
    note?: string;
    responseProvinceCode?: string[];
}

export interface ZoneCoordinatorSearchParam {
    zone?: ZoneDto;
    name?: string;
    organizationName?: string;
    email?: string;
    link?: string;
    mobile?: string;
    active?: ActiveStatus;
}

export interface ZoneDto {
    code?: string;
    name?: string;
}

export interface ZoneEmailDto {
    code?: string;
    name?: string;
    emails?: string[];
}

export interface ZoneInfo {
    code?: string;
    name?: string;
}

export interface ZoningDistrictDto {
    id?: number;
    district?: DistrictDto;
    total?: number;
    used?: number;
}

export interface ZoningDto {
    id?: number;
    zone?: ZoneDto;
    orgType?: QuotaOrgType;
    startDate?: any;
    endDate?: any;
    type?: TypeWithoutChildAndParentDto;
    total?: number;
    used?: number;
    provinces?: ZoningProvinceDto[];
    lastUpdateUser?: OssUserDto;
    modifyUser?: OssUserDto;
}

export interface ZoningProvinceDto {
    id?: number;
    province?: ProvinceDto;
    total?: number;
    used?: number;
    districts?: ZoningDistrictDto[];
}

export interface ZoningQuotaResult {
    resultText?: string;
    typeName?: string;
}

export interface HosAdditionalFileItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    displayText?: string;
    type?: AddForType;
    file?: FileUploadDto;
}

export interface HosAssessItemCheckboxDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    questionId?: string;
    value?: boolean;
    autoAssess?: boolean;
    autoAssessNote?: string;
    additionalDesc?: string;
}

export interface HosAssessItemDateDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    questionId?: string;
    value?: any;
    autoAssess?: boolean;
    autoAssessNote?: string;
    additionalDesc?: string;
}

export interface HosAssessItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    questionId?: string;
    value?: boolean;
    autoAssess?: boolean;
    autoAssessNote?: string;
    additionalDesc?: string;
    textAnswerExtension?: HosTextAnswerExtensionDto;
    fileAnswerExtension?: HosFileAnswerExtensionDto;
    radioAnswerExtension?: HosRadioAnswerExtensionDto;
    checkboxAnswerExtensionMap?: { [index: string]: HosCheckboxAnswerExtensionDto };
    hcodeAnswerExtensionList?: HosHcodeAnswerExtensionDto[];
    nhsoValue?: NhsoValue;
    nhsoAutoAssess?: boolean;
}

export interface HosAssessTypeDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    assessType?: AssessType;
    questionClassName?: string;
    itemMap?: { [index: string]: HosAssessItemDto };
    itemCheckboxMap?: { [index: string]: HosAssessItemCheckboxDto };
    itemDateMap?: { [index: string]: HosAssessItemDateDto };
    optionalFlag?: boolean;
    nhsoOpinion?: string;
    nhsoSuggestions?: string;
    nhsoConsider?: boolean;
    fileUpload?: FileUploadDto;
}

export interface HosAssessmentDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    typeMap?: { [index: string]: HosAssessTypeDto };
    budgetYear?: number;
}

export interface HosAttributeDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    itemList?: HosAttributeItemDto[];
    population?: HosPopulationDto;
}

export interface HosAttributeItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
    attribute?: Attribute;
    pccCheckDate?: any;
    pccCheckRemark?: string;
    pccDate?: any;
}

export interface HosBankAccountDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    accountNumber?: string;
    accountName?: string;
    accountNameEn?: string;
    addressEn?: string;
    ampurNameEn?: string;
    provinceNameEn?: string;
    bank?: BankDto;
    branch?: string;
    branchEn?: string;
    branchCode?: string;
    bookBank?: FileUploadDto;
}

export interface HosCardiacSurgeryCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    numberOfBeds?: number;
    cathLab?: boolean;
    cathLabReferralHospital?: HospitalDto;
    generalRadiologyDiagnosis24hrs?: boolean;
    prc?: boolean;
    ffp?: boolean;
    plt?: boolean;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    heartTeamConferenceWithPhysicianPerMonth?: number;
    heartTeamConferenceWithPediatricPerMonth?: number;
    morbidityWithSurgicalTeamPerMonth?: number;
}

export interface HosCardiacSurgeryDirectorDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    fullName?: string;
    pid?: string;
    licenseNo?: string;
    specializedList?: HosDirectorSpecializedDto[];
    totalExperienceOfSurgery?: string;
    numberOfSurgeryInAYear?: string;
}

export interface HosCardiacSurgeryStatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    serviceDesc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    service?: CardiacSurgeryStatistic;
    serviceOtherList?: string[];
}

export interface HosCheckboxAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    label?: string;
    value?: boolean;
}

export interface HosCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    radiationTherapyCriteriaMap?: { [index: string]: HosRadiationTherapyCriteriaDto };
    cardiacSurgeryCriteriaMap?: { [index: string]: HosCardiacSurgeryCriteriaDto };
    hsctCriteriaMap?: { [index: string]: HosHSCTCriteriaDto };
}

export interface HosDelegateOfAuthorityDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    personalId?: string;
    name?: string;
    executivePersonalId?: string;
    executiveName?: string;
    organization?: OssOrganizationDto;
    authorizedFile?: FileUploadDto;
    delegateFile?: FileUploadDto;
    approvedThaiId?: boolean;
    verificationDate?: any;
    expireDate?: any;
    status?: DelegateStatus;
    authorizedPersonalId?: string;
    authorizedName?: string;
    authorizedApprovedThaiId?: boolean;
    authorizedVerificationDate?: any;
    delegateType?: DelegateType;
    notApproveReason?: string;
    authorized?: boolean;
}

export interface HosDepartmentDto {
    checked?: boolean;
    department?: Department;
}

export interface HosDirectorSpecializedDto {
    prectitionCode?: string;
    snameTh?: string;
    snameEn?: string;
    sdate?: any;
}

export interface HosFileAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: FileUploadDto;
}

export interface HosFileDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    files?: { [index: string]: FileUploadDto };
    additionalFiles?: AdditionalFileItemDto[];
}

export interface HosHSCTCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfServices?: number;
    totalNotLessThan?: number;
}

export interface HosHcodeAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    hcode?: string;
}

export interface HosISODto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    surgeryDirector?: HosCardiacSurgeryDirectorDto;
}

export interface HosInfoDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    address?: string;
    soi?: string;
    moo?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
    tel?: string;
    email?: string;
    lat?: number;
    lon?: number;
}

export interface HosOpeningHourDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    openingHourItems?: HosOpeningHourItemDto[];
    totalDay?: number;
    totalHour?: number;
}

export interface HosOpeningHourItemDto {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface HosPCI1PotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: HosRadiationProtectionDto };
}

export interface HosPCI1StatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci1Statistic?: Pci1StatisticDto;
    desc?: string;
}

export interface HosPCI2PotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBedsInCcu?: number;
    prcUnit?: number;
    ffpUnit?: number;
    pltUnit?: number;
    numberOfHemodialysis?: number;
    numberOfPortableHemodialysis?: number;
    protectionMap?: { [index: string]: HosRadiationProtectionDto };
    referralHospital?: HospitalDto;
}

export interface HosPCI2StatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    lastBudgetYearTotal?: number;
    currentBudgetYearTotal?: number;
    pci2Statistic?: Pci2StatisticDto;
    desc?: string;
    year1TotalEveryRight?: number;
    year1TotalUc?: number;
    year2TotalEveryRight?: number;
    year2TotalUc?: number;
    year3TotalEveryRight?: number;
    year3TotalUc?: number;
    serviceOtherList?: string[];
}

export interface HosPh5Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
}

export interface HosPopulationDto {
    populationInArea?: number;
    rightsPopulation?: number;
}

export interface HosPotentialDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    numberOfBeds?: number;
    numberOfOr?: number;
    numberOfIcu?: number;
    numberOfNicu?: number;
    departmentList?: HosDepartmentDto[];
    surgeryPotential?: HosSurgeryPotentialDto;
    pci1Potential?: HosPCI1PotentialDto;
    pci2Potential?: HosPCI2PotentialDto;
}

export interface HosPropertyDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    itemList?: HosPropertyItemDto[];
    itemOtherList?: HosPropertyOtherItemDto[];
    mn4?: string;
    mn4Date?: any;
    sk6?: string;
    sk6Date?: any;
}

export interface HosPropertyItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
    property?: Property;
    fileUpload?: FileUploadDto;
}

export interface HosPropertyOtherItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
    property?: PropertyOther;
    fileUpload?: FileUploadDto;
}

export interface HosQualificationDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    attribute?: HosAttributeDto;
    property?: HosPropertyDto;
    potential?: HosPotentialDto;
    openingHour?: HosOpeningHourDto;
    support?: HosSupportDto;
    staffList?: HosStaffDto[];
    mode?: RegMode;
    criteria?: HosCriteriaDto;
    iso?: HosISODto;
    statistic?: HosStatisticDto;
}

export interface HosRadiationProtectionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    protectionId?: string;
    protectionClassName?: string;
    desc?: string;
    authorizedDate?: any;
    expiredDate?: any;
    maintainDate?: any;
    numberOfRadiationMetersForASurgeon?: number;
}

export interface HosRadiationTherapyCriteriaDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    criteriaId?: string;
    criteriaClassName?: string;
    desc?: string;
    numberOfRooms?: number;
    fileUpload?: FileUploadDto;
    referralHospital?: HospitalDto;
}

export interface HosRadioAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: boolean;
}

export interface HosRegistrationDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    version?: number;
    uuid?: string;
    sp7?: HosSp7Dto;
    sp19?: HosSp19Dto;
    sp19CitizenId?: string;
    ph5?: HosPh5Dto;
    delegateOfAuthority?: HosDelegateOfAuthorityDto;
    bankAccount?: HosBankAccountDto;
    info?: HosInfoDto;
    hcode?: string;
    htitle?: HtitleDto;
    hospital?: HospitalDto;
    hcode9?: string;
    step?: Step;
    hname?: string;
    htype?: HtypeDto;
    hofftype?: HofftypeDto;
    hsubtype?: HsubtypeDto;
    province?: ProvinceDto;
    applicant?: ApplicantDto;
    type?: HosType;
    qualification?: HosQualificationDto;
    assessment?: HosAssessmentDto;
    file?: HosFileDto;
    status?: RegistrationStatus;
    autoApprove?: boolean;
    cancelReason?: string;
    cancelRegistrationDate?: any;
    acceptConsiderResult?: boolean;
    acceptConsiderDate?: any;
    sendConsiderDate?: any;
    considerLevel?: ConsiderLevel;
    officerSignature?: string;
    officer?: OfficerDto;
    considerDate?: any;
    considerReason?: string;
    thaidPid?: string;
    thaidName?: string;
    thaidDate?: any;
    step1ReachFlag?: boolean;
    step2ReachFlag?: boolean;
    step3ReachFlag?: boolean;
    step4ReachFlag?: boolean;
    step5ReachFlag?: boolean;
    step6ReachFlag?: boolean;
    sp7DeniedFlag?: boolean;
    blacklistConsiderFlag?: boolean;
    section3Flag?: boolean;
    section3NoHcode?: boolean;
    active?: ActiveStatus;
    requestType?: RequestType;
    latestRegistrationFlag?: boolean;
    templeId?: string;
    temple?: TempleDto;
}

export interface HosSp19Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    postCode?: string;
}

export interface HosSp7Dto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    principalPersonId?: string;
    principalPersonNameTh?: string;
    medicalAdditionalServices?: string;
    bedCapacity?: string;
    characteristicTextTh?: string;
    addressNo?: string;
    name?: string;
    licenseCode?: string;
    type?: string;
    workTime1?: string;
    workTime2?: string;
    licenseCodeStartDate?: any;
    licenseCodeEndDate?: any;
    licenseType?: HospitalLicenseType;
    moo?: string;
    soi?: string;
    road?: string;
    subDistrict?: SubDistrictDto;
    subDistrictId?: string;
    postCode?: string;
}

export interface HosStaffDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    qualificationId?: number;
    pid?: string;
    licenseNo?: string;
    fullName?: string;
    workingType?: WorkingType;
    addon?: boolean;
    cppMPerson?: CppMPersonDto;
    hosStaffHourItems?: HosStaffHourItemDto[];
    totalDay?: number;
    totalHour?: number;
    licenseStartDate?: any;
    licenseEndDate?: any;
    course?: string;
    courseDate?: any;
}

export interface HosStaffHourItemDto {
    day?: HraDayOfWeek;
    timeOpening?: any;
    timeClosing?: any;
    timeOpening2?: any;
    timeClosing2?: any;
    timeOpening3?: any;
    timeClosing3?: any;
    open?: boolean;
}

export interface HosStatisticDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    year1?: number;
    year2?: number;
    year3?: number;
    cardiacSurgeryList?: HosCardiacSurgeryStatisticDto[];
    pci1StatisticList?: HosPCI1StatisticDto[];
    pci2StatisticList?: HosPCI2StatisticDto[];
}

export interface HosSupportDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    itemList?: HosSupportItemDto[];
}

export interface HosSupportItemDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    checked?: boolean;
    support?: Support;
}

export interface HosSurgeryPotentialDto {
    pediatricCardiacSurgery?: boolean;
    cardiacSurgery?: boolean;
}

export interface HosTextAnswerExtensionDto {
    createdId?: number;
    createdDate?: any;
    lastUpdatedId?: number;
    lastUpdatedDate?: any;
    id?: number;
    value?: string;
    nhsoValue?: string;
}

export interface Pci1StatisticDto {
    id?: number;
    desc?: string;
    type?: StatType;
}

export interface Pci2StatisticDto {
    id?: number;
    desc?: string;
    type?: StatType;
}

export interface inquiryLgDetailResponse {
    responseCode?: string;
    responseDesc?: string;
    timestamp?: string;
    lgRequestDetail?: LgRequestDetailDto;
}

export interface PdfSigningWithPosition {
    cad?: string;
    certify?: string;
    contentData?: string;
    location?: string;
    reason?: string;
    visibleSignature?: boolean;
    visibleSignatureImagePath?: string;
    visibleSignaturePage?: number;
    visibleSignatureRectangle?: string;
    workerName?: string;
}

export interface PdfSigningWithPositionResponses extends Responses404 {
}

export interface Responses404 {
    description?: string;
    status?: string;
    signingResponse?: string;
}

export interface BlacklistBoardDto {
    id?: number;
    name?: string;
}

export interface BlacklistDto {
    id?: number;
    zone?: string;
    province?: string;
    hcode?: string;
    name?: string;
    effectiveDate?: any;
    sp19PersonName?: string;
    address?: string;
    juristicName?: string;
    authorizedSignatory?: string;
    board1?: string;
    board2?: string;
    board3?: string;
    board4?: string;
    board5?: string;
    board6?: string;
    board7?: string;
    board8?: string;
    board9?: string;
    board10?: string;
    board11?: string;
    note?: string;
    durationDate?: number;
    boards?: BlacklistBoardDto[];
    status?: BlacklistStatus;
    alternativeDate?: string;
}

export interface BlacklistSearchParam {
    zone?: string;
    fromEffectiveDate?: any;
    toEffectiveDate?: any;
    code?: string;
    name?: string;
    juristicName?: string;
    boardName?: string;
    status?: BlacklistStatus;
    sp19Name?: string;
}

export interface BlacklistXlsDto {
    id?: number;
    zone?: string;
    province?: string;
    hcode?: string;
    name?: string;
    effectiveDate?: string;
    sp19PersonName?: string;
    address?: string;
    juristicName?: string;
    authorizedSignatory?: string;
    board1?: string;
    board2?: string;
    board3?: string;
    board4?: string;
    board5?: string;
    board6?: string;
    board7?: string;
    board8?: string;
    board9?: string;
    board10?: string;
    board11?: string;
    note?: string;
}

export interface CheckBlacklistDto {
    blacklist?: BlacklistDto;
    registration?: RegistrationDto;
}

export interface Catm {
    catm?: string;
    name?: string;
    changwatName?: string;
    amphurName?: string;
    tumbonName?: string;
    moobanName?: string;
    moo?: string;
}

export interface Fstatus {
    fstatusId?: string;
    fstatusName?: string;
    fstatusDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Hlevel {
    hlevelId?: number;
    hlevelDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Hmodel {
    hmodelId?: string;
    hmodelName?: string;
    hmodelDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Hofftype {
    hofftypeId?: string;
    hofftypeDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Hospital {
    hid?: number;
    refHid?: number;
    hcode?: string;
    hcode9?: string;
    hcodeInterior?: string;
    htitle?: Htitle;
    hname?: string;
    vendorId?: string;
    dateeffective?: any;
    hlevel?: number;
    hcodeSss?: string;
    catmCode?: string;
    catm?: Catm;
    address?: string;
    moo?: string;
    soi?: string;
    road?: string;
    tumbonName?: string;
    zoneAddress?: string;
    provinceId?: string;
    province?: Province;
    postCode?: string;
    tel?: string;
    fax?: string;
    website?: string;
    email?: string;
    crossProvince?: string;
    bed?: number;
    contractorName?: string;
    contractorPid?: string;
    mobile?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    typeTransactions?: TypeTrans[];
    htype?: Htype;
    hsubtype?: Hsubtype;
    hofftype?: Hofftype;
    lat?: number;
    lon?: number;
    htitleHname?: string;
    fullAddress?: string;
    fullAddressWithoutComma?: string;
    finclusive?: string;
    fuc?: string;
    fhsss?: string;
    fstatus?: string;
}

export interface HospitalLight {
    hid?: number;
    hcode9?: string;
    hcode?: string;
    htitle?: Htitle;
    hname?: string;
    used?: string;
    htype?: Htype;
    hsubtype?: Hsubtype;
    hofftype?: Hofftype;
    htitleHname?: string;
}

export interface HospitalWithHTitle {
    hid?: number;
    hcode?: string;
    htitle?: Htitle;
    hname?: string;
}

export interface Hrib {
    hribId?: number;
    hribValue?: string;
    hribDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Hsubtype {
    hsubtypeId?: string;
    htypeId?: string;
    hsubtypeDesc?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    htype?: Htype;
}

export interface Htitle {
    htitleId?: number;
    htitleName?: string;
    shortName?: string;
    used?: string;
}

export interface Htype {
    htypeId?: string;
    htypeName?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    hsubtypes?: Hsubtype[];
}

export interface ModelHosp {
    hmodelHospId?: number;
    hid?: number;
    hmodel?: string;
    startDate?: any;
    endDate?: any;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface PotentHosp {
    potentHospId?: number;
    typeHospId?: number;
    potentialityId?: number;
    startDate?: any;
    endDate?: any;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Potentiality {
    potentialityId?: number;
    typeId?: number;
    potentialityCode?: string;
    potentialityName?: string;
    potentialityDesc?: string;
    potentialityParent?: number;
    orderSeq?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Province {
    code?: string;
    name?: string;
    zone?: Zone;
}

export interface Service {
    serviceId?: number;
    typeId?: number;
    serviceCode?: string;
    serviceName?: string;
    serviceDesc?: string;
    serviceParent?: number;
    orderSeq?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface ServiceHosp {
    serviceHospId?: number;
    typeHospId?: number;
    serviceId?: number;
    service?: Service;
    startDate?: any;
    endDate?: any;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface StatusUc {
    statusId?: string;
    statusName?: string;
    remark?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface Tree {
    parent?: Tree;
    sub?: Tree[];
}

export interface Type {
    id?: number;
    code?: string;
    name?: string;
    description?: string;
    typeParent?: number;
    parent?: Type;
    subTypes?: Type[];
    orderSeq?: number;
    used?: string;
}

export interface TypeGroup {
    typegroupId?: number;
    typeId?: number;
    groupId?: string;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
}

export interface TypeTrans {
    typeHospId?: number;
    hid?: number;
    typeId?: number;
    type?: Type;
    startDate?: any;
    endDate?: any;
    provinceIdRegis?: string;
    hrib?: number;
    used?: string;
    createDate?: any;
    createBy?: string;
    updateDate?: any;
    updateBy?: string;
    hospital?: Hospital;
}

export interface Zone {
    code?: string;
    name?: string;
    hname?: string;
    hmain?: string;
}

export interface Pageable {
    offset?: number;
    sort?: any;
    unpaged?: boolean;
    pageSize?: number;
    paged?: boolean;
    pageNumber?: number;
}

export interface UserProfileMetadata {
    attributes?: UserProfileAttributeMetadata[];
    groups?: UserProfileAttributeGroupMetadata[];
}

export interface CredentialRepresentation {
    id?: string;
    type?: string;
    userLabel?: string;
    createdDate?: number;
    secretData?: string;
    credentialData?: string;
    priority?: number;
    value?: string;
    temporary?: boolean;
    /**
     * @deprecated
     */
    device?: string;
    /**
     * @deprecated
     */
    hashedSaltedValue?: string;
    /**
     * @deprecated
     */
    salt?: string;
    /**
     * @deprecated
     */
    hashIterations?: number;
    /**
     * @deprecated
     */
    counter?: number;
    /**
     * @deprecated
     */
    algorithm?: string;
    /**
     * @deprecated
     */
    digits?: number;
    /**
     * @deprecated
     */
    period?: number;
    /**
     * @deprecated
     */
    config?: { [index: string]: string[] };
}

export interface FederatedIdentityRepresentation {
    identityProvider?: string;
    userId?: string;
    userName?: string;
}

export interface UserConsentRepresentation {
    clientId?: string;
    grantedClientScopes?: string[];
    createdDate?: number;
    lastUpdatedDate?: number;
    /**
     * @deprecated
     */
    grantedRealmRoles?: string[];
}

export interface SocialLinkRepresentation {
    socialProvider?: string;
    socialUserId?: string;
    socialUsername?: string;
}

export interface AbstractUserRepresentation {
    id?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    emailVerified?: boolean;
    attributes?: { [index: string]: string[] };
    userProfileMetadata?: UserProfileMetadata;
}

export interface Data {
    statusActive?: boolean;
    license_id?: number;
    status?: string;
}

export interface Record {
}

export interface BankVendor {
    sapProviderCode?: string;
    name?: string;
    providerTypeName?: string;
    providerName?: string;
    address?: string;
    vatId?: string;
    provinceName?: string;
}

export interface MultipartFile extends InputStreamSource {
    name?: string;
    bytes?: any;
    empty?: boolean;
    resource?: Resource;
    size?: number;
    originalFilename?: string;
    contentType?: string;
}

export interface Streamable<T> extends Supplier<Stream<T>> {
    empty?: boolean;
}

export interface UserProfileAttributeMetadata {
    name?: string;
    displayName?: string;
    required?: boolean;
    readOnly?: boolean;
    annotations?: { [index: string]: any };
    validators?: { [index: string]: { [index: string]: any } };
    group?: string;
    multivalued?: boolean;
}

export interface UserProfileAttributeGroupMetadata {
    name?: string;
    displayHeader?: string;
    displayDescription?: string;
    annotations?: { [index: string]: any };
}

export interface Resource extends InputStreamSource {
    open?: boolean;
    file?: any;
    readable?: boolean;
    url?: URL;
    description?: string;
    uri?: URI;
    filename?: string;
    contentAsByteArray?: any;
}

export interface InputStreamSource {
    inputStream?: any;
}

export interface URL {
}

export interface URI extends Comparable<URI> {
}

export interface Supplier<T> {
}

export interface Stream<T> extends BaseStream<T, Stream<T>> {
}

export interface Comparable<T> {
}

export interface BaseStream<T, S> extends AutoCloseable {
    parallel?: boolean;
}

export interface AutoCloseable {
}

export type State = "TEMP" | "STORED";

export type HospitalSize = "SMALL" | "MEDIUM" | "LARGE";

export type CppBed = "IP" | "ICU" | "NICU";

export type ActiveStatus = "A" | "C";

export type AddBy = "CLIENT" | "OFFICER";

export type AddForType = "CLIENT" | "OFFICER";

export type AnswerType = "RADIO" | "CHECKBOX" | "DATE";

export type AssessType = "P" | "H" | "R01" | "R01_EXTRA" | "R02" | "R020201" | "R020202" | "R0203" | "R0204" | "R0205" | "R0219_OLD" | "R0219_NEW";

export type AssessTypeGroup = "P_H_R01" | "R02" | "OTHER";

export type AssessmentResult = "NO_RESULT" | "PASS" | "DO_NOT_PASS" | "NOT_PROCESS" | "PENDING";

export type Authority = "nhso" | "zone" | "company" | "personal" | "ga" | "hospital" | "province" | "aumphur" | "admin" | "hra" | "executive" | "register_staff" | "zone_director" | "finance_director" | "compensation_director" | "before_compensation_director" | "compensation_audit_director" | "legal_protection_director" | "assistant_secretary_general" | "healthlink";

export type BloodComponent = "PRC" | "FFP" | "PLT";

export type ConditionType = "P" | "H" | "R01" | "R02" | "R01_R02" | "R0201" | "R0202" | "R0203" | "R0204" | "R0205" | "R020201" | "R020202";

export type ConsiderLevel = "NHSO" | "ZONE" | "ANY" | "EXECUTIVE";

export type ContractFoundationType = "ASSOCIATION" | "MN_4" | "SK_6" | "LICENSE_HOSPITAL" | "DISABLED_CENTER_COMMITTEE" | "BLIND" | "DISABLED" | "REPORT_NO" | "COMMUNITY_NO" | "DIRECTOR_NO" | "COMMUNITY_WELFARE_NO" | "FOUNDATION_NO" | "OKP_3";

export type CriteriaType = "RT";

export type DelegateStatus = "WAIT" | "NOT_APPROVE" | "VERIFIED";

export type DelegateType = "AUTHORIZED_PERSON" | "OFFICER";

export type Department = "OB_GYN" | "SUR" | "MED" | "PED" | "DENTAL";

export type DirectorDepartment = "A_FINANCE" | "B_COMPENSATION" | "C_BEFORE_COMPENSATION" | "D_COMPENSATION_AUDIT" | "E_LEGAL_PROTECTION" | "F_PUBLIC_SERVICE";

export type EmployeeStatus = "I" | "A";

export type HospitalLicenseType = "SP7" | "SP19" | "PH5";

export type HraDayOfWeek = "MONDAY" | "TUESDAY" | "WEDNESDAY" | "THURSDAY" | "FRIDAY" | "SATURDAY" | "SUNDAY" | "HOLIDAY";

export type LetterGuaranteeCharge = "CHARGE" | "NONE" | "EXCEPT";

export type LetterGuaranteeStatus = "PENDING" | "WAIT" | "APPROVE" | "CORRECTION";

export type LetterGuaranteeType = "CASH" | "LG" | "E_LG" | "EXCEPT" | "NONE";

export type NhsoValue = "VALUE_FALSE" | "VALUE_TRUE" | "VALUE_NO_SELECT";

export type NonInvasiveACSService = "SERVICE1" | "SERVICE2" | "SERVICE3" | "SERVICE4" | "SERVICE5" | "SERVICE6";

export type PopulationRate = "L2000" | "M2000" | "M4000" | "M6000" | "M8000" | "M10000" | "M12000" | "M16000" | "M18000" | "M20000" | "PL10000" | "PM10000";

export type PotentialFormType = "PotentialImproveRequest" | "PotentialResignRequest";

export type PotentialModifyStatus = "WAIT" | "CORRECTION" | "REJECT" | "CANCELED" | "APPROVED";

export type PotentialValidateType = "ALL" | "SOME" | "NONE";

export type PropertyOption = "NONE" | "OPTION1";

export type PropertyType = "ALL" | "COMPANY" | "GOV";

export type PublishContractType = "PUBLISH" | "CONTRACT";

export type PublishFileTypeStatus = "NEW" | "ADDITION";

export type PublishStatus = "REVIEW" | "CORRECTION_CONTRACT" | "PREPARE_FOR_CONTRACT" | "PREPARE_FOR_PUBLISH" | "PUBLISHED";

export type QuotaOrgType = "GOV" | "PRIVATE";

export type QuotaType = "DISTRICT" | "PROVINCE" | "ZONE";

export type RegMode = "CREATE" | "UPDATE";

export type RegalContractType = "AGREEMENT" | "CONTRACT";

export type RegistrationStatus = "PENDING" | "CANCELED" | "VERIFYING_BLACKLIST" | "BLACKLIST" | "CONSIDERING" | "CORRECTION" | "APPROVED" | "DENIED";

export type RenewRegistrationStatus = "PENDING" | "CANCELED" | "VERIFYING_BLACKLIST" | "BLACKLIST" | "CONSIDERING" | "CORRECTION" | "APPROVED" | "DENIED";

export type RequestType = "REGISTER" | "RENEW" | "RESIGN";

export type ResignPublishFileTypeStatus = "REMAIN" | "NO_REMAIN";

export type ResignationPublishStatus = "PREPARE_FOR_PUBLISH" | "PUBLISHED";

export type ResignationStatus = "WAIT" | "WAIT_DIRECTOR" | "WAIT_FOUND" | "WAIT_APPROVAL" | "PREPARE_REJECT" | "REJECT" | "APPROVED";

export type ReviewLetterGuaranteeStatus = "PENDING" | "CONSIDERING" | "CORRECTION" | "APPROVED";

export type SapActionType = "AUTO" | "BY_STAFF";

export type SapProcessType = "CREATE" | "CHANGE";

export type SapStatus = "SUCCESS" | "FAIL";

export type SapVendorType = "TYPE_01" | "TYPE_02" | "TYPE_03";

export type StatType = "SERVICE" | "CARDIAC_SURGERY" | "PCI1_NON_INVASIVE" | "PCI2_NON_INVASIVE";

export type Step = "STEP1" | "STEP2" | "STEP3" | "STEP4" | "STEP5" | "STEP6";

export type TypeEnum = "P" | "H" | "R0101" | "R0102" | "R020201" | "R020202" | "R0203" | "R0204" | "R020401" | "R020402" | "R020403" | "R020404" | "R0205";

export type WorkingType = "PART_TIME" | "FULL_TIME";

export type DiffType = "ADDED" | "REMOVED";

export type Certify = "NON_CERTIFY" | "CERTIFY";

export type OrgType = "GOV" | "COMPANY" | "PERSONAL" | "PRIVATE" | "FOUNDATION" | "ASSOCIATION" | "DISABLED_SERVICE_CENTER" | "VACCINATION_CENTER" | "CIVIL_SOCIETY" | "TEMPLE" | "GA" | "CLUB" | "CENTRE" | "HEALTH_ESTABLISHMENT" | "OTHER_RELIGIOUS_ORGANIZATION" | "PRIVATE_ORGANIZATION" | "NURSING_HOME" | "GROUP";

export type FromType = "H" | "A" | "P" | "Z" | "O" | "C" | "T" | "D";

export type Source = "DC" | "OSS" | "LDAP";

export type BlacklistStatus = "BLACKLISTED" | "CANCEL_BLACKLIST";


// Added by 'EnumTypeAliasExtension' extension
export type AllEnumClass = "State" | "HospitalSize" | "CppBed" | "ActiveStatus" | "AddBy" | "AddForType" | "AnswerType" | "AssessType" | "AssessTypeGroup" | "AssessmentResult" | "Authority" | "BloodComponent" | "ConditionType" | "ConsiderLevel" | "ContractFoundationType" | "CriteriaType" | "DelegateStatus" | "DelegateType" | "Department" | "DirectorDepartment" | "EmployeeStatus" | "HospitalLicenseType" | "HraDayOfWeek" | "LetterGuaranteeCharge" | "LetterGuaranteeStatus" | "LetterGuaranteeType" | "NhsoValue" | "NonInvasiveACSService" | "PopulationRate" | "PotentialFormType" | "PotentialModifyStatus" | "PotentialValidateType" | "PropertyOption" | "PropertyType" | "PublishContractType" | "PublishFileTypeStatus" | "PublishStatus" | "QuotaOrgType" | "QuotaType" | "RegMode" | "RegalContractType" | "RegistrationStatus" | "RenewRegistrationStatus" | "RequestType" | "ResignPublishFileTypeStatus" | "ResignationPublishStatus" | "ResignationStatus" | "ReviewLetterGuaranteeStatus" | "SapActionType" | "SapProcessType" | "SapStatus" | "SapVendorType" | "StatType" | "Step" | "TypeEnum" | "WorkingType" | "DiffType" | "Certify" | "OrgType" | "FromType" | "Source" | "BlacklistStatus"
// Enum description
//skip State because it not have getValue() method
//skip HospitalSize because it not have getValue() method
//skip CppBed because it not have getValue() method
export const ActiveStatus : {[p in ActiveStatus] : string } = {
   A:'ใช้งาน',
   C:'ยกเลิก'
}
export const AddBy : {[p in AddBy] : string } = {
   CLIENT:'หน่วยบริการ',
   OFFICER:'เจ้าหน้าที่'
}
export const AddForType : {[p in AddForType] : string } = {
   CLIENT:'สำหรับหน่วยบริการ',
   OFFICER:'สำหรับเจ้าหน้าที่'
}
//skip AnswerType because it not have getValue() method
export const AssessType : {[p in AssessType] : string } = {
   P:'หน่วยบริการปฐมภูมิ',
   H:'หน่วยบริการประจำ',
   R01:'หน่วยบริการที่รับการส่งต่อทั่วไป',
   R01_EXTRA:'ด้านคุณภาพในระบบงานสำคัญของโรงพยาบาล',
   R02:'หน่วยบริการที่รับส่งต่อเฉพาะด้าน',
   R020201:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านการทำหัตถการรักษาโรคหลอดเลือดโคโรนารีผ่านสายสวน ระดับ 1',
   R020202:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านการทำหัตถการรักษาโรคหลอดเลือดโคโรนารีผ่านสายสวน ระดับ 2',
   R0203:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านการรักษาโรคหัวใจและหลอดเลือดด้วยวิธีการผ่าตัด',
   R0204:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านการบริการปลูกถ่ายเซลล์ต้นกำเนิดเม็ดโลหิต',
   R0205:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านรังสีรักษาสำหรับผู้ป่วยโรคมะเร็ง',
   R0219_OLD:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านบริการล้างไตผ่านทางช่องท้อง (หน่วยเก่า)',
   R0219_NEW:'หน่วยบริการที่รับการส่งต่อเฉพาะด้านบริการล้างไตผ่านทางช่องท้อง (หน่วยใหม่)'
}
//skip AssessTypeGroup because it not have getValue() method
export const AssessmentResult : {[p in AssessmentResult] : string } = {
   NO_RESULT:'ยังไม่ทราบผล',
   PASS:'ผ่านการตรวจประเมิน',
   DO_NOT_PASS:'ไม่ผ่านการตรวจประเมิน',
   NOT_PROCESS:'ยังไม่ดำเนินการ',
   PENDING:'กำลังดำเนินการ'
}
//skip Authority because it not have getValue() method
//skip BloodComponent because it not have getValue() method
//skip ConditionType because it not have getValue() method
export const ConsiderLevel : {[p in ConsiderLevel] : string } = {
   NHSO:'จนท.ส่วนกลาง',
   ZONE:'จนท.เขต',
   ANY:'ส่วนกลาง หรือเขต',
   EXECUTIVE:'ผู้บริการ'
}
export const ContractFoundationType : {[p in ContractFoundationType] : string } = {
   ASSOCIATION:'ใบอนุญาตจัดตั้งสมาคมหรือองค์การ',
   MN_4:'ใบสำคัญแสดงการจดทะเบียนการเปลี่ยนแปลงกรรมการของมูลนิธิ(ม.น.4)',
   SK_6:'ใบสำคัญแสดงการจดทะเบียนการเปลี่ยนแปลงกรรมการของสมาคม(ส.ค.6)',
   LICENSE_HOSPITAL:'ใบอนุญาตให้ประกอบกิจการสถานพยาบาล',
   DISABLED_CENTER_COMMITTEE:'หนังสือเปลี่ยนแปลงคณะกรรมการศูนย์คนพิการ',
   BLIND:'คำสั่งศูนย์บริการคนตาบอด',
   DISABLED:'ใบอนุญาตจัดตั้งศูนย์บริการคนพิการทั่วไป',
   REPORT_NO:'รายงานการประชุม',
   COMMUNITY_NO:'ใบสำคัญแสดงการรับรองเป็นองค์กรสวัสดิการชุมชน',
   DIRECTOR_NO:'ใบสำคัญแสดงการจดทะเบียนแต่งตั้งกรรมการ',
   COMMUNITY_WELFARE_NO:'ใบสำคัญแสดงการรับรองเป็นองค์กรสวัสดิการชุมชน',
   FOUNDATION_NO:'ใบสำคัญแสดงการจดทะเบียนจัดตั้งมูลนิธิ',
   OKP_3:'ประกาศนายทะเบียนกลาง (แบบ อกผ.3)'
}
export const CriteriaType : {[p in CriteriaType] : string } = {
   RT:'Radiation Therapy - รังสีรักษา'
}
export const DelegateStatus : {[p in DelegateStatus] : string } = {
   WAIT:'รอตรวจสอบ',
   NOT_APPROVE:'ไม่อนุมัติ',
   VERIFIED:'ตรวจสอบแล้ว'
}
export const DelegateType : {[p in DelegateType] : string } = {
   AUTHORIZED_PERSON:'ผู้มีอำนาจลงนาม',
   OFFICER:'เจ้าหน้าที่'
}
export const Department : {[p in Department] : string } = {
   OB_GYN:'สูตินารีเวช',
   SUR:'ศัลยกรรม',
   MED:'อายุรกรรม',
   PED:'กุมารเวชกรรม',
   DENTAL:'ทันตกรรม'
}
export const DirectorDepartment : {[p in DirectorDepartment] : string } = {
   A_FINANCE:'ฝ่ายการเงินและบัญชีกองทุน',
   B_COMPENSATION:'ฝ่ายบริหารการจัดสรรและชดเชยค่าบริการ',
   C_BEFORE_COMPENSATION:'ฝ่ายตรวจสอบก่อนการจ่ายชดเชยค่าบริการ',
   D_COMPENSATION_AUDIT:'ฝ่ายตรวจสอบหลังการจ่ายชดเชยและคุณภาพบริการ',
   E_LEGAL_PROTECTION:'ฝ่ายกฎหมายพิทักษ์สิทธิ์',
   F_PUBLIC_SERVICE:'สำนักบริการประชาชน'
}
export const EmployeeStatus : {[p in EmployeeStatus] : string } = {
   I:'ยกเลิก',
   A:'ใช้งาน'
}
export const HospitalLicenseType : {[p in HospitalLicenseType] : string } = {
   SP7:'สพ.7',
   SP19:'สพ.19',
   PH5:'ขย.5'
}
//skip HraDayOfWeek because it not have getValue() method
export const LetterGuaranteeCharge : {[p in LetterGuaranteeCharge] : string } = {
   CHARGE:'เรียกเก็บ',
   NONE:'ไม่มีการเรียกเก็บ',
   EXCEPT:'ยกเว้นค่าหลักประกันสัญญา'
}
export const LetterGuaranteeStatus : {[p in LetterGuaranteeStatus] : string } = {
   PENDING:'ระหว่างจัดทำ',
   WAIT:'รอตรวจสอบ',
   APPROVE:'ตรวจสอบแล้ว',
   CORRECTION:'สั่งแก้ไข'
}
export const LetterGuaranteeType : {[p in LetterGuaranteeType] : string } = {
   CASH:'เงินสด',
   LG:'LG',
   E_LG:'e-LG',
   EXCEPT:'ยกเว้น',
   NONE:'ไม่เรียกเก็บ'
}
//skip NhsoValue because it not have getValue() method
export const NonInvasiveACSService : {[p in NonInvasiveACSService] : string } = {
   SERVICE1:'จำนวนการตรวจหัวใจด้วยคลื่นเสียง (echocardiogram) (ราย)',
   SERVICE2:'จำนวนการทดสอบสมรรถภาพหัวใจด้วยการวิ่งสายพาน (exercise stress test) (ครั้ง)',
   SERVICE3:'จำนวนการทดสอบสมรรถภาพหัวใจด้วยการใช้ยา (dobutamine stress test) (ครั้ง)',
   SERVICE4:'การตรวจเอกซเรย์คอมพิวเตอร์หัวใจ (cardiac CT scan) (ครั้ง)',
   SERVICE5:'การตรวจภาพทางการแพทย์ด้วยคลื่นแม่เหล็ก (cardiac MRI) (ครั้ง)',
   SERVICE6:'การตรวจหัวใจทางเวชศาสตร์นิวเคลียร์(ครั้ง)'
}
export const PopulationRate : {[p in PopulationRate] : string } = {
   L2000:'จำนวนประชากรน้อยกว่า 2,000 คน',
   M2000:'จำนวนประชากรมากกว่า 2,001 - 4,000 คน',
   M4000:'จำนวนประชากรมากกว่า 4,001 - 6,000 คน',
   M6000:'จำนวนประชากรมากกว่า 6,001 - 8,000 คน',
   M8000:'จำนวนประชากรมากกว่า 8,001 - 10,000 คน',
   M10000:'จำนวนประชากรมากกว่า 10,001 - 12,000 คน',
   M12000:'จำนวนประชากรมากกว่า 12,001 - 14,000 คน',
   M16000:'จำนวนประชากรมากกว่า 16,001 - 18,000 คน',
   M18000:'จำนวนประชากรมากกว่า 18,001 - 20,000 คน',
   M20000:'จำนวนประชากรมากกว่า 20,001 - 40,000 คน',
   PL10000:'จำนวนประชากรน้อยกว่า 10,000 คน',
   PM10000:'จำนวนประชากรมากกว่า 10,000 คน ขึ้นไป'
}
export const PotentialFormType : {[p in PotentialFormType] : string } = {
   PotentialImproveRequest:'เพิ่มศักยภาพ',
   PotentialResignRequest:'ยกเลิกศักยภาพ'
}
export const PotentialModifyStatus : {[p in PotentialModifyStatus] : string } = {
   WAIT:'รอพิจารณา',
   CORRECTION:'สั่งแก้ไข',
   REJECT:'ไม่อนุมัติ',
   CANCELED:'ยกเลิกคำขอ',
   APPROVED:'อนุมัติ'
}
export const PotentialValidateType : {[p in PotentialValidateType] : string } = {
   ALL:'บังคับเลือกทุกประเภท',
   SOME:'อย่างใดอย่างนึง',
   NONE:'ไม่บังคับเลือก'
}
export const PropertyOption : {[p in PropertyOption] : string } = {
   NONE:'Not support',
   OPTION1:'อัพโหลดไฟล์'
}
export const PropertyType : {[p in PropertyType] : string } = {
   ALL:'ภาครัฐและเอกชน',
   COMPANY:'เอกชน',
   GOV:'ภาครัฐ'
}
export const PublishContractType : {[p in PublishContractType] : string } = {
   PUBLISH:'ลงนามประกาศ',
   CONTRACT:'นิติกรรมสัญญา'
}
export const PublishFileTypeStatus : {[p in PublishFileTypeStatus] : string } = {
   NEW:'สมัครใหม่',
   ADDITION:'แก้ไขเพิ่มเติม'
}
export const PublishStatus : {[p in PublishStatus] : string } = {
   REVIEW:'รอจัดทำนิติกรรมสัญญา',
   CORRECTION_CONTRACT:'สั่งแก้ไขนิติกรรมสัญญา',
   PREPARE_FOR_CONTRACT:'รอตรวจสอบนิติกรรมสัญญา',
   PREPARE_FOR_PUBLISH:'รอลงนาม',
   PUBLISHED:'ลงนามแล้ว'
}
export const QuotaOrgType : {[p in QuotaOrgType] : string } = {
   GOV:'รัฐบาล',
   PRIVATE:'เอกชน'
}
export const QuotaType : {[p in QuotaType] : string } = {
   DISTRICT:'อำเภอ',
   PROVINCE:'จังหวัด',
   ZONE:'เขต'
}
//skip RegMode because it not have getValue() method
export const RegalContractType : {[p in RegalContractType] : string } = {
   AGREEMENT:'ข้อตกลงให้บริการสาธารณสุข',
   CONTRACT:'สัญญาให้บริการสาธารณสุข'
}
export const RegistrationStatus : {[p in RegistrationStatus] : string } = {
   PENDING:'ระหว่างจัดทำ',
   CANCELED:'ยกเลิกการสมัคร',
   VERIFYING_BLACKLIST:'รอตรวจสอบ (Blacklist)',
   BLACKLIST:'ปฏิเสธการรับสมัคร (Blacklist)',
   CONSIDERING:'รอพิจารณา',
   CORRECTION:'สั่งแก้ไข',
   APPROVED:'อนุมัติ',
   DENIED:'ปฏิเสธการรับสมัคร'
}
export const RenewRegistrationStatus : {[p in RenewRegistrationStatus] : string } = {
   PENDING:'ระหว่างจัดทำ',
   CANCELED:'ยกเลิกการประเมิน',
   VERIFYING_BLACKLIST:'รอตรวจสอบ (Blacklist)',
   BLACKLIST:'ปฏิเสธการประเมิน (Blacklist)',
   CONSIDERING:'รอพิจารณา',
   CORRECTION:'สั่งแก้ไข',
   APPROVED:'อนุมัติ',
   DENIED:'ปฏิเสธการประเมิน'
}
//skip RequestType because it not have getValue() method
export const ResignPublishFileTypeStatus : {[p in ResignPublishFileTypeStatus] : string } = {
   REMAIN:'ประกาศเปลี่ยนแปลงแบบลดบ้างประเภทขึ้นทะเภท',
   NO_REMAIN:'ประกาศยกเลิกหน่วยบริการแบบไม่เหลือประเภท'
}
export const ResignationPublishStatus : {[p in ResignationPublishStatus] : string } = {
   PREPARE_FOR_PUBLISH:'รอลงนาม',
   PUBLISHED:'ลงนามประกาศ'
}
export const ResignationStatus : {[p in ResignationStatus] : string } = {
   WAIT:'รอพิจารณา',
   WAIT_DIRECTOR:'รอพิจารณาข้อมูลเพิ่มเติม',
   WAIT_FOUND:'รอพิจารณา(พบข้อมูล)',
   WAIT_APPROVAL:'รอตรวจสอบผลการอนุมัติ',
   PREPARE_REJECT:'รอจัดทำหนังสือแจ้งปฏิเสธ',
   REJECT:'ปฏิเสธการแจ้งลาออก',
   APPROVED:'อนุมัติแจ้งลาออก'
}
export const ReviewLetterGuaranteeStatus : {[p in ReviewLetterGuaranteeStatus] : string } = {
   PENDING:'ระหว่างจัดทำ',
   CONSIDERING:'รอพิจารณา',
   CORRECTION:'สั่งแก้ไข',
   APPROVED:'อนุมัติ'
}
//skip SapActionType because it not have getValue() method
//skip SapProcessType because it not have getValue() method
//skip SapStatus because it not have getValue() method
export const SapVendorType : {[p in SapVendorType] : string } = {
   TYPE_01:'01',
   TYPE_02:'02',
   TYPE_03:'03'
}
export const StatType : {[p in StatType] : string } = {
   SERVICE:'',
   CARDIAC_SURGERY:'',
   PCI1_NON_INVASIVE:'',
   PCI2_NON_INVASIVE:''
}
export const Step : {[p in Step] : string } = {
   STEP1:'ข้อมูลทั่วไป',
   STEP2:'ประเภทการขอขึ้นทะเบียน',
   STEP3:'คุณสมบัติ',
   STEP4:'ประเมิน',
   STEP5:'อัปโหลดไฟล์',
   STEP6:'การพิจารณา'
}
//skip TypeEnum because it not have getValue() method
export const WorkingType : {[p in WorkingType] : string } = {
   PART_TIME:'ปฏิบัติงานที่หน่วยเป็นบางวัน',
   FULL_TIME:'ปฏิบัติงานที่หน่วยทุกวัน'
}
//skip DiffType because it not have getValue() method
//skip Certify because it not have getValue() method
export const OrgType : {[p in OrgType] : string } = {
   GOV:'รัฐ',
   COMPANY:'นิติบุคคล',
   PERSONAL:'บุคคลธรรมดา',
   PRIVATE:'เอกชน',
   FOUNDATION:'มูลนิธิ',
   ASSOCIATION:'สมาคม',
   DISABLED_SERVICE_CENTER:'ศูนย์บริการคนพิการ',
   VACCINATION_CENTER:'ศูนย์ฉีดวัคซีน',
   CIVIL_SOCIETY:'องค์กรภาคประชาสังคม',
   TEMPLE:'วัด',
   GA:'Government Agency',
   CLUB:'ชมรม',
   CENTRE:'ศูนย์',
   HEALTH_ESTABLISHMENT:'สถานประกอบการเพื่อสุขภาพ',
   OTHER_RELIGIOUS_ORGANIZATION:'องค์กรศาสนาอื่น',
   PRIVATE_ORGANIZATION:'องค์กรเอกชน',
   NURSING_HOME:'Nursing Home',
   GROUP:'กลุ่ม'
}
export const FromType : {[p in FromType] : string } = {
   H:'hospital',
   A:'amphur',
   P:'province',
   Z:'zone',
   O:'nhso',
   C:'c',
   T:'t',
   D:'d'
}
//skip Source because it not have getValue() method
export const BlacklistStatus : {[p in BlacklistStatus] : string } = {
   BLACKLISTED:'ติด Blacklist',
   CANCEL_BLACKLIST:'ยกเลิกการติด Blacklist'
}


// Added by 'EnumTypeArrayExtension' extension
export const StateArray : State[]  = ['TEMP','STORED']
export const HospitalSizeArray : HospitalSize[]  = ['SMALL','MEDIUM','LARGE']
export const CppBedArray : CppBed[]  = ['IP','ICU','NICU']
export const ActiveStatusArray : ActiveStatus[]  = ['A','C']
export const AddByArray : AddBy[]  = ['CLIENT','OFFICER']
export const AddForTypeArray : AddForType[]  = ['CLIENT','OFFICER']
export const AnswerTypeArray : AnswerType[]  = ['RADIO','CHECKBOX','DATE']
export const AssessTypeArray : AssessType[]  = ['P','H','R01','R01_EXTRA','R02','R020201','R020202','R0203','R0204','R0205','R0219_OLD','R0219_NEW']
export const AssessTypeGroupArray : AssessTypeGroup[]  = ['P_H_R01','R02','OTHER']
export const AssessmentResultArray : AssessmentResult[]  = ['NO_RESULT','PASS','DO_NOT_PASS','NOT_PROCESS','PENDING']
export const AuthorityArray : Authority[]  = ['nhso','zone','company','personal','ga','hospital','province','aumphur','admin','hra','executive','register_staff','zone_director','finance_director','compensation_director','before_compensation_director','compensation_audit_director','legal_protection_director','assistant_secretary_general','healthlink']
export const BloodComponentArray : BloodComponent[]  = ['PRC','FFP','PLT']
export const ConditionTypeArray : ConditionType[]  = ['P','H','R01','R02','R01_R02','R0201','R0202','R0203','R0204','R0205','R020201','R020202']
export const ConsiderLevelArray : ConsiderLevel[]  = ['NHSO','ZONE','ANY','EXECUTIVE']
export const ContractFoundationTypeArray : ContractFoundationType[]  = ['ASSOCIATION','MN_4','SK_6','LICENSE_HOSPITAL','DISABLED_CENTER_COMMITTEE','BLIND','DISABLED','REPORT_NO','COMMUNITY_NO','DIRECTOR_NO','COMMUNITY_WELFARE_NO','FOUNDATION_NO','OKP_3']
export const CriteriaTypeArray : CriteriaType[]  = ['RT']
export const DelegateStatusArray : DelegateStatus[]  = ['WAIT','NOT_APPROVE','VERIFIED']
export const DelegateTypeArray : DelegateType[]  = ['AUTHORIZED_PERSON','OFFICER']
export const DepartmentArray : Department[]  = ['OB_GYN','SUR','MED','PED','DENTAL']
export const DirectorDepartmentArray : DirectorDepartment[]  = ['A_FINANCE','B_COMPENSATION','C_BEFORE_COMPENSATION','D_COMPENSATION_AUDIT','E_LEGAL_PROTECTION','F_PUBLIC_SERVICE']
export const EmployeeStatusArray : EmployeeStatus[]  = ['I','A']
export const HospitalLicenseTypeArray : HospitalLicenseType[]  = ['SP7','SP19','PH5']
export const HraDayOfWeekArray : HraDayOfWeek[]  = ['MONDAY','TUESDAY','WEDNESDAY','THURSDAY','FRIDAY','SATURDAY','SUNDAY','HOLIDAY']
export const LetterGuaranteeChargeArray : LetterGuaranteeCharge[]  = ['CHARGE','NONE','EXCEPT']
export const LetterGuaranteeStatusArray : LetterGuaranteeStatus[]  = ['PENDING','WAIT','APPROVE','CORRECTION']
export const LetterGuaranteeTypeArray : LetterGuaranteeType[]  = ['CASH','LG','E_LG','EXCEPT','NONE']
export const NhsoValueArray : NhsoValue[]  = ['VALUE_FALSE','VALUE_TRUE','VALUE_NO_SELECT']
export const NonInvasiveACSServiceArray : NonInvasiveACSService[]  = ['SERVICE1','SERVICE2','SERVICE3','SERVICE4','SERVICE5','SERVICE6']
export const PopulationRateArray : PopulationRate[]  = ['L2000','M2000','M4000','M6000','M8000','M10000','M12000','M16000','M18000','M20000','PL10000','PM10000']
export const PotentialFormTypeArray : PotentialFormType[]  = ['PotentialImproveRequest','PotentialResignRequest']
export const PotentialModifyStatusArray : PotentialModifyStatus[]  = ['WAIT','CORRECTION','REJECT','CANCELED','APPROVED']
export const PotentialValidateTypeArray : PotentialValidateType[]  = ['ALL','SOME','NONE']
export const PropertyOptionArray : PropertyOption[]  = ['NONE','OPTION1']
export const PropertyTypeArray : PropertyType[]  = ['ALL','COMPANY','GOV']
export const PublishContractTypeArray : PublishContractType[]  = ['PUBLISH','CONTRACT']
export const PublishFileTypeStatusArray : PublishFileTypeStatus[]  = ['NEW','ADDITION']
export const PublishStatusArray : PublishStatus[]  = ['REVIEW','CORRECTION_CONTRACT','PREPARE_FOR_CONTRACT','PREPARE_FOR_PUBLISH','PUBLISHED']
export const QuotaOrgTypeArray : QuotaOrgType[]  = ['GOV','PRIVATE']
export const QuotaTypeArray : QuotaType[]  = ['DISTRICT','PROVINCE','ZONE']
export const RegModeArray : RegMode[]  = ['CREATE','UPDATE']
export const RegalContractTypeArray : RegalContractType[]  = ['AGREEMENT','CONTRACT']
export const RegistrationStatusArray : RegistrationStatus[]  = ['PENDING','CANCELED','VERIFYING_BLACKLIST','BLACKLIST','CONSIDERING','CORRECTION','APPROVED','DENIED']
export const RenewRegistrationStatusArray : RenewRegistrationStatus[]  = ['PENDING','CANCELED','VERIFYING_BLACKLIST','BLACKLIST','CONSIDERING','CORRECTION','APPROVED','DENIED']
export const RequestTypeArray : RequestType[]  = ['REGISTER','RENEW','RESIGN']
export const ResignPublishFileTypeStatusArray : ResignPublishFileTypeStatus[]  = ['REMAIN','NO_REMAIN']
export const ResignationPublishStatusArray : ResignationPublishStatus[]  = ['PREPARE_FOR_PUBLISH','PUBLISHED']
export const ResignationStatusArray : ResignationStatus[]  = ['WAIT','WAIT_DIRECTOR','WAIT_FOUND','WAIT_APPROVAL','PREPARE_REJECT','REJECT','APPROVED']
export const ReviewLetterGuaranteeStatusArray : ReviewLetterGuaranteeStatus[]  = ['PENDING','CONSIDERING','CORRECTION','APPROVED']
export const SapActionTypeArray : SapActionType[]  = ['AUTO','BY_STAFF']
export const SapProcessTypeArray : SapProcessType[]  = ['CREATE','CHANGE']
export const SapStatusArray : SapStatus[]  = ['SUCCESS','FAIL']
export const SapVendorTypeArray : SapVendorType[]  = ['TYPE_01','TYPE_02','TYPE_03']
export const StatTypeArray : StatType[]  = ['SERVICE','CARDIAC_SURGERY','PCI1_NON_INVASIVE','PCI2_NON_INVASIVE']
export const StepArray : Step[]  = ['STEP1','STEP2','STEP3','STEP4','STEP5','STEP6']
export const TypeEnumArray : TypeEnum[]  = ['P','H','R0101','R0102','R020201','R020202','R0203','R0204','R020401','R020402','R020403','R020404','R0205']
export const WorkingTypeArray : WorkingType[]  = ['PART_TIME','FULL_TIME']
export const DiffTypeArray : DiffType[]  = ['ADDED','REMOVED']
export const CertifyArray : Certify[]  = ['NON_CERTIFY','CERTIFY']
export const OrgTypeArray : OrgType[]  = ['GOV','COMPANY','PERSONAL','PRIVATE','FOUNDATION','ASSOCIATION','DISABLED_SERVICE_CENTER','VACCINATION_CENTER','CIVIL_SOCIETY','TEMPLE','GA','CLUB','CENTRE','HEALTH_ESTABLISHMENT','OTHER_RELIGIOUS_ORGANIZATION','PRIVATE_ORGANIZATION','NURSING_HOME','GROUP']
export const FromTypeArray : FromType[]  = ['H','A','P','Z','O','C','T','D']
export const SourceArray : Source[]  = ['DC','OSS','LDAP']
export const BlacklistStatusArray : BlacklistStatus[]  = ['BLACKLISTED','CANCEL_BLACKLIST']
