<div *ngIf="title" class="modal-header" [ngClass]="{'custom-bg-warning': type == 'warning', 'custom-bg-danger': type == 'danger', 'text-white': type == 'danger'}">
  <strong class="modal-title">
    <i *ngIf="type == 'info'" class="bi bi-patch-question mr-2"></i>
    <i *ngIf="type != 'info'" class="bi bi-patch-exclamation mr-2"></i>
    {{title}}
  </strong>
</div>
<div class="modal-body">
  <p *ngIf="!isInnerHTML" class="mb-0">{{content}}</p>
  <div *ngIf="isInnerHTML" [innerHTML]="content"></div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-center">
    <button type="button"
            class="btn btn-outline-dark btn-custom"
            (click)=" emit(); this.activeModal.close(false);">ตกลง
    </button>
  </div>
</div>
