import {Injectable} from '@angular/core';

export interface ToastInfo {
  type?: 'info' | 'success' | 'warning' | 'danger',
  header?: string;
  body?: string;
  delay?: number;
  classname?: string | string[]
}

@Injectable({providedIn: 'root'})
export class AutoSaveToastService {

  toasts: ToastInfo[] = [];

  show(toast: ToastInfo = {header: 'การแจ้งเตือน', type: 'info'}) {
    this.toasts = [];
    this.toasts.push(toast);
  }

  remove(toast: ToastInfo) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  clear() {
    this.toasts.splice(0, this.toasts.length);
  }
}
