<ng-container *ngIf="!disabled">
  <ng-select #hospitalList
             [id]="id"
             [name]="name"
             [items]="hospitals"
             [typeahead]="term$"
             [editableSearchTerm]="true"
             [loading]="typeaheadLoading"
             [readonly]="disabled"
             [(ngModel)]="selectedHospital"
             (ngModelChange)="onSelectHospital()"
             dropdownPosition="auto"
             (open)="onSelectOpen()">
    <ng-template ng-label-tmp let-item="item">
      {{item?.hcode}} : {{item?.titleName || item?.htitle?.htitleName}}{{item?.hname }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item">
      {{item?.hcode}} : {{item?.titleName || item?.htitle?.htitleName}}{{item?.hname }}
    </ng-template>
  </ng-select>
  <div class="row mt-3"></div>
  <gt-error *ngFor="let field of validateFields" [field]="field" [forComponent]="hospitalList"></gt-error>
</ng-container>
<div class="table-responsive mt-3">
  <table class="table table-sm caption-top table-striped-columns">
    <thead class="color-black font-weight-light">
    <tr class="table-light text-center" style="background-color: #eeeeee;">
      <th class="text-center col-1" nowrap>ลำดับ</th>
      <th nowrap class="col-3">รหัส</th>
      <th nowrap class="col">ชื่อหน่วยบริการ</th>
      <th *ngIf="!disabled" nowrap class="col-1">จัดการข้อมูล</th>
    </tr>
    </thead>
    <tbody class="table-group-divider">
    <tr *ngIf="!value?.length">
      <td class="text-muted text-center" colspan="4">
        <i class="bi bi-inbox"></i><br>
        ไม่พบข้อมูล
      </td>
    </tr>
    <tr *ngFor="let item of value; let index = index">
      <td class="text-center">{{ (index + 1)}}</td>
      <td>{{item?.hcode || '-'}}</td>
      <td>{{item?.htitle?.htitleName}}{{item.hname || '-'}}</td>
      <td *ngIf="!disabled" class="text-center align-middle">
        <button [disabled]="disabled" (click)="remove(item)" type="button" class="btn btn-default">
          <i class="bi bi-trash"></i>
        </button>
      </td>
    </tr>
    </tbody>
  </table>
</div>

