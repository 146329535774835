import {Directive, ElementRef, Input, OnInit, Renderer2, ViewContainerRef} from '@angular/core';
import {NgModel, Validators} from "@angular/forms";
import {CustomErrorComponent} from "./custom-error.component";
import {distinctUntilChanged} from "rxjs";

@Directive({
  selector: '[customError]',
  // hostDirectives: [ModelEmptyValueClassDirective]
})
export class CustomErrorDirective implements OnInit {

  readonly emptyClass = 'app-model-empty-value'
  private _constraint: { [constraint: string]: string }
  @Input("customErrorAppendTo") customErrorAppendTo: string;

  // private _validator: { [id: string]: ValidatorFn | ValidatorFn[] } = {};

  constructor(public elementRef: ElementRef,
              public viewContainerRef: ViewContainerRef,
              public ngModel: NgModel,
              public renderer: Renderer2) {
  }

  ngOnInit(): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'app-model-empty-value', '');
    this.ngModel.control.valueChanges
      // .pipe(distinctUntilChanged())
      .subscribe(value => {
        if (value == undefined || value === '') {
          this.renderer.addClass(this.elementRef.nativeElement, this.emptyClass);
        } else {
          this.renderer.removeClass(this.elementRef.nativeElement, this.emptyClass);
        }
        if (this.ngModel?.invalid && (this.ngModel?.dirty || this.ngModel.touched)) {
          if (this.viewContainerRef.get(0)) {
            this.viewContainerRef.remove();
          }
          for (let constraint in this._constraint) {
            if (this.ngModel.errors?.[constraint]) {
              let componentRef = this.viewContainerRef.createComponent(CustomErrorComponent);
              componentRef.instance.error = this._constraint[constraint];
              // console.log('customErrorAppendTo ', this.customErrorAppendTo)
              // if (this.customErrorAppendTo) {
              //   let appendToElement = document.querySelector(this.customErrorAppendTo);
              //   console.log('appendToElement = ', appendToElement);
              //   this.renderer.appendChild(appendToElement, componentRef);
              // }
            }
          }
        } else {
          this.viewContainerRef.remove();
        }
      });
  }

  get constraint(): { [p: string]: string } {
    return this._constraint;
  }

  @Input("customError")
  set constraint(value: { [p: string]: string }) {
    this._constraint = value || {};
    for (let mapKey in this._constraint) {
      // this.ngModel.control.addValidators(Validators[mapKey]);
      this.ngModel.control.markAsUntouched();
    }
  }
}
