import {NgModule} from '@angular/core';
import {NgbToastModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule} from "@angular/common";
import {AutoSaveToastContainerComponent} from "./auto-save-toast-container.component";

@NgModule({
  imports: [CommonModule, NgbToastModule],
  declarations: [AutoSaveToastContainerComponent],
  exports: [
    AutoSaveToastContainerComponent
  ]
})
export class AutoSaveToastGlobalModule {
}
