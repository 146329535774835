import {Component, forwardRef, Injector, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Subject} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CppMPersonDto} from "../../../../generated-model/model";
import {MasterService} from "../../services/master.service";

@UntilDestroy()
@Component({
  selector: 'app-select-potential-cpp-m-person-autocomplete',
  templateUrl: './gt-potential-cpp-m-person-autocomplete.component.html',
  styleUrls: ['./gt-potential-cpp-m-person-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtPotentialCppMPersonAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtPotentialCppMPersonAutocompleteComponent implements OnInit, ControlValueAccessor {

  term$ = new Subject<string>();
  allCppMPersonDto: CppMPersonDto[] = []
  cppMPersonDtos: CppMPersonDto[] = []
  private _value: CppMPersonDto;
  @Input('id') id: string = 'CppMPersonDto';
  @Input('name') name: string = 'CppMPersonDto';
  @Input() disabled: boolean = false;
  @Input() cppMPersonDtoId: string;
  @Input() restrictCppMPersonDto: CppMPersonDto
  @Input() clearable = true
  @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) cppMPersonDtoAutocomplete: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;
  typeaheadLoading = false;
  @Input() removeValueFromInput: boolean = false
  @Input() personFilter: CppMPersonDto[] = []
  @Input() filterPersonType: number[] = []
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: CppMPersonDto) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor(private inj: Injector, public masterService: MasterService) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {

    this.masterService.getCppMPerson().subscribe(data => {
      this.allCppMPersonDto = this.prepareCppMPerson(data)
      if (this.removeValueFromInput == true) {
        this.allCppMPersonDto = data
      }
      Object.freeze(this.allCppMPersonDto);
      this.cppMPersonDtos = this.allCppMPersonDto;
    })

    this.term$.subscribe(term => {
      this.cppMPersonDtos = this.allCppMPersonDto?.filter(f => (f.personName.includes(term) || f.personNo.startsWith(term)))
    });
  }

  get value(): CppMPersonDto {
    return this._value;
  }

  set value(value: CppMPersonDto) {
    this.cppMPersonDtoAutocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
    this.clearInput()
  }

  writeValue(value: CppMPersonDto) {
    this.cppMPersonDtoAutocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clear() {
    if (this.removeValueFromInput == false && this.personFilter == null) {
      this.cppMPersonDtoAutocomplete.handleClearClick();
    }
    this.cppMPersonDtos = this.allCppMPersonDto
  }


  prepareCppMPerson(allCppMPersonDto: CppMPersonDto[]) {

    let result: CppMPersonDto[] = []
    if (!allCppMPersonDto?.length) {
      return result
    }
    // แสดงเฉพาะประเภทที่ตั้งค่า
    console.log('this.filterPersonType', this.filterPersonType)
    console.log('result', result)
    result = allCppMPersonDto.filter(f => this.filterPersonType.includes(f.id));
    result.sort((a, b) => a.sequenceOrder - b.sequenceOrder);
    return Array.from(new Set<CppMPersonDto>(result));
  }

  /**
   * หน่วยบริการปฐมภูมิ เพิ่มพยาบาลได้ทุกประเภท
   */
  disableCppMPerson(item: CppMPersonDto): boolean {
    return !item.personType
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.removeValueFromInput == true && this.personFilter?.length > 0) {
      this.cppMPersonDtos = this.allCppMPersonDto.filter(e => !this.personFilter.map(ee => ee.id).includes(e?.id));

    }
  }

  clearInput() {
    if (this.removeValueFromInput) {
      this.ngSelectComponent?.handleClearClick();
    }
  }
}
