import {AfterViewInit, Component, forwardRef, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Hospital} from "../../../../generated-model/model";
import {catchError, debounceTime, of, Subject, switchMap} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TypeService} from "../../services/type.service";
import {tap} from "rxjs/operators";

@Component({
  selector: 'gt-hospital-autocomplete',
  templateUrl: './gt-hospital-autocomplete.component.html',
  styleUrls: ['./gt-hospital-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtHospitalAutocompleteComponent),
      multi: true
    }
  ]
})
export class GtHospitalAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  hospitals: Hospital[] = [];
  private _value: Hospital;
  @Input('id') id: string = 'hospital';
  @Input('name') name: string = 'hospital';
  @Input() disabled: boolean = false;
  @Input() hospitalId: string;
  @Input() hospitalType: string;
  @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) hospitalAutocomplete: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;
  term$ = new Subject<string>();
  typeaheadLoading = false;
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: Hospital) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor(public typeService: TypeService, private inj: Injector) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
    this.term$.pipe(
      debounceTime(300),
      tap(() => this.typeaheadLoading = true),
      switchMap(term => {
        return this.typeService.getHospital(term, this.hospitalType)
          .pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.typeaheadLoading = false),
          );
      })
    ).subscribe(e => {
      this.hospitals = e;
    })
  }

  get value(): Hospital {
    return this._value;
  }

  set value(value: Hospital) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: Hospital) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clear() {
    this.hospitalAutocomplete.handleClearClick();
  }

  onSelectOpen() {
    // this.term$.next('');
  }
}
