<ng-container>
  <form #form="ngForm" (ngSubmit)="submit()">
    <div class="modal-header">
      <strong class="modal-title">ตั้งค่าเบอร์โทรศัพท์</strong>
      <button type="button" class="btn-close" aria-label="Close"
              (click)="activeModal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body">
      <div class="mb-3 row">
        <label class="col-xl-4 ps-xl-4 col-form-label ui-require text-end">ระบุเบอร์มือถือ 10 หลัก</label>
        <div class="col-xl-3">
          <input class="form-control" type="text" minlength="10" maxlength="10" [(ngModel)]="attributeDto.attributes['mobile']" required [customError]="{'required' : 'กรุณากรอกเบอร์มือถือ', 'minlength' : 'ระบุเบอร์มือถือ 10 หลัก'}"
                 name="mobile" placeholder="ระบุเบอร์มือถือ 10 หลัก" id="mobile" digitOnly />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary custom-button mr-2 btn-main confirm-btn"> ยืนยันบันทึกข้อมูล</button>
      </div>
    </div>
  </form>
</ng-container>
