import {Component, forwardRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {catchError, debounceTime, lastValueFrom, of, Subject, switchMap} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {NgSelectComponent} from "@ng-select/ng-select";
import {Hospital} from "../../../../generated-model/model";
import {TypeService} from "../../services/type.service";
import {tap} from "rxjs/operators";

@UntilDestroy()
@Component({
  selector: 'app-select-hospital-list-autocomplete',
  templateUrl: './gt-hospital-list-autocomplete.component.html',
  styleUrls: ['./gt-hospital-list-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtHospitalListAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtHospitalListAutocompleteComponent implements OnInit, OnChanges, ControlValueAccessor {

  selectedHospital: Hospital;
  private selectedSet: Set<Hospital> = new Set<Hospital>();
  allHospital: Hospital[] = [];
  hospitals: Hospital[] = [];
  private _value: Hospital[];
  @Input() removeValueFromInput: boolean = false
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  @Input('id') id: string = 'hospital';
  @Input('name') name: string = 'hospital';
  @Input('disabled') disabled: boolean = false;
  @Input() validateFields: string[] = [];
  @Input() hospitalType: string;
  term$ = new Subject<string>();
  typeaheadLoading = false;

  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: Hospital[]) => void = () => {
  };

  constructor(public typeService: TypeService) {


  }

  ngOnInit(): void {
    this.term$.pipe(
      debounceTime(300),
      tap(() => this.typeaheadLoading = true),
      switchMap(term => {
        return this.typeService.getHospital(term, this.hospitalType)
          .pipe(
            catchError(() => of([])), // empty list on error
            tap(() => this.typeaheadLoading = false),
          );
      })
    ).subscribe(hosps => {
      this.hospitals = hosps;
    })
  }

  get value(): Hospital[] {
    return this._value;
  }

  set value(value: Hospital[]) {

    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }

    //ตอน set ค่ากลับมา
    if (this.value?.length && !this.selectedSet.size) {
      this.selectedSet = new Set<Hospital>(this.value);
    }
    this.clearInput()
  }

  ngOnChanges(changes: SimpleChanges): void {
    (async () => {
      let hospital = await lastValueFrom(this.typeService.getHospital(null, this.hospitalType));
      this.allHospital = hospital;
      Object.freeze(this.allHospital);
      this.hospitals = [...hospital];
    })();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onSelectOpen() {
    this.hospitals = [...this.allHospital].filter(e => [...this.selectedSet]
      .every(e2 => e.hcode !== e2.hcode));
  }

  onSelectHospital() {

    if (this.selectedHospital) {
      let newItem = [...this.selectedSet].every(e => e.hcode !== this.selectedHospital?.hcode);
      if (newItem) {
        this.selectedSet.add(this.selectedHospital);
      }
      this.selectedHospital = undefined;
    }
    if (this.selectedSet?.size) {
      this.value = [...this.selectedSet];
    }
  }

  remove(hospital: Hospital) {

    this.selectedSet.delete(hospital);
    this.hospitals = [...this.allHospital].filter(e => [...this.value].every(e2 => e.hcode !== e2.hcode));
    this.value = [...this.selectedSet];
  }

  clearInput() {
    if (this.removeValueFromInput) {
      this.ngSelectComponent?.handleClearClick();
    }
  }
}
