import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../environments/environment";
import {Observable} from "rxjs";

export type Version = {
  build: {
    version: string,
    datetime: string,
    time: string
  }
}

@Injectable({providedIn: "root"})
export class VersionService {

  constructor(private http: HttpClient) {

  }

  getVertion(): Observable<Version> {
    return this.http.get<Version>(`${environment.serverUrl}/actuator/info`)
  }
}
