<ng-select #autocomplete
           [id]="id"
           [name]="name"
           [typeahead]="term$"
           [editableSearchTerm]="true"
           [loading]="typeaheadLoading"
           placeholder="กรุณาเลือก"
           [readonly]="disabled"
           [compareWith]="compareWithId"
           app-model-empty-value
           [clearable]="clearable"
           (clear)="clear()"
           [(ngModel)]="value">
  <ng-option *ngFor="let item of cppMPersonDtos" [value]="item" [disabled]="disableCppMPerson(item)"></ng-option>
  <ng-template ng-label-tmp let-item="item">
    {{item.personNo}} : {{item.personName}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div><span>{{item.personNo}} : {{item.personName}}</span></div>
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled">
      ไม่พบข้อมูลสำหรับ "{{searchTerm}}"
    </div>
  </ng-template>
</ng-select>
