import {Component, Input, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgForm} from "@angular/forms";
import {markInvalidControlsAsDirtyAndTouched} from "../../../../app.constants";
import {RegistrationStatus} from "../../../../../generated-model/model";

@Component({
  selector: 'revert-status-modal',
  templateUrl: './revert-status-modal.component.html',
  styleUrls: ['./revert-status-modal.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class RevertStatusModalComponent {

  @ViewChild('form', {static: true}) public form: NgForm;
  @Input({alias: 'status', required: true}) status: RegistrationStatus;
  reason: string;

  constructor(public activeModal: NgbActiveModal,
              private renderer: Renderer2) {
  }

  confirm() {
    let formValid = this.updateFormAndValidity(this.form);
    if (formValid) {
      this.activeModal.close(this.reason);
    } else {
      console.log("form invalid");
      this.renderer.selectRootElement('#consider-form .ng-invalid').focus();
    }
  }

  cancel() {
    this.activeModal.dismiss()
  }

  updateFormAndValidity(form: NgForm): boolean {
    if (!form.valid) {
      markInvalidControlsAsDirtyAndTouched(form)
    }
    return form.valid;
  }
}
