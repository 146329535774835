<div class="modal-header">
  <h6 class="modal-title">ประวัติการพิจารณา</h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body overflow-hidden">
  <div class="row mt-3">
    <div class="col">
      <div class="table-responsive">
        <table class="table table-sm table-bordered caption-top border">
          <caption>
            แสดง {{ (pageData?.content?.length ? (pageData?.number * pageData?.size) + 1 : 0) | number }}
            ถึง {{ ((pageData?.number) * pageData?.size + pageData?.content?.length) | number }}
            จากทั้งหมด {{ pageData?.totalElements | number }} รายการ
          </caption>
          <thead class="color-black font-weight-light">
          <tr class="table-light text-center" style="background-color: #eeeeee;">
            <th nowrap scope="col">ลำดับ</th>
            <th nowrap scope="col">สถานะ</th>
            <th nowrap scope="col">เจ้าหน้าที่พิจารณา</th>
            <th nowrap scope="col">วันที่</th>
          </tr>
          </thead>
          <tbody class="table-group-divider">
          <tr *ngIf="!pageData?.content?.length">
            <td class="text-muted text-center" colspan="6">
              <i class="bi bi-inbox"></i><br>
              ไม่พบข้อมูล
            </td>
          </tr>
          <tr *ngFor="let item of pageData?.content; let i= index">
            <td nowrap class="text-center">{{ (pageData.size * pageData.number) + (i + 1) }}</td>
            <td nowrap class="text-center">{{ item.status }}</td>
            <td nowrap class="text-center">{{ item.officer?.name || '-' }}</td>
            <td nowrap class="text-center">{{ (item.activeDate| thaidatetime:{showTime: true}) || '-' }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col-12">
          <ngb-pagination class="d-flex justify-content-center"
                          [maxSize]="5"
                          [rotate]="true"
                          [ellipses]="false"
                          [boundaryLinks]="true"
                          [page]="pageData?.number + 1"
                          (pageChange)="search($event)"
                          [pageSize]="pageData?.size"
                          [collectionSize]="pageData?.totalElements"></ngb-pagination>
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <button type="button" [disabled]="loadingService.isLoading()" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom float-end">
        ปิด
      </button>
    </div>
  </div>
</div>
