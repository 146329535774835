<div class="modal-header ">
  <h6 class="modal-title">{{prefix}}เอกสาร</h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row mb-3">
    <label for="name" class="col-xl-2 col-form-label text-end ui-require">ชื่อเอกสาร</label>
    <div class="col">
      <textarea class="form-control" id="name" name="name" [(ngModel)]="fileConfigItem.displayText"></textarea>
    </div>
  </div>
  <div class="mb-3 row">
    <label for="status" class="col-xl-2 col-form-label text-end">สถานะ</label>
    <div class="col-xl-5">
      <div class="col-auto">
        <select class="form-select" id="status" name="status" [(ngModel)]="fileConfigItem.active">
          <option selected [ngValue]="'A'">ใช้งาน</option>
          <option [ngValue]="'C'">ยกเลิก</option>
        </select>
      </div>
    </div>
  </div>
  <div class="my-5"></div>
  <div class="row mt-3">
    <div class="offset-xl-2 col-xl-auto">
      <button type="button" (click)="save()" class="btn btn-primary btn-custom me-2">
        บันทึก
      </button>
      <button type="button" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
        ยกเลิก
      </button>
    </div>
  </div>
</div>
