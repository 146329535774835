import {AfterViewInit, Component, inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Page, PotentialConsiderHistoryDto} from "../../../../../generated-model/model";
import {LoadingService} from "../../../../shared/commons/loader/loading.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../../../shared/commons/Ignore-null-http-params";

@Component({
  selector: 'app-potential-consider-history-modal',
  templateUrl: './potential-consider-history-modal.component.html',
  // styleUrls: ['./method-modal.component.scss']
  styles: [
    `
      .custom-modal-content .modal-content {
        overflow: auto;
      }

      .custom-modal-content .modal-content .modal-body {
        //background-color: aliceblue;
      }
    `
  ],
})
export class PotentialConsiderHistoryModalComponent implements OnInit, AfterViewInit {

  @Input({required: true, alias: 'requestId'}) requestId: number;
  private http = inject(HttpClient);
  pageData: Page<PotentialConsiderHistoryDto> = {size: 10};


  constructor(protected loadingService: LoadingService,
              public activeModal: NgbActiveModal,
              public modalService: NgbModal) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.search(1);
  }

  search(page: number = 1) {
    const param = IgnoreNullHttpParams.fromObject({page: page - 1, size: this.pageData.size, sort: 'id,desc'})
      .set("sort", "id,desc").toHttpParam();
    this.http.get<Page<PotentialConsiderHistoryDto>>(`${environment.serverUrl}/api/potential-request/history/${this.requestId}`, {params: param})
      .subscribe(e => {
        this.pageData = e;
      });
  }
}
