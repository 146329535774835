import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {WarningDialogService} from "../dialog/warning-dialog/warning-dialog.service";

export const IGNORE_424_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http424Interceptor implements HttpInterceptor {

  constructor(private warningDialogService: WarningDialogService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return req.context.get(IGNORE_424_ERROR) ? next.handle(req) : next.handle(req)
      .pipe(tap
        (event => {
          },
          (err: HttpErrorResponse) => {
            if (err.status == 424) {
              this.warningDialogService.open({title: 'เกิดการละเมิดข้อกำหนดของข้อมูล', isJSON: true, json: err.error});
            }
          })
      );
  }


}
