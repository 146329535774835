<ng-select #autocomplete
           [id]="id"
           [name]="name"
           [items]="hospitals"
           placeholder="กรุณาเลือก"
           [readonly]="disabled"
           [compareWith]="compareWithId"
           [loading]="typeaheadLoading"
           [(ngModel)]="value"
           (open)="onSelectOpen()"
           [editableSearchTerm]="true"
           app-model-empty-value>
  <ng-template ng-label-tmp let-item="item">
    <!-- TODO  Use HospitalDto instead of use both (HospitalDto,HospitalProj)-->
    {{ item?.hcode }} : {{ item?.titleName || item?.htitle?.htitleName }}{{ item?.hname }}
  </ng-template>
  <ng-template ng-option-tmp let-item="item">
    {{ item?.hcode }} : {{ item?.titleName || item?.htitle?.htitleName }}{{ item?.hname }}
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    <div class="ng-option disabled">
      ไม่พบข้อมูลสำหรับ "{{ searchTerm }}"
    </div>
  </ng-template>
</ng-select>
