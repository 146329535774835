import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";

@Directive({
  selector: '[thaiDConfirmValid]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ThaiDConfirmValid,
      multi: true
    }
  ],
})
export class ThaiDConfirmValid implements Validator {

  @Input({alias: 'thaiDConfirmValid', required: true}) name: string;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value) {
      return {'required': true}
    } else if (control.value != this.name) {
      return {'mismatch': true}
    }

    return undefined;
  }

}
