import {Injectable} from '@angular/core';

import {
  ApplicantDto,
  AssessmentSummary,
  CheckConsiderationResult,
  CheckRegisterHsubtypeResult,
  CheckRegistrationValidResult,
  CheckRegTypeResult,
  ConfirmReason,
  ConsiderationDto,
  FileConfigItem,
  HaCheckResult,
  HsubtypeDto,
  Md5,
  Page,
  PharmacyCouncilRespone,
  PhysicalTherapyResponse,
  PrerequisiteCheck,
  ProcessingTypeWithStatus,
  RegAssessmentDto,
  RegDelegateOfAuthorityDto,
  RegistrationControlState,
  RegistrationDto,
  RegistrationSearchDto,
  RegistrationSearchParam,
  RegistrationStep5Dto,
  RegOpeningHourDto,
  RegQualificationDto,
  RegSp19Dto,
  RegSp7Dto,
  RegTypeDto,
  RevertStatusDto,
  TDCLicense,
  TypeChangeResult,
  UpdateHospitalRequest
} from "../../../generated-model/model";
import {Observable} from "rxjs";
import {HttpClient, HttpContext} from "@angular/common/http";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {WizardComponent} from "./wizard/wizard.component";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../../environments/environment";
import {ALL_PHARMACY_HSUBTYPE_ID_LIST, GeoLocation, PageRequest, PRIVATE_HOSPITAL_HSUBTYPE_ID} from "../../app.constants";
import {IgnoreNullHttpParams} from "../../shared/commons/Ignore-null-http-params";
import {IGNORE_5XX_ERROR} from "../../shared/commons/interceptor/http5xx-interceptor";

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {
  wizard: WizardComponent;

  constructor(private http: HttpClient, private modalService: NgbModal, public oidcSecurityService: OidcSecurityService) {
  }

  persist() {
    // sessionStorage.setItem('requestRegistration', JSON.stringify(this._register))
  }

  restore() {
    // this._register = JSON.parse(sessionStorage.getItem('requestRegistration'))
  }

  clearSessionStorage() {
    sessionStorage.removeItem('requestRegistration')
  }

  findById(id): Observable<RegistrationDto> {
    return this.http.get<RegistrationDto>(`${environment.serverUrl}/api/registration/${id}`);
  }

  findSp7ByCitizenId(citizenId): Observable<RegSp7Dto[]> {
    return this.http.get<RegSp7Dto[]>(`${environment.serverUrl}/api/registration/license/sp7/by-citizen-id/${citizenId}`);
  }

  findStep1ById(id): Observable<RegistrationDto> {
    return this.http.get<RegistrationDto>(`${environment.serverUrl}/api/registration/${id}/step1`);
  }

  prerequisiteCheck(): Observable<PrerequisiteCheck> {
    return this.http.get<PrerequisiteCheck>(`${environment.serverUrl}/api/registration/prerequisite/check`);
  }

  controlState(id: string): Observable<RegistrationControlState> {
    return this.http.get<RegistrationControlState>(`${environment.serverUrl}/api/registration/${id}/control/state`);
  }

  controlStateWithSecret(id: string, secret: string): Observable<RegistrationControlState> {
    return this.http.post<RegistrationControlState>(`${environment.serverUrl}/api/registration/${id}/control/state`, {secret: secret});
  }

  getQualificationById(id: string): Observable<RegQualificationDto> {
    return this.http.get<RegQualificationDto>(`${environment.serverUrl}/api/reg-qualification/${id}/step3`);
  }

  getQualificationForPatchById(id: string, secret: string): Observable<RegQualificationDto> {
    if ("oculusReparo" == secret) {
      return this.http.get<RegQualificationDto>(`${environment.serverUrl}/api/reg-qualification/${id}/step3-patch`);
    }
    return this.http.get<RegQualificationDto>(`${environment.serverUrl}/api/reg-qualification/${id}/step3`);
  }

  updateSp7ByStaff(id: number): Observable<RegSp7Dto> {
    return this.http.post<RegSp7Dto>(`${environment.serverUrl}/api/reg-qualification/${id}/sp7/staff`, {});
  }

  updateSp19ByStaff(id: number): Observable<RegSp19Dto> {
    return this.http.post<RegSp19Dto>(`${environment.serverUrl}/api/reg-qualification/${id}/sp19/staff`, {});
  }

  getAssessmentById(id: string): Observable<RegAssessmentDto> {
    return this.http.get<RegAssessmentDto>(`${environment.serverUrl}/api/reg-assessment/${id}/step4`);
  }

  getAssessmentForPathById(id: string, secret: string): Observable<RegAssessmentDto> {
    if ("oculusReparo" == secret) {
      return this.http.get<RegAssessmentDto>(`${environment.serverUrl}/api/reg-assessment/${id}/step4-patch`);
    }
    return this.getAssessmentById(id);
  }

  getConsiderationSummaryById(id: string): Observable<ConsiderationDto> {
    return this.http.get<ConsiderationDto>(`${environment.serverUrl}/api/consideration/${id}/summary`);
  }

  considerApprove(id: number, value: ConfirmReason): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/approve`, value);
  }

  considerDeny(id, value: ConfirmReason): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/deny`, value);
  }

  considerApproveBlacklist(id: number, value: ConfirmReason) {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/blacklist/approve`, value);
  }

  considerDenyBlacklist(id: number, value: ConfirmReason) {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/blacklist/deny`, value);
  }

  considerCorrect(id, value: ConfirmReason): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/correct`, value);
  }

  cancelRegistration(id, value: ConfirmReason): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/consideration/${id}/registration/cancel`, value);
  }

  revertStatus(id, value: RevertStatusDto): Observable<any> {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/revert-status`, value);
  }

  updateBankAccount(id: number) {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/bankaccount/sync`, {});
  }

  getAssessmentSummary(id: number): Observable<AssessmentSummary[]> {
    return this.http.get<AssessmentSummary[]>(`${environment.serverUrl}/api/consideration/${id}/assessment/summary`);
  }

  getRegFileById(id: string): Observable<RegistrationStep5Dto> {
    return this.http.get<RegistrationStep5Dto>(`${environment.serverUrl}/api/reg-file/${id}`);
  }

  search(searchParam: RegistrationSearchParam, page: PageRequest): Observable<Page<RegistrationSearchDto>> {
    const param = IgnoreNullHttpParams.fromObject({...searchParam, ...page})
      .set("sort", "lastUpdatedDate,asc").toHttpParam();
    return this.http.get<Page<RegistrationSearchDto>>(`${environment.serverUrl}/api/registration/search`, {params: param});
  }

  searchConsider(searchParam: RegistrationSearchParam, page: PageRequest): Observable<Page<RegistrationSearchDto>> {
    const param = IgnoreNullHttpParams.fromObject({...searchParam, ...page})
      .set("sort", "lastUpdatedDate,asc").toHttpParam();
    return this.http.get<Page<RegistrationSearchDto>>(`${environment.serverUrl}/api/registration/consideration/search`, {params: param});
  }

  /**
   * hsubtypeId == '001305' == คลีนิกเอกชน
   * hsubtypeId == '001302' == โรงพยาบาลเอกชน
   */
  public hospitalOrClinic(hsubtype: HsubtypeDto): boolean {
    return hsubtype?.hsubtypeId == '001305' || hsubtype?.hsubtypeId == '001302';
  }

  /**
   * hsubtypeId == '001306' == ร้านยา
   * hsubtypeId == '001307' == ศูนย์คนพิการ
   * hsubtypeId == '001308' == ศูนย์ฉีดวัคซีน
   * hsubtypeId == '001309' == สถานพยาบาลในสถานประกอบการ (ห้องพยาบาล)
   * hsubtypeId == '001310' == องค์กรภาคประชาสังคม/ภาคประชาชน
   * hsubtypeId == '001311' == มูลนิธิ
   * hsubtypeId == '001312' == สมาคม
   * hsubtypeId == '001014' == ร้านขายยาแผนปัจจุบัน (รัฐ)
   * hsubtypeId == '001111' == ร้านขายยาแผนปัจจุบัน (รัฐ)
   * hsubtypeId == '001218' == ร้านขายยาแผนปัจจุบัน (รัฐ)
   *
   * hsubtypeId == '001314' == ชมรม
   * hsubtypeId == '001315' == ศูนย์
   * hsubtypeId == '001316' == สถานประกอบการเพื่อสุขภาพ
   * hsubtypeId == '001317' == องค์กรศาสนาอื่น
   * hsubtypeId == '001318' == องค์กรเอกชน
   * hsubtypeId == '001319' == Nursing Home
   * hsubtypeId == '001320' == กลุ่ม
   */
  public section3(hsubtype: HsubtypeDto): boolean {
    return hsubtype?.hsubtypeId == '001306'
      || hsubtype?.hsubtypeId == '001307'
      || hsubtype?.hsubtypeId == '001308'
      || hsubtype?.hsubtypeId == '001309'
      || hsubtype?.hsubtypeId == '001310'
      || hsubtype?.hsubtypeId == '001311'
      || hsubtype?.hsubtypeId == '001312'

      || hsubtype?.hsubtypeId == '001014'
      || hsubtype?.hsubtypeId == '001111'
      || hsubtype?.hsubtypeId == '001218'

      || hsubtype?.hsubtypeId == '001314'
      || hsubtype?.hsubtypeId == '001315'
      || hsubtype?.hsubtypeId == '001316'
      || hsubtype?.hsubtypeId == '001317'
      || hsubtype?.hsubtypeId == '001318'
      || hsubtype?.hsubtypeId == '001319'
      || hsubtype?.hsubtypeId == '001320'
  }

  public pharmacy(hsubtype: HsubtypeDto): boolean {
    return hsubtype?.hsubtypeId == '001306';
  }

  saveRegistration(dto: RegistrationDto): Observable<RegistrationDto> {
    return this.http.post<RegistrationDto>(`${environment.serverUrl}/api/registration`, dto);
  }

  updateRegistration(dto: RegistrationDto): Observable<RegistrationDto> {
    return this.http.put<RegistrationDto>(`${environment.serverUrl}/api/registration`, dto)
  }

  updateRegistrationByStaff(dto: RegistrationDto): Observable<RegistrationDto> {
    return this.http.put<RegistrationDto>(`${environment.serverUrl}/api/registration/staff/update`, dto)
  }

  getApplicant(id: number): Observable<ApplicantDto> {
    return this.http.get<ApplicantDto>(`${environment.serverUrl}/api/registration/${id}/applicant`);
  }

  saveType(dto: RegTypeDto): Observable<RegTypeDto> {
    return this.http.post<RegTypeDto>(`${environment.serverUrl}/api/reg-type`, dto)
  }

  checkTypeChanges(dto: RegTypeDto): Observable<TypeChangeResult> {
    return this.http.post<TypeChangeResult>(`${environment.serverUrl}/api/reg-type/type-changes/check`, dto)
  }

  saveQualification(dto: RegQualificationDto): Observable<RegQualificationDto> {
    console.log('dt0 = ', dto)
    return this.http.post<RegQualificationDto>(`${environment.serverUrl}/api/reg-qualification`, dto);
  }

  patchQualification(dto: RegQualificationDto): Observable<RegQualificationDto> {
    console.log('dt0 = ', dto)
    return this.http.post<RegQualificationDto>(`${environment.serverUrl}/api/reg-qualification/patch`, dto);
  }

  saveAssessment(dto: RegQualificationDto): Observable<RegAssessmentDto> {
    console.log('dt0 = ', dto)
    return this.http.post<RegAssessmentDto>(`${environment.serverUrl}/api/reg-assessment`, dto);
  }

  patchAssessment(dto: RegQualificationDto): Observable<RegAssessmentDto> {
    console.log('dt0 = ', dto)
    return this.http.post<RegAssessmentDto>(`${environment.serverUrl}/api/reg-assessment/patch`, dto);
  }

  saveAssessmentOfficer(dto: RegQualificationDto): Observable<RegAssessmentDto> {
    console.log('dt0 = ', dto)
    return this.http.post<RegAssessmentDto>(`${environment.serverUrl}/api/reg-assessment/officer`, dto);
  }

  getRegistrationTypeById(id): Observable<RegTypeDto> {
    return this.http.get<RegistrationDto>(`${environment.serverUrl}/api/reg-type/${id}`);
  }

  checkRegTypeResult(id: number) {
    return this.http.get<CheckRegTypeResult>(`${environment.serverUrl}/api/reg-type/${id}/types/check`);
  }

  checkRegisterHsubtypeResult(id: number) {
    return this.http.get<CheckRegisterHsubtypeResult>(`${environment.serverUrl}/api/registration/${id}/hsubtype/check`);
  }

  linkToGoogleMap(location: GeoLocation) {
    window.open(`https://maps.google.com/?q=${location.lat},${location.lon}`)
  }

  confirmRegistration(value: any): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/consideration/${value.id}/registration/confirm`, value);
  }

  acceptConsiderResult(value: any): Observable<any> {
    return this.http.post(`${environment.serverUrl}/api/consideration/${value.id}/registration/accept-consider-result`, value);
  }

  findSP7License(id: number): Observable<RegSp7Dto[]> {
    return this.http.get<RegSp7Dto[]>(`${environment.serverUrl}/api/registration/${id}/sp7/license`);
  }

  findSP19License(id: number): Observable<RegSp7Dto[]> {
    return this.http.get<RegSp7Dto[]>(`${environment.serverUrl}/api/registration/${id}/sp19/license`);
  }

  findPharmacyLicense(id: number): Observable<RegSp7Dto[]> {
    return this.http.get<RegSp7Dto[]>(`${environment.serverUrl}/api/registration/${id}/ph5/license`);
  }

  checkHa(hcode: string): Observable<HaCheckResult> {
    const httpContext = new HttpContext();
    httpContext.set(IGNORE_5XX_ERROR, true);
    return this.http.get<HaCheckResult>(`${environment.serverUrl}/api/ha/hospital/check`, {
      context: httpContext,
      params: IgnoreNullHttpParams.fromObject({hcode: hcode}).toHttpParam()
    });
  }

  addPharmacyLicense(id: number, item: RegSp7Dto): Observable<RegSp7Dto> {
    return this.http.post(`${environment.serverUrl}/api/reg-qualification/${id}/ph5/license`, item);
  }

  findActivity(): Observable<RegistrationDto[]> {
    return this.http.get<RegistrationDto[]>(`${environment.serverUrl}/api/registration/activity`);
  }

  validateConsider(id: string): Observable<CheckRegistrationValidResult> {
    return this.http.get<CheckRegistrationValidResult>(`${environment.serverUrl}/api/consideration/${id}/validate`);
  }

  getTypeProcessingByHcode(hcode: string): Observable<ProcessingTypeWithStatus[]> {
    return this.http.get<ProcessingTypeWithStatus[]>(`${environment.serverUrl}/api/registration/get-type-processing-by-hcode`,
      {params: IgnoreNullHttpParams.fromObject({hcode: hcode}).toHttpParam()});
  }

  getTypeProcessingByHname(hname: string, id: number): Observable<ProcessingTypeWithStatus[]> {
    return this.http.get<ProcessingTypeWithStatus[]>(`${environment.serverUrl}/api/registration/get-type-processing-by-hname`,
      {params: IgnoreNullHttpParams.fromObject({hname: hname, registrationId: id}).toHttpParam()});
  }

  validateMd5(fullName: string): Observable<Md5> {
    let params = IgnoreNullHttpParams.fromObject({fullName: fullName});
    return this.http.get<Md5>(`${environment.serverUrl}/api/registration/md5/verify`, {params: params.toHttpParam()});
  }

  verifyTdcLicense(tdcId: string, fullName: string): Observable<TDCLicense> {
    let params = IgnoreNullHttpParams.fromObject({tdcId: tdcId, fullName: fullName});
    return this.http.get<TDCLicense>(`${environment.serverUrl}/api/registration/tdc/license/verify`, {params: params.toHttpParam()});
  }

  validatePharmacy(licenseId: string, fullName: string): Observable<PharmacyCouncilRespone> {
    let params = IgnoreNullHttpParams.fromObject({licenseId: licenseId, fullName: fullName});
    return this.http.get<PharmacyCouncilRespone>(`${environment.serverUrl}/api/registration/pharmacy/verify`, {params: params.toHttpParam()});
  }

  validatePhysicalTherapy(personalId: string): Observable<PhysicalTherapyResponse> {
    let params = IgnoreNullHttpParams.fromObject({personalId: personalId});
    return this.http.get<PhysicalTherapyResponse>(`${environment.serverUrl}/api/registration/physical-therapy/verify`, {params: params.toHttpParam()});
  }

  deleteFlag(dto: RegistrationSearchDto) {
    return this.http.delete<FileConfigItem>(`${environment.serverUrl}/api/registration/${dto.id}`);
  }

  registrationApproved(): Observable<boolean> {
    return this.http.get<boolean>(`${environment.serverUrl}/api/registration/registration-approved`);
  }

  isPrivateHospitalOwnByFoundation(registration: RegistrationDto) {
    // console.log(registration?.hsubtype?.hsubtypeId, '  ', registration.applicant.organization?.orgType )
    return registration?.hsubtype?.hsubtypeId == PRIVATE_HOSPITAL_HSUBTYPE_ID
      && registration.applicant.organization?.orgType == "FOUNDATION";
  }

  isPharmacy(registration: RegistrationDto) {
    return ALL_PHARMACY_HSUBTYPE_ID_LIST.includes(registration?.hsubtype?.hsubtypeId);
  }

  updateHospital(request: UpdateHospitalRequest) {
    return this.http.post(`${environment.serverUrl}/api/registration/update-hospital`, request);
  }

  updateOpeningHour(registrationId: number, request: RegOpeningHourDto) {
    return this.http.post(`${environment.serverUrl}/api/reg-qualification/${registrationId}/opening-hour`, request);
  }

  checkConsider(id: number): Observable<CheckConsiderationResult> {
    return this.http.post<any>(`${environment.serverUrl}/api/consideration/${id}/check`, {});
  }

  updateDelegate(registrationId: number): Observable<RegDelegateOfAuthorityDto> {
    return this.http.get<any>(`${environment.serverUrl}/api/registration/update-delegate/${registrationId}`);
  }
}
