import {Component} from '@angular/core';

import {AutoSaveToastService} from './auto-save-toast.service';

@Component({
  selector: 'app-auto-save-toasts',
  template: `
    <ngb-toast *ngFor="let toast of toastService.toasts"
               [class]="toast.classname"
               [autohide]="true"
               [delay]="toast.delay || 1000"
               (hidden)="toastService.remove(toast)">
      <div class="spinner-grow spinner-grow-sm text-primary me-2" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <span class="text-primary">กำลังบันทึกร่าง...</span>
    </ngb-toast>
  `,
  host: {'class': 'toast-container position-fixed end-0 p-3', 'style': 'z-index: 1200; top:40px; border:0;'}
})
export class AutoSaveToastContainerComponent {

  constructor(public toastService: AutoSaveToastService) {
  }
}
