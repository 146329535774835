import {AfterViewInit, Component, inject, Input, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {Page, RegConsiderHistoryDto, RegistrationStatus, Step} from "../../../../../generated-model/model";
import {LoadingService} from "../../../../shared/commons/loader/loading.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../../../shared/commons/Ignore-null-http-params";

@Component({
  selector: 'app-consider-history-modal',
  templateUrl: './consider-history-modal.component.html',
  // styleUrls: ['./method-modal.component.scss']
  styles: [
    `
      .custom-modal-content .modal-content {
        overflow: auto;
      }

      .custom-modal-content .modal-content .modal-body {
        //background-color: aliceblue;
      }
    `
  ],
})
export class ConsiderHistoryModalComponent implements OnInit, AfterViewInit {

  @Input({required: true, alias: 'registrationId'}) registrationId: number;
  private http = inject(HttpClient);
  pageData: Page<RegConsiderHistoryDto> = {size: 10};
  protected readonly Step = Step;
  protected readonly RegistrationStatus = RegistrationStatus;
  considerStatus: string[] = ['โดยเจ้าหน้าที่', 'โดยระบบ', 'โดยหน่วยบริการ']

  constructor(protected loadingService: LoadingService,
              public activeModal: NgbActiveModal,
              public modalService: NgbModal) {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.search(1);
  }

  search(page: number = 1) {
    const param = IgnoreNullHttpParams.fromObject({page: page - 1, size: this.pageData.size})
      .set("sort", "activeDate,asc")
      .toHttpParam();
    this.http.get<Page<RegConsiderHistoryDto>>(`${environment.serverUrl}/api/reg-consider-history/registration/${this.registrationId}`, {params: param})
      .subscribe(e => {
        this.pageData = e;
      });
  }

  getConsiderStatus(status: string, autoApprove: boolean): string {
    const trimmedStatus = status.trim();
    if (trimmedStatus === 'ยกเลิกการสมัคร (สมัครขึ้นทะเบียน)' ||
      trimmedStatus === 'ยกเลิกการสมัคร' ||
      trimmedStatus === 'ยืนยันตัวตน thaid (นิติกรรมสัญญา)') {
      return 'โดยหน่วยบริการ';
    }
    return autoApprove ? 'โดยระบบ' : 'โดยเจ้าหน้าที่';
  }
}


