<div class="container-fluid">
  <div class="row flex-nowrap">
    <div class="col-auto px-0" [(ngbCollapse)]="sidebarCollapsed" [horizontal]="true">
      <div id="sidebar" class="collapse collapse-horizontal show border-end-0 sticky-top">
        <div id="sidebar-nav" class="list-group border-0 rounded-0 text-sm-start min-vh-100">
          <div class="text-center py-2">
            <img src="/assets/images/hra3.png" alt="Logo" height="65" class="d-inline-block align-text-top">
          </div>
          <div class="px-3">
            <span class="form-text mb-0 mt-0 p-0 text-muted">version. {{ version?.build?.version }}</span>
          </div>
          <div class="my-2"></div>
          <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
        </div>
      </div>
    </div>
    <main class="col p-0">
      <nav id="top-nav" class="navbar bg-white sticky-top border-bottom">
        <div class="container-fluid">
          <ng-container *ngIf="!toggleOffCanvas">
            <button (click)="sidebarCollapsed = !sidebarCollapsed" [attr.aria-expanded]="!sidebarCollapsed" class="navbar-toggler border-0 p-0" type="button"
                    data-bs-toggle="collapse" data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
              <!--            <span class="navbar-toggler-icon text-white opacity-50"></span>-->
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
              <!--              <i class="bi bi-justify text-white fs-3"></i>-->
              <!--              <img src="../../../assets/images/hamburger2.png" height="35" alt="hamburger">-->
            </button>
          </ng-container>
          <ng-container *ngIf="toggleOffCanvas">
            <ng-template #content let-offcanvas>
              <div class="offcanvas-header">
                <h6 class="offcanvas-title" id="offcanvas-basic-title">เมนู</h6>
                <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
              </div>
              <div class="offcanvas-body">
                <ng-container [ngTemplateOutlet]="sidebar"></ng-container>
              </div>
            </ng-template>
            <button (click)="open(content)" [attr.aria-expanded]="!sidebarCollapsed" class="navbar-toggler border-white" type="button" data-bs-toggle="collapse"
                    data-bs-target="#sidebar" aria-controls="sidebar" aria-expanded="false" aria-label="Toggle navigation">
              <i class="bi bi-layout-sidebar text-white fs-3"></i>
            </button>
          </ng-container>
          <div class="d-flex">
            <div class="mx-3 pt-1 pe-3 border-end">
              <button (click)="gotoEportal()" type="button" class="btn btn-sm btn-default"><i class="bi bi-box-arrow-right me-2"></i>ไปยัง e-Portal</button>
            </div>
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 ms-3 text-truncate text-end" style="min-width: 150px;">
                <div class="align-items-center">
                  <a role="button" class="btn btn-sm text-white" id="dropdownMenu" style="text-align: start; padding: 0;">
                    {{ user?.name }}
                  </a>
                </div>
                <div *ngIf="!(user?.organization?.orgType === 'PERSONAL')" class="text-white" style="font-size: 0.8rem;">
                  <!--ชื่อหน่วยงานที่มาจาก DC, OSS-->
                  {{ organizationName(user?.organization) }}
                </div>
              </div>
            </div>
            <div ngbDropdown class="flex-shrink-0 mx-3">
              <a role="button" ngbDropdownToggle class="text-white">
                <i class="bi-person-circle fs-3 text-white"></i>
              </a>
              <div ngbDropdownMenu aria-labelledby="dropdownMenu">
                <button ngbDropdownItem (click)="userInfo()">
                  <i class="bi bi-person me-3"></i>บัญชีผู้ใช้งาน
                </button>
                <button *ngIf="user?.organization?.fromType === 'Z' || user?.organization?.fromType === 'O'" ngbDropdownItem (click)="emailNotify()">
                  <i class="bi bi-envelope me-3"></i>แจ้งเตือนอีเมล
                </button>
                <button ngbDropdownItem (click)="mobileSetting()">
                  <i class="bi bi-telephone me-3"></i>ตั้งค่าเบอร์โทรศัพท์
                </button>
                <button ngbDropdownItem (click)="logout()">
                  <i class="bi bi-door-open me-3"></i>ออกจากระบบ
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div class="ps-3">
        <app-version></app-version>
        <app-header></app-header>
      </div>
      <div class="p-3">
        <app-network-unstable></app-network-unstable>
        <app-loading></app-loading>
        <router-outlet></router-outlet>
      </div>
    </main>
  </div>
</div>
<ng-template #sidebar>
  <ng-container>
    <a routerLink="/landing" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
      <i class="bi bi-house"></i> <span>หน้าแรก</span>
    </a>

    <ng-container *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']">
      <button class="btn btn-toggle d-inline-flex align-items-center border-0" data-bs-toggle="collapse" data-bs-target="#hospital-collapse" aria-expanded="true">
        สมัครขึ้นทะเบียนหน่วยบริการ
      </button>
      <div class="collapse show" id="hospital-collapse" style="">
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/delegate-of-authority" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ข้อมูลผู้มีอำนาจของสถานบริการ/หน่วยบริการ<br>ตามกฎหมาย <span style="color:#f21378;">(สำหรับสมัครขึ้นทะเบียนเป็นหน่วย <br>บริการเท่านั้น)</span></span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/bank-account" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ข้อมูลบัญชีเงินฝากธนาคาร</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/info" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ข้อมูลพื้นฐานสถานพยาบาล</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/registration" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>รายการสมัครขึ้นทะเบียนหน่วยบริการ</span>
        </a>
        <a *hasAnyAuthority="[ 'hospital', 'company', 'personal', 'ga']" routerLink="/hospital/letter-guarantee" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>หลักประกันสัญญา</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/publish/contract" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>นิติกรรมสัญญา</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone', 'hospital', 'company', 'personal', 'ga', 'executive', 'admin','register_staff']" routerLink="/hospital/publish/check-publish"
           routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ประกาศ</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal']"
           routerLink="/renew/" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจประเมินประจำปี</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/potential" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ศักยภาพหน่วยบริการ</span>
        </a>
        <a *hasAnyAuthority="['hospital', 'company', 'personal', 'ga']" routerLink="/hospital/review-letter-guarantee" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ทบทวนหลักประกันสํญญา</span>
        </a>
      </div>
    </ng-container>

    <ng-container *hasAnyAuthority="['nhso', 'zone', 'executive', 'admin', 'register_staff']">
      <button class="btn btn-toggle d-inline-flex align-items-center border-0" data-bs-toggle="collapse" data-bs-target="#officer-hospital-collapse" aria-expanded="true">
        สมัครขึ้นทะเบียนหน่วยบริการ
      </button>
      <div class="collapse show" id="officer-hospital-collapse" style="">
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/sp7/search" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจสอบ API สพ.7</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/sp19/search" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจสอบ API สพ.19</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/drugstore/search" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจสอบ API ขย.5</span>
        </a>
        <a *hasAnyAuthority="['admin','nhso', 'zone']" routerLink="/officer/elg/search" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจสอบ API lg</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/delegate-of-authority" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ข้อมูลผู้มีอำนาจของสถานบริการ/<br>หน่วยบริการ ตามกฎหมาย</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/bank-account" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ข้อมูลบัญชีเงินฝากธนาคาร</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/consideration" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>รายการสมัครขึ้นทะเบียนหน่วยบริการ</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/letter-guarantee" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>หลักประกันสัญญา</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/publish/contract" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>นิติกรรมสัญญา</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/hospital/publish/check-publish"
           routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ประกาศ</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']"
           routerLink="/renew/officer" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจประเมินประจำปี</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']"
           routerLink="/renew/report" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>รายงานสรุปการตรวจประเมินประจำปี</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']"
           routerLink="/renew/dashboard" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>Dashboard การตรวจประเมินประจำปี</span>
        </a>
        <a *hasAnyAuthority="['executive', 'admin','register_staff']" routerLink="/officer/regal-contract" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ลงนามประกาศ/สัญญา/ข้อตกลง</span>
        </a>
        <a *hasAnyAuthority="['executive', 'admin','register_staff']" routerLink="/officer/review-letter-guarantee" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ทบทวนหลักประกันสํญญา</span>
        </a>
        <a *hasAnyAuthority="['executive', 'admin','register_staff']" routerLink="/officer/sap" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ติดตามสถานะ SAP</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone']" routerLink="/officer/potential" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ศักยภาพหน่วยบริการ</span>
        </a>
      </div>
    </ng-container>
    <ng-container *hasAnyAuthority="['nhso', 'zone', 'admin']">
      <button class="btn btn-toggle d-inline-flex align-items-center border-0" data-bs-toggle="collapse" data-bs-target="#master-data-collapse" aria-expanded="true">
        Master Data
      </button>
      <div class="collapse show" id="master-data-collapse" style="">
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/file-config-item" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>เอกสารการขึ้นทะเบียน</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/type" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>เอกสารตามประเภทการขอขึ้นทะเบียน</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/htype" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>เอกสารตามสังกัดหลักของหน่วยบริการ</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'zone', 'admin']" routerLink="/officer/master-data/zoning" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>การแบ่งเขตพื้นที่</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/performance-bond" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>อัตราการวางหลักประกันสัญญา</span>
        </a>
        <a *hasAnyAuthority="['admin']" routerLink="/officer/master-data/authority" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>การจัดการสิทธิ์ผู้ใช้งาน</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/zone-email" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>การจัดการอีเมล</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/email-config-mcup" routerLinkActive="active" class="list-group-item border-0 d-inline-block text-truncate"
           data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>การจัดการอีเมล ลงทะเบียน(Master Cup)</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/responsible-zone" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>การจัดการผู้รับผิดชอบแต่ละเขต</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/blacklist" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>Blacklist</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/check-blacklist" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ตรวจสอบ Blacklist</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/list-of-problems" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>รายการปัญหาการสมัคร</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/service-potential" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>ศักยภาพ</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/service-potential-attachment" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>เอกสารแนบศักยภาพ</span>
        </a>
        <a *hasAnyAuthority="['nhso', 'admin']" routerLink="/officer/master-data/service-potential-place" routerLinkActive="active"
           class="list-group-item border-0 d-inline-block text-truncate" data-bs-parent="#sidebar">
          <i class="bi bi-window"></i> <span>รายการสถานที่ (ศักยภาพหน่วยบริการ)</span>
        </a>
      </div>
    </ng-container>
    <ng-container *hasAnyAuthority="['hospital', 'company', 'personal']">
      <a role="button" style="pointer-events: none; width: 100%;" class="list-group-item border-0 d-inline-block text-muted text-truncate" data-bs-parent="#sidebar">
        Health Link
      </a>
      <a routerLink="/hospital/healthlink/enroll/list" routerLinkActive="active"
         class="list-group-item border-0 d-inline-block"
         data-bs-parent="#sidebar">
        <i class="bi bi-window"></i>ลงทะเบียนเข้าร่วมโครงการ (เฉพาะหน่วยบริการกทม.)
      </a>
      <a routerLink="/hospital/healthlink/enroll/delegate" routerLinkActive="active"
         class="list-group-item border-0 d-inline-block"
         data-bs-parent="#sidebar">
        <i class="bi bi-window"></i>ข้อมูลผู้มีอำนาจของสถานบริการ/<br>หน่วยบริการ (เฉพาะหน่วยบริการกทม.)
      </a>
    </ng-container>
    <ng-container *hasAnyAuthority="['nhso', 'zone', 'healthlink'];">
      <a role="button" style="pointer-events: none; width: 100%;" class="list-group-item border-0 d-inline-block text-muted text-truncate" data-bs-parent="#sidebar">
        Health Link
      </a>
      <a routerLink="/officer/healthlink/consider-list" routerLinkActive="active"
         class="list-group-item border-0 d-inline-block"
         data-bs-parent="#sidebar">
        <i class="bi bi-window"></i>ลงทะเบียนเข้าร่วมโครงการ
      </a>
      <a routerLink="/officer/healthlink-delegate" routerLinkActive="active" *hasAnyAuthority="['nhso', 'zone'];"
         class="list-group-item border-0 d-inline-block text-truncate"
         data-bs-parent="#sidebar">
        <i class="bi bi-window"></i>ข้อมูลผู้มีอำนาจของสถานบริการ/<br>หน่วยบริการ
      </a>
    </ng-container>
  </ng-container>
</ng-template>
