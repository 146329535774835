<form #form="ngForm" (ngSubmit)="confirm()" id="consider-form" class="form-validate">
  <div class="modal-header">
    <h6 class="modal-title">
      ยืนยันข้อมูล
    </h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center mb-3">
      <div class="col-12">
        <textarea type="text" id="reason" name="reason" class="form-control" placeholder="ระบุเหตุผล" required rows="5"
                  [(ngModel)]="reason">
        </textarea>
      </div>
    </div>
  </div>
  <div class="row mt-3 mb-3">
    <div class="col-12 text-center">
      <button type="button" class="btn btn-primary custom-button btn-custom" (click)="confirm()">ยืนยัน</button>
      <button type="button" class="btn btn-default2 custom-button btn-light ms-2 btn-custom"
              (click)="cancel()">ยกเลิก</button>
    </div>
  </div>
</form>
