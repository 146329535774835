import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-warning-dialog',
  templateUrl: './warning-dialog.component.html',
  styleUrls: ['./warning-dialog.component.css']
})
export class WarningDialogComponent implements OnInit,OnChanges {

  @Input()
  content: string = '';

  @Input()
  title: string = 'การแจ้งเตือน';

  @Input()
  isInnerHTML: boolean = false;

  @Input()
  isJSON: boolean = false;

  @Input()
  json: any;

  @Output()
  confirmEmit: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
  }

  close() {
    this.confirmEmit.emit(false);
  }

  emit() {
    this.confirmEmit.emit(true);
  }
  ngOnChanges(changes: SimpleChanges) {
    console.log(changes)
  }
}
