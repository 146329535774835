import {FormGroup, NgForm} from "@angular/forms";
import {ConfigUserDataResult, UserDataResult} from "angular-auth-oidc-client";
import {ApplicantDto, OssOrganization} from "../generated-model/model";

export function updateFormAndValidity(form: NgForm): boolean {
  if (!form.valid) {
    markInvalidControlsAsDirtyAndTouched(form)
  }
  return form.valid;
}

export function markInvalidControlsAsDirtyAndTouched(form: NgForm): void {
  Object.keys(form.controls)
    .filter(key => form.controls[key].invalid)
    .forEach((key, index) => {
      console.log('form.controls[', key, '], invalid', form.controls[key], form.controls[key]?.invalid)
      if (form.controls[key] instanceof FormGroup) {
        let formGroup = form.controls[key] as FormGroup;
        console.log('formGroup= ', formGroup);
        Object.keys(formGroup.controls)
          .map(k => {
            console.log(`formGroup.controls[${k}] invalid? `, form.controls[k]?.invalid);
            return k;
          })
          .filter(k => formGroup.controls[k].invalid)
          .forEach((groupKey, index) => {
            console.log('form.formGroup[', groupKey, '], invalid', formGroup.controls[groupKey], formGroup.controls[groupKey]?.invalid)
            formGroup.controls[groupKey].markAsDirty({onlySelf: false})
            formGroup.controls[groupKey].markAsTouched({onlySelf: false})
            formGroup.controls[groupKey].updateValueAndValidity({emitEvent: true});
          });
      } else {
        form.controls[key].markAsDirty({onlySelf: false})
        form.controls[key].markAsTouched({onlySelf: false})
        form.controls[key].updateValueAndValidity({emitEvent: true});
      }
    });
}

export function scrollToInvalidElement(formId: string) {
  //for more info aboute the measure see https://stackoverflow.com/questions/24665602/scrollintoview-scrolls-just-too-far
  let y = document.querySelector(`#${formId} .ng-invalid`)?.getBoundingClientRect().top + window.pageYOffset + -80;
  window.scrollTo({top: y});
}

export type CRUD = 'CREATE' | 'READ' | 'UPDATE' | 'DELETE';

export const DayOfWeekThai: { [id: string]: string } = {
  MONDAY: 'จันทร์',
  TUESDAY: 'อังคาร',
  WEDNESDAY: 'พุธ',
  THURSDAY: 'พฤหัสบดี',
  FRIDAY: 'ศุกร์',
  SATURDAY: 'เสาร์',
  SUNDAY: 'อาทิตย์',
  HOLIDAY: 'วันหยุดนักขัตฤกษ์'
};

export function cloneObject(object: any): any {
  if (!object) {
    return undefined;
  }
  return JSON.parse(JSON.stringify(object))
}

export function notImplementedYet() {
  alert("not implemented yet.");
}

export type PageRequest = { size?: number, page?: number, sort?: string | string[] }
export const compareWithId = (o1: { id: any }, o2: { id: any }): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.id === o2.id));
export const compareWithCode = (o1: { code: any }, o2: { code: any }): boolean => (o1 == o2) || ((!!o1 && !!o2) && (o1.code === o2.code));
export type GeoLocation = { lat: number, lon: number };

export function isCompany(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'COMPANY'
    || user?.userData?.organization?.orgType == 'PERSONAL'
    || user?.userData?.organization?.orgType == 'FOUNDATION'
    || user?.userData?.organization?.orgType == 'ASSOCIATION'
    || user?.userData?.organization?.orgType == 'DISABLED_SERVICE_CENTER'
    || user?.userData?.organization?.orgType == 'VACCINATION_CENTER'
    || user?.userData?.organization?.orgType == 'CIVIL_SOCIETY'
    || user?.userData?.organization?.orgType == 'CLUB'
    || user?.userData?.organization?.orgType == 'CENTRE'
    || user?.userData?.organization?.orgType == 'HEALTH_ESTABLISHMENT'
    || user?.userData?.organization?.orgType == 'OTHER_RELIGIOUS_ORGANIZATION'
    || user?.userData?.organization?.orgType == 'PRIVATE_ORGANIZATION'
    || user?.userData?.organization?.orgType == 'NURSING_HOME'
    || user?.userData?.organization?.orgType == 'GROUP'
    ;
}

export function isFoundation(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'FOUNDATION';
}

export function isTemple(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'TEMPLE';
}

export function isGov(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'GOV';
}

export function isGA(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'GA';
}

export function isCompany_Applicant(applicant: ApplicantDto) {
  return applicant?.organization?.orgType == 'COMPANY'
    || applicant?.organization?.orgType == 'PERSONAL'
    || applicant?.organization?.orgType == 'FOUNDATION'
    || applicant?.organization?.orgType == 'ASSOCIATION'
    || applicant?.organization?.orgType == 'DISABLED_SERVICE_CENTER'
    || applicant?.organization?.orgType == 'VACCINATION_CENTER'
    || applicant?.organization?.orgType == 'CIVIL_SOCIETY';
}

export function isGov_Applicant(applicant: ApplicantDto) {
  return applicant.organization?.orgType == 'GOV';
}

export function isTemple_Applicant(applicant: ApplicantDto) {
  return applicant.organization?.orgType == 'TEMPLE';
}

export function isGroup_Applicant(applicant: ApplicantDto) {
  return applicant.organization?.orgType == 'GROUP';
}

export function isCompanyOrNonProfitsCompany(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'FOUNDATION'
    || user?.userData?.organization?.orgType == 'ASSOCIATION'
    || user?.userData?.organization?.orgType == 'DISABLED_SERVICE_CENTER'
    || user?.userData?.organization?.orgType == 'VACCINATION_CENTER'
    || user?.userData?.organization?.orgType == 'CIVIL_SOCIETY'
    || user?.userData?.organization?.orgType == 'CLUB'
    || user?.userData?.organization?.orgType == 'CENTRE'
    || user?.userData?.organization?.orgType == 'HEALTH_ESTABLISHMENT'
    || user?.userData?.organization?.orgType == 'OTHER_RELIGIOUS_ORGANIZATION'
    || user?.userData?.organization?.orgType == 'PRIVATE_ORGANIZATION'
    || user?.userData?.organization?.orgType == 'NURSING_HOME'
    || user?.userData?.organization?.orgType == 'GROUP'
}

export function selectableHsubType(user: UserDataResult) {
  return user?.userData?.organization?.orgType == 'COMPANY'
    || user?.userData?.organization?.orgType == 'PERSONAL'
    || user?.userData?.organization?.orgType == 'ASSOCIATION'
    || user?.userData?.organization?.orgType == 'DISABLED_SERVICE_CENTER'
    || user?.userData?.organization?.orgType == 'VACCINATION_CENTER'
    || user?.userData?.organization?.orgType == 'CIVIL_SOCIETY'
}

export const ALL_PHARMACY_HSUBTYPE_ID_LIST = [
  "001014",
  "001111",
  "001218",
  "001306",
  "001508",
  "001602"
];
export const ALL_CASE_OTHER_ORG = [
  'FOUNDATION',
  'ASSOCIATION',
  'DISABLED_SERVICE_CENTER',
  'VACCINATION_CENTER',
  'CIVIL_SOCIETY',
  'CLUB',
  'CENTRE',
  'HEALTH_ESTABLISHMENT',
  'OTHER_RELIGIOUS_ORGANIZATION',
  'PRIVATE_ORGANIZATION',
  'NURSING_HOME',
  'GROUP'
];

export const PRIVATE_HOSPITAL_HSUBTYPE_ID = "001302";
export const TEMPLE_HSUBTYPE_ID = "001313";
export const FOUNDATION_HSUBTYPE_ID = "001311";
export const ASSOCIATION_HSUBTYPE_ID = "001312";
export const CIVIL_SOCIETY_HSUBTYPE_ID = "001310";
export const CLUB_HSUBTYPE_ID = "001314";
export const CENTER_HSUBTYPE_ID = "001315";
export const HEALTH_ESTABLISHMENT_HSUBTYPE_ID = "001316";
export const OTHER_RELIGIOUS_ORGANIZATION_HSUBTYPE_ID = "001317";
export const PRIVATE_ORGANIZATION_HSUBTYPE_ID = "001318";
export const NURSING_HOME_HSUBTYPE_ID = "001319";
export const GROUP_HSUBTYPE_ID = "001320";
export const GROUP_SPS_ID = "001221";
export const GROUP_SOPS_ID = "001603";


export interface CustomUserDataResult {
  userData: UserData;
  allUserData: ConfigUserDataResult[];
}

export interface UserData {
  sub?: string;
  personalId?: string;
  email_verified?: boolean;
  staffUserType?: string;
  name?: string;
  thaiD?: string;
  source?: 'OSS' | 'DC' | 'LDAP';
  userId?: number;
  staffId?: number;
  email?: string;
  organization?: OssOrganization
}

export const ALL_HSUBTYPE_ID_NO_HTITLE_NAME_LIST = [
  ...ALL_PHARMACY_HSUBTYPE_ID_LIST, // ร้านขายยา
  '001307', //ศูนย์บริการคนพิการ
  '001308', //ศูนย์ฉีดวัคซีน
  '001309', // สถานพยาบาลในสถานประกอบการ
  '001310', // องค์กรภาคประชาสังคม
  '001311', // มูลนิธิ
  '001312', // สมาคม
  '001313' // วัด
]

export const STEP3_COLLECTIONS = "hra_step3_collections";
export const STEP4_COLLECTIONS = "hra_step4_collections";
