import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {AlertDialogService} from "../dialog/alert-dialog/alert-dialog.service";

export const IGNORE_404_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http404Interceptor implements HttpInterceptor {

  constructor(private alertDialogService: AlertDialogService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return req.context.get(IGNORE_404_ERROR) ? next.handle(req) : next.handle(req)
      .pipe(
        tap(event => {

        }, (err: HttpErrorResponse) => {
          if (err.status == 404) {
            console.log(err)
            this.alertDialogService.open({content: err?.error?.message === 'No message available' ? `ไม่พบข้อมูล (Resource Not Found 404)` : err?.error?.message});
          }
        })
      );
  }


}
