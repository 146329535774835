import {Pipe, PipeTransform} from "@angular/core";
import {OssAuthorizeDescriptionDto} from "../../generated-model/model";

@Pipe({
  name: 'authorizeDescription'
})

export class AuthorizeDescriptionPipe implements PipeTransform {
  transform(value: OssAuthorizeDescriptionDto[]): string {
    if (!value) {
      return '-';
    }
    return value.map(e => e.description).join(" ");
  }
}

