import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {LoadingService} from "./loading.service";
import {finalize, Observable} from "rxjs";
import {tap} from "rxjs/operators";

export const IGNORE_LOADING = new HttpContextToken<boolean>(() => false);

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {

  stack: any[] = [];

  constructor(private loadingService: LoadingService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(IGNORE_LOADING)) {
      return next.handle(req)
    }
    if (req.url?.includes('openid-connect')) {
      return next.handle(req)
    }
    if (req.url?.includes('actuator')) {
      return next.handle(req)
    }
    this.loadingService.show();
    this.stack.push(1);
    return next.handle(req)
      .pipe(
        tap(event => {
          if (event instanceof HttpResponse) {
            this.stack.pop();
            if (!this.stack.length) {
              setTimeout(() => this.loadingService.hide());
            }
          }
        }, (err: HttpErrorResponse) => {
          setTimeout(() => this.loadingService.hide());
        }),
        finalize(() => {
          setTimeout(() => this.loadingService.hide());
        })
      );
  }
}
