import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../environments/environment";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {BlacklistDto} from "../../../generated-model/model";

@Injectable({
  providedIn: 'root'
})
export class BlacklistService {

  constructor(private http: HttpClient) {
  }

  search(keyword: string): Observable<BlacklistDto[]> {
    const param = IgnoreNullHttpParams.fromObject({keyword: keyword}).toHttpParam()
    return this.http.get<BlacklistDto[]>(`${environment.serverUrl}/api/blacklist/search-with-duration`, {params: param})
  }
}
