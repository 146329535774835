import {Component, OnDestroy} from '@angular/core';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {OssUserService} from "./security/oss-user.service";
import {UserData} from "./oidc-user";
import {NavigationStart, Router} from "@angular/router";
import {ConfirmDialogService} from "./shared/commons/dialog/confirm-dialog/confirm-dialog.service";
import {Subscription} from "rxjs";

export let browserRefresh: boolean = false;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {
  title = 'HRA';
  isLoginConfirmDialogOpen = false;
  browserRefreshSubscription: Subscription;

  constructor(public oidcSecurityService: OidcSecurityService, private userService: OssUserService, private router: Router, private confirmDialogService: ConfirmDialogService) {
    this.browserRefreshSubscription = router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !router.navigated;
      }
    });
  }

  ngOnInit() {
    this.oidcSecurityService
      .checkAuth()
      .subscribe((loginResponse: LoginResponse) => {
        const {isAuthenticated, userData, accessToken, idToken, configId} = loginResponse;
        console.log(loginResponse)
        if (isAuthenticated) {
          const user: UserData = loginResponse.userData;
          this.userService.getUser()
            .subscribe(e => {
              console.log('oss user = ', e);
              if (!user.organization) {
                console.log("!!! no Organization to to Portal.....")
                this.router.navigate(['/403']);
              } else {
                this.userService.updateUser().subscribe(e => console.log("update user profile complete"))
              }
            });
        } else {
          this.oidcSecurityService.authorize();
        }
      });

    this.oidcSecurityService.checkSessionChanged$.subscribe(e => {
      console.log('checkSessionChanged$', e)
      if (e && !this.isLoginConfirmDialogOpen) {
        this.isLoginConfirmDialogOpen = true
        this.confirmDialogService.open({content: 'session หมดอายุ ต้องการล็อกอินอีกครั้งกด ยืนยัน?'}).subscribe(e => {
          if (e) {
            this.oidcSecurityService.logoff()
            this.oidcSecurityService.authorize()
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    if (this.browserRefreshSubscription && !this.browserRefreshSubscription.closed) {
      this.browserRefreshSubscription.unsubscribe()
    }
  }
}
