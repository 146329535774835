import {Injectable} from '@angular/core';
import {
  Hospital,
  HospitalDto,
  HospitalInfo,
  HospitalLicense,
  HospitalWithHtypeDto,
  Htitle,
  MedicalTechType,
  ServiceDto,
  ServiceHospDto,
  Type,
  TypeTrans,
  TypeWithoutChildAndParentDto
} from "../../../generated-model/model";
import {HttpClient, HttpContext} from "@angular/common/http";
import {Observable} from "rxjs/index";
import {environment} from "../../../environments/environment";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {IGNORE_400_ERROR} from "../commons/interceptor/http400-interceptor";

@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  constructor(private http: HttpClient) {
  }

  getHospitalLicenseBusiness(citizenId): Observable<HospitalLicense[]> {
    return this.http.get<HospitalLicense[]>(`${environment.serverUrl}/api/gdx/hospital/license/business?citizenId=${citizenId}`)
  }

  getAllServiceType(): Observable<Type[]> {
    return this.http.get<Type[]>(`${environment.serverUrl}/public/api/type`);
  }

  getTypeTransByHid(hid: number): Observable<TypeTrans[]> {
    return this.http.get<TypeTrans[]>(`${environment.serverUrl}/api/type-trans/${hid}`);
  }

  getServiceByTypeId(typeId: number): Observable<ServiceDto[]> {
    return this.http.get<ServiceDto[]>(`${environment.serverUrl}/api/hosp-potential/service/${typeId}`);
  }

  getServiceByTypeHospId(typeHospId: number): Observable<ServiceHospDto[]> {
    return this.http.get<ServiceHospDto[]>(`${environment.serverUrl}/api/hosp-potential/service-hosp/${typeHospId}`);
  }

  getAllServiceTypeSelectList(): Observable<Type[]> {
    return this.http.get<Type[]>(`${environment.serverUrl}/public/api/type/select-list`);
  }

  getAllMedicalTechType(): Observable<MedicalTechType[]> {
    return this.http.get<MedicalTechType[]>(`${environment.serverUrl}/public/api/medical-tech-type`);
  }

  getServiceTypeById(id: number): Observable<Type> {
    return this.http.get<Type>(`${environment.serverUrl}/public/api/type/${id}`);
  }

  getByHname(hname: string): Observable<HospitalDto> {
    return this.http.get(`${environment.serverUrl}/api/hospital/${hname}`);
  }

  getByhcodeOrHname(term: string): Observable<HospitalInfo> {
    return this.http.get<HospitalInfo>(`${environment.serverUrl}/api/hospital/hcode-or-hname/${term}`);
  }

  getByhcode(hcode: string): Observable<HospitalInfo> {
    return this.http.get<HospitalInfo>(`${environment.serverUrl}/api/hospital/${hcode}/hospital`);
  }

  getAllCurrentUser(): Observable<HospitalInfo[]> {
    return this.http.get<HospitalInfo[]>(`${environment.serverUrl}/api/hospital/all-hosp-current-user`);
  }

  findByLicenseName(name: string): Observable<HospitalDto[]> {
    let httpParams = IgnoreNullHttpParams.fromObject({name: name}).toHttpParam();
    const httpContext = new HttpContext();
    httpContext.set(IGNORE_400_ERROR, undefined);
    return this.http.get<HospitalDto[]>(`${environment.serverUrl}/api/hospital/license`, {params: httpParams, context: httpContext});
  }

  findByDrugstoreName(name: string): Observable<HospitalDto[]> {
    let httpParams = IgnoreNullHttpParams.fromObject({name: name}).toHttpParam();
    const httpContext = new HttpContext();
    httpContext.set(IGNORE_400_ERROR, undefined);
    return this.http.get<HospitalDto[]>(`${environment.serverUrl}/api/hospital/drugstore/license`, {params: httpParams, context: httpContext});
  }

  findByTerm(hsubtypeId: string, term: string): Observable<HospitalDto[]> {
    let httpParams = IgnoreNullHttpParams.fromObject({hsubtypeId: hsubtypeId, term: term}).toHttpParam();
    return this.http.get<HospitalDto[]>(`${environment.serverUrl}/api/hospital/term`, {params: httpParams});
  }

  getForCurrentUser(): Observable<HospitalWithHtypeDto> {
    return this.http.get(`${environment.serverUrl}/api/hospital/current-user`);
  }

  getHospWithHtypeByHcode(hcode: string): Observable<HospitalWithHtypeDto> {
    return this.http.get(`${environment.serverUrl}/api/hospital/${hcode}/hospital-with-htype`);
  }

  findAllHtitle(): Observable<Htitle[]> {
    return this.http.get<Htitle[]>(`${environment.serverUrl}/api/hospital/htitle`);
  }

  getAllGuaranteeHospitalTypeSelectList(): Observable<TypeWithoutChildAndParentDto[]> {
    return this.http.get<TypeWithoutChildAndParentDto[]>(`${environment.serverUrl}/public/api/guarantee-hospital-type/select-list-fitter`);
  }

  findByManualHospital(hname: string, addressNo: string, subDistrictId: string): Observable<Hospital[]> {
    return this.http.get<Hospital[]>(`${environment.serverUrl}/api/hospital/find-manual-hospital`,
      {params: IgnoreNullHttpParams.fromObject({hname: hname, addressNo: addressNo, subDistrictId: subDistrictId}).toHttpParam()});
  }
}
