import {AfterViewInit, Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Bank, BankDto} from "../../../../generated-model/model";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-bank-autocomplete',
  templateUrl: './bank-autocomplete.component.html',
  styleUrls: ['./bank-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BankAutocompleteComponent),
      multi: true
    },
  ],
})
export class BankAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  banks: BankDto[] = [];
  bankLoading = false;
  private _value: BankDto = undefined;
  @Input('id') id: string = 'bank';
  @Input('name') name: string = 'bank';
  @Input('disabled') disabled: boolean = false;
  @ViewChild(NgControl) innerNgControl: NgControl;
  onChange: any = () => {
  }
  onTouch: any = () => {
  }
  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor(private http: HttpClient, private inj: Injector) {

  }

  ngOnInit(): void {
    this.bankLoading = true;
    this.http.get<BankDto[]>(`${environment.serverUrl}/api/public/bank`)
      .subscribe({
        next: e => {
          this.banks = e;
          this.bankLoading = false;
        }, error: err => {
          this.bankLoading = false;
        }
      });
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  get value(): Bank {
    return this._value;
  }

  set value(value: Bank) {
    this._value = value;
    this.onChange(value);
    this.onTouch(value);
  }

  writeValue(value: Bank) {
    this._value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log('bank-autocomplete changes = ', changes)
  }
}
