import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {OidcSecurityService, UserDataResult} from "angular-auth-oidc-client";
import {MobileSettingDialogService} from "./mobile-setting-dialog.service";
import {ToastService} from "../../toast/toast-service";
import {NgForm} from "@angular/forms";
import {markInvalidControlsAsDirtyAndTouched} from "../../../../app.constants";
import {KeyCloakAttributeDto} from 'src/generated-model/model';


@Component({
  selector: 'app-mobile-setting-dialog',
  templateUrl: './mobile-setting-dialog.component.html',
  styleUrls: ['./mobile-setting-dialog.component.scss']
})
export class MobileSettingDialogComponent implements OnInit {

  attributeDto: KeyCloakAttributeDto = {attributes : {}}
  @ViewChild('form', {static: true}) public form: NgForm;

  constructor(public activeModal: NgbActiveModal,
              private oidcSecurityService: OidcSecurityService,
              private toastService: ToastService,
              private emailNotifyDialogService: MobileSettingDialogService) {
  }

  ngOnInit(): void {
    this.oidcSecurityService.userData$.subscribe(user => {
      this.attributeDto.attributes['mobile'] = user?.userData?.mobile
    })
  }

  submit() {
    if (!this.form.valid) {
      markInvalidControlsAsDirtyAndTouched(this.form)
      return
    }
    this.emailNotifyDialogService.createOrUpdateAttribute(this.attributeDto).subscribe(e => {
      this.oidcSecurityService.forceRefreshSession().subscribe(data => {
        this.activeModal.close(e)
        this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
      });
    })
  }
}
