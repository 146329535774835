<!--<div class="alert alert-warning alert-dismissible fade show mb-3 border-5 border-white text-center" role="alert">-->
<!--  <strong>ประกาศ!</strong>-->
<!--  <hr>-->
<!--  <p>-->
<!--    ขณะนี้ การเชื่อมโยงข้อมูลเกี่ยวกับขึ้นทะเบียนหน่วยบริการจากสำนักงานพัฒนารัฐบาลดิจิทัล (องค์การมหาชน) ขัดข้อง <br>-->
<!--    อยู่ระหว่างการปรับปรุง มีผลทำให้ไม่สามารถใช้งานระบบการขึ้นทะเบียนได้ <br>-->
<!--    <u>คาดว่าจะแล้วเสร็จ</u><span class="text-primary mx-2">ภายใน วันที่ 14 สิงหาคม 2567 เวลา 16.00 น.</span>-->
<!--  </p>-->
<!--  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
<!--</div>-->
<!--<div class="alert alert-warning alert-dismissible fade show mb-3 border-5 border-white text-center" role="alert">-->
<!--  <strong>ประกาศ!</strong>-->
<!--  <hr>-->
<!--  <p>-->
<!--    ประชาสัมพันธ์การสมัครขึ้นทะเบียนเป็นหน่วยบริการในระบบหลักประกันสุขภาพแห่งชาติ-->
<!--    สำนักงานหลักประกันสุขภาพแห่งชาติเขต 13 กรุงเทพมหานคร ประชาสัมพันธ์การรับสมัครคลินิกเวชกรรม หรือสหคลินิกที่ให้บริการด้านเวชกรรม <br>-->
<!--    ขึ้นทะเบียนเป็นหน่วยบริการปฐมภูมิ ในพื้นที่กรุงเทพมหานคร เขตบางขุนเทียน จำนวน 1 แห่ง โดยมีคุณสมบัติเป็นไปตามเกณฑ์การตรวจประเมินขึ้นทะเบียนเป็นหน่วยบริการปฐมภูมิ ผ่านระบบเว็บไซด์<br>-->
<!--    <a role="button" class="text-decoration-none mx-1">http://ossregister.nhso.go.th</a>-->
<!--  </p>-->
<!--  <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>-->
<!--</div>-->

<div *hasAnyAuthority="['nhso', 'zone']" class="row justify-content-center" style="padding-top:20%;">
  <div class="col-xl-6 text-center my-auto">
    <div class="fs-6 text-muted">
      Hospital Registration and Announcement
    </div>
  </div>
</div>

<ng-container *hasAnyAuthority="['hospital', 'company', 'personal']">
  <ng-container *ngIf="{result: activity$ | async, error: activityError$| async} as r">
    <ng-container *ngIf="!r.error && r.result as result; else loading">
      <div *ngIf="!result?.length" class="row justify-content-center" style="padding-top:20%;">
        <div class="col-xl-6 text-center my-auto">
          <div class="fs-6 text-muted">
            Hospital Registration and Announcement
          </div>
        </div>
      </div>
      <div *ngIf="result?.length" class="mb-3 fs-6 text-muted">
        <i class="bi bi-activity me-2"></i>
        กิจกรรมล่าสุด
      </div>
      <div class="row g-4">
        <ng-container *ngFor="let item of result">
          <div class="col-auto">
            <div class="card" style="width: 22rem;">
              <div class="card-body">
                <h6 class="card-title">สมัครขึ้นทะเบียนหน่วยบริการ</h6>
                <hr>
                <!--              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>-->
                <p class="card-text text-truncate">
                  <span class="text-muted me-1">รหัส</span>{{ item.hcode || '-' }}
                </p>
                <p class="card-text text-truncate">
                  <span class="text-muted me-1">ชื่อ</span>{{ item.hname || '-' }}
                </p>
                <p class="card-text text-truncate">
                  <span class="text-muted me-1">ประเภท</span>{{ item.hsubtype?.hsubtypeDesc || '-' }}
                </p>
                <p class="card-text text-truncate">
                  <span class="text-muted me-1">สถานะคำขอ</span>
                  <span *ngIf="item.status == 'PENDING'" class="custom-badge bg-info-lt">
                    <i class="bi bi-hourglass me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'CONSIDERING'" class="custom-badge bg-warning-lt ">
                    <i class="bi bi-lightbulb me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'CORRECTION'" class="custom-badge bg-warning-lt ">
                    <i class="bi bi-lightbulb me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'APPROVED'" class="custom-badge bg-primary-lt ">
                    <i class="bi bi-check me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'DENIED'" class="custom-badge bg-danger-lt ">
                    <i class="bi bi-x-octagon me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'BLACKLIST'" class="custom-badge bg-dark text-white ">
                    <i class="bi bi-x-octagon me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'VERIFYING_BLACKLIST'" class="custom-badge  bg-verifying-blacklist-lt text-white ">
                    <i class="bi bi bi-stopwatch me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                  <span *ngIf="item.status == 'CANCELED'" class="custom-badge bg-danger-lt">
                    <i class="bi bi-x-circle me-2"></i>{{ RegistrationStatus[item.status] }}
                  </span>
                </p>

                <a *ngIf="item.status == 'PENDING'" (click)="continueFiling(item)" role="button" class="card-link">
                  บันทึกข้อมูล{{ Step[item.step] }}ต่อ
                </a>
                <a *ngIf="item.status != 'PENDING'" (click)="continueFiling(item)" role="button" class="card-link">
                  ดูรายละเอียด
                </a>
                <!--                <a href="#" class="card-link">Another link</a>-->
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="r.error as error">
      <div class="row">
        <div class="col-12 py-5 text-center text-muted fw-semibold">
          <!--        <i class="bi bi-lightning me-2"></i>-->
          <!--        Oops!-->
        </div>
      </div>
    </ng-container>
    <ng-template #loading>
      <div *ngIf="!r.error" class="row">
        <div class="col-12 py-5 text-center text-muted">
          <div class="bubblingG">
            <span id="bubblingG_1"></span>
            <span id="bubblingG_2"></span>
            <span id="bubblingG_3"></span>
          </div>
          กำลังโหลดกิจกรรมล่าสุด...
        </div>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
