import {NgModule} from "@angular/core";
import {HasAnyAuthorityDirective} from "./has-any-authority.directive";
import {HasAllAuthorityDirective} from "./has-all-authority.directive";

@NgModule({
   imports: [],
   declarations: [
      HasAnyAuthorityDirective,
      HasAllAuthorityDirective
   ],
   exports: [
      HasAnyAuthorityDirective,
      HasAllAuthorityDirective
   ],
   providers: [

   ]
})
export class AppSecurityModule {

}
