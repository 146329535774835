import {inject, Injectable} from "@angular/core";
import {Authority} from "../../generated-model/model";
import {first} from "rxjs/operators";
import {environment} from "../../environments/environment";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {forkJoin, map, Observable} from "rxjs";
import {UserDataService} from "../shared/services/user-data.service";

@Injectable({providedIn: "root"})
export class SecurityAuthorityService {

  private userDataService = inject(UserDataService);

  constructor(public oidc: OidcSecurityService) {
  }

  public hasAnyAuthority(checkAuthorities: (Authority | string)[]): Observable<boolean> {
    return forkJoin([
      this.userDataService.userData$
        .pipe(
          map(e => e.authorities?.map(a => a.authority))
        ),
      this.oidc.userData$
        .pipe(
          first(),
          map(e => {
            let userAuthorities: Authority[] = e.userData?.resource_access?.[environment.clientId]?.roles || [];
            let realmRole: Authority[] = e.userData?.realm_access?.roles || [];
            return [...userAuthorities, ...realmRole, `zone_${e.userData.ldapWsZoneCode}`];
          })
        )
    ]).pipe(map(value => this.hasAny(checkAuthorities, [...value[0], ...value[1]])));
  }

  private hasAny(authorityList: (Authority | string)[], authorities: string[] = []): boolean {
    if(!authorities.some(e => e == 'hra' || e == 'healthlink')) {
      return false;
    }
    if (authorities) {
      return authorities.some(e => authorityList.some(ex => e == ex));
    } else {
      return false;
    }
  }
}
