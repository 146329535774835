import {AfterViewInit, Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Province, Zone} from "../../../../generated-model/model";
import {Observable, of} from "rxjs";
import {MasterService} from "../../services/master.service";

@Component({
  selector: 'gt-province-autocomplete',
  templateUrl: './gt-province-autocomplete.component.html',
  styleUrls: ['./gt-province-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtProvinceAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtProvinceAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  provinces$: Observable<Province[]> = of([]);
  private _value: Province = undefined;
  @Input('id') id: string = 'province';
  @Input('name') name: string = 'province';
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() restrictZoneCode: string
  @Input() restrictProvince: Province;
  @ViewChild(NgControl) innerNgControl: NgControl;
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: Province) => void = () => {
  };
  compareWithCode = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.code == o2.code))
  };

  constructor(public masterService: MasterService, private inj: Injector) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
  }

  get value(): Province {
    return this._value;
  }

  set value(value: Province) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: Province) {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
    const restrictProvinceChange = changes['restrictProvince']
    if (restrictProvinceChange?.currentValue) {
      this.provinces$ = of([restrictProvinceChange.currentValue])
    } else if (this.restrictZoneCode) {
      this.provinces$ = this.masterService.findProvince(restrictProvinceChange?.currentValue, this.restrictZoneCode)
    } else {
      this.provinces$ = this.masterService.findProvince(restrictProvinceChange?.currentValue)

    }

    const restrictZoneCodeChange = changes['restrictZoneCode']?.currentValue as Zone;
    if (restrictZoneCodeChange?.code == '13') {
      this._value = {
        code: "1000",
        name: "กรุงเทพฯ",
        zone: restrictZoneCodeChange
      };
    }
  }
}
