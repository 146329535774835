import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {CppMPersonDto, Province, SubDistrictDto} from "../../../generated-model/model";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class MasterService {

  constructor(private http: HttpClient) {
  }

  findProvince(term: string, restrictZoneCode?: string): Observable<Province[]> {
    return this.http.get<Province[]>(`${environment.serverUrl}/public/api/province/search`, {
      params: IgnoreNullHttpParams.fromObject({
        term: term,
        restrictZoneCode: restrictZoneCode
      }).toHttpParam()
    });
  }

  findSubdistrict(term: string): Observable<SubDistrictDto[]> {
    return this.http.get<SubDistrictDto[]>(`${environment.serverUrl}/public/api/subdistrict/search`, {
      params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()
    });
  }

  getCppMPerson(term?: string) {
    return this.http.get<CppMPersonDto[]>(`${environment.serverUrl}/public/api/cpp-m-person/search-item`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
  }
}
