import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {BankVendorDto, FileConfigItem, HospitalInfo, Page, ProviderDto} from "../../../../generated-model/model";
import {environment} from "../../../../environments/environment";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";

@Injectable({
  providedIn: 'root'
})
export class HospitalInfoService {

  constructor(private http: HttpClient) {
  }

  findHospitalBySapProviderCode(code: string): Observable<HospitalInfo> {
    return this.http.get<HospitalInfo>(`${environment.serverUrl}/public/api/provider/sap-provider/${code}/hospital`);
  }

  findBankVendorByVatId(params: IgnoreNullHttpParams): Observable<Page<BankVendorDto>> {
    return this.http.get<Page<BankVendorDto>>(`${environment.serverUrl}/public/api/bank-vendor`,
      {params: params.toHttpParam()});
  }

  findBankVendorListByVatId(params: IgnoreNullHttpParams): Observable<BankVendorDto[]> {
    return this.http.get<BankVendorDto[]>(`${environment.serverUrl}/public/api/bank-vendor/list`,
      {params: params.toHttpParam()});
  }

  findHospitalByHcode(hcode: string): Observable<HospitalInfo> {
    return this.http.get<HospitalInfo>(`${environment.serverUrl}/api/hospital/${hcode}/hospital`);
  }
  updateRegHosp(hospitalInfo:HospitalInfo): Observable<HospitalInfo> {
    return this.http.post<HospitalInfo>(`${environment.serverUrl}/api/hospital/reghosp/update`,hospitalInfo);
  }
}
