<ng-select [id]="id"
           [name]="name"
           [items]="provinces$ | async"
           placeholder="กรุณาเลือก"
           [readonly]="disabled"
           [required]="required"
           [searchable]="true"
           [clearable]="true"
           bindLabel="name"
           [compareWith]="compareWithCode"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngIf="item.code">
      {{item.name}}
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <ng-container *ngIf="item.code">
      {{item.name}}
    </ng-container>
  </ng-template>
</ng-select>
