import {Component, Input, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgForm} from "@angular/forms";
import {markInvalidControlsAsDirtyAndTouched} from "../../../../app.constants";
import {environment} from "../../../../../environments/environment";
import {ConfirmRegistrationDto} from "../../../../../generated-model/model";

@Component({
  selector: 'thaid-modal',
  templateUrl: './thaid-modal.component.html',
  styleUrls: ['./thaid-modal.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class ThaidModalComponent {

  @ViewChild('form', {static: true}) public form: NgForm;
  @Input({alias: 'applicant', required: true}) applicant: any;
  name: string;
  pid: string

  constructor(public activeModal: NgbActiveModal,
              private renderer: Renderer2) {
  }

  authenThaiD() {
    let formValid = this.updateFormAndValidity(this.form);
    if (formValid) {
      window.open(environment.thaiDUrl, '_blank');
      window.addEventListener('message', (event) => {
        if (event.data.payload?.pid && !this.pid) {
          this.pid = event.data.payload?.pid;
          let data: ConfirmRegistrationDto = {};
          data.thaidPid = event.data.payload.pid;
          data.thaidName = this.name;
          window.removeEventListener('message', ev => console.log('no loger listen on message'));
          this.activeModal.close(data);
        }
      });
    } else {
      console.log("form invalid");
      this.renderer.selectRootElement('#confirm-registration-form .ng-invalid').focus();
    }
  }

  updateFormAndValidity(form: NgForm): boolean {
    if (!form.valid) {
      markInvalidControlsAsDirtyAndTouched(form)
    }
    return form.valid;
  }
}
