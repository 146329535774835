import {Injectable} from '@angular/core';
import {AbstractLoggerService} from "angular-auth-oidc-client";

@Injectable({
  providedIn: 'root'
})
export class LogService implements AbstractLoggerService{

  constructor() { }

  logDebug(message: any, ...args: any[]): void {
    // console.log(message,args)
  }

  logError(message: any, ...args: any[]): void {
    // console.log(message,args)
  }

  logWarning(message: any, ...args: any[]): void {
    // console.log(message,args)
  }
}
