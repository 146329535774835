import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {ToastService} from "../toast/toast-service";
import {WarningDialogService} from "../dialog/warning-dialog/warning-dialog.service";

export const IGNORE_5XX_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http5xxInterceptor implements HttpInterceptor {

  constructor(private toastService: ToastService, private warningDialogService: WarningDialogService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.context.get(IGNORE_5XX_ERROR)) {
      return next.handle(req)
    }
    return next.handle(req)
      .pipe(tap
      (event => {
      }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          // A client-side or network error occurred. Handle it accordingly.
          this.toastService.showDanger();
        } else if (err.status >= 500 && err.status <= 505) {
          if (err.status == 503) {
            //Error on PdfCertSignImpl class throw ServiceUnavailableException 503 on server
            this.warningDialogService.open({title: 'พบข้อผิดพลาด', isJSON: true, json: err.error});
          } else {
            this.toastService.showDanger();
          }
        } else if (err.status == 0) {
          this.toastService.showDanger();
        }
      }));
  }

}
