import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HospitalInfo, OrgType, RegOpeningHour, RegOpeningHourItem} from "../../../../../generated-model/model";
import {HospitalInfoService} from "../../../../pages/hospital/info/hospital-info.service";
import {CRUD, DayOfWeekThai} from "../../../../app.constants";
import {RegistrationService} from "../../../../pages/shared/registration.service";
import {ToastService} from "../../../commons/toast/toast-service";

@Component({
  selector: 'app-hospital-modal',
  templateUrl: './hospital-modal.component.html',
  // styleUrls: ['./method-modal.component.scss']
  styles: [
    `
      .custom-modal-content .modal-content {
        overflow: auto;
      }

      .custom-modal-content .modal-content .modal-body {
        //background-color: aliceblue;
      }
    `
  ],
})
export class HospitalModalComponent implements OnInit {

  crudMode: CRUD = 'CREATE';
  itemList: any[] = [{weekDay: 'จันทร์'}, {weekDay: 'อังคาร'}, {weekDay: 'พุธ'}, {weekDay: 'พฤหัสบดี'}, {weekDay: 'ศุกร์'}, {weekDay: 'เสาร์'}, {weekDay: 'อาทิตย์'}];
  sapProviderCode: string;
  hospitalInfo: HospitalInfo = {}
  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();
  orgType: OrgType;
  dayOfWeekThai = DayOfWeekThai;
  openingHour: RegOpeningHour = {};

  constructor(public activeModal: NgbActiveModal,
              public modalService: NgbModal,
              private hospitalInfoService: HospitalInfoService,
              private registrationService: RegistrationService,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
    if (this.openingHour.openingHourItems == null) {
      this.openingHour.openingHourItems =
        [
          {day: "MONDAY", open: false},
          {day: "TUESDAY", open: false},
          {day: "WEDNESDAY", open: false},
          {day: "THURSDAY", open: false},
          {day: "FRIDAY", open: false},
          {day: "SATURDAY", open: false},
          {day: "SUNDAY", open: false}
        ]
    }

  }

  submit() {
    this.hospitalInfoService.updateRegHosp(this.hospitalInfo).subscribe({
      next: e => {
        this.onSubmit.emit(e);
        this.activeModal.close(e)
        this.toastService.showSuccess('บันทึกข้อมูลสำเร็จ')
      }
    })

  }
  getLocation(): void {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.hospitalInfo.lon  = position.coords.longitude;
        this.hospitalInfo.lat = position.coords.latitude;
        console.log(this.hospitalInfo.lon, this.hospitalInfo.lat);
      });
    } else {
      console.log("No support for geolocation")
    }
  }

  linkToGoogleMap() {
    this.registrationService.linkToGoogleMap({lat: this.hospitalInfo.lat, lon: this.hospitalInfo.lon});
  }

  isOpenChanges(item: RegOpeningHourItem) {
    this.openingHour.totalDay = this.openingHour?.openingHourItems.filter(e => e.open).length;
  }

  timeChanges(item: RegOpeningHourItem) {
    if ((item.timeOpening || item.timeClosing)) {
      item.open = !!(item.timeOpening || item.timeClosing);
      this.isOpenChanges(item);
    }
    this.openingHour.totalHour = this.openingHour?.openingHourItems.filter(e => e.timeOpening && e.timeClosing)
      .map(e => new Date(`1970-01-01T${e.timeClosing}`).getHours() - new Date(`1970-01-01T${e.timeOpening}`).getHours())
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0);
  }
}
