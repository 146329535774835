import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {CppMPersonDto, ServicePotentialAttachmentDto, ServicePotentialServiceDto} from "../../../generated-model/model";
import {environment} from "../../../environments/environment";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {ServicePotentialAttachmentService} from "../../pages/officer/master-data/service-potential-attachment/service/service-potential-attachment.service";

@Injectable({
    providedIn: 'root'
})
export class PotentialService {

    constructor(private http: HttpClient) {
    }


    searchServiceTerm(term?: string) {
        return this.http.get<ServicePotentialServiceDto[]>(`${environment.serverUrl}/api/service-potential-service/search-term`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
    }

    searchAttachmentTerm(term?: string) {
        return this.http.get<ServicePotentialAttachmentDto[]>(`${environment.serverUrl}/api/service-potential-attachment/search-term`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
    }
}
