import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {RegStaffDto} from "../../../generated-model/model";

@Directive({
  selector: '[validStaff]',
  providers: [{provide: NG_VALIDATORS, useExisting: ValidStaff, multi: true}]
})
export class ValidStaff implements Validator {

  @Input({alias: 'validStaff', required: true}) staffList: RegStaffDto[];
  @Input({alias: 'uniqueField', required: true}) uniqueField: 'pid' | 'licenseNo';

  validate(control: AbstractControl): ValidationErrors | null {
    let exist = this.staffList?.filter(e => !!e.licenseNo).some(e => e[this.uniqueField] == control.value);
    if (exist) {
      return {uniqueStaff: true};
    }
    return null;
  }
}

