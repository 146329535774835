import {AfterViewInit, Component, forwardRef, inject, Injector, Input, OnInit, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Hospital} from "../../../../generated-model/model";
import {NgSelectComponent} from "@ng-select/ng-select";
import {HospitalService} from "../../services/hospital.service";

@Component({
  selector: 'gt-user-hospital-autocomplete',
  templateUrl: './gt-user-hospital-autocomplete.component.html',
  styleUrls: ['./gt-user-hospital-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtUserHospitalAutocompleteComponent),
      multi: true
    }
  ]
})
export class GtUserHospitalAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  hospitals: Hospital[] = [];
  private _value: Hospital;
  @Input('id') id: string = 'hospital';
  @Input('name') name: string = 'hospital';
  @Input() disabled: boolean = false;
  @Input("hname") hname: string;
  @Input("addressNo") addressNo: string;
  @Input("subDistrictId") subDistrictId: string;
  @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) hospitalAutocomplete: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;
  typeaheadLoading = false;
  hospitalService = inject(HospitalService);
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: Hospital) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor(private inj: Injector) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
    this.hospitalService.findByManualHospital(this.hname, this.addressNo, this.subDistrictId)
      .subscribe(e => {
        this.hospitals = e;
      })
  }

  get value(): Hospital {
    return this._value;
  }

  set value(value: Hospital) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: Hospital) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clear() {
    this.hospitalAutocomplete.handleClearClick();
  }

  onSelectOpen() {
    // this.term$.next('');
  }
}
