import {Directive, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from "@angular/forms";
import {Observable} from "rxjs/internal/Observable";
import {RegistrationService} from "../../pages/shared/registration.service";
import {catchError, debounceTime, distinctUntilChanged, map, mergeMap, of} from "rxjs";
import {Md5} from "../../../generated-model/model";

@Directive({
  selector: '[validateDoctor]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: ValidateDoctor,
      multi: true
    }
  ],
})
export class ValidateDoctor implements AsyncValidator {

  @Input({alias: 'name', required: true}) name: string;

  constructor(private registrationService: RegistrationService) {
  }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return of();
    }
    return of(control.value)
      .pipe(
        distinctUntilChanged(),
        debounceTime(500),
        mergeMap((e: string) => {
          console.log('validateDoctor name = ' + e);
          let nameRemoveMultipleSpaces = e.replace(/\s\s+/g, ' ');
          // let splitResult = nameRemoveMultipleSpaces.split(" ");
          return this.registrationService.validateMd5(nameRemoveMultipleSpaces)
            .pipe(
              map((md5: Md5) => {
                if (md5) {
                  console.log('found md5 ', md5);
                  return undefined;
                } else {
                  return {'md5NotFound': true};
                }
              }),
              catchError((err) => {
                return of({validationApiError: err.message});
              })
            )
        })
      );
  }
}
