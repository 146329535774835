import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LayoutComponent} from "./pages/layout/layout.component";
import {FrontChannelLogoutComponent} from "./front-channel-logout/front-channel-logout.component";
import {LandingComponent} from "./landing/landing.component";
import {BlacklistComponent} from "./blacklist/blacklist.component";
import {P403Component} from "./shared/commons/interceptor/error/403.component";
import {FileUploadPatcherComponent} from "./pages/file-upload-patcher/file-upload-patcher.component";
import {SapPatcherComponent} from "./pages/sap-patcher/sap-patcher.component";

const routes: Routes = [
  {
    path: '',
    pathMatch: "full",
    redirectTo: "landing"
  },
  {
    path: '',
    pathMatch: 'prefix',
    component: LayoutComponent,
    children: [
      {
        path: 'landing',
        component: LandingComponent
      },
      {
        path: 'choose-system',
        loadChildren: () => import("./pages/choose-system/choose-system.module").then(value => value.ChooseSystemModule)
      },
      {
        path: 'hospital',
        loadChildren: () => import("./pages/hospital/hospital.module").then(value => value.HospitalModule),
      },
      {
        path: 'officer',
        loadChildren: () => import("./pages/officer/officer.module").then(value => value.OfficerModule),
      },
      {
        path: 'renew',
        loadChildren: () => import("./pages/renew/renew.module").then(value => value.RenewModule),
      },
      {
        path: '403',
        component: P403Component,
      },
    ]
  },
  {
    path: 'logout',
    component: FrontChannelLogoutComponent
  },
  {
    path: 'blacklist',
    component: BlacklistComponent,
  },
  {
    path: 'file-upload-patcher',
    component: FileUploadPatcherComponent
  },
  {
    path: 'sap-patcher',
    component: SapPatcherComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    urlUpdateStrategy: "eager",
    paramsInheritanceStrategy: "always"
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
