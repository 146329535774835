import {Pipe, PipeTransform} from "@angular/core";
import {OssCommitteeInformation} from "../../generated-model/model";

@Pipe({
  name: 'CommitteeInformation'
})

export class CommitteeInformationPipe implements PipeTransform {
  transform(value: OssCommitteeInformation[]): string {
    if (!value?.length) {
      return '-';
    }
    return value.map(e => `${e.title || ''}${e.firstName || ''} ${e.lastName || ''}`).join(", ");
  }
}

