import {Component, EventEmitter, forwardRef, inject, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {FileItem, FileUploader, ParsedResponseHeaders} from "ng2-file-upload";
import {LoadingService} from "../../loader/loading.service";
import {FileUploadService, UPLOAD_URL} from "./file-upload.service";
import {IgnoreNullHttpParams} from "../../Ignore-null-http-params";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {ConfirmDialogService} from "../../dialog/confirm-dialog/confirm-dialog.service";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    },
    FileUploadService
  ],
})
export class FileUploadComponent implements ControlValueAccessor, OnInit {
  accessToken: string = undefined;
  uploader: FileUploader;
  uploading: boolean = false;
  response: string;
  validates: string[];
  @Input() size: 'sm-inline' | 'normal' = 'normal';
  @Input() fileConfigItem: any
  value: any;
  @Input('id') id: string = 'file';
  @Input('name') name: string = 'file';
  @Input() validateFields: string[] = [];
  @Input('disabled') disabled: boolean = false;
  @Input() fileType: string[] = undefined;
  @Output() uploaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() removed: EventEmitter<any> = new EventEmitter<any>();
  @Input() colorText: string;
  @Input() showHelperText: boolean = true;
  private confirmDialogService: ConfirmDialogService = inject(ConfirmDialogService);

  @Input() buttonClass ='btn-default'
  onChange = (value) => {
  };
  onTouched = () => {
  };
  touched = false;


  constructor(private oidcSecurityService: OidcSecurityService,
              private loadingService: LoadingService) {

  }

  ngOnInit(): void {
    this.oidcSecurityService.getAccessToken()
      .subscribe(accessToken => {
        this.accessToken = accessToken;
        this.uploader = new FileUploader(
          {
            authToken: `Bearer ${this.accessToken}`,
            authTokenHeader: 'Authorization',
            url: UPLOAD_URL,
            allowedFileType: this.fileType
          });
        this.response = '';
        this.uploader.response.subscribe(res => this.response = res);
        this.uploader.onProgressAll = (progress: any) => {
          this.loadingService.show();
        };

        this.uploader.onSuccessItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

          this.loadingService.hide();
          this.uploader.clearQueue();

          this.uploading = false;
          let result = {...this.value, ...JSON.parse(response || "{}")};
          this.writeValue(result);
          this.onChange(this.value);
          this.markAsTouched();
        };

        this.uploader.onErrorItem = (item: FileItem, response: string, status: number, headers: ParsedResponseHeaders) => {

          this.validates = [];
          if (status == 400 || status == 413) {
            let error = JSON.parse(response);
            console.log('errors resp = ', error);
            if (error?.errors?.length) {
              this.validates = [...error?.errors?.map((e: any) => e.defaultMessage)];
            } else {
              this.validates = [error.error + "-" + error?.message];
            }
            console.log('errors = ', this.validates);
            this.uploader.clearQueue();
          }
          if (status == 401) {
            console.log('unauthorized = ', response);
            this.confirmDialogService.open({content: 'session หมดอายุ ต้องการล็อกอินอีกครั้บกด ยืนยัน?'})
              .subscribe(e => {
                if (e) {
                  this.oidcSecurityService.logoff();
                  this.oidcSecurityService.authorize();
                }
              });
            this.uploader.clearQueue();
          }
          this.loadingService.hide()
          this.writeValue(undefined);
          this.onChange(this.value);
          this.markAsTouched();
        };

        this.uploader.onAfterAddingFile = (fileItem: FileItem) => {
          console.log('after adding file = ', fileItem);
          console.log('after adding file = ', this.uploader.queue);
          let currFileItem = this.uploader.queue[this.uploader.queue?.length - 1];
          this.uploader.queue = [currFileItem];
        }

        this.uploader.onCompleteAll = () => {

          this.loadingService.hide()
        };
      });
  }

  upload(event: any) {
    event.target.value = '';
    this.validates = [];
    this.uploader.uploadAll();
  }

  isUploading() {
    return !!this.uploading;
  }

  preview() {
    if (!(this.value.state == 'TEMP')) {
      window.open(`${UPLOAD_URL}/${this.value.uuid}/preview`, '_blank');
    } else {
      let params = new IgnoreNullHttpParams()
        .set("path", this.value.path)
        .set("fileName", this.value.fileName)
        .set("contentType", this.value.mime);
      window.open(`${UPLOAD_URL}/preview?${params.toHttpParam()}`, '_blank');
    }
  }

  removeFile() {
    this.value = undefined;
    this.writeValue(this.value);
    this.valueChange(this.value);
    this.removed.emit(true);
  }

  getAcceptType() {
    if (!this.fileType) {
      return [];
    }
    return this.fileType
      .map(e => {
        if (e == 'pdf') {
          return ["application/pdf"];
        }
        if (e == 'image') {
          return ['image/jpeg', 'image/png'];
        }
        return [];
      })
      .flatMap(e => e);
  }

  getFileTypeDescription() {
    return (this.fileType || [])
      .map(e => {
        if (e == 'pdf') {
          return ["PDF"];
        }
        if (e == 'image') {
          return ['รูปภาw (เฉพาะ JPEG, JPG, PNG)'];
        }
        return [];
      })
      .flatMap(e => e)
      .reduce((previousValue, currentValue) => previousValue + ' หรือ ' + currentValue, '');
  }

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  valueChange(value: any) {
    this.onChange(value);
    this.onTouched();
  }
}
