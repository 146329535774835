import {Directive, ElementRef, Injector, Renderer2} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: '[app-model-empty-value]',
})
export class ModelEmptyValueClassDirective {
  readonly emptyClass = 'app-model-empty-value'

  constructor(private el: ElementRef, private render: Renderer2, private inj: Injector) {
    this.inj.get(NgControl).valueChanges.subscribe(e => {
      if (e == undefined || e === '') {
        render.addClass(el.nativeElement, this.emptyClass)
      } else {
        render.removeClass(el.nativeElement, this.emptyClass)
      }
    });
  }

}
