import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {
  District,
  FileConfigHofftype,
  FileConfigHtype,
  FileConfigItem,
  FileConfigType,
  Htype,
  Page,
  Province,
  Zone,
  ZoneDto,
  UserRepresentation,
  ZoningDto,
  OssUser,
  ResponseZoneConfig,
  ResponseZoneConfigDto,
  RateLetterGuaranteeDto,
  PublishSearchInfoDto,
  ProvinceDto,
  BlacklistDto,
  UserAuthoritySearchParam,
  ZoneCoordinatorDto
} from "../../../../generated-model/model";
import {IgnoreNullHttpParams} from "../../../shared/commons/Ignore-null-http-params";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {PageRequest} from "../../../app.constants";

@Injectable({providedIn: 'root'})
export class MasterDataService {

    constructor(private http: HttpClient) {
    }

    findAllFileConfigItem(currentValue: string): Observable<FileConfigItem[]> {
        return this.http.get<FileConfigItem[]>(`${environment.serverUrl}/api/file-config-item/search`,
            {params: IgnoreNullHttpParams.fromObject({attachFileName: currentValue}).toHttpParam()});
    }

    searchFileConfigItems(params: IgnoreNullHttpParams): Observable<Page<FileConfigItem>> {
        return this.http.get<Page<FileConfigItem>>(`${environment.serverUrl}/api/file-config-item`, {params: params.toHttpParam()});
    }

    searchConfigTypes(params: IgnoreNullHttpParams): Observable<Page<FileConfigType>> {
        return this.http.get<Page<FileConfigType>>(`${environment.serverUrl}/api/file-config-type`, {params: params.toHttpParam()});
    }

  searchConfigHtypes(params: IgnoreNullHttpParams): Observable<Page<FileConfigHtype>> {
    return this.http.get<Page<FileConfigHtype>>(`${environment.serverUrl}/api/file-config-htype`, {params: params.toHttpParam()});
  }

  searchBlacklist(params: IgnoreNullHttpParams): Observable<Page<BlacklistDto>> {
    return this.http.get<Page<BlacklistDto>>(`${environment.serverUrl}/api/blacklist/search`, {params: params.toHttpParam()});
  }

    searchConfigHofftypes(params: IgnoreNullHttpParams): Observable<Page<FileConfigHofftype>> {
        return this.http.get<Page<FileConfigHofftype>>(`${environment.serverUrl}/api/file-config-hofftype`, {params: params.toHttpParam()});
    }

    searchZoning(params: IgnoreNullHttpParams): Observable<Page<ZoningDto>> {
        return this.http.get<Page<ZoningDto>>(`${environment.serverUrl}/api/zoning`, {params: params.toHttpParam()});
    }

    saveConfigItem(item: FileConfigItem): Observable<FileConfigItem> {
        return this.http.post<FileConfigItem>(`${environment.serverUrl}/api/file-config-item`, item);
    }

    deleteConfigItem(item: FileConfigItem): Observable<FileConfigItem> {
        let param = IgnoreNullHttpParams.fromObject({id: item.id})
        return this.http.delete<FileConfigItem>(`${environment.serverUrl}/api/file-config-item`, {params: param.toHttpParam()});
    }

  getZoningById(id: number): Observable<ZoningDto> {
    return this.http.get<ZoningDto>(`${environment.serverUrl}/api/zoning/${id}`);
  }

  getBlacklistById(id: number): Observable<BlacklistDto> {
    return this.http.get<BlacklistDto>(`${environment.serverUrl}/api/blacklist/${id}`);
  }

  deleteZoning(id: number): Observable<ZoningDto> {
    return this.http.delete<ZoningDto>(`${environment.serverUrl}/api/zoning/${id}`);
  }

    saveConfigHtype(item: FileConfigHtype): Observable<FileConfigHtype> {
        return this.http.post<FileConfigHtype>(`${environment.serverUrl}/api/file-config-htype`, item);
    }

    saveZoning(item: ZoningDto): Observable<ZoningDto> {
        return this.http.post<ZoningDto>(`${environment.serverUrl}/api/zoning`, item);
    }

  saveBlacklist(item: BlacklistDto): Observable<BlacklistDto> {
    return this.http.post<BlacklistDto>(`${environment.serverUrl}/api/blacklist`, item);
  }

  remainQuotaZoning(registrationId: number): Observable<ZoningDto[]> {
    return this.http.get<ZoningDto[]>(`${environment.serverUrl}/api/zoning/remain-quota/${registrationId}`);
  }

  deleteConfigHtype(item: FileConfigItem): Observable<FileConfigItem> {
    let param = IgnoreNullHttpParams.fromObject({id: item.id})
    return this.http.delete<FileConfigItem>(`${environment.serverUrl}/api/file-config-htype`, {params: param.toHttpParam()});
  }

  deleteBlacklist(item: BlacklistDto): Observable<BlacklistDto> {
    return this.http.delete<BlacklistDto>(`${environment.serverUrl}/api/blacklist/${item.id}`);
  }

    saveConfigHofftype(item: FileConfigHofftype): Observable<FileConfigHofftype> {
        return this.http.post<FileConfigHofftype>(`${environment.serverUrl}/api/file-config-hofftype`, item);
    }

    deleteConfigHofftype(item: FileConfigItem): Observable<FileConfigItem> {
        let param = IgnoreNullHttpParams.fromObject({id: item.id})
        return this.http.delete<FileConfigItem>(`${environment.serverUrl}/api/file-config-hofftype`, {params: param.toHttpParam()});
    }

    getProvince(): Observable<ProvinceDto[]> {
        return this.http.get<ProvinceDto[]>(`${environment.serverUrl}/public/api/province/all`)
    }

    saveConfigType(item: FileConfigType): Observable<FileConfigType> {
        return this.http.post<FileConfigType>(`${environment.serverUrl}/api/file-config-type`, item);
    }

    deleteConfigType(item: FileConfigItem): Observable<FileConfigItem> {
        let param = IgnoreNullHttpParams.fromObject({id: item.id})
        return this.http.delete<FileConfigItem>(`${environment.serverUrl}/api/file-config-type`, {params: param.toHttpParam()});
    }

    getHtype(term: string): Observable<Htype[]> {
        return this.http.get<Htype[]>(`${environment.serverUrl}/public/api/htype/search`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
    }

    getZone(): Observable<Zone[]> {
        return this.http.get<Zone[]>(`${environment.serverUrl}/public/api/zone`)
    }

    getZoneEmail(): Observable<ZoneDto[]> {
        return this.http.get<ZoneDto[]>(`${environment.serverUrl}/api/zone-email`)
    }

    allProvinceByZone(code: string): Observable<Province[]> {
        return this.http.get<Province[]>(`${environment.serverUrl}/public/api/province/all-by-zone-code/${code}`);
    }

    AllDistrictByProvince(code: string): Observable<District[]> {
        return this.http.get<District[]>(`${environment.serverUrl}/public/api/district/all-by-province-code/${code}`);
    }

    searchOSSUserLdap(searchParam:UserAuthoritySearchParam, pageRequest: PageRequest): Observable<UserRepresentation[]> {
      const param = IgnoreNullHttpParams.fromObject({...searchParam, ...pageRequest}).toHttpParam()
        return this.http.get<UserRepresentation[]>(`${environment.serverUrl}/api/keycloak/search`, {params: param});
    }

    saveUserAuthority(item: UserRepresentation): Observable<UserRepresentation> {
        return this.http.post<UserRepresentation>(`${environment.serverUrl}/api/keycloak/save-user-role`, item);
    }

    updateZoneEmail(item: ZoneDto): Observable<ZoneDto> {
        return this.http.put<ZoneDto>(`${environment.serverUrl}/api/zone-email/update`, item);
    }

    searchUserSourceLDAP(): Observable<OssUser[]> {
        return this.http.get<OssUser[]>(`${environment.serverUrl}/api/oss-user/search/ldap`);
    }

    saveResponsibleName(item: ResponseZoneConfig): Observable<ResponseZoneConfigDto> {
        return this.http.post<ResponseZoneConfigDto>(`${environment.serverUrl}/api/response-zone-config/responsible-name`, item)
    }

    getUserResponsible(): Observable<ResponseZoneConfigDto[]> {
        return this.http.get<ResponseZoneConfigDto[]>(`${environment.serverUrl}/api/response-zone-config/get-all`)
    }

    saveRateLetterGuarantee(item: RateLetterGuaranteeDto): Observable<RateLetterGuaranteeDto> {
        return this.http.post<RateLetterGuaranteeDto>(`${environment.serverUrl}/api/rate-letter-guarantee`, item);
    }

    deleteRateLetterGuarantee(item: RateLetterGuaranteeDto): Observable<RateLetterGuaranteeDto> {
        let param = IgnoreNullHttpParams.fromObject({id: item.id})
        return this.http.delete<RateLetterGuaranteeDto>(`${environment.serverUrl}/api/rate-letter-guarantee`, {params: param.toHttpParam()});
    }

    searchRateLetterGuarantee(params: IgnoreNullHttpParams): Observable<Page<PublishSearchInfoDto>> {
        return this.http.get<Page<PublishSearchInfoDto>>(`${environment.serverUrl}/api/rate-letter-guarantee`, {params: params.toHttpParam()});
    }
    searchZoneCoordinator(params: IgnoreNullHttpParams): Observable<Page<ZoneCoordinatorDto>> {
    return this.http.get<Page<ZoneCoordinatorDto>>(`${environment.serverUrl}/api/zone-coordinator`, {params: params.toHttpParam()});
    }

     saveZoneCoordinator(item:ZoneCoordinatorDto): Observable<ZoneCoordinatorDto> {
     return this.http.post<ZoneCoordinatorDto>(`${environment.serverUrl}/api/zone-coordinator`, item);
    }
    deleteZoneCoordinator(item:ZoneCoordinatorDto): Observable<ZoneCoordinatorDto> {
      let param = IgnoreNullHttpParams.fromObject({id: item.id})
      return this.http.delete<ZoneCoordinatorDto>(`${environment.serverUrl}/api/zone-coordinator`, {params: param.toHttpParam()});
  }
}
