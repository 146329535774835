<form (submit)="submit()">
  <div class="modal-header">
    <h6 class="modal-title">{{hospitalInfo?.hname}}</h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <fieldset [disabled]="crudMode == 'READ'">
    <div class="modal-body overflow-hidden">
      <div class="mb-3 row">
        <label for="licenseNo" class="col-xl-3  col-form-label">เลขรหัสสถานบริการ</label>
        <div class="col-xl-2">
          <input id="licenseNo" name="licenseNo" type="text" class="form-control" [value]="hospitalInfo?.hcode||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="name" class="col-xl-3  col-form-label">ชื่อหน่วยบริการ</label>
        <div class="col-xl-5">
          <input id="name" name="name" type="text" class="form-control" [value]="hospitalInfo?.hname||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label class=" col-auto fw-semibold">สถานที่ตั้ง</label>
      </div>
      <div class="mb-3 row">
        <label for="address" class="col-xl-3  col-form-label">เลขที่</label>
        <div class="col-xl-2">
          <input id="address" name="address" type="text" class="form-control" [value]="hospitalInfo?.address||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="soi" class="col-xl-3  col-form-label">ซอย</label>
        <div class="col-xl-5">
          <input id="soi" name="soi" type="text" class="form-control" [value]="hospitalInfo?.soi||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="moo" class="col-xl-3  col-form-label ui-require">หมู่ที่</label>
        <div class="col-xl-3">
          <input id="moo" name="moo" type="text" class="form-control" [value]="hospitalInfo?.moo||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="road" class="col-xl-3  col-form-label">ถนน</label>
        <div class="col-xl-5">
          <input id="road" name="road" type="text" class="form-control" [value]="hospitalInfo?.road||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="tumbol" class="col-xl-3  col-form-label ui-require">ตำบล/แขวง</label>
        <div class="col-xl-5">
          <input id="tumbol" name="tumbol" type="text" class="form-control" [value]="hospitalInfo?.catm?.tumbonName||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="amphurName" class="col-xl-3  col-form-label ui-require">อำเภอ/เขต</label>
        <div class="col-xl-5">
          <input id="amphurName" name="amphurName" type="text" class="form-control" [value]="hospitalInfo?.catm?.amphurName||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="changwatName" class="col-xl-3  col-form-label ui-require">จังหวัด</label>
        <div class="col-xl-5">
          <input id="changwatName" name="changwatName" type="text" class="form-control" [value]="hospitalInfo?.province?.name||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="postCode" class="col-xl-3  col-form-label">รหัสไปรษณีย์</label>
        <div class="col-xl-1">
          <input id="postCode" name="postCode" type="text" class="form-control" [value]="hospitalInfo?.postCode ||'-'" formDisabled disabled>
        </div>
      </div>
      <div class="mb-3 row">
        <label for="tel" class="col-xl-3  col-form-label">เบอร์โทร</label>
        <div class="col-xl-4">
          <input id="tel" name="tel" type="text" class="form-control"
                 [disabled]="crudMode == 'READ'"
                 [(ngModel)]="hospitalInfo.tel">
        </div>
      </div>
      <div class="mb-3 row">
        <label for="email" class="col-xl-3  col-form-label ui-require">อีเมล</label>
        <div class="col-xl-4">
          <input id="email" name="email" type="text" class="form-control"
                 [disabled]="crudMode == 'READ'"
                 gt-error="email"
                 [(ngModel)]="hospitalInfo.email">
        </div>
      </div>

      <div class="mb-3 row">
        <div class=" col-xl-3 col-form-label fs-6 fw-semibold">พิกัดทางภูมิศาสตร์</div>
        <label for="lat" class="col-auto col-form-label">ละติจูด (Lat)</label>
        <div class="col-auto">
          <input id="lat" name="lat" type="text" class="form-control" [(ngModel)]="hospitalInfo.lat">
        </div>
        <label for="lon" class="col-auto col-form-label">ลองจิจูด (Long)</label>
        <div class="col-auto">
          <input id="lon" name="lon" type="text" class="form-control" [(ngModel)]="hospitalInfo.lon">
        </div>
      </div>
      <div class="mb-3 row">
        <div class="offset-xl-3 col-auto">
          <label *ngIf="crudMode != 'READ'" (click)="getLocation()" role="button" class="p-2 rounded btn-default2 me-2">
            <img class="me-2" src="../../../../../assets/images/google-maps2.png" height="20" alt="google-map">
            ตำแหน่งปัจจุบัน
            <a href="https://www.flaticon.com/free-icons/map" title="map icons" class="d-none">Map icons created by Freepik - Flaticon</a>
          </label>
          <label (click)="linkToGoogleMap()" role="button" class="p-2 rounded btn-default2 me-2">
            <img class="me-2" src="../../../../../assets/images/google-maps.png" height="17" alt="google-map">
            เปิดด้วย Google Map
            <a href="https://www.flaticon.com/free-icons/map" title="map icons" class="d-none">Map icons created by Freepik - Flaticon</a>
          </label>
        </div>
      </div>
      <!--            <div class="my-5"></div>-->
      <!--            <div class="row mb-3">-->
      <!--                <div class="offset-xl-1 col-auto mx-auto">-->
      <!--                    <div class="table-responsive">-->
      <!--                        <table class="table caption-top table-bordered">-->
      <!--                            <caption class="text-center">-->
      <!--                                เวลาทำการ-->
      <!--                            </caption>-->
      <!--                            <thead class="color-black font-weight-light">-->
      <!--                            <tr class="table-light text-center" style="background-color: #eeeeee;">-->
      <!--                                <th scope="col">วัน</th>-->
      <!--                                <th scope="col">เปิด/ปิด</th>-->
      <!--                                <th scope="col">เวลาเปิด</th>-->
      <!--                                <th nowrap scope="col">เวลาปิด</th>-->
      <!--                            </tr>-->
      <!--                            </thead>-->
      <!--                            <tbody class="table-group-divider">-->
      <!--                            <tr *ngFor="let item of openingHour.openingHourItems; let i= index">-->
      <!--                                <td class="text-center">{{dayOfWeekThai[item.day]}}</td>-->
      <!--                                <td nowrap class="text-center">-->
      <!--                                    <div class="form-check form-switch">-->
      <!--                                        <input class="form-check-input"-->
      <!--                                               [(ngModel)]="item.open"-->
      <!--                                               (ngModelChange)="isOpenChanges(item)"-->
      <!--                                               [isOpenValid]="item"-->
      <!--                                               [customError]="{'required': 'กรุณาเลือก'}"-->
      <!--                                               type="checkbox"-->
      <!--                                               name="item[{{i}}].opened">-->
      <!--                                    </div>-->
      <!--                                </td>-->
      <!--                                <td class="text-center">-->
      <!--                                    <input type="time"-->
      <!--                                           name="item[{{i}}].timeOpening"-->
      <!--                                           [(ngModel)]="item.timeOpening"-->
      <!--                                           (ngModelChange)="timeChanges(item)"-->
      <!--                                           [timeOpeningValid]="item"-->
      <!--                                           [customError]="{'required': 'กรุณาระบุ', 'timeOpeningInvalid': 'เวลาทำการไม่ถูกต้อง'}"-->
      <!--                                           class="form-control">-->
      <!--                                </td>-->
      <!--                                <td class="text-center">-->
      <!--                                    <input type="time"-->
      <!--                                           name="item[{{i}}].timeClosing"-->
      <!--                                           [(ngModel)]="item.timeClosing"-->
      <!--                                           (ngModelChange)="timeChanges(item)"-->
      <!--                                           [timeClosingValid]="item"-->
      <!--                                           [customError]="{'required': 'กรุณาระบุ', 'timeClosingInvalid': 'เวลาทำการไม่ถูกต้อง'}"-->
      <!--                                           class="form-control">-->
      <!--                                </td>-->
      <!--                            </tr>-->

      <!--                            </tbody>-->
      <!--                          <tfoot>-->
      <!--                          <tr>-->
      <!--                            <td class="text-center">-->
      <!--                              รวมจำนวน .........{{openingHour.totalDay}}.............. วัน/สัปดาห์-->
      <!--                            </td>-->
      <!--                            <td></td>-->
      <!--                            <td class="text-center" colspan="2">-->
      <!--                              รวมเวลา ........{{openingHour.totalHour}}.......... ชม./สัปดาห์-->
      <!--                            </td>-->
      <!--                          </tr>-->
      <!--                          </tfoot>-->
      <!--                        </table>-->
      <!--                    </div>-->
      <!--                </div>-->
      <!--            </div>-->
    </div>
  </fieldset>
  <div *ngIf="crudMode != 'READ'" class="modal-footer d-block">
    <div class="row">
      <div class="col-12 text-center">
        <button type="submit" class="btn btn-primary btn-custom me-2">
          บันทึก
        </button>
        <button type="button" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</form>
