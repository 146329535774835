<div class="modal-header">
  <h6 class="modal-title">
    เลือกสถานบริการ
  </h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 fw-semibold">ใบอนุญาตให้ประกอบกิจการสถานพยาบาล (สพ.7) : {{ sp7.name || '-' }}</div>
  </div>
  <div class="row mb-3">
    <div class="col-12">ที่อยู่ : {{ sp7.fullAddress || '-' }}</div>
  </div>
  <div *ngFor="let item of hospitalList; let i= index" class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-10">
              <div>
                <span class="fw-semibold text-primary me-2">{{ item.hcode }}</span>
                {{ item.hname }}
              </div>
              <div class="form-text">{{ item.fullAddress || '-' }}</div>
            </div>
            <div class="col-xl-2">
              <button class="btn btn-default2 float-end" (click)="select(item)">
                เลือก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showDiffTable">
    <div class="row mb-3">
      <div class="col-12">
        เปรียบเทียบที่อยู่จากฐานข้อมูล สปสช. ที่เลือก กับ สพ.7
      </div>
    </div>
    <!--    <div class="row mb-3">-->
    <!--      <div class="col-12">-->
    <!--        <table class="table table-bordered table-sm">-->
    <!--          <tbody>-->
    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">เลขที่</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.addressNo}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.address}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="addressNo" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">หมู่</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.moo}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.moo}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="moo" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">ซอย</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.soi}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.soi}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="soi" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">ถนน</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.road}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.road}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="road" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">แขวง/ตำบล</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.subDistrict?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.subDistrict?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="subDistrict" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">เขต/อำเภอ</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.subDistrict?.district?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.subDistrict?.district?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="district" style="white-space: break-spaces;"></td>-->
    <!--          </tr>-->

    <!--          <tr>-->
    <!--            <td rowspan="3" class="fw-semibold">จังหวัด</td>-->
    <!--            <td>ที่อยู่จาก สพ.7</td>-->
    <!--            <td style="white-space: break-spaces;">{{sp7.subDistrict?.district?.province?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>-->
    <!--            <td style="white-space: break-spaces;">{{selectedItem?.subDistrict?.district?.province?.name}}</td>-->
    <!--          </tr>-->
    <!--          <tr>-->
    <!--            <td>ความแตกต่าง</td>-->
    <!--            <td id="province"></td>-->
    <!--          </tr>-->
    <!--          </tbody>-->
    <!--        </table>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="row mb-3">
      <div class="col-12">
        <table class="table table-bordered table-sm">
          <thead class="table-light">
          <tr>
            <td></td>
            <td class="bg-warning-subtle">ที่อยู่จาก สพ.7 (ข้อมูลดิบ)</td>
            <td>ที่อยู่จาก สพ.7 (หลังประมวลผล)</td>
            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>
            <td>ความแตกต่าง</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="fw-semibold">เลขที่</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.address?.addressNumber }}</td>
            <td>{{ sp7.addressNo }}</td>
            <td>{{ selectedItem?.address }}</td>
            <td id="addressNo"></td>
          </tr>
          <tr>
            <td class="fw-semibold">หมู่</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.address?.addressMoo }}</td>
            <td>{{ sp7.moo }}</td>
            <td>{{ selectedItem?.moo }}</td>
            <td id="moo"></td>
          </tr>
          <tr>
            <td class="fw-semibold">ซอย</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.address?.addressSoi?.soiTextTh }}</td>
            <td>{{ sp7.soi }}</td>
            <td>{{ selectedItem?.soi }}</td>
            <td id="soi"></td>
          </tr>
          <tr>
            <td class="fw-semibold">ถนน</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.address?.addressRoad?.roadTextTh }}</td>
            <td>{{ sp7.road }}</td>
            <td>{{ selectedItem?.road }}</td>
            <td id="road"></td>
          </tr>
          <tr>
            <td class="fw-semibold">แขวง/ตำบล</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.tambon?.tambonTextTH }}</td>
            <td>{{ sp7.subDistrict?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.name }}</td>
            <td id="subDistrict"></td>
          </tr>
          <tr>
            <td class="fw-semibold">เขต/อำเภอ</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.amphoe?.amphoeTextTh }}</td>
            <td>{{ sp7.subDistrict?.district?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.district?.name }}</td>
            <td id="district"></td>
          </tr>
          <tr>
            <td class="fw-semibold">จังหวัด</td>
            <td class="bg-warning-subtle">{{ sp7.rawLocation?.province?.provinceTextTh }}</td>
            <td>{{ sp7.subDistrict?.district?.province?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.district?.province?.name }}</td>
            <td id="province"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p>รบกวนแจ้งเจ้าหน้าที่ สปสช ผ่าน Mail: <a role="button" class="text-decoration-none">register@nhso.go.th</a> เพื่อตรวจสอบข้อมูล</p>
  </ng-container>
</div>
