<div class="modal-header">
  <h6 class="modal-title">
    เลือกสถานบริการ
  </h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12 fw-semibold">ใบอนุญาตขายยาแผนปัจจุบัน (แบบ ข.ย.5): {{ ph5.name || '-' }}</div>
  </div>
  <div class="row mb-3">
    <div class="col-12">ที่อยู่ : {{ ph5.fullAddress || '-' }}</div>
  </div>
  <div *ngFor="let item of drugstoreList; let i= index" class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-10">
              <div>
                <span class="fw-semibold text-primary me-2">{{ item.hcode }}</span>
                {{ item.hname }}
              </div>
              <div class="form-text">{{ item.fullAddress || '-' }}</div>
            </div>
            <div class="col-xl-2">
              <button class="btn btn-default2 float-end" (click)="select(item)">
                เลือก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-container *ngIf="showDiffTable">
    <div class="row mb-3">
      <div class="col-12">
        เปรียบเทียบที่อยู่จากฐานข้อมูล สปสช. ที่เลือก กับ ข.ย.5
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-12">
        <table class="table table-bordered table-sm">
          <thead class="table-light">
          <tr>
            <td></td>
            <td class="bg-warning-subtle">ที่อยู่จาก ข.ย.5 (ข้อมูลดิบ)</td>
            <td>ที่อยู่จาก ข.ย.5 (หลังประมวลผล)</td>
            <td>ที่อยู่จากฐานข้อมูล สปสช.</td>
            <td>ความแตกต่าง</td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="fw-semibold">เลขที่</td>
            <td class="bg-warning-subtle">{{ ph5.rawData?.locationInfo?.addrNo }}</td>
            <td>{{ ph5.addressNo }}</td>
            <td>{{ selectedItem?.address }}</td>
            <td id="addressNo"></td>
          </tr>
          <tr>
            <td class="fw-semibold">หมู่</td>
            <td class="bg-warning-subtle">{{ 'todo' }}</td>
            <td>{{ ph5.moo }}</td>
            <td>{{ selectedItem?.moo }}</td>
            <td id="moo"></td>
          </tr>
          <tr>
            <td class="fw-semibold">ซอย</td>
            <td class="bg-warning-subtle">{{ 'todo' }}</td>
            <td>{{ ph5.soi }}</td>
            <td>{{ selectedItem?.soi }}</td>
            <td id="soi"></td>
          </tr>
          <tr>
            <td class="fw-semibold">ถนน</td>
            <td class="bg-warning-subtle">{{ 'tod' }}</td>
            <td>{{ ph5.road }}</td>
            <td>{{ selectedItem?.road }}</td>
            <td id="road"></td>
          </tr>
          <tr>
            <td class="fw-semibold">แขวง/ตำบล</td>
            <td class="bg-warning-subtle">{{ ph5.rawData?.locationInfo?.subdistrict }}</td>
            <td>{{ ph5.subDistrict?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.name }}</td>
            <td id="subDistrict"></td>
          </tr>
          <tr>
            <td class="fw-semibold">เขต/อำเภอ</td>
            <td class="bg-warning-subtle">{{ ph5.rawData?.locationInfo?.district }}</td>
            <td>{{ ph5.subDistrict?.district?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.district?.name }}</td>
            <td id="district"></td>
          </tr>
          <tr>
            <td class="fw-semibold">จังหวัด</td>
            <td class="bg-warning-subtle">{{ ph5.rawData?.locationInfo?.province }}</td>
            <td>{{ ph5.subDistrict?.district?.province?.name }}</td>
            <td>{{ selectedItem?.subDistrict?.district?.province?.name }}</td>
            <td id="province"></td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <p>รบกวนแจ้งเจ้าหน้าที่ สปสช ผ่าน Mail: <a role="button" class="text-decoration-none">register@nhso.go.th</a> เพื่อตรวจสอบข้อมูล</p>
  </ng-container>
</div>
<div *ngIf="validateMessage" class="modal-footer">
  <div class="row">
    <div class="col-12 text-danger">{{ validateMessage }}</div>
  </div>
</div>
