import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BlacklistService} from "../shared/services/blacklist.service";
import {BlacklistDto} from "../../generated-model/model";

@Component({
  selector: 'app-blacklist',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './blacklist.component.html',
  styleUrls: ['./blacklist.component.scss']
})
export class BlacklistComponent {
  blacklist: BlacklistDto[]

  constructor(private blackListService: BlacklistService) {
  }

  search(keyword: string) {
    this.blackListService.search(keyword).subscribe(e => {
        this.blacklist = e
      }
    )
  }
}
