import {AfterViewChecked, AfterViewInit, Component, HostListener, inject, OnDestroy} from '@angular/core';
import {NgbOffcanvas, OffcanvasDismissReasons} from "@ng-bootstrap/ng-bootstrap";
import {OidcSecurityService, UserDataResult} from "angular-auth-oidc-client";
import {environment} from "../../../environments/environment";
import {EmailNotifyDialogService} from "../../shared/commons/dialog/email-notify-dialog/email-notify-dialog.service";
import {ToastService} from "../../shared/commons/toast/toast-service";
import {MobileSettingDialogService} from "../../shared/commons/dialog/mobile-setting-dialog/mobile-setting-dialog.service";
import {Version, VersionService} from "../../version.service";
import {CustomUserDataResult, UserData} from "../../app.constants";
import {OssOrganization} from "../../../generated-model/model";
import {NavigationEnd, Router} from "@angular/router";
import {DelegateOfAuthorityService} from "../hospital/delegate-of-authority/delegate-of-authority.service";
import {shareReplay} from "rxjs/operators";

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnDestroy, AfterViewInit, AfterViewChecked {
  user: UserData;
  toggleOffCanvas = false;
  sidebarCollapsed = false;
  closeResult = '';
  version: Version;
  router = inject(Router);
  showMenu =false;

    constructor(private offcanvasService: NgbOffcanvas,
                private toastService: ToastService,
                private oidcSecurityService: OidcSecurityService,
                private emailNotifyDialogService: EmailNotifyDialogService,
                private mobileSettingDialogService: MobileSettingDialogService,
                private versionService: VersionService) {
    }

    ngAfterViewChecked(): void {
        this.checkActiveDropdownMenu();
    }

    ngOnInit() {
        this.oidcSecurityService.userData$
            .subscribe((userData: UserDataResult) => {
                this.user = userData.userData;
            });
        this.versionService.getVertion().subscribe(e => this.version = e);
    }

  ngAfterViewInit(): void {
    // this.router.events.subscribe(val => {
    //   if (val instanceof NavigationEnd) {
    //     this.checkActiveDropdownMenu();
    //   }
    // });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    // 992px is break point of large devices
    this.toggleOffCanvas = event.target.innerWidth < 992;
    this.sidebarCollapsed = event.target.innerWidth < 992;
  }

    userInfo() {
        window.open(`${environment.oidcIssuerUrl}/account`, '_blank');
    }

    logout() {
        this.oidcSecurityService.logoffAndRevokeTokens().subscribe(e => {
            console.log(e)
        });
    }

    open(content) {
        this.offcanvasService.open(content, {ariaLabelledBy: 'offcanvas-basic-title'}).result.then(
            (result) => {
                this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            },
        );
    }

    private getDismissReason(reason: any): string {
        if (reason === OffcanvasDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === OffcanvasDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on the backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    gotoEportal() {
        window.location.href = `${environment.portalUrl}/#/portal`;
    }

    ngOnDestroy(): void {
    }

    emailNotify() {
        if (!this.user.email) {
            this.toastService.showWarning("ไม่พบอีเมล")
        } else {
            this.emailNotifyDialogService.open()
        }
    }

    mobileSetting() {
        this.mobileSettingDialogService.open()
    }

    organizationName(organization: OssOrganization): string {
        if (!organization) {
            return "";
        }
        if ("บริษัทจำกัด" === organization.juristicType || "บริษัทมหาชนจำกัด" === organization.juristicType) {
            return "บริษัท " + organization.name;
        } else if ("ห้างหุ้นส่วนจำกัด" === organization.juristicType || "ห้างหุ้นส่วนจำกัดสามัญนิติบุคคล" === organization.juristicType) {
            return organization.juristicType + " " + organization.name;
        }
        //else anything ignore juristicType
        return organization.name;
    }

    private checkActiveDropdownMenu() {
        let list = document.querySelectorAll('.btn-toggle');
        list.forEach((toggler, key) => {
            let dataBsTarget = toggler.getAttribute('data-bs-target');
            let childrenActiveList = document.querySelectorAll(`${dataBsTarget} a.active`);
            if (childrenActiveList.length) {
                toggler.classList.add('active');
            } else {
                toggler.classList.remove('active');
            }
        });
    }
}
