import {Pipe, PipeTransform} from "@angular/core";
import {Hospital} from "../../../../generated-model/model";

@Pipe({
  name: 'HospitalListExcludeSelectedFilter',
  pure: false
})
export class HospitalListExcludeSelectedFilterPipe implements PipeTransform {

  transform(items: Hospital[], selectedItems: Hospital[]): Hospital[] {
    if (!items || !selectedItems?.length) {
      return items;
    }
    return items.filter(item => [...selectedItems].some(e => e.hid !== item.hid));
  }
}
