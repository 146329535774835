import {AfterViewInit, Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Htitle, HtitleDto} from "../../../../generated-model/model";
import {Observable, of, share} from "rxjs";
import {HospitalService} from "../../services/hospital.service";

@Component({
  selector: 'gt-htitle-autocomplete',
  templateUrl: './gt-htitle-autocomplete.component.html',
  styleUrls: ['./gt-htitle-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtHtitleAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtHtitleAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  htitles: HtitleDto[] = [];
  private _value: HtitleDto = undefined;
  @Input('id') id: string = 'htitle';
  @Input('name') name: string = 'htitle';
  @Input() disabled: boolean = false;
  @ViewChild(NgControl) innerNgControl: NgControl;
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: HtitleDto) => void = () => {
  };
  compareWithHtitleId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.htitleId == o2.htitleId))
  };

  constructor(public hospitalService: HospitalService, private inj: Injector) {
    this.hospitalService.findAllHtitle()
      .subscribe(e => {
        this.htitles = e;
      });
  }

  ngAfterViewInit() {
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
  }

  get value(): HtitleDto {
    return this._value;
  }

  set value(value: HtitleDto) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: HtitleDto) {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
}
