import {Component} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-front-channel-logout',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './front-channel-logout.component.html',
  styleUrls: ['./front-channel-logout.component.scss']
})
export class FrontChannelLogoutComponent {
  constructor(public oidcSecurityService: OidcSecurityService,) {
    oidcSecurityService.logoffAndRevokeTokens().subscribe(e=> console.log(e))
    sessionStorage.clear()
  }
}
