<ng-select [id]="id"
           [name]="name"
           [items]="subDistrict$ | async"
           placeholder="กรุณาเลือก"
           class="novalidate"
           [readonly]="disabled"
           [searchable]="true"
           [clearable]="true"
           [compareWith]="compareWithCode"
           [editableSearchTerm]="true"
           [typeahead]="term$"
           [loading]="typeaheadLoading"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    <ng-container *ngIf="item.code">
      {{item.name}}
    </ng-container>
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <ng-container *ngIf="item.code">
      {{item.name}}  <span class="text-opacity-50">&gt; {{item?.district?.name}} &gt; {{item?.district?.province?.name}}</span>
    </ng-container>
  </ng-template>
</ng-select>
