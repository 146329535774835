import {Component, Renderer2, ViewChild, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {NgForm} from "@angular/forms";
import {markInvalidControlsAsDirtyAndTouched} from "../../../../app.constants";
import {ToastService} from "../../../commons/toast/toast-service";
import {filter} from "rxjs/operators";
import {ConfirmDialogService} from "../../../commons/dialog/confirm-dialog/confirm-dialog.service";

@Component({
    selector: 'consider-modal',
    templateUrl: './consider-modal.component.html',
    styleUrls: ['./consider-modal.component.scss'],
    encapsulation: ViewEncapsulation.None

})
export class ConsiderModalComponent {

    @ViewChild('form', {static: true}) public form: NgForm;
    reason: string;
    title = 'ยืนยันข้อมูล'
    confirmDialog = false;

    constructor(public activeModal: NgbActiveModal,
                private toastService: ToastService,
                private renderer: Renderer2,
                private confirmDialogService: ConfirmDialogService,
    ) {
    }

    confirm() {
        let formValid = this.updateFormAndValidity(this.form);
        if (formValid && this.confirmDialog) {
            this.confirmDialogService.open({content: 'ยืนยันข้อมูล'})
                .pipe(filter(e => !!e))
                .subscribe({
                    next: e => {
                        this.activeModal.close(this.reason);

                    }
                });
        } else if (formValid) {
            this.activeModal.close(this.reason);
        } else {
            console.log("form invalid");
            this.renderer.selectRootElement('#consider-form .ng-invalid').focus();
        }
    }

    cancel() {
        this.activeModal.dismiss()
    }

    updateFormAndValidity(form: NgForm): boolean {
        if (!form.valid) {
            markInvalidControlsAsDirtyAndTouched(form)
        }
        return form.valid;
    }
}
