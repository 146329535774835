<div class="modal-header">
  <h6 class="modal-title">
    ค้นหาสถานบริการ
  </h6>
  <button type="button" class="btn-close" aria-label="Close"
          (click)="activeModal.dismiss('Cross click')"></button>
</div>
<div class="modal-body">
  <div class="row mb-3 justify-content-center">
    <div class="col-xl-6">
      <input type="text" id="term" name="term" class="form-control" placeholder="ค้นหาด้วยชื่อหรือรหัส" [(ngModel)]="term">
    </div>
    <div class="col-auto">
      <button type="button" (click)="searchHospital()" [disabled]="!term" class="btn btn-default2">
        <i class="bi bi-search"></i>
      </button>
    </div>
  </div>
  <div *ngIf="!hospitalList?.length" class="row mb-3">
    <div class="col-12">
      <div class="card border-0">
        <div class="card-body border-0">
          <div class="card-text text-center text-muted">
            <i class="bi bi-inbox"></i><br>
            ไม่พบข้อมูล
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngFor="let item of hospitalList; let i= index" class="row mb-3">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-xl-10">
              <div>
                <span class="fw-semibold text-primary me-2">{{item.hcode}}</span>
                {{item.hname}}
              </div>
              <div class="form-text">{{item.fullAddress || '-'}}</div>
            </div>
            <div class="col-xl-2">
              <button class="btn btn-default2 float-end" (click)="select(item)">
                เลือก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
