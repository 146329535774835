import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {LoadingService} from "./loading.service";

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  isloading: boolean = false;

  constructor(public loadingService: LoadingService, private cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.loadingService.loadingState.subscribe(value => {
      this.isloading = value;
      this.cdr.detectChanges();
    });
  }

}
