import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {RegOpeningHourItem} from "../../../generated-model/model";

@Directive({
  selector: '[isOpenValid]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: IsOpenValid,
      multi: true
    }
  ],
})
export class IsOpenValid implements Validator {

  @Input({alias: 'isOpenValid', required: true}) item: RegOpeningHourItem;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.item.timeOpening || this.item.timeClosing) {
      if (!control.value) {
        return {'required': true}
      }
    }
    return undefined;
  }

}
