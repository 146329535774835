import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Hofftype, Hospital, Hsubtype, HsubtypeDto, Htype, Type} from "../../../generated-model/model";
import {first, shareReplay} from "rxjs/operators";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class TypeService {
  $leafTypes: Observable<Type[]>

  constructor(private http: HttpClient) {
    this.$leafTypes = this.http.get<Type[]>(`${environment.serverUrl}/public/api/type/leaf`).pipe(shareReplay(1), first());
  }

  getHsubtype(term: string, htypeId: string): Observable<Hsubtype[] | HsubtypeDto[]> {
    if (htypeId) {
      return this.http.get<Hsubtype[]>(`${environment.serverUrl}/public/api/hsubtype/htype/${htypeId}/search`,
        {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
      );
    }
    return this.http.get<Hsubtype[]>(`${environment.serverUrl}/public/api/hsubtype/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
    );
  }

  getHsubtype2(term: string, htypeId: string): Observable<Hsubtype[] | HsubtypeDto[]> {
    if (htypeId) {
      return this.http.get<Hsubtype[]>(`${environment.serverUrl}/public/api/hsubtype/htype/${htypeId}/search2`,
        {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
      );
    }
    return this.http.get<Hsubtype[]>(`${environment.serverUrl}/public/api/hsubtype/search2`,
      {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()}
    );
  }

  getHtype(term: string): Observable<Htype[]> {
    return this.http.get<Htype[]>(`${environment.serverUrl}/public/api/htype/search`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
  }

  getHospital(term: string, type: string = null): Observable<Hospital[]> {
    return this.http.get<Hospital[]>(`${environment.serverUrl}/api/hospital/search`,
      {params: IgnoreNullHttpParams.fromObject({term: term, type: type}).toHttpParam()});
  }

  getGovernmentHtype(): Observable<Htype[]> {
    return this.http.get<Htype[]>(`${environment.serverUrl}/public/api/htype/government`)
  }

  getCompanyHtype(): Observable<Htype[]> {
    return this.http.get<Htype[]>(`${environment.serverUrl}/public/api/htype/company`)
  }

  getDefaultHsubtype(): Observable<Hsubtype> {
    return this.http.get<Hsubtype>(`${environment.serverUrl}/public/api/hsubtype/user/default`)
  }

  getHofftype(): Observable<Hofftype[]> {
    return this.http.get<Hofftype[]>(`${environment.serverUrl}/public/api/hofftype`)
  }
}
