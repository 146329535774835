import {Component, Input, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ToastService} from "../../../commons/toast/toast-service";
import {HospitalDto, RegPh5Dto} from "../../../../../generated-model/model";
import {StringUtils} from "../../../commons/gt-ng-datepicker/utils/string-utils";
import * as Diff from "diff";

@Component({
  selector: 'select-license-drugstore-modal',
  templateUrl: './select-license-drugstore-modal.component.html',
  styleUrls: ['./select-license-drugstore-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SelectLicenseDrugstoreModalComponent implements OnInit {

  @Input({alias: 'drugstoreList', required: true}) drugstoreList: HospitalDto[];
  @Input({alias: 'ph5', required: true}) ph5: RegPh5Dto;
  validateMessage: string;
  selectedItem: HospitalDto;
  showDiffTable = false;

  constructor(public activeModal: NgbActiveModal,
              private renderer: Renderer2,
              private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  select(item: HospitalDto) {
    this.selectedItem = item;
    console.log('ph5 ', this.ph5);
    //ตรวจสอบที่อยู่ address + moo ต้องตรงกับ ข.ย.5
    let diff: { [id: string]: any[] } = {
      'addressNo': [],
      'moo': [],
      'soi': [],
      'road': [],
      'subDistrict': [],
      'district': [],
      'province': [],
    };
    const diffAddressNo = Diff.diffChars(StringUtils.emptyToUndefinedAndRemoveSpaces(item.address), StringUtils.emptyToUndefinedAndRemoveSpaces(this.ph5.addressNo));
    this.extractDiff('addressNo', diff, diffAddressNo);

    const diffMoo = Diff.diffChars(this.hospitalMoo(item), StringUtils.emptyToUndefinedAndRemoveSpaces(this.ph5.moo));
    this.extractDiff('moo', diff, diffMoo);

    const diffSoi = Diff.diffChars(StringUtils.emptyToUndefinedAndRemoveSpaces(item.soi), StringUtils.emptyToUndefinedAndRemoveSpaces(this.ph5.soi));
    this.extractDiff('soi', diff, diffSoi);

    const diffRoad = Diff.diffChars(StringUtils.emptyToUndefinedAndRemoveSpaces(item.road), StringUtils.emptyToUndefinedAndRemoveSpaces(this.ph5.road));
    this.extractDiff('road', diff, diffRoad);

    const diffSubDistrictName = Diff.diffChars(StringUtils.emptyToUndefined(item.subDistrict?.name), StringUtils.emptyToUndefined(this.ph5.subDistrict?.name));
    this.extractDiff('subDistrict', diff, diffSubDistrictName);

    const diffDistrictName = Diff.diffChars(StringUtils.emptyToUndefined(item.subDistrict?.district?.name), StringUtils.emptyToUndefined(this.ph5.subDistrict?.district?.name));
    this.extractDiff('district', diff, diffDistrictName);

    const diffProvinceName = Diff.diffChars(StringUtils.emptyToUndefined(item.subDistrict?.district?.province?.name), StringUtils.emptyToUndefined(this.ph5.subDistrict?.district?.province?.name));
    this.extractDiff('province', diff, diffProvinceName);
    console.log('diff = ', diff);
    let addressMatches = Object.keys(diff).flatMap(key => diff[key]).every(e => !e.changes);
    if (!addressMatches) {
      this.showDiffTable = true;
      setTimeout(() => {
        for (let diffKey in diff) {
          let whereToAppend = document.getElementById(diffKey);
          whereToAppend.innerHTML = '';
          diff[diffKey].forEach(value => {
            let span = this.renderer.createElement('span');
            span.style.color = value.color;
            span.style.backgroundColor = value.backgroundColor;
            let textNode = this.renderer.createText(value.part?.value || '');
            this.renderer.appendChild(span, textNode)
            this.renderer.appendChild(whereToAppend, span);
          });
        }
      });
      this.validateMessage = "ที่อยู่สถานบริการไม่ตรงกับที่อยู่ของ ข.ย.5";
      this.toastService.showWarning("ที่อยู่สถานบริการไม่ตรงกับที่อยู่ของ ข.ย.5");
    } else {
      this.showDiffTable = false;
      this.validateMessage = undefined;
      this.activeModal.close(item);
    }
  }

  private extractDiff(key: string, diff, diffList: any[]) {
    diffList.forEach((part) => {
      const backgroundColor = part.added ? '#d1e7dd' : part.removed ? '#fde1ee' : '#ffffff';
      const color = part.added ? '#198754' : part.removed ? '#f21378' : 'grey';
      const changes = part.added || part.removed;
      diff[key].push({changes: changes, part: part, color: color, backgroundColor: backgroundColor})
    });
  }

  cancel() {
    this.activeModal.dismiss()
  }

  private hospitalMoo(item: HospitalDto) {
    let hospitalMoo = StringUtils.emptyToUndefined(item.moo);
    if (hospitalMoo) {
      hospitalMoo = (+hospitalMoo) + "";
    }
    return hospitalMoo;
  }
}
