import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {from, Observable} from "rxjs";
import {MobileSettingDialogComponent} from "./mobile-setting-dialog.component";
import {environment} from "../../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {KeyCloakAttributeDto} from "../../../../../generated-model/model";

@Injectable({providedIn: 'root'})
export class MobileSettingDialogService {

  constructor(private modalService: NgbModal, private http: HttpClient) {
  }

  open(): Observable<boolean> {
    const modalRef = this.modalService.open(MobileSettingDialogComponent, {
      centered: true,
      size: 'xl',
      keyboard: false,
    });
    return from(modalRef.result)
  }

  createOrUpdateAttribute(attributeDto: KeyCloakAttributeDto): Observable<void> {
    return this.http.put<void>(`${environment.serverUrl}/api/keycloak/create-or-update-attribute`, attributeDto);
  }

}
