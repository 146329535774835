<form>
  <div style="padding-left: 80px;padding-top: 50px;">
    <input name="keyword" #keyword>
    <button type="button"  (click)="search(keyword.value)"> search </button>
  </div>
  <div style="padding-left: 80px;padding-top: 50px;">
    <div *ngFor="let b of blacklist">
      {{b|json}}
    </div>
  </div>
</form>
