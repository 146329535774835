import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {IsOpenValid} from "./opening-hours-valid.directive";
import {TimeOpeningValid} from "./time-opening-valid.directive";
import {TimeClosingValid} from "./time-closing-valid.directive";
import {RequiredAtLeastOneOpeningHour} from "./required-atleast-one-opening-hours.directive";
import {ValidStaff} from "./valid-staff.directive";
import {ThaiDConfirmValid} from "./thaid-confirm-valid.directive";
import {ValidateDoctor} from "./validate-doctor.directive";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    IsOpenValid,
    TimeOpeningValid,
    TimeClosingValid,
    RequiredAtLeastOneOpeningHour,
    ValidStaff,
    ThaiDConfirmValid,
    ValidateDoctor
  ],
  exports: [
    IsOpenValid,
    TimeOpeningValid,
    TimeClosingValid,
    RequiredAtLeastOneOpeningHour,
    ValidStaff,
    ThaiDConfirmValid,
    ValidateDoctor
  ],
  providers: []
})
export class SharedValidatorsModule {
}
