<app-loading></app-loading>
<div class="container py-5">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-2">
          <input type="text" class="form-control" placeholder="hcode" [(ngModel)]="hcode">
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-secondary" (click)="search()">ค้นหา</button>
        </div>
      </div>
      <ng-container *ngIf="data">
        <div class="row my-3">
          <div class="col table-responsive">
            <table class="table table-bordered">
              <tbody>
              <tr class="">
                <th nowrap>id</th>
                <td>{{ data.id }}</td>
              </tr>
              <tr class="">
                <th nowrap>hcode</th>
                <td>{{ data.hcode }}</td>
              </tr>
              <tr class="">
                <th nowrap>hname</th>
                <td>{{ data.hname }}</td>
              </tr>
              <tr class="">
                <th nowrap>Sap Process Type</th>
                <td>{{ data.sapProcessType }}</td>
              </tr>
              <tr class="">
                <th nowrap>Sap Outbound</th>
                <td>{{ data.sapOutbound }}</td>
              </tr>
              <tr class="">
                <th nowrap>Sap Inbound</th>
                <td>{{ data.sapInbound }}</td>
              </tr>
              <tr class="">
                <th nowrap>Sap Request Body</th>
                <td>
                  <ngx-json-viewer [json]="data.sapRequestBody" [expanded]="true"></ngx-json-viewer>
                </td>
              </tr>
              <tr class="">
                <th nowrap>Sap Response Message</th>
                <td>
                  <ngx-json-viewer [json]="data.sapResponseMessage" [expanded]="true"></ngx-json-viewer>
                </td>
              </tr>
              <tr>
                <th nowrap>Sap Send Date</th>
                <td>{{ data.sapSendDate }}</td>
              </tr>
              <tr>
                <th nowrap>Action Type</th>
                <td>{{ data.sapActionType }}</td>
              </tr>
              <tr>
                <th nowrap>Sap Status</th>
                <td>{{ data.sapStatus }}</td>
              </tr>
              <tr>
                <th nowrap>Sign Date</th>
                <td>{{ data.signDate }}</td>
              </tr>
              <tr>
                <th nowrap>updateDate</th>
                <td>{{ data.lastUpdatedDate }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col-auto">
            <button type="button" (click)="patchCreate(data.id)" class="btn btn-danger">สร้าง vendor ใหม่</button>
            <button type="button" (click)="patchChange(data.id)" class="btn btn-warning mx-3">ปรับปรุง vendor</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>
