<div *ngIf="title" class="modal-header">
  <strong class="modal-title">
    {{title}}
  </strong>
</div>
<div class="modal-body">
  <ngx-json-viewer [json]="content"></ngx-json-viewer>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-center">
    <button type="button"
            class="btn btn-outline-dark btn-custom"
            (click)=" emit(); this.activeModal.close(false);">ปิด
    </button>
  </div>
</div>
