import {Directive, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewContainerRef} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Authority} from "../../generated-model/model";
import {SecurityAuthorityService} from "./security-authority.service";
import {filter} from "rxjs/operators";

@Directive({
  selector: '[hasAnyAuthority]'
})
export class HasAnyAuthorityDirective implements OnInit, OnChanges {
  private alreadyAddView = false;
  @Input({required: true, alias: 'hasAnyAuthority'}) checkAuthorities: (Authority | 'zone_13')[];
  restrictZoneId: string;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, public oidc: OidcSecurityService, private authenService: SecurityAuthorityService) {
  }

  @Input({required: false})
  set hasAnyAuthorityRestrictZoneId(exceptZoneId: string) {
    this.restrictZoneId = exceptZoneId;
  }

  ngOnInit(): void {
    this.detectChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['checkAuthorities']?.currentValue) {
      this.detectChange();
    }
  }

  detectChange() {
    this.oidc.userData$.pipe(
      filter(value => value?.userData))
      .subscribe(userData => {
        this.authenService.hasAnyAuthority(this.checkAuthorities)
          .subscribe(e => {
            if (e) {
              if (!this.alreadyAddView) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.alreadyAddView = true;
              }
            } else {
              this.viewContainer.clear();
              this.alreadyAddView = false;
            }
          })
      })

  }
}
