<ng-select [id]="id"
           [name]="name"
           [items]="htitles"
           placeholder="กรุณาเลือก"
           class="novalidate"
           bindLabel="htitleName"
           [readonly]="disabled"
           [searchable]="true"
           [clearable]="true"
           [(ngModel)]="value">
</ng-select>
