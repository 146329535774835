import {Component, OnInit} from '@angular/core';
import {AppBreadcrumbService} from "./app-breadcrumb.service";
import {Observable} from "rxjs";

@Component({
    selector: 'app-header',
    templateUrl: './app-header.component.html',
    styleUrls: ['./app-header.component.scss']
})
export class AppHeaderComponent implements OnInit {

    public breadcrumbs: Observable<any[]>;

    constructor(private appBreadcrumbService: AppBreadcrumbService) {
    }

    ngOnInit(): void {
        this.breadcrumbs = this.appBreadcrumbService.breadcrumbs;
    }
}
