<ng-select #autocomplete
           [id]="id"
           [name]="name"
           [items]="hsubtype$ | async"
           placeholder="กรุณาเลือก"
           [readonly]="readonly"
           [compareWith]="compareWithId"
           [clearable]="clearable"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    {{item.hsubtypeId}} : {{item.hsubtypeDesc}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    {{item.hsubtypeId}} : {{item.hsubtypeDesc}}
  </ng-template>
</ng-select>
