import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {from, Observable} from "rxjs";
import {CRUD} from "../../../../app.constants";
import {ThaidModalComponent} from "./thaid-modal.component";

@Injectable({providedIn: "root"})
export class ThaidModalService {

  constructor(private modalService: NgbModal) {
  }

  open(applicant: any, options?: { disabled?: boolean, crudMode?: CRUD }): Observable<any> {

    const modalRef = this.modalService.open(ThaidModalComponent, {
      // fullscreen: true,
      // size: "sm",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    modalRef.componentInstance.applicant = applicant;
    return from(modalRef.result);
  }
}
