<ng-select #autocomplete
           [id]="id"
           [name]="name"
           [items]="htype$ | async"
           placeholder="กรุณาเลือก"
           [readonly]="disabled"
           [required]="required"
           [compareWith]="compareWithId"
           app-model-empty-value
           [clearable]="clearable"
           #htype="ngModel"
           [(ngModel)]="value">
  <ng-template ng-label-tmp let-item="item">
    {{item.htypeId}} : {{item.htypeName}}
  </ng-template>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    {{item.htypeId}} : {{item.htypeName}}
  </ng-template>
  <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
    ไม่พบข้อมูลสำหรับ "{{searchTerm}}"
  </ng-template>
</ng-select>
<div *ngIf="htype.invalid && (htype.dirty || htype.touched) &&required" class="ng-invalid-feedback">
  <div *ngIf="htype.errors?.['required']">
    กรุณาเลือกสังกัดหลักของหน่วยบริการ
  </div>
</div>
