import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {HospitalModalComponent} from "./hospital-modal.component";
import {Observable} from "rxjs";
import {BankVendorDto, HospitalInfo, OrgType} from "../../../../../generated-model/model";
import {CRUD} from "../../../../app.constants";
import {NgbModalOptions} from "@ng-bootstrap/ng-bootstrap/modal/modal-config";
import {HospitalInfoService} from "../../../../pages/hospital/info/hospital-info.service";

@Injectable({providedIn: "root"})
export class HospitalModalService {

  constructor(private modalService: NgbModal,
              private hospitalInfoService: HospitalInfoService) {
  }

  open(item: BankVendorDto,
       crudMode?: CRUD,
       orgType?: OrgType,
       hospitalInfoGov?: HospitalInfo,
       options: NgbModalOptions = {
         // fullscreen: true,
         size: "xl",
         windowClass: 'custom-modal-content',
         backdrop: 'static',
         keyboard: false
       }): Observable<any> {

    const modalRef = this.modalService.open(HospitalModalComponent, options);
    modalRef.componentInstance.orgType = orgType
    modalRef.componentInstance.sapProviderCode = item?.sapProviderCode;
    if (orgType == 'GOV') {
      modalRef.componentInstance.hospitalInfo = hospitalInfoGov
    } else {
      this.hospitalInfoService.findHospitalBySapProviderCode(item?.sapProviderCode).subscribe(e => {
        modalRef.componentInstance.hospitalInfo = e
      })
    }
    modalRef.componentInstance.crudMode = crudMode;
    return modalRef.componentInstance.onSubmit;
  }
}
