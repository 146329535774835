import {Component, Input, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {HospitalDto} from "../../../../../generated-model/model";
import {HospitalService} from "../../../services/hospital.service";

@Component({
  selector: 'select-license-hospital-modal',
  templateUrl: './search-hospital-modal.component.html',
  styleUrls: ['./search-hospital-modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SearchHospitalModalComponent {
  @Input({alias: 'hsubtypeId', required: true}) hsubtypeId: string;
  hospitalList: HospitalDto[] = [];
  term: string;

  constructor(private hospitalService: HospitalService, public activeModal: NgbActiveModal) {
  }

  select(item: HospitalDto) {
    this.activeModal.close(item);
  }

  cancel() {
    this.activeModal.dismiss()
  }

  searchHospital() {
    this.hospitalService.findByTerm(this.hsubtypeId, this.term)
      .subscribe(e => {
        this.hospitalList = e;
      });
  }
}
