<form #form="ngForm" (ngSubmit)="authenThaiD()" id="confirm-registration-form" class="form-validate">
  <div class="modal-header">
    <h6 class="modal-title">
      <i class="bi bi-shield-check me-1"></i>ยืนยันตัวตน
    </h6>
    <button type="button" class="btn-close" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <div class="row justify-content-center mb-3">
      <div class="col-12">
        <input type="text" id="name" name="name" class="form-control" placeholder="ชื่อ-นามสกุล" required
               [thaiDConfirmValid]="applicant.name" [(ngModel)]="name" [customError]="{'mismatch': 'ชื่อต้องตรงกัน'}">
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="d-grid">
      <button type="submit" class="btn btn-default2">
        <img src="../../../../../assets/images/thaid.png" alt="" height="32" class="rounded border-white border me-2">
        ยืนยันตัวตนด้วย ThaiD
      </button>
    </div>
  </div>
</form>
