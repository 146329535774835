import {Component, inject, OnInit} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {FileUpload, FileUploadPatcher} from "../../../generated-model/model";
import {from} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {IgnoreNullHttpParams} from "../../shared/commons/Ignore-null-http-params";
import {ConfirmDialogComponent} from "../../shared/commons/dialog/confirm-dialog/confirm-dialog.component";
import {ToastService} from "../../shared/commons/toast/toast-service";
import {SharedModule} from "../../shared/shared.module";
import {NgIf} from "@angular/common";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-file-upload-patcher',
  templateUrl: './file-upload-patcher.component.html',
  styleUrls: ['./file-upload-patcher.component.css'],
  imports: [
    SharedModule,
    NgIf
  ],
  standalone: true
})
export class FileUploadPatcherComponent implements OnInit {

  fileUpload: FileUpload;
  patcher: FileUploadPatcher = {};
  uuid: string;
  newFile: FileUpload;
  private toastService: ToastService = inject(ToastService);

  constructor(private http: HttpClient,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  search() {
    if (!this.uuid) {
      alert("ไม่ได้ระบุ uuid");
    }
    this.http.get(`${environment.serverUrl}/api/file-upload-patcher`, {params: IgnoreNullHttpParams.fromObject({uuid: this.uuid}).toHttpParam()})
      .subscribe(value => {
        this.fileUpload = value;
        this.newFile = undefined;
      });
  }

  patch() {
    const modalRef = this.modalService.open(ConfirmDialogComponent, {centered: true});
    modalRef.componentInstance.content = `ต้องการซ่อมไฟล์นี้ใช่หรือไม่? ไฟล์ใหม่จะถูกแทนอย่างถาวรที่และไม่สามารถนำกลับมาได้`;
    from(modalRef.result)
      .subscribe(e => {
        if (!!e) {
          this.patcher = {};
          this.patcher.id = this.fileUpload.id;
          this.patcher.ref = this.fileUpload.uuid;
          this.patcher.newFile = this.newFile;
          this.http.post(`${environment.serverUrl}/api/file-upload-patcher`, this.patcher)
            .subscribe(value => {
              this.toastService.showSuccess("ซ่อมไฟล์เรียบร้อยแล้ว");
              this.fileUpload = value;
              this.newFile = undefined;
            });
        } else {
          console.log('denied.');
        }
      });
  }
}
