import {Component, forwardRef, Injector, Input, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Subject} from "rxjs";
import {UntilDestroy} from "@ngneat/until-destroy";
import {NgSelectComponent} from "@ng-select/ng-select";
import {CheckRegisterHsubtypeResult, CheckRegTypeResult, CppMPersonDto} from "../../../../generated-model/model";
import {MasterService} from "../../services/master.service";

@UntilDestroy()
@Component({
  selector: 'app-select-cpp-m-person-autocomplete',
  templateUrl: './gt-cpp-m-person-autocomplete.component.html',
  styleUrls: ['./gt-cpp-m-person-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtCppMPersonAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtCppMPersonAutocompleteComponent implements OnInit, ControlValueAccessor {

  term$ = new Subject<string>();
  allCppMPersonDto: CppMPersonDto[] = []
  cppMPersonDtos: CppMPersonDto[] = []
  private _value: CppMPersonDto;
  @Input('id') id: string = 'CppMPersonDto';
  @Input('name') name: string = 'CppMPersonDto';
  @Input() disabled: boolean = false;
  @Input() cppMPersonDtoId: string;
  @Input() restrictCppMPersonDto: CppMPersonDto
  @Input() clearable = true
  @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) cppMPersonDtoAutocomplete: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;
  @Input() checkRegTypeResult: CheckRegTypeResult = {}
  @Input() checkRegisterHsubtypeResult: CheckRegisterHsubtypeResult = {}
  typeaheadLoading = false;
  @Input() removeValueFromInput: boolean = false
  @Input() personFilter: CppMPersonDto[] = []
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;

  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: CppMPersonDto) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor(private inj: Injector, public masterService: MasterService) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {

    this.masterService.getCppMPerson().subscribe(data => {
      this.allCppMPersonDto = this.prepareCppMPerson(data)
        if (this.removeValueFromInput==true){
            this.allCppMPersonDto =data
        }
      Object.freeze(this.allCppMPersonDto);
      this.cppMPersonDtos = this.allCppMPersonDto;
    })

    this.term$.subscribe(term => {
      this.cppMPersonDtos = this.allCppMPersonDto?.filter(f => (f.personName.includes(term) || f.personNo.startsWith(term)))
    });
  }

  get value(): CppMPersonDto {
    return this._value;
  }

  set value(value: CppMPersonDto) {
    this.cppMPersonDtoAutocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
      this.clearInput()
  }

  writeValue(value: CppMPersonDto) {
    this.cppMPersonDtoAutocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clear() {
      if (this.removeValueFromInput == false && this.personFilter==null) {
          this.cppMPersonDtoAutocomplete.handleClearClick();
      }
      this.cppMPersonDtos = this.allCppMPersonDto
  }


  /**
   *
   * @param isP เป็นหน่วยบริการปฐมภูมิ
   * @param isH เป็นหน่วยหน่วยบริการประจำ
   * @param isR เป็นหน่วยบริการที่รับการส่งต่อ
   * @param isR01 เป็นหน่วยบริการที่รับการส่งต่อทั่วไป
   * @param isR02 เป็นหน่วยบริการที่รับการส่งต่อเฉพาะด้าน
   * @param isR0211 หน่วยบริการที่รับการส่งต่อเฉพาะด้านเภสัชกรรม
   * @param isR0207 หน่วยบริการที่รับการส่งต่อเฉพาะด้านเวชกรรม
   * @param isR0208 หน่วยบริการที่รับการส่งต่อเฉพาะด้านทันตกรรม
   * @param isR0213 หน่วยบริการที่รับการส่งต่อเฉพาะด้านการพยาบาลและการผดุงครรภ์
   * @param isR0209 หน่วยบริการที่รับการส่งต่อเฉพาะด้านกายภาพบำบัด
   * @param isR0212 หน่วยบริการที่รับการส่งต่อเฉพาะด้านสร้างเสริมสุขภาพและป้องกัน
   * @param isR0210 หน่วยบริการที่รับการส่งต่อเฉพาะด้านแพทย์แผนไทย
   * @param isR0206 หน่วยบริการที่รับการส่งต่อเฉพาะด้านเทคนิคการแพทย์
   * @param isR0201 หน่วยบริการที่รับการส่งต่อเฉพาะด้านฟอกไต(HD)
   * @param allCppMPersonDto
   */
  prepareCppMPerson(allCppMPersonDto: CppMPersonDto[]) {

    let result: CppMPersonDto[] = []
    if (!allCppMPersonDto?.length) {
      return result
    }

    /**
     *  รับส่งต่อเฉพาะด้านฟื้นฟูสมรรถภาพทางการแพทย์ ที่เป็นศูนย์บริการคนพิการ
     *  รับส่งต่อเฉพาะด้านบริการเอชไอวีและโรคติดต่อทางเพศสัมพันธ์ในชุมชน ที่เป็นองค์กรภาคประชาชน
     *  รับส่งต่อเฉพาะด้านบริการสร้างเสริมสุขภาพและป้องกันโรค ที่เป็นองค์กรภาคประชาชน
     *  รับส่งต่อเฉพาะด้านบริการสร้างเสริมสุขภาพและป้องกันโรค ที่เป็นสถานพยาบาลในสถานประกอบการ
     */
    if (this.checkRegTypeResult.isR0212 || this.checkRegTypeResult.isR0214 || this.checkRegTypeResult.isR0216 || this.checkRegisterHsubtypeResult.isDisabledServiceCenter) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('5.') || f.personNo === '5'
          || f.personNo.startsWith('6.') || f.personNo === '6'
          || f.personNo.startsWith('16.') || f.personNo === '16'
          || f.personNo.startsWith('19.') || f.personNo === '19'
          || f.personNo.startsWith('20.') || f.personNo === '20'
          || f.personNo.startsWith('21.') || f.personNo === '21'
          || f.personNo.startsWith('22.') || f.personNo === '22'
          || f.personNo.startsWith('23.') || f.personNo === '23'
        )
      )]
    }

    /**
     * หน่วยบริการปฐมภูมิ / ประจำ เงื่อนไขการกรอกข้อมูล
     * แพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('1')
     * พยาบาล ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('4')
     * นักวิชาการสาธารณสุข startsWith('19')
     * เภสัชกรรม f.personNo.startsWith('3')
     * กายภาพบำบัด startsWith('5')
     * ทันตกรรม startsWith('2')
     * เทคนิคการแพทย์ startsWith('7')
     */
    if (this.checkRegTypeResult.isP || this.checkRegTypeResult.isH) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('2.') || f.personNo === '2'
          || f.personNo.startsWith('3.') || f.personNo === '3'
          || f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('5.') || f.personNo === '5'
          || f.personNo.startsWith('7.') || f.personNo === '7'
          || f.personNo.startsWith('19.') || f.personNo === '19'
        )
      )]
    }

    /**
     * หน่วยบริการรับส่งต่อทั่วไป เงื่อนไขการกรอกข้อมูล
     *
     * แพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('1')
     * พยาบาล ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('4')
     * บุคลากรด้านทันตกรรม ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('2')
     * บุคลากรด้านเภสัชกรรม ทั้งหมดตามประเภทบุคลากรของ CPP  startsWith('3')
     * นักวิชาการสาธารณสุข startsWith('19')
     * บุคลากรด้านกายภาพบำบัด ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('5')
     * นักกิจกรรมบำบัด ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('6')
     * บุคลากรด้านเทคนิคการแพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('7')
     * บุคลากรด้านรังสีการแพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('8')
     */
    if (this.checkRegTypeResult.isR01) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('2.') || f.personNo === '2'
          || f.personNo.startsWith('3.') || f.personNo === '3'
          || f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('5.') || f.personNo === '5'
          || f.personNo.startsWith('7.') || f.personNo === '7'
          || f.personNo.startsWith('19.') || f.personNo === '19'
          || f.personNo.startsWith('6.') || f.personNo === '6'
          || f.personNo.startsWith('8.') || f.personNo === '8'
        )
      )]
    }


    /**
     * หน่วยบริการรับส่งต่อเฉพาะด้าน เภสัชกรรม
     */
    if (this.checkRegTypeResult.isR0211) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('3.') || f.personNo === '3'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านบริการเวชกรรม
     * - เพิ่มให้เพิ่มแพทย์ได้ทุกประเภท แพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('1')
     * - เพิ่มพยาบาลทุกประเภท พยาบาล ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('4')
     */
    if (this.checkRegTypeResult.isR0207) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('4.') || f.personNo === '4'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านบริการทันตกรรม
     */
    if (this.checkRegTypeResult.isR0208) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('2.') || f.personNo === '2'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านบริการพยาบาลและการผดุงครรภ์
     */
    if (this.checkRegTypeResult.isR0213) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('4.') || f.personNo === '4'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านกายภาพบำบัด
     */
    if (this.checkRegTypeResult.isR0209) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('5.') || f.personNo === '5'
          || f.personNo.startsWith('6.') || f.personNo === '6'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านบริการสร้างเสริมสุขภาพและป้องกันโรค
     */
    if (this.checkRegTypeResult.isR0212) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('4.') || f.personNo === '4'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านบริการแพทย์แผนไทย
     */
    if (this.checkRegTypeResult.isR0210) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('15.') || f.personNo === '15'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านเทคนิคการแพทย์
     */
    if (this.checkRegTypeResult.isR0206) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('7.') || f.personNo === '7'
        )
      )]
    }

    /**
     * ขึ้นทะเบียนเป็นหน่วยบริการเฉพาะด้านฟอกเลือดด้วยเครื่องไตเทียม
     */
    if (this.checkRegTypeResult.isR0201) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          ['1.1', '4.2', '4.2.13', '4.2.14', '1.5.7', '1.9.3'].includes(f.personNo)
        )
      )]
    }

    /**
     *  isR0202   หน่วยบริการที่รับการส่งต่อเฉพาะด้านPCI
     *  isR020201 หน่วยบริการที่รับการส่งต่อเฉพาะด้านPCIระดับ1
     *  isR020202 หน่วยบริการที่รับการส่งต่อเฉพาะด้านPCIระดับ2
     * แพทย์ ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('1')
     * พยาบาล ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('4')
     * 11 นักเทคโนโลยีหัวใจและทรวงอก CPP startsWith('11')
     */
    if (this.checkRegTypeResult.isR0202 || this.checkRegTypeResult.isR020201 || this.checkRegTypeResult.isR020202) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('11.') || f.personNo === '11'
        )
      )]
    }



    /**
     * ขึ้นทะเบียนเป็นผ่าตัดหัวใจ
     */
    if (this.checkRegTypeResult.isR0203) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          ['1', '1.2.4', '1.8.1', '1.8.2', '1.9.4', '1.5.13'].includes(f.personNo)
          || f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('1.8')
          || f.personNo.startsWith('11.') || f.personNo === '11'
        )
      )]
    }

    /**
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านรังสีรักษา
     */
    if (this.checkRegTypeResult.isR0205) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1.') || f.personNo === '1'
          || f.personNo.startsWith('4.') || f.personNo === '4'
          || f.personNo.startsWith('8.') || f.personNo === '8'
          || f.personNo.startsWith('1.13')
        )
      )]
    }
    /**
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านHSCT
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านHSCT:เด็ก:Autologous
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านHSCT:ผู้ใหญ่:Autologous
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านHSCT:เด็ก:Allogeneic
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านHSCTผู้ใหญ่:Allogeneic
     */
    if (this.checkRegTypeResult.isR0204 || this.checkRegTypeResult.isR020401 || this.checkRegTypeResult.isR020402 || this.checkRegTypeResult.isR020403
      || this.checkRegTypeResult.isR020404) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo === '1'
          || f.personName.includes('เลือด') || f.personName.includes('โลหิต')
          //อายุรศาสตร์ทั้งหมด
          || f.personNo.startsWith('1.5')
          || f.personNo.startsWith('4.') || f.personNo === '4'
          //ด้านศัลยศาสตร์
          || f.personNo.startsWith('1.2')
          //ด้านวิสัญญีวิทยา
          || f.personNo.startsWith('1.8')
          //ด้านพยาธิวิทยา
          || f.personNo.startsWith('1.14')
        )
      )]
    }
    //หน่วยบริการที่รับการส่งต่อเฉพาะด้านสถานชีวาภิบาล
    if (this.checkRegTypeResult.isR0218) {
      result = [
        ...result,
        ...allCppMPersonDto.filter(f =>
          //24	พระที่ผ่านการอบรมหลักสูตรเกี่ยวกับการดูแลผู้ป่วยแบบประคับประคอง
          f.personNo.startsWith('24')
          //25	บุคลากรที่ผ่านการอบรมหลักสูตรเกี่ยวกับการดูแลผู้ป่วยแบบประคับประคอง
          || f.personNo.startsWith('25')
          //26	นักบริบาลชุมชน
          || f.personNo.startsWith('26')
          || (f.personNo.startsWith('4.') || f.personNo === '4')
        )]
    }

    //หน่วยบริการที่รับการส่งต่อเฉพาะด้านบริการล้างไตผ่านทางช่องท้อง
    /**
     * หน่วยบริการที่รับการส่งต่อเฉพาะด้านบริการล้างไตผ่านทางช่องท้อง
     *
     * แพทย์ ทั้งหมดตามประเภทบุคลากรของ startsWith('1')
     * อนุสาขาอายุรศาสตร์โรคไต startsWith('1.5.7')
     * อนุสาขากุมารเวชศาสตร์โรคไต startsWith('1.9.3')
     * ผู้ประกอบวิชาชีพเวชกรรมที่ได้ผ่านการอบรมการฟอกเลือดด้วยเครื่องไตเทียมจากสถาบันที่คณะกรรมการสถานพยาบาลรับรอง startsWith('1.18.1')
     * พยาบาล startsWith('4')
     * พยาบาลวิชาชีพ startsWith('4.1')
     * สาขาการพยาบาลผู้ป่วยที่ได้รับการบำบัดทดแทนไต(การล้างทางหน้าท้อง) startsWith('4.2.13')
     * สาขาการพยาบาลผู้ป่วยที่ได้รับการบำบัดทดแทนไต (ฟอกเลือดด้วยเครื่องไตเทียม) startsWith('4.2.14')
     */
    if (this.checkRegTypeResult.isR0219) {
      result = [...result, ...allCppMPersonDto.filter(f =>
        (
          f.personNo.startsWith('1') || f.personNo === '1'
          || f.personNo.startsWith('1.5.7') || f.personNo === '1.5.7'
          || f.personNo.startsWith('1.9.3') || f.personNo === '1.9.3'
          || f.personNo.startsWith('1.18.1') || f.personNo === '1.18.1'
          || f.personNo.startsWith('4') || f.personNo === '4'
          || f.personNo.startsWith('4.1') || f.personNo === '4.1'
          || f.personNo.startsWith('4.2.13') || f.personNo === '4.2.13'
          || f.personNo.startsWith('4.2.14') || f.personNo === '4.2.14'
        )
      )]
    }

    result.sort((a, b) => a.sequenceOrder - b.sequenceOrder);
    return Array.from(new Set<CppMPersonDto>(result));
  }

  /**
   * หน่วยบริการปฐมภูมิ เพิ่มพยาบาลได้ทุกประเภท
   */
  disableCppMPerson(item: CppMPersonDto): boolean {
    /**
     * isP หน่วยบริการปฐมภูมิ
     * พยาบาล ทั้งหมดตามประเภทบุคลากรของ CPP startsWith('4')
     */
    if (this.checkRegTypeResult.isP && (item.personNo.startsWith('4.') || item.personNo === '4')) {
      return false
    }
    return !item.personType
  }
  ngOnChanges(changes: SimpleChanges): void {
        if (this.removeValueFromInput == true && this.personFilter?.length > 0) {
            this.cppMPersonDtos = this.allCppMPersonDto.filter(e => !this.personFilter.map(ee => ee.id).includes(e?.id));

        }
    }
  clearInput() {
        if (this.removeValueFromInput) {
            this.ngSelectComponent?.handleClearClick();
        }
    }
}
