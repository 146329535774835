import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ServiceMasterDto, ServicePotentialAttachmentDto} from "../../../generated-model/model";
import {environment} from "../../../environments/environment";
import {IgnoreNullHttpParams} from "../commons/Ignore-null-http-params";

@Injectable({
    providedIn: 'root'
})
export class PotentialService {

    constructor(private http: HttpClient) {
    }


    searchServiceTerm(term?: string) {
        return this.http.get<ServiceMasterDto[]>(`${environment.serverUrl}/api/service-master/search-term`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
    }

    searchAttachmentTerm(term?: string) {
        return this.http.get<ServicePotentialAttachmentDto[]>(`${environment.serverUrl}/api/service-potential-attachment/search-term`, {params: IgnoreNullHttpParams.fromObject({term: term}).toHttpParam()});
    }
}
