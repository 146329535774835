import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {Authority} from "../../generated-model/model";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {environment} from "../../environments/environment";
import {filter} from "rxjs/operators";

@Directive({
  selector: '[hasAllAuthority]'
})
export class HasAllAuthorityDirective {
  private alreadyAddView = false;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>, public oidc: OidcSecurityService) {
  }

  @Input({required: true})
  set hasAllAuthority(checkAuthorities: Authority[]) {
    this.oidc.userData$
      .pipe(filter(e => e?.userData))
      .subscribe(u => {
        let userAuthorities: Authority[] = u.userData?.resource_access?.[environment.clientId]?.roles || [];
        let realmRole: Authority[] = u.userData?.realm_access?.roles || [];
        userAuthorities = [...userAuthorities, ...realmRole]
        if (this.hasAny(checkAuthorities, userAuthorities)) {
          if (!this.alreadyAddView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.alreadyAddView = true;
          }
        } else {
          this.viewContainer.clear();
          this.alreadyAddView = false;
        }
      })
  }

  private hasAny(authorityList: Authority[], authorities: string[] = []): boolean {
    if (authorities) {
      let hasAuthority = authorities.some(e => authorityList.every(ex => e == ex));
      return hasAuthority;
    } else {
      return false;
    }
  }

}
