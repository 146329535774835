<ng-select #autocomplete
           [id]="id"
           [name]="name"
           [typeahead]="term$"
           [editableSearchTerm]="true"
           placeholder="กรุณาเลือก"
           [readonly]="disabled"
           [compareWith]="compareWithId"
           app-model-empty-value
           [clearable]="true"
           [(ngModel)]="value">
    <ng-option *ngFor="let item of services" [value]="item"></ng-option>
    <ng-template ng-label-tmp let-item="item">
      {{item.typeName}} :   {{item.serviceCode}} : {{item.serviceName}}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
        <div><span> {{item.typeName}} :   {{item.serviceCode}} : {{item.serviceName}}</span></div>
    </ng-template>
    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-option disabled">
            ไม่พบข้อมูลสำหรับ "{{searchTerm}}"
        </div>
    </ng-template>
</ng-select>
