import {AfterViewInit, Component, forwardRef, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {Observable, of} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";
import {TypeService} from "../../services/type.service";
import {Htype, HtypeDto} from "../../../../generated-model/model";

@Component({
  selector: 'gt-htype-autocomplete',
  templateUrl: './gt-htype-autocomplete.component.html',
  styleUrls: ['./gt-htype-autocomplete.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtHtypeAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtHtypeAutocompleteComponent implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges {

  htype$: Observable<(Htype | HtypeDto)[]> = of(null)
  private _value: Htype | HtypeDto;
  @Input('id') id: string = 'htype';
  @Input('name') name: string;
  @Input() disabled: boolean = false;
  @Input() required: boolean = false;
  @Input() htypeId: string;
  @Input() restrictHtype: Htype | HtypeDto
  @Input() clearable = true
  @ViewChild("autocomplete", {static: true, read: NgSelectComponent}) autocomplete: NgSelectComponent;
  @ViewChild(NgControl) innerNgControl: NgControl;
  @Input() forGovernment: boolean = false;
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: Htype | HtypeDto) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.id == o2.id))
  };

  constructor(public typeService: TypeService, private inj: Injector) {
  }

  ngAfterViewInit() {
    //Mark inner component dirty/untouch if AbstractControl.markAsDirty or markAsTouched was called
    // link https://stackoverflow.com/questions/64775440/how-to-touch-inner-control-in-custom-control-component-in-angular
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
  }

  get value(): Htype | HtypeDto {
    return this._value;
  }

  set value(value: Htype | HtypeDto) {
    this.autocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
  }

  writeValue(value: Htype | HtypeDto) {
    this.autocomplete.searchTerm = undefined
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  clear() {
    this.autocomplete.handleClearClick();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const hsubtypeChange = changes['restrictHtype']
    if (hsubtypeChange?.currentValue) {
      this.htype$ = of([hsubtypeChange.currentValue]);
    } else {
      if (this.forGovernment) {
        this.htype$ = this.typeService.getGovernmentHtype();
      } else {
        this.htype$ = this.typeService.getHtype(hsubtypeChange?.currentValue);
      }
    }
  }
}
