import {Injectable} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {SapPatcherModalComponent} from "./sap-patcher-modal.component";
import {defer, Observable} from "rxjs";
import {CRUD} from "../../../../app.constants";
import {SapPageDataDto} from "../../../../../generated-model/model";

@Injectable({providedIn: "root"})
export class SapPatcherModalService {

  constructor(private modalService: NgbModal) {
  }

  open(sapData: SapPageDataDto, options?: { disabled?: boolean, crudMode?: CRUD }): Observable<any> {

    const modalRef = this.modalService.open(SapPatcherModalComponent, {
      // fullscreen: true,
      size: "lg",
      windowClass: 'custom-modal-content',
      backdrop: 'static',
      keyboard: false
    });
    const component: SapPatcherModalComponent = modalRef.componentInstance
    component.sapData = sapData;
    return defer(() => modalRef.result);
  }
}
