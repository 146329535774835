import {inject, Pipe, PipeTransform} from "@angular/core";
import {ThaiDateTimePipe} from "./thai-datetime.pipe";

@Pipe({
  name: 'thaidateyearmonthday',

})

export class ThaiDateYearMonthDayPipe implements PipeTransform {

  thaiDatetimePipe = inject((ThaiDateTimePipe));

  transform(value: any): any {
    if (value && value.length == 8) {
      return this.thaiDatetimePipe.transform(`${+value.substring(0, 4) - 543}-${value.substring(4, 6)}-${value.substring(6, 8)}`, {showTime: false})
    } else {
      return "";
    }

  }
}

