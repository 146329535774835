<div class="modal-header ">
  <div class="modal-title fw-semibold" >{{title}}</div>
</div>
<div class="modal-body">
  <p *ngIf="!isInnerHTML" class="mb-0 text-center">{{content}}</p>
  <div *ngIf="isInnerHTML" [innerHTML]="content"></div>
</div>
<div class="row mt-3 mb-3">
  <div class="col-12 text-center">
    <button type="button" class="btn btn-primary custom-button btn-custom" (click)="confirm()">{{confirmButtonMsg}}</button>
    <button *ngIf="closeButtonMsg" type="button" class="btn btn-default2 custom-button btn-light ms-2 btn-custom"
            (click)="cancel()">{{closeButtonMsg}}</button>
  </div>
</div>
