import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable, share, single} from "rxjs";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  public loggedIn = false;
  private http = inject(HttpClient);
  public userData$ = this.http.get<any>(`${environment.serverUrl}/api/oss-user/user-data`)
    .pipe(
      single(e => !!e),
      share()
    );

  constructor() {
  }

  getCurrent(): Observable<any> {
    return this.userData$;
  }
}
