import {Component, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {catchError, distinctUntilChanged, ignoreElements, Observable, of, share} from "rxjs";
import {RegistrationService} from "../pages/shared/registration.service";
import {ActivityDto, RegistrationStatus, Step} from "../../generated-model/model";
import {SharedCommonModule} from "../shared/commons/shared-common.module";
import {filter, take} from "rxjs/operators";
import {AppSecurityModule} from "../security/app-security.module";
import {SecurityAuthorityService} from "../security/security-authority.service";

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [CommonModule, SharedCommonModule, AppSecurityModule],
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {

  activity$: Observable<ActivityDto[]>;
  activityError$: Observable<any>;
  protected readonly RegistrationStatus = RegistrationStatus;
  protected readonly Step = Step;

  constructor(private service: RegistrationService,
              private oidc: OidcSecurityService,
              private router: Router, private securityAuthoriryService: SecurityAuthorityService) {
  }

  ngOnInit(): void {
    this.oidc
      .userData$
      .pipe(
        filter(value => value?.userData),
        take(1)
      ).subscribe(() => {
      this.activity$ = this.service.findActivity().pipe(share());
      this.activityError$ = this.activity$.pipe(
        ignoreElements(),
        catchError(e => of(e))
      );
      this.securityAuthoriryService.hasAnyAuthority(['hra', 'healthlink'])
        .pipe(filter(e => !e))
        .subscribe(() => {
          this.router.navigate(['/403'])
        })
    });
  }

  view(item: ActivityDto) {
    this.router.navigate(['/hospital/registration', item.id, 'step6'], {queryParams: {uuid: item.uuid}});
  }

  continueFiling(item: ActivityDto) {
    this.router.navigate(['/hospital/registration', item.id, item.step?.toLowerCase() || 'step6'], {queryParams: {uuid: item.uuid}});
  }
}
