<!--<div class="input-group">-->
<!--    <input #datepicker-->
<!--           class="form-control"-->
<!--           [(ngModel)]="value"-->
<!--           [minDate]="minDate"-->
<!--           [maxDate]="maxDate"-->
<!--           placeholder="วว/ดด/ปปปป"-->
<!--           name="date"-->
<!--           ngbDatepicker-->
<!--           #dateRef=ngbDatepicker-->
<!--           container="body"-->
<!--           autocomplete="off"-->
<!--           [disabled]="readonly">-->
<!--    <div class="input-group-append">-->
<!--        <button class="btn btn-default"-->
<!--                [disabled]="readonly"-->
<!--                (click)="dateRef.toggle();widthAuto()"-->
<!--                type="button">-->
<!--            <div class="input-group-addon">-->
<!--                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"-->
<!--                     class="bi bi-calendar2-date" viewBox="0 0 16 16">-->
<!--                    <path d="M6.445 12.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>-->
<!--                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>-->
<!--                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>-->
<!--                </svg>-->
<!--            </div>-->
<!--        </button>-->
<!--    </div>-->
<!--</div>-->

<div class="dp-hidden position-absolute">
    <div class="input-group">
        <input name="datepicker"
               class="form-control"
               ngbDatepicker
               #datepicker="ngbDatepicker"
               [autoClose]="'outside'"
               (dateSelect)="onDateSelection($event)"
               [displayMonths]="2"
               [dayTemplate]="t"
               outsideDays="hidden"
               [startDate]="fromDate!"
               tabindex="-1"/>
        <ng-template #t let-date let-focused="focused">
            <span class="custom-day"
                  [class.focused]="focused"
                  [class.range]="isRange(date)"
                  [class.faded]="isHovered(date) || isInside(date)"
                  (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null">
                {{ date.day }}
            </span>
        </ng-template>
    </div>
</div>
<div class="input-group">
    <input #dpFromDate
           class="form-control"
           placeholder="วว/ดด/ปปปป - วว/ดด/ปปปป"
           name="dpFromDate"
           readonly
           [value]="displayValue()"
           (input)="fromDate = validateInput(fromDate, dpFromDate.value)"/>
    <div class="input-group-append">
        <button class="btn btn-default"
                [disabled]="readonly"
                (click)="datepicker.toggle();widthAuto()"
                type="button">
            <div class="input-group-addon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                     class="bi bi-calendar2-date" viewBox="0 0 16 16">
                    <path d="M6.445 12.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"/>
                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"/>
                    <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"/>
                </svg>
            </div>
        </button>
    </div>
</div>
