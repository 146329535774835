import {Component, forwardRef, Injector, Input, SimpleChanges, ViewChild} from '@angular/core';
import {Subject} from "rxjs";
import {ServicePotentialAttachmentDto} from "../../../../generated-model/model";
import {NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {PotentialService} from "../../services/potential.service";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
    selector: 'service-potential-attachment-autocomplete',
    templateUrl: './gt-select-service-potential-attachment-autocomplete.component.html',
    styleUrls: ['./gt-select-service-potential-attachment-autocomplete.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => GtSelectServicePotentialAttachmentAutocompleteComponent),
            multi: true
        }
    ],
})
export class GtSelectServicePotentialAttachmentAutocompleteComponent {
    term$ = new Subject<string>();
    attachmentDtos: ServicePotentialAttachmentDto[] = [];
    allAttachmentDtos: ServicePotentialAttachmentDto[] = [];
    @Input() attachmentFilter: ServicePotentialAttachmentDto[] = [];
    private _value: ServicePotentialAttachmentDto = undefined;
    @Input('id') id: string = 'htitle';
    @Input('name') name: string = 'htitle';
    @Input() disabled: boolean = false;
    @ViewChild(NgControl) innerNgControl: NgControl;
    @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
    @Input() removeValueFromInput: boolean = false
    private onTouchedCallback: () => void = () => {
    };
    private onChangeCallback: (_: ServicePotentialAttachmentDto) => void = () => {
    };
    compareWithId = (o1: any, o2: any): boolean => {
        return (o1 == o2) || ((!!o1 && !!o2) && (o1.htitleId == o2.htitleId))
    };

    constructor(public potentialService: PotentialService) {
        this.potentialService.searchAttachmentTerm().subscribe(e => {
            this.allAttachmentDtos = e;
            this.attachmentDtos = e
            Object.freeze(this.allAttachmentDtos);
        })
        this.term$.subscribe(term => {
            this.attachmentDtos = this.allAttachmentDtos.filter(f => (f.name.includes(term)))
        });
    }

    ngAfterViewInit() {

    }

    ngOnInit(): void {
    }

    get value(): ServicePotentialAttachmentDto {
        return this._value;
    }

    set value(value: ServicePotentialAttachmentDto) {
        if (value !== this._value) {
            this._value = value;
            this.onChangeCallback(value);
        }
        this.clearInput()
    }

    writeValue(value: ServicePotentialAttachmentDto) {
        if (value !== this._value) {
            this._value = value;
        }
    }

    registerOnChange(fn: any) {
        this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
        this.onTouchedCallback = fn;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.removeValueFromInput == true && this.attachmentFilter?.length > 0) {
            this.attachmentDtos = this.allAttachmentDtos.filter(e => !this.attachmentFilter.map(ee => ee.id).includes(e?.id));

        }
    }

    clearInput() {
        if (this.removeValueFromInput) {
            this.ngSelectComponent?.handleClearClick();
        }
    }
}
