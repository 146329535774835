import {Directive, Input} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from "@angular/forms";
import {RegOpeningHourItem} from "../../../generated-model/model";

@Directive({
  selector: '[timeClosingValid]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: TimeClosingValid,
      multi: true
    }
  ],
})
export class TimeClosingValid implements Validator {

  @Input({alias: 'timeClosingValid', required: true}) item: RegOpeningHourItem;

  constructor() {
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.item.open || this.item.timeOpening) {
      if (!control.value) {
        return {'required': true}
      } else if (this.item.timeOpening) {
        if (control.value <= this.item.timeOpening) {
          return {'timeClosingInvalid': true}
        }
      }
    }
    return undefined;
  }

}
