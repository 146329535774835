<form id="sapForm" class="form-validate" #form="ngForm" (submit)="submit()">
  <div class="modal-body overflow-hidden">
    <div class="my-3 row">
      <label class="col-xl-3  col-form-label">ชื่อคู่สัญญา</label>
      <div class="col-6 col-xl-6">
        <input id="vendorName" name="vendorName" type="text" class="form-control-plaintext" [ngModel]="data.vendorName" readonly>
      </div>
    </div>
    <div class="my-3 row">
      <label class="col-xl-3  col-form-label">เลข 13 หลัก</label>
      <div class="col-6 col-xl-6">
        <input id="taxId" name="taxId" type="text" class="form-control-plaintext" [ngModel]="data.taxId" readonly>
      </div>
    </div>
    <div class="row">
      <label class="col-xl-3 col-form-label">ประเภทคู่สัญญา</label>
      <div class="col-auto">
        <div class="form-check form-check-inline">
          <input class="form-check-input" [(ngModel)]="data.sapVendorType" type="radio" name="sapVendorType" id="TYPE_01" [value]="'TYPE_01'">
          <label class="form-check-label" for="TYPE_01">
            บุคคลธรรมดา
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" [(ngModel)]="data.sapVendorType" type="radio" name="sapVendorType" id="TYPE_02" [value]="'TYPE_02'">
          <label class="form-check-label" for="TYPE_02">
            นิติบุคคล
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" [(ngModel)]="data.sapVendorType" type="radio" name="sapVendorType" id="TYPE_03" [value]="'TYPE_03'">
          <label class="form-check-label" for="TYPE_03">
            ยกเว้นภาษี
          </label>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="offset-xl-3 col-xl-auto">
        <button [disabled]="loadingService.isLoading()" type="submit" class="btn btn-primary btn-custom me-2">
          บันทึก
        </button>
        <button type="button" [disabled]="loadingService.isLoading()" (click)="activeModal.dismiss()" class="btn btn-default2 btn-custom">
          ยกเลิก
        </button>
      </div>
    </div>
  </div>
</form>
