import {HttpContextToken, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {NavigationStart, Router} from "@angular/router";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {WarningDialogService} from "../dialog/warning-dialog/warning-dialog.service";

export const IGNORE_409_ERROR = new HttpContextToken<boolean>(() => false);

@Injectable()
export class Http409Interceptor implements HttpInterceptor {


  constructor(private router: Router, private warningDialogService: WarningDialogService) {

    this.router.events.subscribe(e => {
      if (e instanceof NavigationStart) {
      }
        })
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return req.context.get(IGNORE_409_ERROR) ? next.handle(req) : next.handle(req)
            .pipe(
                tap(event => {}, (err: HttpErrorResponse) => {
                    if (err.status == 409) {
                      this.warningDialogService.open({title: 'แจ้งเตือน', content: err?.error?.message || 'ข้อมูลที่ท่านต้องการบันทึกมีการเปลี่ยนแปลง'});
                    }
                })
            );
    }

}
