import {Injectable} from '@angular/core';
import {Observable} from "rxjs";
import {Applicant, ApplicantDto, Authority, OssUser} from "../../generated-model/model";
import {OidcSecurityService} from "angular-auth-oidc-client";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class OssUserService {

  constructor(public oidcSecurityService: OidcSecurityService, private http: HttpClient) {
  }

  public getUser(): Observable<OssUser> {
    return this.http.get<OssUser>(`${environment.serverUrl}/api/oss-user`);
  }

  public findApplicantById(id): Observable<ApplicantDto> {
    return this.http.get<ApplicantDto>(`${environment.serverUrl}/api/oss-user/${id}/applicant`);
  }

  public updateUser(): Observable<void> {
    return this.http.put<void>(`${environment.serverUrl}/api/oss-user`,{});
  }

  public hasAnyAuthority(authorityList: Authority[], authorities: string[] = []): boolean {
    // debugger
    if (authorities) {
      return authorities.some(e => authorityList.some(ex => e == ex));
    } else {
      return false;
    }
  }

  public hasAuthority(authorityList: Authority[], authorities: string[] = []): boolean {
    if (authorities) {
      let hasAuthority = authorities.some(e => authorityList.every(ex => e == ex));
      return hasAuthority;
    } else {
      return false;
    }
  }
}
