import {Component, forwardRef, Injector, Input, SimpleChanges, ViewChild} from '@angular/core';
import {NG_VALUE_ACCESSOR, NgControl} from "@angular/forms";
import {PotentialService} from "../../services/potential.service";
import {ServiceMasterDto} from "../../../../generated-model/model";
import {Subject} from "rxjs";
import {NgSelectComponent} from "@ng-select/ng-select";

@Component({
  selector: 'service-master-autocomplete',
  templateUrl: './gt-select-service-master-autocomplete.component.html',
  styleUrls: ['./gt-select-service-master-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GtSelectServiceMasterAutocompleteComponent),
      multi: true
    }
  ],
})
export class GtSelectServiceMasterAutocompleteComponent {
  term$ = new Subject<string>();
  services: ServiceMasterDto[] = [];
  allServices: ServiceMasterDto[] = [];
  @Input() removeValueFromInput: boolean = false
  private _value: ServiceMasterDto = undefined;
  @Input('id') id: string = 'htitle';
  @Input('name') name: string = 'htitle';
  @Input() disabled: boolean = false;
  @ViewChild(NgControl) innerNgControl: NgControl;
  @ViewChild(NgSelectComponent) ngSelectComponent: NgSelectComponent;
  private onTouchedCallback: () => void = () => {
  };
  private onChangeCallback: (_: ServiceMasterDto) => void = () => {
  };
  compareWithId = (o1: any, o2: any): boolean => {
    return (o1 == o2) || ((!!o1 && !!o2) && (o1.serviceCode == o2.serviceCode))
  };

  constructor(public potentialService: PotentialService, private inj: Injector) {
    this.potentialService.searchServiceTerm().subscribe(e => {
      this.allServices = e;
      this.services = e;
      Object.freeze(this.allServices);
    })
    this.term$.subscribe(term => {
      this.services = this.allServices?.filter(f => (
        f.serviceName.toUpperCase()?.includes(term.toUpperCase()) ||
        f.serviceName.toLowerCase()?.includes(term.toLowerCase()) ||
        f.serviceCode.toUpperCase()?.startsWith(term.toUpperCase()) ||
        f.serviceCode.toLowerCase()?.startsWith(term.toLowerCase())))
    });
  }

  ngAfterViewInit() {
    const outerControl = this.inj.get(NgControl).control;
    const prevMarkAsTouched = outerControl.markAsTouched;
    outerControl.markAsTouched = (...args: any) => {
      this.innerNgControl.control.markAsTouched();
      prevMarkAsTouched.bind(outerControl)(...args);
    };
    const prevMarkAsDirty = outerControl.markAsDirty;
    outerControl.markAsDirty = (...args: any) => {
      this.innerNgControl.control.markAsDirty();
      prevMarkAsDirty.bind(outerControl)(...args);
    };
    const prevMarkAsPristine = outerControl.markAsPristine;
    outerControl.markAsPristine = (...args: any) => {
      this.innerNgControl.control.markAsPristine();
      prevMarkAsPristine.bind(outerControl)(...args);
    };
    const prevMarkAsUntouched = outerControl.markAsUntouched;
    outerControl.markAsUntouched = (...args: any) => {
      this.innerNgControl.control.markAsUntouched();
      prevMarkAsUntouched.bind(outerControl)(...args);
    };
  }

  ngOnInit(): void {
  }

  get value(): ServiceMasterDto {
    return this._value;
  }

  set value(value: ServiceMasterDto) {
    if (value !== this._value) {
      this._value = value;
      this.onChangeCallback(value);
    }
    this.clearInput()
  }

  writeValue(value: ServiceMasterDto) {
    if (value !== this._value) {
      this._value = value;
    }
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  clearInput() {
    if (this.removeValueFromInput) {
      this.ngSelectComponent?.handleClearClick();
    }
  }
}
