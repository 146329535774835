<app-loading></app-loading>
<div class="container py-5">
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-xl-6">
          <input type="text" class="form-control" placeholder="uuid" [(ngModel)]="uuid">
        </div>
        <div class="col-auto">
          <button type="button" class="btn btn-secondary" (click)="search()">ค้นหา</button>
        </div>
      </div>
      <ng-container *ngIf="fileUpload">
        <div class="row mb-3">
          <div class="col table-responsive">
            <table class="table table-bordered">
              <tbody>
              <tr class="table-info">
                <th>id</th>
                <td>{{ fileUpload.id }}</td>
              </tr>
              <tr class="table-info">
                <th>originalFileName</th>
                <td>{{ fileUpload.originalFileName }}</td>
              </tr>
              <tr class="table-info">
                <th>ref</th>
                <td>{{ fileUpload.uuid }}</td>
              </tr>
              <tr class="table-info">
                <th>fileName</th>
                <td>{{ fileUpload.fileName }}</td>
              </tr>
              <tr class="table-danger">
                <th>filePath</th>
                <td>{{ fileUpload.path }}</td>
              </tr>
              <tr>
                <th>contentType</th>
                <td>{{ fileUpload.mime }}</td>
              </tr>
              <tr>
                <th>createdDate</th>
                <td>{{ fileUpload.createdDate }}</td>
              </tr>
              <tr>
                <th>createdId</th>
                <td>{{ fileUpload.createdId }}</td>
              </tr>
              <tr>
                <th>lastUpdatedDate</th>
                <td>{{ fileUpload.lastUpdatedDate }}</td>
              </tr>
              <tr>
                <th>lastUpdatedId</th>
                <td>{{ fileUpload.lastUpdatedId }}</td>
              </tr>
              <tr>
                <th>size</th>
                <td>{{ fileUpload.size }}</td>
              </tr>
              <tr>
                <th>updateDate</th>
                <td>{{ fileUpload.lastUpdatedDate }}</td>
              </tr>
              <tr>
                <th>upload</th>
                <td>{{ fileUpload.upload }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <app-file-upload [id]="'newFile'" [name]="'newFile'" [(ngModel)]="newFile" [fileType]="['pdf']" size="sm-inline"></app-file-upload>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-4">
            <button type="button" (click)="patch()" class="btn btn-danger">ซ่อมไฟล์นี้</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

</div>
