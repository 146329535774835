import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AppSecurityModule} from "../security/app-security.module";
import {NgbModule, NgbPaginationModule} from "@ng-bootstrap/ng-bootstrap";
import {SharedCommonModule} from './commons/shared-common.module';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {GtProvinceAutocompleteComponent} from "./components/gt-province-autocomplete/gt-province-autocomplete.component";
import {NgSelectModule} from "@ng-select/ng-select";
import {GtHtypeAutocompleteComponent} from "./components/gt-htype-autocomplete/gt-htype-autocomplete.component";
import {GtHsubtypeAutocompleteComponent} from "./components/gt-hsubtype-autocomplete/gt-hsubtype-autocomplete.component";
import {ClipboardModule} from "ngx-clipboard";
import {ModelEmptyValueClassDirective} from './model-empty-value-class.directive';
import {GtHospitalAutocompleteComponent} from "./components/gt-hospital-autocomplete/gt-hospital-autocomplete.component";
import {HospitalModalComponent} from "./components/modal/hospital-modal/hospital-modal.component";
import {FileConfigItemModalComponent} from "./components/modal/file-config-item-modal/file-config-item-modal.component";
import {HospitalModalService} from "./components/modal/hospital-modal/hospital-modal.service";
import {BankAutocompleteComponent} from "./components/bank-autocomplete/bank-autocomplete.component";
import {GtHospitalListAutocompleteComponent} from "./components/gt-hospital-list-autocomplete/gt-hospital-list-autocomplete.component";
import {HospitalListExcludeSelectedFilterPipe} from "./components/gt-hospital-list-autocomplete/select-hospital-list-filter.pipe";
import {DigitOnlyModule} from "@uiowa/digit-only";
import {AuthorizeDescriptionPipe} from "./authorizeDescription.pipe";
import {CustomErrorComponent} from "./commons/custom-error.component";
import {GtCppMPersonAutocompleteComponent} from "./components/gt-cpp-m-person-autocomplete/gt-cpp-m-person-autocomplete.component";
import {GtSubdistrictAutocompleteComponent} from "./components/gt-subdistrict-autocomplete/gt-subdistrict-autocomplete.component";
import {SharedValidatorsModule} from "./validators/shared-validators.module";
import {ThaidModalComponent} from "./components/modal/thaid-modal/thaid-modal.component";
import {ThaidModalService} from "./components/modal/thaid-modal/thaid-modal.service";
import {EmailNotifyDialogComponent} from "./commons/dialog/email-notify-dialog/email-notify-dialog.component";
import {MobileSettingDialogComponent} from "./commons/dialog/mobile-setting-dialog/mobile-setting-dialog.component";
import {ConsiderModalComponent} from "./components/modal/consider-modal/consider-modal.component";
import {GtHtitleAutocompleteComponent} from "./components/gt-htitle-autocomplete/gt-htitle-autocomplete.component";
import {CommitteeInformationPipe} from "./committeeInformation.pipe";
import {SelectLicenseHospitalModalComponent} from "./components/modal/select-license-hospital-modal/select-license-hospital-modal.component";
import {SearchHospitalModalComponent} from "./components/modal/search-hospital-modal/search-hospital-modal.component";
import {RevertStatusModalComponent} from "./components/modal/revert-status-modal/revert-status-modal.component";
import {ConsiderHistoryModalComponent} from "../pages/shared/components/consider-history-modal/consider-history-modal.component";
import {GtUserHospitalAutocompleteComponent} from "./components/gt-user-hospital-autocomplete/gt-user-hospital-autocomplete.component";
import {SelectLicenseDrugstoreModalComponent} from "./components/modal/select-license-drugstore-modal/select-license-drugstore-modal.component";
import {
  GtSelectServicePotentialAttachmentAutocompleteComponent
} from './components/gt-select-service-potential-attachment-autocomplete/gt-select-service-potential-attachment-autocomplete.component';
import {GtPotentialCppMPersonAutocompleteComponent} from "./components/gt-potential-cpp-m-person-autocomplete/gt-potential-cpp-m-person-autocomplete.component";
import {PotentialConsiderHistoryModalComponent} from "../pages/shared/components/potential-consider-history-modal/potential-consider-history-modal.component";
import {SelectManualLicenseHospitalModalComponent} from "./components/modal/select-manual-license-hospital-modal/select-manual-license-hospital-modal.component";
import {GtSelectServiceMasterAutocompleteComponent} from "./components/gt-select-service-potential-service-autocomplete/gt-select-service-master-autocomplete.component";


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppSecurityModule,
    NgbModule,
    NgbPaginationModule,
    // NgbDropdownModule,
    NgSelectModule,
    ClipboardModule,
    DigitOnlyModule,
    SharedCommonModule,
    CustomErrorComponent,
    SharedValidatorsModule
  ],
  declarations: [
    GtProvinceAutocompleteComponent,
    GtHtypeAutocompleteComponent,
    GtHsubtypeAutocompleteComponent,
    GtHospitalAutocompleteComponent,
    GtUserHospitalAutocompleteComponent,
    ModelEmptyValueClassDirective,
    HospitalModalComponent,
    FileConfigItemModalComponent,
    BankAutocompleteComponent,
    GtHospitalListAutocompleteComponent,
    HospitalListExcludeSelectedFilterPipe,
    AuthorizeDescriptionPipe,
    GtCppMPersonAutocompleteComponent,
    ThaidModalComponent,
    GtSubdistrictAutocompleteComponent,
    EmailNotifyDialogComponent,
    MobileSettingDialogComponent,
    ConsiderModalComponent,
    GtHtitleAutocompleteComponent,
    CommitteeInformationPipe,
    SelectLicenseHospitalModalComponent,
    SelectLicenseDrugstoreModalComponent,
    SelectManualLicenseHospitalModalComponent,
    SearchHospitalModalComponent,
    RevertStatusModalComponent,
    ConsiderHistoryModalComponent,
    PotentialConsiderHistoryModalComponent,
    GtSelectServiceMasterAutocompleteComponent,
    GtSelectServicePotentialAttachmentAutocompleteComponent,
    GtPotentialCppMPersonAutocompleteComponent
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    AppSecurityModule,
    NgbModule,
    NgbPaginationModule,
    NgSelectModule,
    ClipboardModule,
    SharedCommonModule,
    GtProvinceAutocompleteComponent,
    GtHtypeAutocompleteComponent,
    GtHsubtypeAutocompleteComponent,
    GtHospitalAutocompleteComponent,
    GtUserHospitalAutocompleteComponent,
    ModelEmptyValueClassDirective,
    HospitalModalComponent,
    FileConfigItemModalComponent,
    BankAutocompleteComponent,
    GtHospitalListAutocompleteComponent,
    DigitOnlyModule,
    AuthorizeDescriptionPipe,
    GtCppMPersonAutocompleteComponent,
    ThaidModalComponent,
    GtSubdistrictAutocompleteComponent,
    EmailNotifyDialogComponent,
    MobileSettingDialogComponent,
    ConsiderModalComponent,
    CustomErrorComponent,
    GtHtitleAutocompleteComponent,
    CommitteeInformationPipe,
    SelectLicenseHospitalModalComponent,
    SelectLicenseDrugstoreModalComponent,
    SelectManualLicenseHospitalModalComponent,
    SearchHospitalModalComponent,
    RevertStatusModalComponent,
    ConsiderHistoryModalComponent,
    PotentialConsiderHistoryModalComponent,
    GtSelectServiceMasterAutocompleteComponent,
    GtSelectServicePotentialAttachmentAutocompleteComponent,
    GtPotentialCppMPersonAutocompleteComponent
  ],
  providers: [
    HospitalModalService,
    ThaidModalService
  ]
})
export class SharedModule {
}
